import { Button } from '@hh.ru/magritte-ui';
import { MagnifierOutlinedHeartOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    notSaved: 'search.vacancy.savedSearch.button.notSaved',
    saved: 'search.vacancy.savedSearch.button.saved',
};

interface SavedSearchButtonProps {
    shortView?: boolean;
    isAlreadySaved?: boolean;
    onClick: () => void;
}

const SavedSearchButton: TranslatedComponent<SavedSearchButtonProps> = ({
    trls,
    shortView,
    isAlreadySaved = false,
    onClick,
}) => {
    const dataQa = isAlreadySaved ? 'vacancy-saved-search-created' : 'vacancy-saved-search-create';
    const buttonText = trls[isAlreadySaved ? TrlKeys.saved : TrlKeys.notSaved];

    return (
        <Button
            mode="secondary"
            style="neutral"
            data-qa={dataQa}
            icon={<MagnifierOutlinedHeartOutlinedSize24 />}
            aria-label={buttonText}
            hideLabel={shortView}
            onClick={onClick}
        >
            {buttonText}
        </Button>
    );
};

export default translation(SavedSearchButton);
