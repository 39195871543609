import { useCallback } from 'react';

import { useChatik } from '@hh.ru/chatik-integration';

type UseOpenChatCommandResult = (urlSearchParams: URLSearchParams) => void;

const CHAT_ID_SEARCH_PARAM_KEY = 'chat_id';
const CHAT_ALIAS_SEARCH_PARAM_KEY = 'chat_alias';
const VALID_ALIASES = ['support', 'kingdom_of_vacancy', 'notification', 'hr_assistant'];

const getChatId = (urlSearchParams: URLSearchParams) => {
    const chatAlias = urlSearchParams.get(CHAT_ALIAS_SEARCH_PARAM_KEY);

    if (chatAlias && VALID_ALIASES.includes(chatAlias)) {
        return chatAlias;
    }

    const chatId = Number(urlSearchParams.get(CHAT_ID_SEARCH_PARAM_KEY));

    return !chatId || isNaN(chatId) || chatId < 1 ? undefined : chatId;
};

export const useOpenChatCommand = (): UseOpenChatCommandResult => {
    const { openChatik } = useChatik();

    return useCallback(
        (urlSearchParams: URLSearchParams) => {
            const chatId = getChatId(urlSearchParams);
            const hhtmFromLabel = urlSearchParams.get('hhtmFromLabel') || undefined;
            const text = urlSearchParams.get('text') || undefined;

            const botParams = {
                scenarioState: urlSearchParams.get('scenario_state') || undefined,
                scenarioTrigger: urlSearchParams.get('scenario_trigger') || undefined,
                scenarioParams: Object.fromEntries(urlSearchParams),
            };

            openChatik({
                chatId,
                message: text
                    ? {
                          text,
                          metadata: {
                              deepLink: true,
                          },
                      }
                    : undefined,
                view: 'medium',
                hhtmFromLabel,
                botParams,
            });
        },
        [openChatik]
    );
};
