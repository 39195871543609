import { SyntheticEvent } from 'react';

import { Text, VSpacing, Banner, Title } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import HelpModalButtonLinks from 'src/components/SupernovaMainMenu/HelpModal/HelpModalButtonLinks';
import HelpModalMessengers from 'src/components/SupernovaMainMenu/HelpModal/HelpModalMessengers';
import HelpModalPersonalManager from 'src/components/SupernovaMainMenu/HelpModal/HelpModalPersonalManager';
import SupportEmployerId from 'src/components/Support/SupportEmployerId/SupportEmployerId';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { NavItems } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

const TrlKeys = {
    vacationTitle: 'supernova.modal.help.vacation.title',
    vacationText: 'supernova.modal.help.vacation.text',
};

const HelpModalContent: TranslatedComponent<{
    subItems?: NavItems;
    handleCloseModal: (event?: SyntheticEvent) => void;
    onClose?: () => void;
}> = ({ trls, subItems, handleCloseModal, onClose }) => {
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;
    const vacation = useSelector(({ vacation }) => vacation);
    const isZarplata = useIsZarplataPlatform();
    const isPersonalManagerAssigned = useSelector(({ personalManagerMenu }) => personalManagerMenu.isAssigned);

    const hasMessengersEnabled = subItems?.some((item) => item.name === 'messengers');

    const personalManagerItem = subItems?.find((item) => item.name === 'personalManager');
    const buttonLinksItem = subItems?.find((item) => item.name === 'helpButtonLinks');

    return (
        <div>
            <SupportEmployerId />
            {vacation && !isZarplata && (
                <>
                    {isEmployer && <VSpacing default={24} />}
                    <Banner
                        style="warning-secondary"
                        title={
                            <Title Element="h5" size="small">
                                {trls[TrlKeys.vacationTitle]}
                            </Title>
                        }
                        content={<Text typography="paragraph-2-regular">{trls[TrlKeys.vacationText]}</Text>}
                        stretched
                        showClose={false}
                    />
                    {!isEmployer && <VSpacing default={12} />}
                </>
            )}
            <HelpModalButtonLinks item={buttonLinksItem} handleCloseModal={handleCloseModal} onClose={onClose} />
            {isEmployer && isPersonalManagerAssigned && personalManagerItem && (
                <>
                    <VSpacing default={12} />
                    <HelpModalPersonalManager item={personalManagerItem} />
                </>
            )}
            {hasMessengersEnabled && (
                <>
                    <VSpacing default={24} />
                    <HelpModalMessengers />
                </>
            )}
        </div>
    );
};

export default translation(HelpModalContent);
