import { ApplicantResumeEditorStore } from 'src/models/applicant/resume/editor/store/slice';
import { Reducer } from 'src/models/applicant/resume/lib/slice/types';

import { EditorLoading, EditorLoadingType } from 'src/models/applicant/resume/editor/store/loading/types';

export const LOADING_INITIAL_STATE: EditorLoading = {
    preload: false,
    saving: false,
    deleting: false,
    renewal: false,
};

export const toggleResumeEditorLoadingReducer: Reducer<
    ApplicantResumeEditorStore,
    { type: EditorLoadingType; loading: boolean }
> = (store, { payload }) => {
    const { type, loading } = payload;

    return {
        ...store,
        loading: { ...store.loading, [type]: loading },
    };
};
