import { createSlice } from 'src/models/applicant/resume/lib/slice';
import { ReducerAction } from 'src/models/applicant/resume/lib/slice/types';
import { VerifiedSkill } from 'src/models/resume/resumeVerifiedSkills.types';

import { LevelBySkillId } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/types';

interface Store {
    opened: boolean;
    alertVisible: boolean;
    infoModalVisible: boolean;
    skills: VerifiedSkill[];
    levels: LevelBySkillId;
    originalLevels: LevelBySkillId;
}
const initialState: Store = {
    opened: false,
    alertVisible: false,
    infoModalVisible: false,
    skills: [],
    levels: {},
    originalLevels: {},
};

const applicantSkillsLevelsEditorSlice = createSlice({
    initialState,
    reducers: {
        setSkillList: (state, { payload }: ReducerAction<VerifiedSkill[]>) => ({ ...state, skills: payload }),

        setSkillLevels: (state, { payload }: ReducerAction<LevelBySkillId>) => ({ ...state, levels: payload }),
        setSkillOriginalLevels: (state, { payload }: ReducerAction<LevelBySkillId>) => ({
            ...state,
            originalLevels: payload,
        }),

        toggleSkillsLevelsAlert: (state, { payload }: ReducerAction<boolean>) => ({ ...state, alertVisible: payload }),
        toggleSkillsLevelsEditor: (state, { payload }: ReducerAction<boolean>) => ({ ...state, opened: payload }),
        toggleSkillsLevelsInfoModal: (state, { payload }: ReducerAction<boolean>) => ({
            ...state,
            infoModalVisible: payload,
        }),

        resetSkillsLevelsEditor: () => initialState,
    },
});

export const {
    setSkillList,
    setSkillLevels,
    setSkillOriginalLevels,
    resetSkillsLevelsEditor,
    toggleSkillsLevelsAlert,
    toggleSkillsLevelsEditor,
    toggleSkillsLevelsInfoModal,
} = applicantSkillsLevelsEditorSlice.actions;

export const applicantSkillsLevelsEditor = applicantSkillsLevelsEditorSlice.reducer;
