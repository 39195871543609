import { useElementShown } from '@hh.ru/analytics-js';
import employerStatusChangedToApprovedElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/verification/employer_status_changed_to_approved_element_shown';
import employerStatusChangedToNotVerifiedElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/verification/employer_status_changed_to_not_verified_element_shown';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.EmployerVerified]: {
        description: 'employer.verification.notification.verified',
    },
    [ComplexUserNotificationsTemplateKey.EmployerNotVerified]: {
        description: 'employer.verification.notification.notVerified',
    },
};

const notificationPropsByTemplateKey = {
    [ComplexUserNotificationsTemplateKey.EmployerVerified]: {
        iconKind: SupernovaUserNotificationsIconKind.Verified,
        dataQa: DataQaInvariants.Verified,
        dataQaText: 'notification-content',
    },
    [ComplexUserNotificationsTemplateKey.EmployerNotVerified]: {
        iconKind: SupernovaUserNotificationsIconKind.Exclamation,
        dataQa: DataQaInvariants.Exclamation,
        dataQaText: 'notification-content',
    },
};

const isEmployerVerificationNotification = (
    templateKey: ComplexUserNotificationsTemplateKey
): templateKey is keyof typeof TrlKeys => Object.keys(TrlKeys).includes(templateKey);

const EmployerVerification: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const templateKey = notification.templateKey;

    const notificationShownRef = useElementShown(
        templateKey === ComplexUserNotificationsTemplateKey.EmployerVerified
            ? employerStatusChangedToApprovedElementShown
            : employerStatusChangedToNotVerifiedElementShown
    );

    if (!isEmployerVerificationNotification(templateKey)) {
        return null;
    }

    const notificationProps = notificationPropsByTemplateKey[templateKey];
    return (
        <Notification {...notificationProps} {...notification}>
            <span ref={notificationShownRef}>{trls[TrlKeys[templateKey].description]}</span>
        </Notification>
    );
};

export default translation(EmployerVerification);
