import { useEffect, useRef } from 'react';

import notVerifiedStatusElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/verification/not_verified_status_element_shown';
import removeRestrictionsButtonClick, {
    ViewLabel,
} from '@hh.ru/analytics-js-events/build/xhh/employer/verification/remove_restrictions_button_click';
import { Button } from '@hh.ru/magritte-ui';
import { ExclamationCircleFilledSize16, VerifiedBadgeFilledSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import {
    EmployerVerificationState,
    TooltipType,
    useEmployerVerificationState,
} from 'src/components/EmployerVerification/useEmployerVerificationState';
import translation from 'src/components/translation';

import EmployerVerificationIcon from 'src/components/EmployerVerification/VerificationIcon/EmployerVerificationIcon';

interface VerificationIconProps {
    tooltipType: TooltipType;
    vacancyId?: string;
    draftId?: string;
    isPreview?: boolean;
}

const TrlKeys = {
    removeRestrictions: 'employer.notVerifiedBanner.removeRestrictions',
    content: {
        vacancy: {
            newWithoutDocs: 'employer.notVerifiedIcon.vacancy.newWithoutDocs',
            newWithDocs: 'employer.notVerifiedIcon.vacancy.newWithDocs',
            notVerifiedWithoutDocs: 'employer.notVerifiedIcon.vacancy.notVerifiedWithoutDocs',
            notVerifiedWithDocs: 'employer.notVerifiedIcon.vacancy.notVerifiedWithDocs',
            verificationNewWithoutDocs: 'employer.verification.notVerifiedIcon.vacancy.newWithoutDocs',
            verificationNewWithDocs: 'employer.verification.notVerifiedIcon.vacancy.newWithDocs',
            notVerified: 'employer.notVerifiedIcon.vacancy.notVerifiedWithDocs',
        },
        profile: {
            newWithoutDocs: 'employer.notVerifiedIcon.profile.newWithoutDocs',
            newWithDocs: 'employer.notVerifiedIcon.profile.newWithDocs',
            notVerifiedWithoutDocs: 'employer.notVerifiedIcon.profile.notVerifiedWithoutDocs',
            notVerifiedWithDocs: 'employer.notVerifiedIcon.profile.notVerifiedWithDocs',
            verificationNewWithoutDocs: 'employer.verification.notVerifiedIcon.profile.newWithoutDocs',
            verificationNewWithDocs: 'employer.verification.notVerifiedIcon.profile.newWithDocs',
            notVerified: 'employer.notVerifiedIcon.profile.notVerifiedWithDocs',
        },
    },
    verifiedEmployerTooltipContent: 'employer.verifiedIcon.tooltip.content',
};

const VerificationIcon: TranslatedComponent<VerificationIconProps> = ({
    trls,
    tooltipType,
    vacancyId,
    draftId,
    isPreview,
}) => {
    const { isUnverifiedHhRuEmployer, employerVerificationState } = useEmployerVerificationState();
    const notVerifiedEmployerIconRef = useRef<HTMLDivElement>(null);
    const isApproved = employerVerificationState === EmployerVerificationState.Approved;
    const newWithoutDocs = employerVerificationState === EmployerVerificationState.NewWithoutDocs;
    const newWithDocs = employerVerificationState === EmployerVerificationState.NewWithDocs;
    const notVerifiedWithoutDocs = employerVerificationState === EmployerVerificationState.NotVerifiedWithoutDocs;
    const notVerifiedWithDocs = employerVerificationState === EmployerVerificationState.NotVerifiedWithDocs;

    const analyticsParams = {
        vacancyId,
        draftId,
        tooltipType,
        viewLabel: (isPreview ? 'vacancy_preview' : 'vacancy_view') as ViewLabel,
    };

    useEffect(() => {
        if (isUnverifiedHhRuEmployer && !isApproved && notVerifiedEmployerIconRef.current) {
            const { stopSpying } = notVerifiedStatusElementShown(notVerifiedEmployerIconRef.current, analyticsParams);
            return () => {
                stopSpying();
            };
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shouldShowTooltip = !isPreview;

    if (isUnverifiedHhRuEmployer && !isApproved) {
        const withActionProps = newWithoutDocs || notVerifiedWithoutDocs;
        return (
            <div ref={notVerifiedEmployerIconRef}>
                <EmployerVerificationIcon
                    icon={<ExclamationCircleFilledSize16 initialColor="warning" />}
                    tipContent={
                        <>
                            {newWithoutDocs && trls[TrlKeys.content[tooltipType].verificationNewWithoutDocs]}
                            {newWithDocs && trls[TrlKeys.content[tooltipType].newWithDocs]}
                            {notVerifiedWithoutDocs && trls[TrlKeys.content[tooltipType].verificationNewWithoutDocs]}
                            {notVerifiedWithDocs && trls[TrlKeys.content[tooltipType].notVerified]}
                        </>
                    }
                    actionButton={
                        <Button
                            Element={SPALink}
                            to="/employer/documents"
                            target={tooltipType === TooltipType.Profile ? '_blank' : ''}
                            mode="primary"
                            style="accent"
                            onClick={() => removeRestrictionsButtonClick(analyticsParams)}
                        >
                            {trls[TrlKeys.removeRestrictions]}
                        </Button>
                    }
                    placement={tooltipType === TooltipType.Profile ? 'left-center' : 'bottom-center'}
                    analyticsParams={analyticsParams}
                    tooltipActionProps={
                        withActionProps && analyticsParams
                            ? {
                                  buttons: () => (
                                      <>
                                          <Button
                                              mode="secondary"
                                              style="contrast"
                                              size="small"
                                              Element={SPALink}
                                              to="/employer/documents"
                                              target="_blank"
                                              onClick={() => removeRestrictionsButtonClick(analyticsParams)}
                                          >
                                              {trls[TrlKeys.removeRestrictions]}
                                          </Button>
                                      </>
                                  ),
                                  showClose: false,
                              }
                            : {
                                  buttons: undefined,
                                  showClose: false,
                              }
                    }
                    shouldShowTooltip={shouldShowTooltip}
                />
            </div>
        );
    }

    if (isApproved) {
        return (
            <EmployerVerificationIcon
                icon={<VerifiedBadgeFilledSize16 initialColor="accent" />}
                tipContent={trls[TrlKeys.verifiedEmployerTooltipContent]}
                placement="bottom-center"
                shouldShowTooltip={shouldShowTooltip}
            />
        );
    }

    return null;
};

export default translation(VerificationIcon);
