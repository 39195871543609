import { Entries } from 'type-fest';

import { rangeKeys } from 'src/components/Search/Common/Filters/utils';
import { Gender } from 'src/models/resume/resumeCommon.types';
import { ExpPeriodOption, LogicOption, PosOption } from 'src/models/search/resume/queries';
import { ClusterKey } from 'src/types/search/common/clusters';
import { CriteriaKey } from 'src/types/search/common/criteria';
import { LabelOption } from 'src/types/search/resume/criteria';

import getSharedSearchParams, {
    SharedSearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/shared';
import { GetSearchParamsArgs } from 'src/components/Search/Common/Filters/utils/getSearchParams/types';

export type ResumeSearchParams = SharedSearchParams & {
    [CriteriaKey.AgeFrom]: string | number | null;
    [CriteriaKey.AgeTo]: string | number | null;
    [CriteriaKey.SalaryFrom]: string | number | null;
    [CriteriaKey.SalaryTo]: string | number | null;
    [CriteriaKey.ExpCompanySize]?: string[];
    [CriteriaKey.Gender]: Gender;
    [CriteriaKey.Logic]: LogicOption;
    [CriteriaKey.Pos]: PosOption;
    [CriteriaKey.ExpPeriod]: ExpPeriodOption;
    [CriteriaKey.Label]: LabelOption[];
};

const getResumeSearchParams = (args: GetSearchParamsArgs): ResumeSearchParams => {
    const searchParams = getSharedSearchParams(args) as ResumeSearchParams;
    const { searchCatalogRedirect, searchClusters } = args;

    // range values
    [ClusterKey.Age, ClusterKey.Salary].forEach((filter) => {
        if (!searchClusters[filter]) {
            return;
        }
        const { selectedValues } = searchClusters[filter];
        rangeKeys.forEach((key) => {
            const value = selectedValues[key];
            if (value) {
                searchParams[`${filter}_${key}`] = value;
            }
        });
        if (selectedValues.onlyWith) {
            const onlyWithStr = filter === ClusterKey.Age ? LabelOption.OnlyWithAge : LabelOption.OnlyWithSalary;
            searchParams[CriteriaKey.Label] = [...(searchParams[CriteriaKey.Label] || []), onlyWithStr];
        }
    });

    if (searchClusters[ClusterKey.Gender]) {
        const { selectedValues } = searchClusters[ClusterKey.Gender];
        searchParams[CriteriaKey.Gender] = selectedValues.value;
        if (selectedValues.onlyWith) {
            searchParams[CriteriaKey.Label] = [...(searchParams[CriteriaKey.Label] || []), LabelOption.OnlyWithGender];
        }
    }

    const etcCluster = searchClusters[ClusterKey.Etc];
    if (etcCluster) {
        const { selectedValues } = etcCluster;
        const entries = Object.entries(selectedValues) as Entries<typeof selectedValues>;
        entries.forEach(([key, value]) => {
            searchParams[key] = value;
        });
    }

    // if we have metro and district, but don't have one region
    // need delete metro and district
    if (searchParams[CriteriaKey.Area]?.length !== 1) {
        searchParams[CriteriaKey.Metro] = [];
        searchParams[CriteriaKey.District] = [];
    }

    if (searchCatalogRedirect) {
        searchParams[CriteriaKey.Logic] = LogicOption.Normal;
        searchParams[CriteriaKey.Pos] = PosOption.FullText;
        searchParams[CriteriaKey.ExpPeriod] = ExpPeriodOption.AllTime;
    }

    return searchParams;
};

export default getResumeSearchParams;
