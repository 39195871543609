import { AxiosRequestConfig } from 'axios';

import axios from 'HHC/Axios';

const markAsViewed = (name: string, data?: unknown, config?: AxiosRequestConfig): void => {
    axios.post('/notices/mark_as_viewed', { name, ...(data ? { data } : {}) }, { ...(config ?? {}) }).catch((error) => {
        if (axios.isCancel(error)) {
            return;
        }

        console.error(error);
    });
};

export enum MarkAsViewedEvent {
    None = 'NONE',
    OnShow = 'ON_SHOW',
    OnHide = 'ON_HIDE',
}

export default { markAsViewed };
