import { useCallback, useMemo, useRef } from 'react';

import {
    Cell,
    CellProps,
    CellText,
    Drop,
    Link,
    Radio,
    VSpacingContainer,
    useBreakpoint,
    StaticDataFetcherItem,
    useStaticDataProvider,
} from '@hh.ru/magritte-ui';
import { Select } from '@hh.ru/magritte-ui-select';
import { ChevronDownOutlinedSize16, ChevronUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';
import { ExpCompanySizeOption, SearchQueries, LogicOption, ExpPeriodOption } from 'src/models/search/resume/queries';
import { CriteriaKey } from 'src/types/search/common/criteria';

interface TranslationKeys {
    title: Record<string, string>;
    option: {
        [key: string]: Record<string, string>;
    };
    companySizeCount: Record<string, string>;
}

const TrlKeys: TranslationKeys = {
    title: {
        [CriteriaKey.Logic]: 'search.resume.query.logic',
        [CriteriaKey.ExpPeriod]: 'search.resume.query.exp_period',
        [CriteriaKey.ExpCompanySize]: 'search.resume.query.exp_company_size',
    },
    option: {
        [CriteriaKey.Logic]: {
            [LogicOption.Normal]: 'search.resume.query.logic.normal',
            [LogicOption.Any]: 'search.resume.query.logic.any',
            [LogicOption.Phrase]: 'search.resume.query.logic.phrase',
            [LogicOption.Except]: 'search.resume.query.logic.except',
        },
        [CriteriaKey.ExpPeriod]: {
            [ExpPeriodOption.AllTime]: 'search.resume.query.exp_period.all_time',
            [ExpPeriodOption.LastYear]: 'search.resume.query.exp_period.last_year',
            [ExpPeriodOption.LastThreeYears]: 'search.resume.query.exp_period.last_three_years',
            [ExpPeriodOption.LastSixYears]: 'search.resume.query.exp_period.last_six_years',
        },
        [CriteriaKey.ExpCompanySize]: {
            [ExpCompanySizeOption.Any]: 'search.resume.query.exp_company_size.any',
            [ExpCompanySizeOption.Small]: 'search.resume.query.exp_company_size.small',
            [ExpCompanySizeOption.Medium]: 'search.resume.query.exp_company_size.medium',
            [ExpCompanySizeOption.Large]: 'search.resume.query.exp_company_size.large',
        },
    },
    companySizeCount: {
        [ExpCompanySizeOption.Any]: 'search.resume.query.exp_company_size.small.count',
        [ExpCompanySizeOption.Small]: 'search.resume.query.exp_company_size.small.count',
        [ExpCompanySizeOption.Medium]: 'search.resume.query.exp_company_size.medium.count',
        [ExpCompanySizeOption.Large]: 'search.resume.query.exp_company_size.large.count',
    },
};

interface ConditionSelectProps {
    name: typeof CriteriaKey.Logic | typeof CriteriaKey.ExpPeriod | typeof CriteriaKey.ExpCompanySize;
    query: SearchQueries;
    setQuery: (value: SearchQueries) => void;
    isHidden?: boolean;
}

const ConditionSelect: TranslatedComponent<ConditionSelectProps> = ({ trls, name, query, setQuery, isHidden }) => {
    const { isMobile } = useBreakpoint();

    const activatorRef = useRef<HTMLAnchorElement>(null);

    const [isDropVisible, toggleDropVisible, setDropVisible] = useToggleState(false);
    const hideDrop = useCallback(() => setDropVisible(false), [setDropVisible]);

    const handleOptionCheck = (query: SearchQueries) => {
        hideDrop();
        setQuery(query);
    };

    const handleSelectChange = useCallback(
        (selectedValue: StaticDataFetcherItem) => {
            setQuery({ ...query, [name]: [selectedValue.value] });
        },
        [setQuery, query, name]
    );

    const dicts = useSelector((state) => state.resumeSearchDictionaries);
    const value = useMemo(() => query[name]?.[0], [name, query]);
    const dropOptions = useMemo(() => dicts?.[name], [dicts, name]);
    const selectItems: StaticDataFetcherItem[] = useMemo(() => {
        const options = dicts?.[name];

        return options?.map((option) => ({
            text: trls[TrlKeys.option[name][option]],
            value: option,
        }));
    }, [dicts, name, trls]);
    const provider = useStaticDataProvider(selectItems);

    const hiddenInput = <input type="hidden" name={name} value={query[name].join(',')} />;

    if (isHidden) {
        return hiddenInput;
    }

    const dropContent = (
        <VSpacingContainer default={16}>
            {dropOptions.map((option) => {
                const radio: CellProps = {
                    align: 'top',
                    left: (
                        <Radio
                            checked={value === option}
                            onChange={() => handleOptionCheck({ ...query, [name]: [option] })}
                        />
                    ),
                };

                return (
                    <Cell {...radio} key={option} data-qa={`resumes-search-wizard-${name}-${option}`}>
                        <CellText>{trls[TrlKeys.option[name][option]]}</CellText>
                        {name === CriteriaKey.ExpCompanySize && option !== ExpCompanySizeOption.Any && (
                            <CellText type="description">
                                {trls[TrlKeys.companySizeCount[option as keyof typeof TrlKeys.companySizeCount]]}
                            </CellText>
                        )}
                    </Cell>
                );
            })}
        </VSpacingContainer>
    );

    return (
        <>
            {hiddenInput}
            {isMobile ? (
                <Select
                    type="radio"
                    dataProvider={provider}
                    value={selectItems.find((item) => item.value === value)}
                    onChange={handleSelectChange}
                    name={name}
                    triggerProps={{
                        size: 'large',
                        label: trls[TrlKeys.title[name]],
                        elevateLabel: true,
                        stretched: true,
                    }}
                    bottomSheetHeight="content"
                />
            ) : (
                <>
                    <Link
                        typography="label-3-regular"
                        style="neutral"
                        ref={activatorRef}
                        onClick={toggleDropVisible}
                        iconRight={isDropVisible ? <ChevronUpOutlinedSize16 /> : <ChevronDownOutlinedSize16 />}
                        data-qa={`resumes-search-wizard-${name}-switcher`}
                    >
                        {trls[TrlKeys.option[name][value]]}
                    </Link>
                    <Drop
                        visible={isDropVisible}
                        activatorRef={activatorRef}
                        onClose={hideDrop}
                        placement="bottom-left"
                        forcePlacement
                    >
                        {dropContent}
                    </Drop>
                </>
            )}
        </>
    );
};

export default translation(ConditionSelect);
