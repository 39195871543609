import { useCallback, FC, useRef } from 'react';
import classnames from 'classnames';

import { UserCircleFilledSize24, UserCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Placement } from 'bloko/common/metrics';

import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import DropdownContent from 'src/components/SupernovaMainMenu/DropdownContent';
import MultiAccountSwitchInfotip from 'src/components/SupernovaMainMenu/MultiAccountSwitchInfotip';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaDropdown from 'src/components/SupernovaMainMenu/SupernovaDropdown';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';

const UserProfile: FC<NavItem> = ({ name, subItems, trackClicks, analytics }) => {
    const employerReviewsApplicantProfileBadge = useSelector(
        ({ employerReviewsApplicantProfileBadge }) => employerReviewsApplicantProfileBadge
    );
    const isSudo = useSelector(({ actualAccount }) => actualAccount !== null);
    const userType = useSelector(({ userType }) => userType);

    const infotipRef = useRef<HTMLDivElement>(null);

    const render = useCallback(() => {
        if (userType !== UserType.BackOffice) {
            return <DropdownContent items={subItems} />;
        }
        return (
            <SupernovaDropdownSection>
                <DropdownContent items={subItems} />
            </SupernovaDropdownSection>
        );
    }, [subItems, userType]);

    return (
        <MultiAccountSwitchInfotip infotipRef={infotipRef}>
            <SupernovaNaviItem data-qa="multiaccount-infotip" ref={infotipRef}>
                <SupernovaDropdown
                    activator={
                        <SupernovaActionLink
                            data-qa={classnames(`mainmenu_${name}`, { 'mainmenu_sudo-sign': isSudo })}
                            name={name}
                            activeIcon={<UserCircleFilledSize24 />}
                            icon={<UserCircleOutlinedSize24 />}
                            trackClicks={trackClicks}
                            analytics={analytics}
                            showBadge={employerReviewsApplicantProfileBadge}
                        />
                    }
                    placement={Placement.BottomEnd}
                    render={render}
                />
                <div className="supernova-navi-underline supernova-navi-underline_for-icon" />
            </SupernovaNaviItem>
        </MultiAccountSwitchInfotip>
    );
};

export default UserProfile;
