import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ProbationCatalogItem {
    title: string;
    translit: string;
}

interface ProbationCatalog {
    collection: ProbationCatalogItem[];
    current?: ProbationCatalogItem;
}

export default autoGeneratedReducer<ProbationCatalog>(null);
