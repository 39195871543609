import { FC, RefObject, useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { Breakpoint } from '@hh.ru/magritte-ui';
import { LocationOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import GeoSwitcherPopup from 'src/components/SupernovaMainMenu/GeoSwitcherPopup';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';
import { OverlayTypeSetterFunc, OverlayTypes } from 'src/components/SupernovaOverlayMenu/Overlay';
import RegionClarificationTooltip from 'src/components/SupernovaOverlayMenu/RegionClarificationTooltip';
import useHandleOpenGeoSwitcher from 'src/hooks/useHandleOpenGeoSwitcher';
import useIsRedesignGeoExpActive from 'src/hooks/useIsRedesignGeoExpActive';
import useSendCheckGeoSwitcherRedesignExp from 'src/hooks/useSendCheckGeoSwitcherRedesignExp';
import { NavItem } from 'src/models/supernovaNaviMenu';

import styles from './geo-switcher.less';

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];

const GeoSwitcher: FC<
    NavItem & { showOverlay: OverlayTypeSetterFunc; headerContentRef: RefObject<HTMLDivElement> }
> = ({ name, trackClicks, showOverlay, translations, analytics, headerContentRef }) => {
    const ref = useRef(null);
    const isRedesignActive = useContext(isRedesignActiveContext);
    const openGeoSwitcher = useHandleOpenGeoSwitcher(() => showOverlay(OverlayTypes.AreaSwitcherOverlay));
    const isRedesignGeoExpActive = useIsRedesignGeoExpActive();

    const sendCheck = useSendCheckGeoSwitcherRedesignExp();

    useEffect(() => {
        if (translations.geoExpName) {
            sendCheck();
        }
    }, [sendCheck, translations.geoExpName]);

    return (
        <SupernovaNaviItem className="supernova-navi-item_geo-switcher" ref={ref} visibleOn={VISIBLE_ON}>
            <SupernovaActionLink
                name={name}
                data-qa="geo-switcher"
                onClick={openGeoSwitcher}
                icon={<LocationOutlinedSize24 />}
                trackClicks={trackClicks}
                analytics={analytics}
            >
                <span
                    className={classNames(styles.geoSwitcherText, {
                        [styles.geoSwitcherTextHiddenOnS]: !isRedesignActive,
                    })}
                >
                    {isRedesignGeoExpActive ? translations.geoExpName || translations.name : translations.name}
                </span>
            </SupernovaActionLink>

            <RegionClarificationTooltip showOverlay={showOverlay} activatorRef={ref} />
            {isRedesignGeoExpActive && <GeoSwitcherPopup activatorRef={headerContentRef} />}
        </SupernovaNaviItem>
    );
};

export default GeoSwitcher;
