import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { AnyAction, Middleware } from 'redux';

import Analytics from '@hh.ru/analytics-js';

import { Counter, CounterType } from 'src/models/counters';
import { TopLevelSite } from 'src/models/topLevelSite';
import { ViewDurationProps } from 'src/models/viewDuration';
import SearchPageLocationForGA from 'src/utils/analytics/pageLocationForGA';
import { resetCheckedExperiment } from 'src/utils/checkedExperiments';
import { sendViewDutaionEvent } from 'src/utils/viewDuration';

const resetMicroFrontendNodes = (): void => {
    const containers = document.querySelectorAll('.HH-MicroFrontendsMiddleware-Container');

    [...containers].forEach((node) => {
        node.remove();
    });
};

const trackUrl = (locationChangeAction: LocationChangeAction): void => {
    const pathname = locationChangeAction.payload.location.pathname;
    const query = locationChangeAction.payload.location.search;

    const pageLocationForGA = new SearchPageLocationForGA().prepareUrl(pathname, query);
    const resultPageLocationForGA = `${location.protocol}//${location.host}${pageLocationForGA}`;

    Analytics.trackPageView(resultPageLocationForGA);
};

const destroyYaBanners = () => {
    if (window.globalVars?.features?.yandex_adfox_enabled && window.Ya?.adfoxCode) {
        const containers = document.querySelectorAll<HTMLDivElement>('.HH-Banner-Adfox');
        [...containers].forEach((node) => {
            if (node.dataset.adfoxRequested === 'true') {
                window.Ya?.adfoxCode.destroy(node.id);
            }
        });
    }
};

const sendDV360Event = (topLevelSite: TopLevelSite, counters: Record<string, Counter[]>): void => {
    if ([TopLevelSite.KZ, TopLevelSite.AZ, TopLevelSite.UZ].includes(topLevelSite)) {
        const goal = {
            [TopLevelSite.AZ]: 'allaz0',
            [TopLevelSite.KZ]: 'hhklr0',
            [TopLevelSite.UZ]: 'alluz0',
        }[topLevelSite as TopLevelSite.AZ | TopLevelSite.KZ | TopLevelSite.UZ];

        if (goal) {
            counters.googleUniversalAnalytics?.forEach((item) => {
                if (item.type === CounterType.DV360) {
                    Analytics.sendDv360Conversion(String(item.id), goal);
                }
            });
        }
    }
};

const locationChangeMiddleware: Middleware =
    (store) => (next) => (action: LocationChangeAction | LocationChangeAction[]) => {
        let locationChangeAction = null;
        const {
            counters,
            topLevelSite: site,
            viewDuration,
        } = store.getState() as {
            counters: Record<string, Counter[]>;
            topLevelSite: TopLevelSite;
            viewDuration: ViewDurationProps;
        };
        const topLevelSite = site === TopLevelSite.JTB ? TopLevelSite.BY : site;

        if (Array.isArray(action)) {
            locationChangeAction = action?.find?.((action: LocationChangeAction) => action?.type === LOCATION_CHANGE);
        } else {
            locationChangeAction = action?.type === LOCATION_CHANGE ? action : null;
        }

        if (locationChangeAction) {
            sendDV360Event(topLevelSite, counters);
        }

        if (locationChangeAction && !locationChangeAction?.payload?.isFirstRendering) {
            trackUrl(locationChangeAction);
            resetCheckedExperiment();
            destroyYaBanners();
            resetMicroFrontendNodes();
            if (viewDuration) {
                sendViewDutaionEvent(viewDuration);
            }
        }

        return next(action as AnyAction);
    };

export default locationChangeMiddleware;
