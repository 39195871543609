import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum TopLevelSite {
    RU = 'hh.ru',
    UA = 'hh.ua',
    UZ = 'hh.uz',
    GE = 'headhunter.ge',
    KG = 'headhunter.kg',
    KZ = 'headhunter.com.kz',
    AZ = 'jobs.day.az',
    BY = 'rabota.by',
    /** @deprecated */
    JTB = 'jobs.tut.by',
    ZP = 'zarplata.ru',
}

export const paymentNotAvailableSites = [TopLevelSite.UZ, TopLevelSite.KG, TopLevelSite.GE, TopLevelSite.KZ];

export default autoGeneratedReducer<TopLevelSite>(TopLevelSite.RU);
