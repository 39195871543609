import { ModelData } from '@hh.ru/magritte-ui';
import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface StateMap<T> {
    itemsMap: Record<string, T>;
    positions: string[];
}

export interface CandidateSolution {
    uidPk?: number | string;
    correct: boolean;
    value: string;
}

export interface Task {
    uidPk?: number | string;
    open: boolean;
    multiple: boolean;
    description: string;
    candidateSolutions?: CandidateSolution[];
}

export interface StateTask extends Omit<Task, 'candidateSolutions'> {
    candidateSolutions?: StateMap<CandidateSolution>;
}

export enum FieldName {
    Guid = 'guid',
    Name = 'name',
    Author = 'author',
    Url = 'url',
    Description = 'description',
    ExtraDescription = 'extraDescription',
    ExtraInfo = 'extraInfo',
    Tasks = 'tasks',
    CategoryIds = 'categoryIds',
    Modifiable = 'modifiable',
    Copyable = 'copyable',
    Template = 'template',
    Common = 'isCommon',
    Overt = 'overt',
}

export interface Test {
    [FieldName.Guid]?: string;
    [FieldName.Name]?: string;
    [FieldName.Author]?: string;
    [FieldName.Url]?: string;
    [FieldName.Description]?: string;
    [FieldName.ExtraDescription]?: string;
    [FieldName.ExtraInfo]?: string;
    [FieldName.Tasks]?: Task[];
    [FieldName.CategoryIds]?: number[];
    [FieldName.Modifiable]?: boolean;
    [FieldName.Copyable]?: boolean;
    [FieldName.Template]?: boolean;
    [FieldName.Common]?: boolean;
    [FieldName.Overt]?: boolean;
}

export interface FormValues extends Omit<Test, FieldName.Tasks> {
    [FieldName.Tasks]?: StateMap<StateTask>;
}

interface EmployerTestsConstructorState {
    initialValues: FormValues;
    categoriesTree?: ModelData[];
    ignoredFields?: Record<FieldName, boolean>;
}

export default autoGeneratedReducer<EmployerTestsConstructorState>({ initialValues: {} });
