import { FC, ComponentProps, MutableRefObject, ReactNode, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Merge } from 'type-fest';

import { Banner, BottomSheet, CardStyle, Tooltip, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';

import Notices, { MarkAsViewedEvent } from 'Modules/Notices';
import TooltipHost from 'src/components/TooltipHost';
import { useSelector } from 'src/hooks/useSelector';

interface InfotipProps {
    /** Название показываемого инфотипа */
    name: string;
    render: (props: { activatorRef?: MutableRefObject<HTMLElement | null> }) => ReactNode;
    visible?: boolean;
    onSeen?: () => void;
    onClose?: () => void;
    markAsViewedEvent?: MarkAsViewedEvent;
    children: ReactNode;
    autoHide?: boolean;
    bannerStyle?: CardStyle;
    showBottomSheetOnMobile?: boolean;
    showBannerOnMobile?: boolean;
    useDefaultHost?: boolean;
}

const MagritteInfotip: FC<
    Merge<InfotipProps, Omit<ComponentProps<typeof Tooltip>, 'activatorRef' | 'onClose' | 'host'>>
> = ({
    name,
    visible = false,
    render,
    onSeen,
    autoHide = true,
    onClose,
    markAsViewedEvent = MarkAsViewedEvent.OnShow,
    children,
    bannerStyle,
    placement,
    buttons,
    title,
    'aria-label-close': ariaClose,
    showBottomSheetOnMobile = false,
    showBannerOnMobile = true,
    useDefaultHost,
    showClose = false,
    ...otherProps
}) => {
    const [isVisible, setVisible] = useState(true);
    const showInfoTip = useSelector(({ infoTip }) => visible && infoTip.name === name);
    const { isMobile } = useBreakpoint();
    const activatorRef = useRef<HTMLElement | null>(null);

    const mark = useCallback(
        (action: MarkAsViewedEvent, abortSignal?: AbortSignal) => {
            if (markAsViewedEvent === action) {
                Notices.markAsViewed(name, undefined, { signal: abortSignal });
                onSeen?.();
            }
        },
        [name, markAsViewedEvent, onSeen]
    );

    useEffect(() => {
        const abortController = new AbortController();

        if (showInfoTip && autoHide) {
            mark(MarkAsViewedEvent.OnShow, abortController.signal);
        }

        return () => {
            abortController.abort();
        };
    }, [autoHide, mark, showInfoTip]);

    const hideInfotip = useCallback(() => {
        mark(MarkAsViewedEvent.OnHide);
        onClose?.();
        setVisible(false);
    }, [mark, onClose]);

    if (isMobile) {
        if (showBottomSheetOnMobile) {
            return (
                <>
                    {render({})}
                    <BottomSheet visible={showInfoTip && isVisible} onClose={hideInfotip} height="half-screen">
                        {children}
                        <VSpacing default={16} />
                    </BottomSheet>
                </>
            );
        }

        if (showBannerOnMobile) {
            return (
                <>
                    {render({})}
                    <Banner
                        visible={showInfoTip && isVisible}
                        onClose={hideInfotip}
                        content={children}
                        style={bannerStyle}
                        stretched
                    />
                </>
            );
        }
    }

    return (
        <TooltipHost tipVisible={showInfoTip && isVisible}>
            {(host, tipVisible) => (
                <>
                    {render({ activatorRef })}
                    <Tooltip
                        visible={tipVisible}
                        onClose={hideInfotip}
                        activatorRef={activatorRef}
                        host={useDefaultHost ? undefined : host}
                        placement={placement}
                        title={title as undefined}
                        showClose={showClose as false}
                        aria-label-close={ariaClose as undefined}
                        {...otherProps}
                        buttons={buttons}
                    >
                        {children}
                    </Tooltip>
                </>
            )}
        </TooltipHost>
    );
};

export default memo(MagritteInfotip);
