import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { PosOption } from 'src/models/search/resume/queries';
import { CriteriaKey } from 'src/types/search/common/criteria';

import { WORKPLACE_OPTIONS } from 'src/components/ResumeAdvancedSearch/SearchQueries/posUtils';

const TrlKeys = {
    selectedValuesTitle: 'resumesSearch.searchByFields.label',
};

const PosSelectOption: TranslatedComponent<{ values: PosOption[] }> = ({ trls, values }) => {
    const preparedTrls = useSelector((state) => state.searchQueries?.trls[CriteriaKey.Pos]);

    if (!preparedTrls) {
        return null;
    }

    if (values.length === 1) {
        return <>{preparedTrls[values[0]]}</>;
    }
    const valuesCount = values.includes(PosOption.Workplaces)
        ? values.length - 1 + WORKPLACE_OPTIONS.length
        : values.length;
    return <>{format(trls[TrlKeys.selectedValuesTitle], { '{0}': valuesCount })}</>;
};

export default translation(PosSelectOption);
