import { FC, PropsWithChildren } from 'react';

import { HSpacingContainer, VSpacing, Button, Text } from '@hh.ru/magritte-ui';
import { TelegramSolidSize24, ViberSolidSize24, VkSolidSize24 } from '@hh.ru/magritte-ui/service';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import { NavItem } from 'src/models/supernovaNaviMenu';

const EnumChatBotInvariants = {
    Telegram: 'chatBotTelegram',
    Viber: 'chatBotViber',
    Vk: 'chatBotVk',
};

const Icon = {
    [EnumChatBotInvariants.Telegram]: <TelegramSolidSize24 />,
    [EnumChatBotInvariants.Viber]: <ViberSolidSize24 />,
    [EnumChatBotInvariants.Vk]: <VkSolidSize24 />,
};

const NavItemsChatBot: FC<NavItem & PropsWithChildren> = ({ subItems, translations }) => {
    return (
        <>
            <Text typography="label-2-regular">{translations.name}</Text>

            <VSpacing default={8} />

            <HSpacingContainer default={16}>
                {subItems?.map(({ url, name }) => (
                    <Button
                        mode="secondary"
                        Element={SPALink}
                        to={url}
                        target="_blank"
                        key={name}
                        hideLabel
                        icon={Icon[name]}
                        aria-label={name}
                    >
                        {name}
                    </Button>
                ))}
            </HSpacingContainer>
        </>
    );
};

export default NavItemsChatBot;
