// tempexp_PORTFOLIO-38449_file
import { ComponentProps, FC, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { Action, Card } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Notification from 'bloko/blocks/notificationManager/Notification';

import { useSelector } from 'src/hooks/useSelector';

import styles from './temp-notifications.less';

const NOTIFICATION_MANAGER_CLASS_JS = 'Temp-Notification-Manager';
const NOTIFICATION_ANIMATION_TIMEOUT_MS = 300;
const NOTIFICATION_HEIGHT = 168;
const OFFSET_BOTTOM = 23;

const TempNotification: FC<ComponentProps<typeof Notification>> = ({ children, onClose }) => {
    const [visible, setVisible] = useState(false);
    const isCookiesPolicyInformerVisible = useSelector((state) => state.isCookiesPolicyInformerVisible);
    const rootRef = useRef<HTMLDivElement | null>(null);
    const notificationRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        rootRef.current = document.querySelector(`.${NOTIFICATION_MANAGER_CLASS_JS}`);

        if (!rootRef.current) {
            rootRef.current = document.createElement('div');
            rootRef.current.className = `${NOTIFICATION_MANAGER_CLASS_JS} ${styles.tempNotificationManager}`;
        }

        if (isCookiesPolicyInformerVisible) {
            const informerHeight = document
                .querySelector('#bottom-cookies-policy-informer')
                ?.getBoundingClientRect().height;
            if (informerHeight) {
                rootRef.current.style.bottom = `calc(${NOTIFICATION_HEIGHT + OFFSET_BOTTOM + informerHeight}px)`;
            }
        } else {
            rootRef.current.style.bottom = `calc(${NOTIFICATION_HEIGHT + OFFSET_BOTTOM}px)`;
        }
        document.body.appendChild(rootRef.current);
        setVisible(true);
    }, [isCookiesPolicyInformerVisible]);

    if (!rootRef.current) {
        return null;
    }

    return ReactDOM.createPortal(
        <CSSTransition
            in={visible}
            timeout={{ exit: NOTIFICATION_ANIMATION_TIMEOUT_MS }}
            enter={false}
            unmountOnExit
            classNames={{
                exit: styles.notificationInactive,
                exitActive: styles.notificationInactive,
            }}
            nodeRef={notificationRef}
        >
            <div className={styles.notificationWrapper} ref={notificationRef}>
                <Card padding={12} borderRadius={24} style="constant-secondary">
                    <div className={styles.notificationContent}>
                        <div className={styles.notificationClose}>
                            <Action
                                icon={CrossOutlinedSize24}
                                mode="secondary"
                                onClick={() => {
                                    onClose?.();
                                    setVisible(false);
                                }}
                                style={'neutral'}
                            />
                        </div>
                        {children}
                    </div>
                </Card>
            </div>
        </CSSTransition>,
        rootRef.current
    );
};

export default TempNotification;
