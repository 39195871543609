import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ArticleItemWithDate } from 'src/models/article.types';
import { Employer, EmployerBillingSummary } from 'src/models/oldEmployer/employerBillingSummary';
import { PfpInfo } from 'src/models/payForPerformance/pfpInfo';

interface OldEmployer {
    eventsMain: {
        body: { events: ArticleItemWithDate[] };
    };
    billingSummary: EmployerBillingSummary;
    clickme?: {
        realBalance?: number;
    };
    clickmeCabinetsCount?: number;
    employer: Employer;
    resumePicksAvailable?: boolean;
    canViewStatPortal?: boolean;
    pfpInfo?: PfpInfo;
    services?: {
        waitForActivationCount?: number;
        waitForPaymentCount?: number;
    };
    balances?: {
        accountBalance?: number;
        activationReserved?: number;
        hrsReserved?: number;
        otherReserved?: number;
        totalReserved?: number;
    };
    hasPaymentFeature?: boolean;
    talantixTariffDaysLeft?: number;
}

interface EmployerIndexPage {
    oldEmployer?: OldEmployer;
}

export default autoGeneratedReducer<EmployerIndexPage>({});
