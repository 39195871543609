import { FC, useCallback, PropsWithChildren, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import openChatPageButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/chat/open_chat_page_button_click';
import { ChatikProvider, ChatikProviderProps } from '@hh.ru/chatik-integration';
import { CounterUpdater, OnUpdate } from '@hh.ru/chatik-integration/lib/CounterUpdater';
import { websocket } from '@hh.ru/chatik-integration/lib/Websocket';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Cookies from 'bloko/common/Cookies';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import modalHelper from 'bloko/common/modalHelper';

import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import ResumeCreationScenario from 'src/components/ChatikIntegration/components/ResumeCreationScenario';
import {
    ANON_SOCKET_COOKIE_NAME,
    CHATIK_CLOSED_EVENT,
    COLLECT_DEBUG_DATA_DELAY_MS,
} from 'src/components/ChatikIntegration/constants';
import useInitParams from 'src/components/ChatikIntegration/hooks/useInitParams';
import useWaitForDocumentTitle from 'src/components/ChatikIntegration/hooks/useWaitForDocumentTitle';
import { collectDebugData } from 'src/components/ChatikIntegration/utils/collectDebugData';
import { onResizeEnd, onDragEnd } from 'src/components/ChatikIntegration/utils/handlers';
import { shouldUseCounterUpdater } from 'src/components/ChatikIntegration/utils/shouldUseCounterUpdater';
import { updateDocumentTitleCounter } from 'src/components/ChatikIntegration/utils/updateDocumentTitleCounter';
import { triggerUxFeedback } from 'src/components/ChatikIntegration/utils/uxFeedback';

type OnOpenCallback = Required<ChatikProviderProps>['onOpen'];
type OnCloseCallback = Required<ChatikProviderProps>['onClose'];

const chatikSetCountersAction = makeSetStoreField('chatikCounters');

const ChatikIntegration: FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useDispatch();
    const [forceCounter, setForceCounter] = useState(false);
    const chatik = useSelector((state) => state.chatik);
    const userType = useSelector(({ userType }) => userType);
    const isErrorPage = useSelector((state) => state.errorPage.isError);
    const hhid = useSelector(({ hhid }) => hhid) ?? '';
    const topLevelDomain = useSelector((state) => state.topLevelDomain);

    const isMobile = useBreakpoint() === Breakpoint.XS;
    const isApplicant = userType === UserType.Applicant;
    const isAnonym = userType === UserType.Anonymous;
    const chatikOrigin = chatik?.chatikOrigin || '';
    const websocketOrigin = chatik?.websocketOrigin || '';
    const tabMessagesCounterEnabled = isApplicant && !isMobile && !isErrorPage;

    const initParams = useInitParams(chatikOrigin);
    const waitForDocumentTitle = useWaitForDocumentTitle();

    const shouldShowResumeCreationScenario = useExperiment(
        'apps_profession_middle_ages',
        userType === UserType.Applicant
    );

    useEffect(() => {
        if (websocketOrigin && !isAnonym) {
            websocket.connect({ origin: websocketOrigin });
        }
    }, [websocketOrigin, isAnonym]);

    const handleOpen = useCallback<OnOpenCallback>(() => {
        if (isMobile) {
            modalHelper.disableScroll();
        }

        if (isAnonym && Cookies.get(ANON_SOCKET_COOKIE_NAME)) {
            setForceCounter(true);
        }

        setTimeout(() => collectDebugData(topLevelDomain), COLLECT_DEBUG_DATA_DELAY_MS);
    }, [isAnonym, topLevelDomain, isMobile]);

    const handleClose = useCallback<OnCloseCallback>(() => {
        if (isMobile) {
            modalHelper.enableScroll();
        }

        triggerUxFeedback();
        dispatchEvent(new CustomEvent(CHATIK_CLOSED_EVENT));
    }, [isMobile]);

    const handleCounterUpdate: OnUpdate = useCallback(
        ({ unreadCount, unreadSupportCount }) => {
            dispatch(
                chatikSetCountersAction({
                    unreadCount: typeof unreadCount === 'number' ? unreadCount : null,
                    unreadSupportCount: typeof unreadSupportCount === 'number' ? unreadSupportCount : null,
                })
            );

            if (!tabMessagesCounterEnabled) {
                return;
            }

            waitForDocumentTitle(() => updateDocumentTitleCounter(unreadCount || 0));
        },
        [dispatch, waitForDocumentTitle, tabMessagesCounterEnabled]
    );

    return (
        <ChatikProvider
            initParams={initParams}
            onOpen={handleOpen}
            onClose={handleClose}
            onResizeEnd={onResizeEnd}
            onDragEnd={onDragEnd}
            showOpenFullChatikButton={!isAnonym}
            onOpenFullChatik={openChatPageButtonClick}
            offsetElementSelector=".supernova-navi-wrapper"
        >
            {children}

            {shouldShowResumeCreationScenario && <ResumeCreationScenario />}

            {shouldUseCounterUpdater({ userType, websocketOrigin, chatikOrigin, force: forceCounter }) && (
                <CounterUpdater
                    counterId={hhid}
                    onUpdate={handleCounterUpdate}
                    websocketOrigin={websocketOrigin}
                    chatikOrigin={chatikOrigin}
                />
            )}
        </ChatikProvider>
    );
};

export default ChatikIntegration;
