import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Push, Replace, usePush, useReplace } from '@hh.ru/redux-spa-middleware';
import debounce from 'bloko/common/debounce';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';

import { useNotification } from 'src/components/Notifications/Provider';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { useUpdateAbortController } from 'src/components/Search/Common/Filters/AbortApplyFiltersContext/hooks/useUpdateAbortController';
import sendFilterForm, {
    SendFilterFormArgs,
    SendFilterFormMode,
} from 'src/components/Search/Common/Filters/actions/sendFilterForm';

const searchLoadingAction = makeSetStoreField('searchLoading');
const searchCountsAction = makeSetStoreField('searchCounts');

const DEBOUNCE_DELAY_MS = 300;

const isDesktop = () => [Breakpoint.M, Breakpoint.L].includes(getBreakpoint());

type DispatchSendFilterFormFunction = (
    dispatch: Dispatch,
    args: SendFilterFormArgs,
    addNotification: AddNotification,
    replace: Replace,
    push: Push
) => void;

const dispatchSendFilterForm: DispatchSendFilterFormFunction = (dispatch, ...args) => dispatch(sendFilterForm(...args));

const debouncedDispatchSendFilterForm = debounce<DispatchSendFilterFormFunction>(
    (...args) => dispatchSendFilterForm(...args),
    DEBOUNCE_DELAY_MS
);

export type SendFilterFormWrapped = (args?: SendFilterFormArgs) => void;

export const useSendFilterForm = (isDebounced?: boolean): SendFilterFormWrapped => {
    const dispatch = useDispatch();
    const abortAndGetUpdatedSignal = useUpdateAbortController();
    const { addNotification } = useNotification();
    const replace = useReplace();
    const push = usePush();

    const dispatchCallback = isDebounced ? debouncedDispatchSendFilterForm : dispatchSendFilterForm;

    return (args) => {
        const abortSignal = abortAndGetUpdatedSignal();
        dispatchCallback(dispatch, { ...args, abortSignal }, addNotification, replace, push);
    };
};

export const useDebouncedSendFilterFormDesktop = (): SendFilterFormWrapped => {
    const dispatch = useDispatch();
    const debouncedSendFilterForm = useSendFilterForm(true);

    return useCallback(
        (args) => {
            if (isDesktop()) {
                dispatch(searchLoadingAction(true));
                debouncedSendFilterForm(args);
            }
        },
        [debouncedSendFilterForm, dispatch]
    );
};

export const useDebouncedCountsRequest = (): (() => void) => {
    const dispatch = useDispatch();
    const debouncedSendFilterForm = useSendFilterForm(true);

    return useCallback(() => {
        dispatch(searchCountsAction({ isLoad: true, value: 0 }));
        debouncedSendFilterForm({ mode: SendFilterFormMode.UpdateCounts });
    }, [debouncedSendFilterForm, dispatch]);
};

export const useDebouncedCountsRequestMobile = (): (() => void) => {
    const debouncedCountsRequest = useDebouncedCountsRequest();

    return useCallback(() => !isDesktop() && debouncedCountsRequest(), [debouncedCountsRequest]);
};
