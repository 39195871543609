import React, { useContext } from 'react';

import switchRoleApplicantHeaderClick from '@hh.ru/analytics-js-events/build/xhh/common/header/switch_role_applicant_header_click';
import switchRoleEmployerHeaderClick from '@hh.ru/analytics-js-events/build/xhh/common/header/switch_role_employer_header_click';
import { Breakpoint, Link } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaDropdown from 'src/components/SupernovaMainMenu/SupernovaDropdown';
import SupernovaDropdownOption from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';
import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';

const UserTypeSwitcher: React.FC<NavItem & { visibleOnDesktop?: boolean }> = ({
    name,
    trackClicks,
    subItems,
    visibleOnDesktop = false,
    analytics,
}) => {
    const isRedesignActive = useContext(isRedesignActiveContext);

    const handleClick = useTrackClicks(name, trackClicks, analytics);
    const handleOptionClick = (name: string) => {
        if (name === 'userTypeSwitcherApplicant' || name === 'userTypeSwitcherApplicantShort') {
            switchRoleApplicantHeaderClick();
        } else {
            switchRoleEmployerHeaderClick();
        }
    };

    const render = React.useCallback(() => {
        return subItems?.map(({ name, trackClicks, trackElementShown, translations, url }) => {
            return (
                <SupernovaDropdownSection key={name}>
                    <SupernovaDropdownOption
                        name={name}
                        trackClicks={trackClicks}
                        trackElementShown={trackElementShown}
                        url={url}
                        onClick={() => handleOptionClick(name)}
                    >
                        {translations.name}
                    </SupernovaDropdownOption>
                </SupernovaDropdownSection>
            );
        });
    }, [subItems]);

    const renderActivator = React.useCallback(() => {
        const activeName = subItems?.find(({ active }) => active)?.translations.name;

        if (isRedesignActive) {
            return (
                <Link
                    typography="label-2-regular"
                    style="neutral"
                    Element="button"
                    data-qa={`mainmenu_${name}`}
                    iconRight={<ChevronDownOutlinedSize24 />}
                >
                    {activeName}
                </Link>
            );
        }

        return (
            <div className="supernova-icon-small-dynamic supernova-navi-user-type-switcher-wrapper">
                <button className="supernova-link supernova-navi-user-type-switcher" data-qa={`mainmenu_${name}`}>
                    <span className="supernova-navi-user-type-switcher-text">{activeName}</span>
                    <span className="supernova-icon-small supernova-icon_chevron-down supernova-navi-user-type-switcher-icon" />
                </button>
            </div>
        );
    }, [isRedesignActive, name, subItems]);

    return (
        <SupernovaNaviItem visibleOn={visibleOnDesktop ? undefined : [Breakpoint.XS]} userTypeSwitcher>
            <SupernovaDropdown
                activator={renderActivator()}
                anchorFullHeight
                render={render}
                activatorClick={handleClick}
            />
        </SupernovaNaviItem>
    );
};

export default UserTypeSwitcher;
