import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { BillType } from 'src/models/billType';
import { CountryCode } from 'src/models/countryId';
import { CurrencyType } from 'src/models/currencies.types';
import { PartyType } from 'src/models/partyType';
import { Payer, OrganisationType } from 'src/models/payerForm.types';
import { PaymentType } from 'src/models/paymentType';

import { BillTypesResponse } from 'src/models/employerInvoicePayment/fetchBillTypes';
import { PayerAgreementsResponse } from 'src/models/employerInvoicePayment/fetchPayerAgreementsData';

export interface BillTypesType {
    value: BillType;
    text: string;
}

export interface Employer {
    currency: CurrencyType;
    country: CountryCode;
    employerId: number | null;
}

export interface Seller {
    sellerId: number;
    sellerName: string;
}

export interface AmountSuggestion {
    amount: number;
}

export interface EmployerInvoicePayment {
    hasPayers: boolean;
    hasPersonAgreement: boolean;
    payers: Payer[];
    payersLoading: boolean;
    employer: Employer;
    paymentTypesOrder: PaymentType[];
    paymentTypes?: Partial<Record<PaymentType, string[]>>;
    organisationTypes: OrganisationType[];
    emailRequiredPaymentTypes?: Record<PartyType, PaymentType[]>;
    vats?: Partial<{
        rows: Array<{ vat: number }>;
    }> | null;
    billTypes: BillTypesType[];
    sellers: Seller[];
    amountSuggestions: AmountSuggestion[];
}

const SET_BILL_TYPES_DATA = 'SET_BILL_TYPES_DATA';
const SET_PAYERS_DATA = 'SET_PAYERS_DATA';
const SET_PAYERS_LOADING = 'SET_PAYERS_LOADING';
const SET_ORGANISATION_TYPES = 'SET_ORGANISATION_TYPES';

interface EmployerInvoicePaymentPayload {
    [SET_BILL_TYPES_DATA]: BillTypesResponse;
    [SET_PAYERS_DATA]: PayerAgreementsResponse;
    [SET_PAYERS_LOADING]: boolean;
    [SET_ORGANISATION_TYPES]: OrganisationType[];
}

const actionCreator = ActionCreatorHelper<EmployerInvoicePaymentPayload>();
export const setBillTypesData = actionCreator(SET_BILL_TYPES_DATA);
export const setPayersData = actionCreator(SET_PAYERS_DATA);
export const setPayersLoading = actionCreator(SET_PAYERS_LOADING);
export const setOrganisationTypes = actionCreator(SET_ORGANISATION_TYPES);

const initialState: EmployerInvoicePayment = {
    hasPayers: false,
    hasPersonAgreement: false,
    payers: [],
    payersLoading: false,
    employer: {
        currency: CurrencyType.RUR,
        country: CountryCode.Russia,
        employerId: null,
    },
    paymentTypesOrder: [],
    paymentTypes: {},
    organisationTypes: [],
    vats: null,
    billTypes: [],
    sellers: [],
    amountSuggestions: [],
};

export default createReducer<EmployerInvoicePayment, EmployerInvoicePaymentPayload>(initialState, {
    [SET_BILL_TYPES_DATA]: (state, { payload }) => {
        return { ...state, billTypes: payload.billTypes };
    },
    [SET_PAYERS_DATA]: (state, { payload }) => {
        return {
            ...state,
            hasPayers: payload.hasPayers,
            hasPersonAgreement: payload.hasPersonAgreement,
            payers: payload.payers,
        };
    },
    [SET_PAYERS_LOADING]: (state, { payload }) => {
        return {
            ...state,
            payersLoading: payload,
        };
    },
    [SET_ORGANISATION_TYPES]: (state, { payload }) => {
        return { ...state, organisationTypes: payload };
    },
});
