import React from 'react';
import classnames from 'classnames';

import { Button, ButtonMode, ButtonSize, ButtonStyle } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import useTrackClicks, { TrackClicksProps } from 'src/components/SupernovaMainMenu/useTrackClicks';

interface SupernovaButtonProps extends TrackClicksProps {
    active?: boolean;
    disabled?: boolean;
    name: string;
    onClick?: React.MouseEventHandler;
    primary?: boolean;
    secondary?: boolean;
    tinted?: boolean;
    url?: string;
    rel?: 'nofollow';
    isMagritte?: boolean;
    magritteMode?: ButtonMode;
    magritteSize?: ButtonSize;
    magritteStyle?: ButtonStyle;
    magritteStretched?: boolean;
    magritteIcon?: React.ReactElement;
}

const ACTIVE_ATTRS = {
    disabled: true,
    tabIndex: -1,
};

const SupernovaButton = React.forwardRef<
    HTMLAnchorElement | HTMLButtonElement,
    SupernovaButtonProps & React.PropsWithChildren
>(
    (
        {
            active,
            children,
            disabled,
            name,
            onClick,
            primary,
            secondary,
            tinted,
            trackClicks,
            url,
            isMagritte = false,
            magritteMode = 'secondary',
            magritteSize = 'small',
            magritteStyle = 'neutral',
            magritteStretched = false,
            magritteIcon,
            analytics,
            ...props
        },
        forwardedRef
    ) => {
        const handleTrackClick = useTrackClicks(name, trackClicks, analytics);

        const handleClick = (event: React.MouseEvent) => {
            onClick && onClick(event);
            handleTrackClick();
        };

        if (isMagritte) {
            if (url) {
                return (
                    <Button
                        ref={forwardedRef as React.Ref<HTMLAnchorElement>}
                        style={magritteStyle}
                        size={magritteSize}
                        href={url}
                        Element="a"
                        onClick={handleClick}
                        mode={magritteMode}
                        icon={magritteIcon}
                        stretched={magritteStretched}
                        disabled={disabled}
                        {...props}
                    >
                        {children}
                    </Button>
                );
            }
            return (
                <Button
                    ref={forwardedRef as React.Ref<HTMLButtonElement>}
                    stretched={magritteStretched}
                    style={magritteStyle}
                    size={magritteSize}
                    onClick={handleClick}
                    mode={magritteMode}
                    disabled={disabled}
                    {...props}
                >
                    {children}
                </Button>
            );
        }

        const className = classnames('supernova-button', {
            'supernova-button_primary': primary,
            'supernova-button_secondary': secondary,
            'supernova-button_tinted': tinted,
        });

        if (url) {
            return (
                <SPALink
                    ref={forwardedRef}
                    className={className}
                    onClick={handleClick}
                    to={url}
                    {...(active ? ACTIVE_ATTRS : {})}
                    {...props}
                >
                    {children}
                </SPALink>
            );
        }

        return (
            <span
                ref={forwardedRef}
                className={className}
                // @ts-expect-error стили миксина bloko-button завязаны на атрибут disabled
                disabled={disabled}
                onClick={handleClick}
                {...(active ? ACTIVE_ATTRS : {})}
                {...props}
            >
                {children}
            </span>
        );
    }
);

export default SupernovaButton;
