import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import NegotiationTopic from 'src/models/negotiationTopic.types';
import { Resume, ResumeSalary } from 'src/models/resume/resume.types';
import { AutoHideTime, Gender, PhotoUrl } from 'src/models/resume/resumeCommon.types';
import { ResumeVisibility } from 'src/models/vacancyResponsePopup';
import { VacancySearchItem } from 'src/types/search/vacancy/card';

export const APPLICANT_VACANCY_RESPONSE_STATUS_UPDATE = 'APPLICANT_VACANCY_RESPONSE_STATUS_UPDATE';
export const APPLICANT_VACANCY_RESPONSE_STATUS_SET_FETCHING = 'APPLICANT_VACANCY_RESPONSE_STATUS_SET_FETCHING';
export const INITIAL_STATE = {};

interface ResponseStatusResumeFields {
    photoUrls?: PhotoUrl[];
    gender?: { string: Gender }[];
    autoHideTime?: { string: AutoHideTime }[];
    isIncomplete?: boolean;
}

export type ResponseStatusResume = Resume & ResponseStatusResumeFields & { salary: ResumeSalary[] };

export interface ShortVacancy {
    isFetching?: boolean;
    isFetchingWithQuestionResponse?: boolean;
    shortVacancy: VacancySearchItem;
    alreadyApplied: boolean;
    negotiations: {
        total: number;
        topicList: NegotiationTopic[];
    };
    hasQuickResponse?: boolean;
    usedResumeIds: string[];
    hiddenResumeIds: string[];
    letterMaxLength: number;
    unusedResumeIds: string[];
    resumes: Record<string, ResponseStatusResume>;
    resumeVisibility: Record<string, ResumeVisibility>;
}

type InitialState = Record<number, ShortVacancy>;

interface VacancyResponseUpdate {
    data: ShortVacancy;
    vacancyId: number;
}
interface VacancyResponseSetFetching {
    isFetching: boolean;
    isFetchingWithQuestionResponse?: boolean;
    vacancyId: number;
}

interface PayloadTypes {
    [APPLICANT_VACANCY_RESPONSE_STATUS_UPDATE]: VacancyResponseUpdate;
    [APPLICANT_VACANCY_RESPONSE_STATUS_SET_FETCHING]: VacancyResponseSetFetching;
}
const actionHelper = ActionCreatorHelper<PayloadTypes>();

export const vacancyResponseUpdate = actionHelper(APPLICANT_VACANCY_RESPONSE_STATUS_UPDATE);

export const vacancyResponseSetFetching = actionHelper(APPLICANT_VACANCY_RESPONSE_STATUS_SET_FETCHING);

export default createReducer<InitialState, PayloadTypes>(INITIAL_STATE, {
    [APPLICANT_VACANCY_RESPONSE_STATUS_UPDATE]: (state, { payload }) => {
        return {
            ...state,
            [payload.vacancyId]: { ...state[payload.vacancyId], ...payload.data },
        };
    },

    [APPLICANT_VACANCY_RESPONSE_STATUS_SET_FETCHING]: (state, { payload }) => ({
        ...state,
        [payload.vacancyId]: {
            ...state[payload.vacancyId],
            isFetching: payload.isFetching,
            isFetchingWithQuestionResponse: payload.isFetchingWithQuestionResponse,
        },
    }),
});
