import appInstallBannerButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/app_install_banner_button_click';
import { Title, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import MobileAppButton from 'src/components/MobileAppButton';
import translation from 'src/components/translation';
import { useAppLinkHref } from 'src/hooks/useAppLinkHref';
import { useSelector } from 'src/hooks/useSelector';
import { StayInTouchApp } from 'src/models/stayInTouch';

import styles from './footer-mobile-banner-redesign.less';

interface AppBannerStoreButtonsProps {
    app: StayInTouchApp;
}

const TrlKeys = {
    title: 'mobile.index.app.title',
    subtitle: 'mobile.index.app.subtitle',
    subtitleVacancies: 'mobile.index.app.subtitle.vacancies',
};

const getAppLinkHref = (appHref: string) => {
    const parsedUrl = urlParser(appHref);
    parsedUrl.params = { ...parsedUrl.params, footer: '' };

    return parsedUrl.href;
};

const AppBannerStoreRedesign: TranslatedComponent<AppBannerStoreButtonsProps> = ({ trls, app }) => {
    const { isMobile } = useBreakpoint();
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const appLink = useAppLinkHref({ from: 'footer' });

    return (
        <div className={styles.footerAppBannerRedesignStore}>
            <Title
                Element="h2"
                size="medium"
                alignment={isMobile ? 'center' : 'left'}
                description={trls[isAnonymousMagritteExp ? TrlKeys.subtitleVacancies : TrlKeys.subtitle]}
                descriptionStyle={isAnonymousMagritteExp ? 'primary' : 'secondary'}
            >
                {trls[TrlKeys.title]}
            </Title>
            {isMobile && <VSpacing default={24} />}
            <div className={styles.footerAppBannerRedesignStoreButtons}>
                <MobileAppButton
                    name={app.name}
                    appLink={getAppLinkHref(appLink)}
                    onClickLink={() => {
                        appInstallBannerButtonClick({ hhtmSourceLabel: 'bottom' });
                    }}
                />
            </div>
            {isMobile && <VSpacing default={32} />}
        </div>
    );
};

export default translation(AppBannerStoreRedesign);
