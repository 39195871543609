import { Fragment } from 'react';

import defferedLinkEvent from '@hh.ru/analytics-js-events/build/xhh/employer/price_notifications/user_notifications/billing_delayed_cart_activation_notification_more';
import articleLinkEvent from '@hh.ru/analytics-js-events/build/xhh/employer/price_notifications/user_notifications/billing_delayed_cart_activation_notification_to_deffered';
import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import HumanDate, { ShowYear } from 'src/components/HumanDate';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import NotificationText from 'src/components/SupernovaMainMenu/UserNotifications/Text';
import translation from 'src/components/translation';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { ComplexUserNotification, ComplexUserNotificationsTemplateKey } from 'src/models/userNotifications';

import styles from './styles.less';

const TrlKeys = {
    [ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationForbidden]: {
        first: 'supernova.notification.billing.delayed.cart.activation.content.1',
        second: 'supernova.notification.billing.delayed.cart.activation.content.2',
        link: 'supernova.notification.billing.delayed.cart.activation.carts',
        articleLink: 'supernova.notification.billing.delayed.cart.activation.details',
    },
    [ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationExpired]: {
        first: 'supernova.notification.billing.delayed.cart.activation.expired.content.1',
        second: 'supernova.notification.billing.delayed.cart.activation.expired.content.2',
        link: 'supernova.notification.billing.delayed.cart.activation.expired.carts',
        articleLink: 'supernova.notification.billing.delayed.cart.activation.expired.details',
    },
};

export type TemplateKeys = keyof typeof TrlKeys;

const BillingCartActivationNotification: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const isHHPlatform = useIsHeadHunterPlatform();
    const key = notification.templateKey as TemplateKeys;
    const articleId = notification.params.articleId;

    return (
        <Fragment>
            <NotificationText>
                {formatToReactComponent(trls[TrlKeys[key].first], {
                    '{0}': <HumanDate showYear={ShowYear.NotCurrent} date={notification.params.dateBeforeExpiration} />,
                })}
            </NotificationText>

            <NotificationText>
                <Text
                    style={
                        key === ComplexUserNotificationsTemplateKey.BillingDelayedCartActivationForbidden
                            ? 'secondary'
                            : 'primary'
                    }
                    typography="label-2-regular"
                >
                    {formatToReactComponent(trls[TrlKeys[key].second], {
                        '{0}': <HumanDate showYear={ShowYear.NotCurrent} date={notification.params.expirationDate} />,
                    })}
                </Text>
            </NotificationText>

            <div className={styles.links}>
                <div className={styles.link}>
                    <NotificationLink to="/employer/carts" onClick={() => defferedLinkEvent({ type: key })}>
                        {trls[TrlKeys[key].link]}
                    </NotificationLink>
                </div>
                {articleId && isHHPlatform && (
                    <div className={styles.link}>
                        <NotificationLink to={`/article/${articleId}`} onClick={() => articleLinkEvent({ type: key })}>
                            {trls[TrlKeys[key].articleLink]}
                        </NotificationLink>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default translation(BillingCartActivationNotification);
