import createReducer from '@hh.ru/redux-create-reducer';

import { Call } from 'src/models/employer/vacancyresponses/calls.types';

interface CallResumeData {
    name: string;
    hash: string;
    id: string;
    topicId: string;
    isReturnCallAvailable: boolean;
    chatId: number;
}

export interface PhoneCalls {
    calls: Call[];
}

interface VacancyResponsesState {
    phoneCalls: PhoneCalls;
    callsToResume: Record<number, CallResumeData>;
}

export const INITIAL_STATE = {
    phoneCalls: {
        calls: [],
    },
    callsToResume: {},
};

export default createReducer<VacancyResponsesState, object>(INITIAL_STATE, {});
