import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';
import { PublicationProductType } from 'src/models/price/product.types';
import { ServiceItemCode } from 'src/models/price/productCodes';

export enum ProlongateUnavailableReason {
    AccessDenied = 'ACCESS_DENIED',
    Archived = 'ARCHIVED',
    AreaVacancyPostDisabled = 'AREA_VACANCY_POST_DISABLED',
    FreeNotMinimumProlongationPeriod = 'FREE_NOT_MINIMUM_PROLONGATION_PERIOD',
    FreeProlongationNotAvailable = 'FREE_PROLONGATION_NOT_AVAILABLE',
    FreeRenewalsAndPaidProlongationsMixed = 'FREE_RENEWALS_AND_PAID_PROLONGATIONS_MIXED',
    NotPremoderated = 'NOT_PREMODERATED',
    PayForPerformanceProlongationDenied = 'PAY_FOR_PERFORMANCE_PROLONGATION_DENIED',
    StandardNotMinimumProlongationPeriod = 'STANDARD_NOT_MINIMUM_PROLONGATION_PERIOD',
    StandardPlusNotMinimumDaysToProlongation = 'STANDARD_PLUS_NOT_MINIMUM_DAYS_TO_PROLONGATION',
    NoDivisionServices = 'NO_DIVISION_SERVICES',
    NoQuotas = 'NO_QUOTAS',
    Unknown = 'UNKNOWN',
}

export type PublicationBillingCode =
    | ServiceItemCode.Standart
    | ServiceItemCode.StandartPlus
    | ServiceItemCode.Premium
    | ServiceItemCode.FreeRegionalVacancy
    | ServiceItemCode.FreeVacancy
    | ServiceItemCode.Anonymous
    | ServiceItemCode.Advertizing
    | ServiceItemCode.ZpPromo
    | ServiceItemCode.ZpBusiness
    | ServiceItemCode.ZpAnonymous
    | ServiceItemCode.ZpSprint;

export interface Publication {
    // Не совсем корректное название, скорее serviceCode
    billingCode: PublicationBillingCode;
    count: number;
    priceRegionIds: number[];
    professionalRoleGroupIds: number[];
    profRoleGroupsTrls: string[];
    regionsTrls: string[];
    vacancyIds: [];
    isZpCrossposting?: boolean;
}

export interface PublicationProductTypeWithTrls extends PublicationProductType {
    regionTrl: string;
    profRoleGroupsTrls: string[];
}

export interface Purchase {
    currency: CurrencyType;
    totalPrice: number;
    products: PublicationProductTypeWithTrls[];
}

export interface PublicationsVacancy {
    vacancyId: number;
    name: string;
    city: string;
}

export interface FailProlongation {
    vacancy: PublicationsVacancy;
    reason: ProlongateUnavailableReason;
}

export interface ProlongateInfo {
    availableToFreeProlongate: {
        vacancyIds: number[];
    };
    availableToPurchase: {
        purchase?: Purchase;
        vacancyIds: number[];
    };
    availableToSpend: {
        publications?: Publication[];
        vacancyIds: number[];
    };
    total: number;
    unavailable: {
        reasons?: Record<number, ProlongateUnavailableReason>;
        vacancyIds: number[];
        failVacancies?: FailProlongation[];
    };
}

export default autoGeneratedReducer<ProlongateInfo>(null);
