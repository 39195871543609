import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import { DataQaInvariants } from 'src/components/SupernovaMainMenu/UserNotifications/constants';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    content: 'applicant.notification.specify.work.address.title',
    link: 'applicant.notification.specify.work.address.link',
};

const ApplicantFindJob: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const editPreferredAreasLink = '/profile/me?edit=preferredWorkAreas';

    return (
        <Notification
            iconKind={SupernovaUserNotificationsIconKind.Info}
            dataQa={DataQaInvariants.Info}
            {...notification}
        >
            <NotificationSpacer>{trls[TrlKeys.content]}</NotificationSpacer>
            <NotificationLink to={editPreferredAreasLink} target="_blank" data-qa="notification-link">
                {trls[TrlKeys.link]}
            </NotificationLink>
        </Notification>
    );
};

export default translation(ApplicantFindJob);
