import { FC } from 'react';

import Analytics from '@hh.ru/analytics-js';

import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import SupportChatMessengers from 'src/components/SupernovaMainMenu/SupportChatMessengers';
import SupportChatPlate from 'src/components/SupportChatPlate';
import translation from 'src/components/translation';
import { useIsRabotaBy } from 'src/hooks/useSites';

import styles from './SupportChatActivator.less';

export interface SupportChatActivatorProps {
    close?: () => void;
}

const logMessengerClick = (_: string, messenger: string) => {
    Analytics.sendHHEventButtonClick('menu_support_item', { item: messenger });
};

const SupportChatActivator: FC<SupportChatActivatorProps> = ({ close }) => {
    const isRabotaBy = useIsRabotaBy();

    return (
        <SupernovaDropdownSection>
            <div className={styles.supportChatPlate}>
                <SupportChatPlate fromLabel="menu_support" onClick={close} />
            </div>

            {!isRabotaBy && (
                <div className={styles.messengers}>
                    <SupportChatMessengers onClick={logMessengerClick} />
                </div>
            )}
        </SupernovaDropdownSection>
    );
};

export default translation(SupportChatActivator);
