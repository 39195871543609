import { PosOption, SearchQueries, ExpCompanySizeOption, ExpPeriodOption } from 'src/models/search/resume/queries';
import { CriteriaKey } from 'src/types/search/common/criteria';

export const EXTERNAL_OPTIONS = [PosOption.Position, PosOption.Education, PosOption.Keywords, PosOption.Workplaces];
export const WORKPLACE_OPTIONS = [
    PosOption.WorkplaceOrganization,
    PosOption.WorkplacePosition,
    PosOption.WorkplaceDescription,
];
export const WORKPLACE_OPTIONS_SET = new Set(WORKPLACE_OPTIONS);

export const includesSome = (a: unknown[], b: unknown[]): boolean => {
    return b.some((item) => a.includes(item));
};

export const includesEvery = (a: unknown[], b: unknown[]): boolean => {
    return b.every((item) => a.includes(item));
};

export const getStateWithDefaultValues = (state: SearchQueries, values: PosOption[]): SearchQueries => {
    if (values.length === 1 && values.every((item) => item === PosOption.FullText)) {
        state[CriteriaKey.ExpCompanySize] = [ExpCompanySizeOption.Any];
        state[CriteriaKey.ExpIndustry] = ['any'];
        state[CriteriaKey.ExpPeriod] = [ExpPeriodOption.AllTime];
    }
    return state;
};

export const getHasWorkplaceOptions = (values: PosOption[]): boolean => {
    return includesSome(values, [PosOption.Workplaces, ...WORKPLACE_OPTIONS]);
};
