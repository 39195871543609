import { H2Section } from 'bloko/blocks/header';
import Modal, { ModalHeader, ModalContent } from 'bloko/blocks/modal';
import Radio from 'bloko/blocks/radio';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ExpCompanySizeOption, ExpPeriodOption, LogicOption } from 'src/models/search/resume/queries';
import { CriteriaKey } from 'src/types/search/common/criteria';

const TrlKeys = {
    [CriteriaKey.Logic]: {
        [LogicOption.Normal]: 'resumesSearch.logic.mobile.normal',
        [LogicOption.Any]: 'resumesSearch.logic.any',
        [LogicOption.Phrase]: 'resumesSearch.logic.phrase',
        [LogicOption.Except]: 'resumesSearch.logic.except',
    },
    [CriteriaKey.ExpPeriod]: {
        [ExpPeriodOption.AllTime]: 'resumeSearch.experience_period.all_time',
        [ExpPeriodOption.LastYear]: 'resumeSearch.experience_period.last_year',
        [ExpPeriodOption.LastThreeYears]: 'resumeSearch.experience_period.last_three_years',
        [ExpPeriodOption.LastSixYears]: 'resumeSearch.experience_period.last_six_years',
    },
    [CriteriaKey.ExpCompanySize]: {
        [ExpCompanySizeOption.Any]: 'resumeSearch.company_size.mobile.any',
        [ExpCompanySizeOption.Small]: 'resumeSearch.company_size.mobile.small',
        [ExpCompanySizeOption.Medium]: 'resumeSearch.company_size.mobile.medium',
        [ExpCompanySizeOption.Large]: 'resumeSearch.company_size.mobile.large',
    },
    companySizeHint: {
        [ExpCompanySizeOption.Small]: 'resumeSearch.company_size.count.small',
        [ExpCompanySizeOption.Medium]: 'resumeSearch.company_size.count.medium',
        [ExpCompanySizeOption.Large]: 'resumeSearch.company_size.count.large',
    },
};

interface QueryParamMap {
    [CriteriaKey.Logic]: LogicOption;
    [CriteriaKey.ExpPeriod]: ExpPeriodOption;
    [CriteriaKey.ExpCompanySize]: ExpCompanySizeOption;
}

interface BottomSheetSelectorProps {
    name: keyof QueryParamMap;
    title: string;
    currentOption: QueryParamMap[keyof QueryParamMap];
    setCurrentOption: (value: QueryParamMap[keyof QueryParamMap]) => void;
    modalIsVisible: boolean;
    setModalIsVisible: (value: boolean) => void;
}

const BottomSheetSelector: TranslatedComponent<BottomSheetSelectorProps> = ({
    trls,
    name,
    title,
    currentOption,
    setCurrentOption,
    modalIsVisible,
    setModalIsVisible,
}) => {
    const options = useSelector((state) => state.resumeSearchDictionaries?.[name]);
    const optionsTrl = TrlKeys[name];

    return (
        <Modal visible={modalIsVisible} onClose={() => setModalIsVisible(false)} useBottomSheet>
            <ModalHeader>
                <H2Section>{title}</H2Section>
                <VSpacing base={1} />
            </ModalHeader>
            <ModalContent>
                {options.map((item) => (
                    <Radio
                        key={item}
                        checked={item === currentOption}
                        onChange={() => {
                            setCurrentOption(item);
                            setModalIsVisible(false);
                        }}
                        reversed
                    >
                        <VSpacing base={3} />
                        {trls[optionsTrl[item as keyof typeof optionsTrl]]}
                        {name === CriteriaKey.ExpCompanySize && (
                            <Text importance={TextImportance.Tertiary}>
                                {item !== ExpCompanySizeOption.Any
                                    ? trls[TrlKeys.companySizeHint[item as keyof typeof TrlKeys.companySizeHint]]
                                    : ''}
                            </Text>
                        )}
                        <VSpacing base={3} />
                    </Radio>
                ))}
            </ModalContent>
        </Modal>
    );
};

export default translation(BottomSheetSelector);
