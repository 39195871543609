import { useEffect, useRef, useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import talantixLinkContactsHideButtonClick from '@hh.ru/analytics-js-events/build/talantix/employer/talantix_link/talantix_link_contacts_hide_button_click';
import talantixLinkContactsHideElementShown from '@hh.ru/analytics-js-events/build/talantix/employer/talantix_link/talantix_link_contacts_hide_element_shown';
import { Text as MagritteText, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { ArrowRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Gap from 'bloko/blocks/gap';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import footerAnalytics from 'src/components/Footer/analytics';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

import Badge from 'src/components/SupernovaMainMenu/Badge';
import SupernovaLink, { SupernovaLinkKind } from 'src/components/SupernovaMainMenu/Link';

interface SupernovaMenuItemProps {
    name: string;
    url: string;
    type: string;
    translations: Record<string, string>;
    badge?: string;
    redesigned?: boolean;
    onClick?: (name: string) => void;
    onClose?: () => void;
}

const sendAnalytics = (name: string, needSendAnalytics = false) => {
    let buttonEventName;
    let eventData = null;

    switch (name) {
        case 'videoInterview':
            buttonEventName = 'videointerview_footer';
            break;
        case 'talantix':
            buttonEventName = name;
            eventData = {
                label: 'footer_click',
                category: 'talantix',
                action: 'footer',
            };
            break;
        default:
            buttonEventName = name;
            break;
    }

    if (name === 'talantix') {
        talantixLinkContactsHideButtonClick({ hhtmSourceLabel: 'footer' });
        return;
    }

    if (needSendAnalytics && buttonEventName !== null) {
        footerAnalytics.servicesItemClick(buttonEventName);
    }

    if (eventData !== null) {
        Analytics.sendEvent(eventData.category, eventData.action, eventData.label);
    }
};

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
    supervonaItemsAnalyticsForCountriesEnabled: 'supervona_items_analytics_for_countries_enabled',
};

const SupernovaMenuItem: TranslatedComponent<SupernovaMenuItemProps> = ({
    name,
    url,
    type,
    translations,
    badge,
    redesigned,
    onClose,
}) => {
    const isRabotaBy = useIsRabotaBy();
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const supervonaItemsAnalyticsForCountriesEnabled = useFeatureEnabled(
        Features.supervonaItemsAnalyticsForCountriesEnabled
    );
    const superNovauserType = useSelector(({ supernovaUserType }) => supernovaUserType);
    const userType = useSelector(({ userType }) => userType);

    const isBelarusEmployer =
        isRabotaBy && (superNovauserType === SupernovaUserType.Employer || userType === UserType.Employer);

    const needSendAnalytics =
        (isBelarusEmployer && supervonaItemsAnalyticsForCountriesEnabled) || employerMainPageAnalyticsEnabled;

    const menuItemRef = useRef(null);

    useEffect(() => {
        if (menuItemRef.current) {
            if (name === 'talantix') {
                talantixLinkContactsHideElementShown(menuItemRef.current, { hhtmSourceLabel: 'footer' });
            } else if (employerMainPageAnalyticsEnabled) {
                footerAnalytics.servicesItemShown(menuItemRef.current, { name, hhtmSourceLabel: type });
            }
        }
    }, [employerMainPageAnalyticsEnabled, menuItemRef, name, type]);

    const handleClick = useCallback(() => {
        sendAnalytics(name, needSendAnalytics);
        if (onClose) {
            setTimeout(() => onClose(), 1000);
        }
    }, [name, needSendAnalytics, onClose]);

    return (
        <Gap bottom={!redesigned}>
            <div ref={menuItemRef} className="supernova-link-wrapper">
                {redesigned ? (
                    <MagritteLink
                        Element={SPALink}
                        isSeoLink
                        to={url}
                        onClick={handleClick}
                        typography="subtitle-1-semibold"
                        inline
                    >
                        <div className="supernova-link__footer-services-title-wrapper">
                            <div data-qa={`supernova.navi.${name}`}>{translations.name}</div>

                            <div className="supernova-link__footer-services-title-icon">
                                <ArrowRightOutlinedSize16 initialColor="accent" />
                            </div>
                        </div>
                    </MagritteLink>
                ) : (
                    <SupernovaLink kind={SupernovaLinkKind.Primary} to={url} onClick={handleClick}>
                        <Text Element="span" size={TextSize.Large} data-qa={`supernova.navi.${name}`}>
                            {translations.name}
                            {badge && <Badge badge="absolute">{badge}</Badge>}
                        </Text>
                    </SupernovaLink>
                )}
            </div>

            {redesigned ? (
                <MagritteText typography="label-3-regular" style="tertiary" data-qa={`supernova.navi.${name}`}>
                    {translations.description}
                </MagritteText>
            ) : (
                <p>{translations.description}</p>
            )}
        </Gap>
    );
};

export default translation(SupernovaMenuItem);
