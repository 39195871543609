import { useMemo, useCallback } from 'react';

import {
    createDataProvider,
    decorateWithQueryTransformer,
    decorateWithResultProducer,
    createStaticDataFetcher,
    DataProviderResult,
    DataProvider,
    combineProviders,
    defaultFetcher,
} from '@hh.ru/magritte-ui';

import { updateUrl } from 'Modules/url';
import { useSelector } from 'src/hooks/useSelector';
import { SuggestUrl } from 'src/utils/suggest/buildDataProvider';

import {
    SuggestItem,
    SuggestResponse,
    CancelCallback,
} from 'src/components/ResumeAdvancedSearch/SearchQueries/MagritteSearchQuery/types';

const fetchData = (url: string, onCancel: CancelCallback): Promise<SuggestItem[]> =>
    defaultFetcher<SuggestResponse<SuggestItem>>(url, onCancel).then((data) => data?.items ?? []);

const removeDuplicates = (items: SuggestItem[]): SuggestItem[] => [
    ...new Map(items.map((item) => [item.text.toLowerCase(), item])).values(),
];

const constructItemFromQuery = (query: string): SuggestItem => {
    return {
        id: query,
        text: query,
        value: query,
    };
};

const useProvider = (): DataProvider<SuggestItem> => {
    const employerLastSearches = useSelector(({ employerLastSearches }) => employerLastSearches);

    const formatter = useCallback((items: SuggestItem[] | null): DataProviderResult<SuggestItem> => {
        if (!items) {
            return [];
        }
        return items.map((item) => ({
            type: 'cells',
            items: [
                {
                    data: item,
                    value: item.text,
                },
            ],
        }));
    }, []);

    const queryString = (q: string) => updateUrl(SuggestUrl.Vacancy, { q });
    const decoratedFetcher = decorateWithQueryTransformer(fetchData, queryString);

    const staticDataProvider = useMemo(
        () =>
            createDataProvider({
                fetcher: decorateWithResultProducer(
                    createStaticDataFetcher(
                        removeDuplicates(employerLastSearches.map((item) => ({ ...item, value: item.id })))
                    ),
                    formatter
                ),
                minCharsCount: 0,
                debounceTimeout: 0,
            }),
        [formatter, employerLastSearches]
    );
    const mixDataWithLastSearches = useCallback(
        (items: SuggestItem[] | null, query: string) => {
            const filteredLastSearches = employerLastSearches
                .filter((item) => item.text !== '' && item.text.toLowerCase().startsWith(query.toLowerCase()))
                .map((item) => ({ ...item, value: item.id }));
            const queryItem = constructItemFromQuery(query);
            return formatter(removeDuplicates([queryItem, ...filteredLastSearches, ...(items || [])]));
        },
        [formatter, employerLastSearches]
    );
    const remoteDataProviderWithLastSearches = useMemo(
        () =>
            createDataProvider({
                fetcher: decorateWithResultProducer(decoratedFetcher, mixDataWithLastSearches),
                minCharsCount: 2,
                debounceTimeout: 300,
            }),
        [mixDataWithLastSearches, decoratedFetcher]
    );

    return combineProviders([remoteDataProviderWithLastSearches, staticDataProvider]);
};

export default useProvider;
