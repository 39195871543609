import { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { HSpacingContainer, Link, Text, TooltipHover } from '@hh.ru/magritte-ui';
import { GiftOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FormatMoney from 'src/components/FormatMoney';
import useEmployerBillingInfo from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { CurrencyType } from 'src/models/currencies.types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import formatNumber from 'src/utils/formatNumber';

import Informer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/Informer';

import styles from 'src/components/SupernovaMainMenu/EmployerProfile/styles.less';

const TrlKeys = {
    clickmeBalanceStart: 'employer.employerProfile.popup.informers.clickmeBalanceStart',
    clickmeBalanceLinkText: 'employer.employerProfile.popup.informers.clickmeBalanceLinkText',
    clickmeBonus: 'employer.employerProfile.popup.informers.bonusText',
};

const clickmeUrl =
    'https://clickme.hh.ru/new/dashboard?utm_source=hh.ru&utm_medium=referral&utm_campaign=main_menu_balance';

const onClickmeClick = () => {
    Analytics.sendEvent('employer', 'click-clickme-link-in-menu');
    Analytics.sendHHEventButtonClick('clickme_link_in_menu');
};

const BalanceInformer: TranslatedComponent = ({ trls }) => {
    const { billingInfo } = useEmployerBillingInfo();
    const isZp = useIsZarplataPlatform();
    const currency = billingInfo?.billingSummary?.currency || CurrencyType.RUR;
    const clickmeLinkRef = useRef<HTMLAnchorElement>(null);
    const tooltipActivatorRef = useRef(null);

    useEffect(() => {
        if (clickmeLinkRef.current) {
            Analytics.sendEventElementShown(clickmeLinkRef.current, 'employer', 'shown-clickme-link-in-menu');
            Analytics.sendHHEventElementShown(clickmeLinkRef.current, { name: 'clickme_link_in_menu' });
        }
    }, [billingInfo.talantixTariffDaysLeft]);

    if (!billingInfo.clickme || isZp) {
        return null;
    }

    return (
        <Informer
            data-qa="clickme-balance"
            title={
                <>
                    {trls[TrlKeys.clickmeBalanceStart]}
                    {NON_BREAKING_SPACE}
                    <Link
                        inline
                        typography="label-3-regular"
                        ref={clickmeLinkRef}
                        onClick={onClickmeClick}
                        href={clickmeUrl}
                        target="_blank"
                    >
                        {trls[TrlKeys.clickmeBalanceLinkText]}
                    </Link>
                </>
            }
        >
            <HSpacingContainer default={8}>
                <div className={styles.clickmeBalance}>
                    <Text typography="subtitle-1-semibold" style="primary">
                        <FormatMoney currency={currency}>{billingInfo.clickme.realBalance}</FormatMoney>
                    </Text>
                </div>
                {billingInfo.clickme.bonusBalance > 0 && (
                    <>
                        <div className={styles.clickmeBonus}>
                            <div className={styles.clickmeBonusTextContainer}>
                                <Text typography="label-2-regular" style="secondary">
                                    <div className={styles.clickmeBonusText}>
                                        {formatNumber(billingInfo.clickme.bonusBalance)}
                                    </div>
                                </Text>
                            </div>
                            <div ref={tooltipActivatorRef} className={styles.clickmeBonusIconContainer}>
                                <GiftOutlinedSize16 initialColor="secondary" />
                            </div>
                        </div>
                        <TooltipHover placement="bottom-center" activatorRef={tooltipActivatorRef}>
                            {trls[TrlKeys.clickmeBonus]} {formatNumber(billingInfo.clickme.bonusBalance)}
                        </TooltipHover>
                    </>
                )}
            </HSpacingContainer>
        </Informer>
    );
};

export default translation(BalanceInformer);
