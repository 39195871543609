import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { VacancyCompany } from 'src/types/search/vacancy/card';

import { Paging } from 'src/models/paging.types';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';
import { VacancyAddress } from 'src/models/vacancyView.types';

export interface BlacklistVacancyItem {
    id: string;
    hrBrand?: {
        type: 'winner' | 'nominee';
        url: string;
    };
    name: string;
    link: string;
    company: VacancyCompany;
    publicationTime: string;
    compensation: VacancyCompensation;
    address?: VacancyAddress;
    area: {
        '@id': number;
        name: string;
        path: string;
    };
}

export interface ApplicantBlacklistVacancy {
    list: BlacklistVacancyItem[];
    paging?: Paging;
}

const REMOVE_VACANCY = 'REMOVE_VACANCY';

interface PayloadTypes {
    [REMOVE_VACANCY]: {
        vacancyId: string;
    };
}

const INITIAL_STATE: ApplicantBlacklistVacancy = {
    list: [],
    paging: undefined,
};

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const removeVacancy = actionCreator(REMOVE_VACANCY);

export default createReducer<ApplicantBlacklistVacancy, PayloadTypes>(INITIAL_STATE, {
    [REMOVE_VACANCY]: (state, action) => {
        const { vacancyId } = action.payload;

        const newList = state.list.filter((vacancy) => vacancy.id !== vacancyId);

        return {
            ...state,
            list: newList,
        };
    },
});
