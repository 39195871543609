import { useMemo } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useEmployerBillingInfo from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';
import translation from 'src/components/translation';
import { ServiceEmployerDescription } from 'src/models/oldEmployer/employerBillingSummary';
import { ServiceItemCode } from 'src/models/price/productCodes';
import formatNumber from 'src/utils/formatNumber';

import Informer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/Informer';

export const isVideoInterviewService = (service: ServiceEmployerDescription): boolean =>
    service.code === ServiceItemCode.VideoInterview;

const TrlKeys = {
    videoInterview: 'employer.employerProfile.popup.informers.videoInterview',
};

const BalanceInformer: TranslatedComponent = ({ trls }) => {
    const { billingInfo } = useEmployerBillingInfo();

    const videoInterviewTotal = useMemo(() => {
        return billingInfo?.employer?.serviceDescription
            ?.filter(isVideoInterviewService)
            .reduce((total, service) => total + Number(service.count), 0);
    }, [billingInfo]);

    if (!videoInterviewTotal) {
        return null;
    }

    return (
        <Informer data-qa="video-interview-count" title={trls[TrlKeys.videoInterview]}>
            {formatNumber(videoInterviewTotal)}
        </Informer>
    );
};

export default translation(BalanceInformer);
