import { SearchPage, SearchType } from 'src/types/search/common';

import getEmployerVacancySearchParams, {
    EmployerVacancySearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/employerVacancy';
import getResumeSearchParams, {
    ResumeSearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/resume';
import { GetSearchParamsArgs } from 'src/components/Search/Common/Filters/utils/getSearchParams/types';
import getVacancySearchParams, {
    VacancySearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancy';
import getVacancyMapSearchParams, {
    VacancyMapSearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancyMap';

const getSearchParams = (
    props: GetSearchParamsArgs
): VacancySearchParams | ResumeSearchParams | VacancyMapSearchParams | EmployerVacancySearchParams => {
    const { searchType, searchPage } = props;

    if (searchPage === SearchPage.VacancySearchMap) {
        return getVacancyMapSearchParams(props);
    }
    if (searchPage === SearchPage.EmployerView) {
        return getEmployerVacancySearchParams(props);
    }

    if (searchType === SearchType.Resume) {
        return getResumeSearchParams(props);
    }
    return getVacancySearchParams(props);
};

export default getSearchParams;
