import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import notVerifiedStatusBannerClose from '@hh.ru/analytics-js-events/build/xhh/employer/verification/not_verified_status_banner_close_button_click';
import notVerifiedStatusBannerElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/verification/not_verified_status_banner_element_shown';
import notVerifiedStatusBannerPrimaryClick from '@hh.ru/analytics-js-events/build/xhh/employer/verification/not_verified_status_banner_primary_click';
import { Banner, Text, Button, Link } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer/lib/createReducer';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { getEmployerStatusForAnalytics } from 'src/components/EmployerVerification/analytics';
import {
    useEmployerVerificationState,
    EmployerVerificationState,
    BannerType,
} from 'src/components/EmployerVerification/useEmployerVerificationState';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    removeRestrictions: 'employer.notVerifiedBanner.removeRestrictions',
    content: {
        candidates: {
            verificationNewWithoutDocs: 'employer.verification.notVerifiedBanner.candidates.newWithoutDocs.content',
            verificationNewWithDocs: 'employer.verification.notVerifiedBanner.candidates.newWithDocs.content',
            newWithoutDocs: 'employer.notVerifiedBanner.candidates.newWithoutDocs.content',
            newWithDocs: 'employer.notVerifiedBanner.candidates.newWithDocs.content',
            notVerifiedWithoutDocs: 'employer.notVerifiedBanner.candidates.notVerifiedWithoutDocs.content',
        },
        vacancies: {
            verificationNewWithoutDocs: 'employer.verification.notVerifiedBanner.vacancies.newWithoutDocs.content',
            verificationNewWithDocs: 'employer.verification.notVerifiedBanner.vacancies.newWithDocs.content',
            newWithoutDocs: 'employer.notVerifiedBanner.vacancies.newWithoutDocs.content',
            newWithDocs: 'employer.notVerifiedBanner.vacancies.newWithDocs.content',
            notVerifiedWithoutDocs: 'employer.notVerifiedBanner.vacancies.notVerifiedWithoutDocs.content',
        },
        vacancyPreview: {
            verificationNewWithoutDocs: 'employer.verification.notVerifiedBanner.vacancyPreview.newWithoutDocs.content',
            newWithoutDocs: 'employer.notVerifiedBanner.vacancyPreview.newWithoutDocs.content',
            newWithDocs: 'employer.notVerifiedBanner.vacancyPreview.newWithDocs.content',
            notVerifiedWithoutDocs: 'employer.notVerifiedBanner.vacancyPreview.notVerifiedWithoutDocs.content',
        },
        profile: {
            verificationNewWithoutDocs: 'employer.verification.notVerifiedIcon.profile.newWithoutDocs',
            newWithoutDocs: 'employer.notVerifiedBanner.profile.newWithoutDocs.content',
            newWithDocs: 'employer.notVerifiedBanner.profile.newWithDocs.content',
            notVerifiedWithoutDocs: 'employer.notVerifiedBanner.profile.notVerifiedWithoutDocs.content',
        },
    },
};

interface NotVerifiedEmployerBannerProps {
    bannerType: BannerType;
}

const setNewEmployerWithoutDocsClosedCandidatesBannerAction = makeSetStoreField(
    'newEmployerWithoutDocsClosedCandidatesBanner'
);

const NotVerifiedEmployerBanner: TranslatedComponent<NotVerifiedEmployerBannerProps> = ({ trls, bannerType }) => {
    const dispatch = useDispatch();
    const newEmployerWithoutDocsClosedCandidatesBanner = useSelector(
        (state) => state.newEmployerWithoutDocsClosedCandidatesBanner
    );
    const setNewEmployerWithoutDocsClosedCandidatesBanner = (closed: boolean) =>
        dispatch(setNewEmployerWithoutDocsClosedCandidatesBannerAction(closed));
    const { employerVerificationState, shouldShowBanner, setBannerHasClosed, setBannerShownCount } =
        useEmployerVerificationState();
    const [showBanner, setShowBanner] = useState(shouldShowBanner);
    const newWithoutDocs = employerVerificationState === EmployerVerificationState.NewWithoutDocs;
    const newWithDocs = employerVerificationState === EmployerVerificationState.NewWithDocs;
    const notVerifiedWithoutDocs = employerVerificationState === EmployerVerificationState.NotVerifiedWithoutDocs;

    const handlePrimaryClick = () => {
        notVerifiedStatusBannerPrimaryClick({
            statusEmployer: getEmployerStatusForAnalytics(employerVerificationState)!,
        });
    };

    const verificationLinkProps = {
        Element: SPALink,
        to: '/employer/documents',
        target: '_blank',
        inline: true,
        onClick: handlePrimaryClick,
    };

    const handleClose = () => {
        setBannerHasClosed?.();
        setShowBanner(false);
        if ([BannerType.Candidates, BannerType.Vacancies].includes(bannerType) && newWithoutDocs) {
            setNewEmployerWithoutDocsClosedCandidatesBanner(true);
        }
        notVerifiedStatusBannerClose({ statusEmployer: getEmployerStatusForAnalytics(employerVerificationState)! });
    };

    let bannerProps;

    useEffect(() => {
        setBannerShownCount?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!showBanner || newEmployerWithoutDocsClosedCandidatesBanner) {
        return null;
    }

    if (bannerType === BannerType.Candidates) {
        bannerProps = {
            stretched: true,
            content: (
                <Text>
                    {(newWithoutDocs || notVerifiedWithoutDocs) &&
                        trls[TrlKeys.content.candidates.verificationNewWithoutDocs]}
                    {newWithDocs && trls[TrlKeys.content.candidates.verificationNewWithDocs]}
                </Text>
            ),
            buttonBase:
                newWithoutDocs || notVerifiedWithoutDocs ? (
                    <Button
                        Element={SPALink}
                        to="/employer/documents"
                        target="_blank"
                        mode="primary"
                        onClick={handlePrimaryClick}
                    >
                        {trls[TrlKeys.removeRestrictions]}
                    </Button>
                ) : undefined,
        };
    }

    if (bannerType === BannerType.Vacancies) {
        bannerProps = {
            content: (
                <Text>
                    {(newWithoutDocs || notVerifiedWithoutDocs) &&
                        formatToReactComponent(trls[TrlKeys.content.vacancies.verificationNewWithoutDocs], {
                            '{0}': <Link {...verificationLinkProps}>{trls[TrlKeys.removeRestrictions]}</Link>,
                        })}
                    {newWithDocs && trls[TrlKeys.content.vacancies.verificationNewWithDocs]}
                </Text>
            ),
        };
    }

    if (bannerType === BannerType.VacancyPreview) {
        bannerProps = {
            content: (
                <Text>
                    {(newWithoutDocs || notVerifiedWithoutDocs) &&
                        formatToReactComponent(trls[TrlKeys.content.vacancyPreview.verificationNewWithoutDocs], {
                            '{0}': <Link {...verificationLinkProps}>{trls[TrlKeys.removeRestrictions]}</Link>,
                        })}
                    {newWithDocs && trls[TrlKeys.content.vacancyPreview.newWithDocs]}
                </Text>
            ),
        };
    }

    if (bannerType === BannerType.Profile) {
        bannerProps = {
            content: (
                <Text>
                    {(newWithoutDocs || notVerifiedWithoutDocs) &&
                        trls[TrlKeys.content.profile.verificationNewWithoutDocs]}
                    {newWithDocs && trls[TrlKeys.content.profile.newWithDocs]}
                </Text>
            ),
            buttonBase:
                newWithoutDocs || notVerifiedWithoutDocs ? (
                    <Button Element={SPALink} to="/employer/documents" mode="primary" onClick={handlePrimaryClick}>
                        {trls[TrlKeys.removeRestrictions]}
                    </Button>
                ) : undefined,
        };
    }

    return (
        <ElementShownAnchor
            fn={notVerifiedStatusBannerElementShown}
            statusEmployer={getEmployerStatusForAnalytics(employerVerificationState)!}
            hhtmFrom={bannerType}
        >
            <Banner style="warning-secondary" showClose onClose={handleClose} {...bannerProps} />
        </ElementShownAnchor>
    );
};

export default translation(NotVerifiedEmployerBanner);
