import { UserType } from 'src/models/userType';
import { EDIT } from 'src/utils/constants/resumePermission';

import { usePrint } from 'src/hooks/usePrint';
import { useSelector } from 'src/hooks/useSelector';

export const useProfile = (callHook = true, ignoreCanEdit = false): boolean => {
    const isApplicant = useSelector((state) => state.userType === UserType.Applicant);
    const canEdit = useSelector((state) => state.resume?.permission === EDIT);
    const print = usePrint();
    return isApplicant && !print && (canEdit || ignoreCanEdit) && callHook;
};
