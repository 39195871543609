import { AxiosResponse } from 'axios';
import { Merge } from 'type-fest';

import { dispatchNotLuxDataUpdate } from 'Modules/notLuxData';

const notLuxDataInterceptor = <R extends Merge<AxiosResponse, { data: Record<string, unknown> }>>(response: R): R => {
    if (response?.data?.notLuxData) {
        dispatchNotLuxDataUpdate(response.data.notLuxData);
        delete response.data.notLuxData;
    }
    return response;
};

export default notLuxDataInterceptor;
