import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';

const TrlKeys = {
    info: 'index.footer.menuitem.recommendTechInfo',
    link: 'index.footer.menuitem.recommendTechInfo.link',
};

const FooterRecommendTechInfo: TranslatedComponent = ({ trls }) => {
    return (
        <span data-qa="footer-recommendTechInfo">
            {formatToReactComponent(trls[TrlKeys.info], {
                '{link}': (
                    <BlokoLink
                        Element={SPALink}
                        disableVisited
                        to="/article/recommendation_technologies"
                        kind={LinkKind.Secondary}
                        appearance={LinkAppearance.Underlined}
                    >
                        {trls[TrlKeys.link]}
                    </BlokoLink>
                ),
            })}
        </span>
    );
};

export default translation(FooterRecommendTechInfo);
