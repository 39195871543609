import { FC, ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Link, LinkProps } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

export enum SupernovaLinkKind {
    Primary,
    Secondary,
    Dimmed,
    OnWhite,
    WithLine,
    /**
     * Временно, пока не подвезли magritte neutral secondary link
     * https://jira.hh.ru/browse/HH-208392
     */
    MagritteNeutralSecondary,
}

interface SupernovaLink {
    to: string;
    kind?: SupernovaLinkKind;
    onClick?: React.MouseEventHandler;
    isMagritte?: boolean;
    magritteTypography?: LinkProps['typography'];
}

const SupernovaLink: FC<SupernovaLink & ComponentPropsWithoutRef<'a'> & PropsWithChildren> = ({
    children,
    kind,
    isMagritte,
    magritteTypography = 'label-3-regular',
    ...props
}) => {
    if (isMagritte) {
        return (
            <Link
                style="neutral"
                typography={magritteTypography}
                Element={SPALink}
                isSeoLink
                to={props.to}
                onClick={props.onClick}
            >
                {children}
            </Link>
        );
    }
    return (
        <SPALink
            isSeoLink
            {...props}
            className={classnames('supernova-link', {
                'supernova-link_primary': kind === SupernovaLinkKind.Primary,
                'supernova-link_secondary': kind === SupernovaLinkKind.Secondary,
                'supernova-link_dimmed': kind === SupernovaLinkKind.Dimmed,
                'supernova-link_on-white': kind === SupernovaLinkKind.OnWhite,
                'supernova-link_with-line': kind === SupernovaLinkKind.WithLine,
                'supernova-link_magritte-neutral-secondary': kind === SupernovaLinkKind.MagritteNeutralSecondary,
            })}
        >
            {children}
        </SPALink>
    );
};

export default SupernovaLink;
