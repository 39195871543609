import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import VacancyDefaultBody from 'src/models/vacancy/defaultBody';
import { VacancyAction } from 'src/utils/constants/vacancyActions';

import availablePublicationTypes from 'src/models/vacancyCreate/availablePublicationTypes';
import {
    VacancyCreateTemplate,
    VacancyCreateTemplateId,
    IVacancyCreateFormValues,
} from 'src/models/vacancyCreate/vacancyCreate.types';
import vacancyCreateAdvices from 'src/models/vacancyCreate/vacancyCreateAdvices';
import vacancyCreateDescriptionAI from 'src/models/vacancyCreate/vacancyCreateDescriptionAI';
import { VacancyCreateDraft, VacancyCreateDraftId } from 'src/models/vacancyCreate/vacancyCreateDrafts';
import vacancyCreateDuplicatedVacancyIds from 'src/models/vacancyCreate/vacancyCreateDuplicatedVacancyIds';
import vacancyDiscountPromoControlsInfo from 'src/models/vacancyCreate/vacancyDiscountPromoControlsInfo';
import vacancyPurchaseProducts from 'src/models/vacancyCreate/vacancyPurchaseProducts';
import withZpCrossPost from 'src/models/vacancyCreate/withZpCrossPost';

export default {
    availablePublicationTypes,
    withZpCrossPost,
    vacancyCreateDrafts: autoGeneratedReducer<VacancyCreateDraft[]>([]),
    vacancyCreateDraftId: autoGeneratedReducer<VacancyCreateDraftId>(null),
    vacancyCreateTemplates: autoGeneratedReducer<VacancyCreateTemplate[]>([]),
    vacancyCreateTemplateId: autoGeneratedReducer<VacancyCreateTemplateId>(null),
    vacancyCreatePreparedTemplateId: autoGeneratedReducer<number>(null),
    vacancyCreateDefaultBody: autoGeneratedReducer<Partial<VacancyDefaultBody>>({}),
    vacancyCreateInitialBody: autoGeneratedReducer<Partial<IVacancyCreateFormValues>>({}),
    vacancyCreateRules: autoGeneratedReducer({}),
    vacancyCreateDraftSaveEnabled: autoGeneratedReducer(true),
    vacancyCreateError: autoGeneratedReducer<{ insufficientPermissions?: boolean }>({}),
    vacancyCreatePublicationSessionKey: autoGeneratedReducer(''),
    vacancyCreatePublicationUrl: autoGeneratedReducer<
        '/employer/vacancy/create' | `/employer/vacancy/edit/${number}` | `/employer/vacancy/restore/${number}`
    >('/employer/vacancy/create'),
    vacancyCreateAdvices,
    vacancyCreateDuplicatedVacancyIds,
    vacancyDiscountPromoControlsInfo,
    vacancyPurchaseProducts,
    vacancyCreateSavedToDraft: autoGeneratedReducer(false),
    vacancyCreateAllowRegions: autoGeneratedReducer(false),
    vacancyCreateLoadSourceStatus: autoGeneratedReducer(null),
    vacancyCreateDescriptionAI,
    newBuilderFieldsAnnouncementEnabled: autoGeneratedReducer(false),
    messageToModerator: autoGeneratedReducer<{ message?: string }>({}),
    isVacancyEditAfterModerationNotAllowed: autoGeneratedReducer(false),
    isVacancyCreationAllowed: autoGeneratedReducer(true),
    isVacancyEditAllowed: autoGeneratedReducer(true),
    isViewYourOwnDraft: autoGeneratedReducer(true),
    isAddressAddAllowed: autoGeneratedReducer(true),
    isVacancyDiscriminationCheckEnabled: autoGeneratedReducer(false),
    vacancyAction: autoGeneratedReducer<VacancyAction>(null),
    formErrorKeys: autoGeneratedReducer([]),
    otherRoleWarning: autoGeneratedReducer(false),
    isVacancyCreateTemplateNameSuggestionsIgnored: autoGeneratedReducer(false),
    isVacancyCreateDraftNotExistWarningVisible: autoGeneratedReducer(false),
    isVacancyCreateDraftLimitWarningVisible: autoGeneratedReducer(false),
    isVacancyCreateSPADraftPublishing: autoGeneratedReducer(false),
    // Существует только на странице вакансии
    isZpEmployer: autoGeneratedReducer(false),
    isVacancyPublicationSchedulingAllowed: autoGeneratedReducer(false),
};
