import { autoGeneratedReducer, makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { YearMonthDayString } from 'bloko/blocks/calendar/datesHelper';

import { CurrencyType } from 'src/models/currencies.types';

import {
    TariffProductType,
    RegionIdType,
    StartAfterPayment,
    SubProductType,
    ProductType,
} from 'src/models/price/product.types';
import { ServiceCode } from 'src/models/price/productCodes';

export type PriceCartProductType<T extends ProductType = ProductType> = T & {
    activationDate: YearMonthDayString | StartAfterPayment;
    profRoleGroupTrls: string[];
    regionTrl: string;
    itemId: string;
    scaledPrice?: number;
};

export interface ZpCartProductProps {
    product: PriceCartProductType<TariffProductType>;
    translations: { tariffTitle: Record<string, string> };
}

interface PriceCartGiftType {
    code: ServiceCode;
    products: Record<string, SubProductType>;
    profRoleGroups: string[];
    region: RegionIdType;
}

export enum PriceNotificationType {
    PriceChangeSoon = 'PRICE_CHANGE_SOON',
    ProductCloseSoon = 'PRODUCT_CLOSE_SOON',
    ProductCloseSoonPriceChangeSoon = 'PRODUCT_CLOSE_SOON_PRICE_CHANGE_SOON',
    NeedMoreRegionalVpplForDiscount = 'NEED_MORE_VPPL_FOR_DISCOUNT',
}

export type PriceChangeNotificationType =
    | PriceNotificationType.PriceChangeSoon
    | PriceNotificationType.ProductCloseSoon
    | PriceNotificationType.ProductCloseSoonPriceChangeSoon;

export interface PriceChangeNotificationCartItem {
    type: PriceChangeNotificationType;
    params: {
        newPriceDate: string;
        lastDateBeforeNewPrice: string;
    };
}

export interface NeedMoreRegionalVpplForDiscountNotificationCartItem {
    type: PriceNotificationType;
    params: {
        discount: number;
    };
}

interface PriceNotification {
    cart: Array<PriceChangeNotificationCartItem | NeedMoreRegionalVpplForDiscountNotificationCartItem>;
}

export interface PriceCart {
    translations: { tariffTitle: Record<string, string> };
    products: PriceCartProductType[];
    gifts: PriceCartGiftType[];
    discountInPercent: number;
    discountInAmount: number;
    totalPriceWithoutDiscount: number;
    totalPrice: number;
    scaledPrice?: number;
    totalProfit?: number;
    currency: CurrencyType;
    vat: number;
    autoActivation: boolean;
    notifications?: PriceNotification;
}

export const updatePriceCart = makeSetStoreField('priceCart');

export default autoGeneratedReducer<PriceCart>(null);
