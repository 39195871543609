import { CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notification from 'src/components/Notifications/Notification';
import { NotificationProps } from 'src/components/Notifications/Provider/types';
import translation from 'src/components/translation';

const TrlKeys = {
    text: 'copyToClipboard.notification.text',
};

const CopiedToClipboardNotification: TranslatedComponent<NotificationProps> = ({ trls, ...rest }) => {
    return (
        <Notification addon={<CheckCircleFilledSize24 initialColor="positive" />} {...rest}>
            {trls[TrlKeys.text]}
        </Notification>
    );
};

export default translation(CopiedToClipboardNotification);
