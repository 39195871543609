import { useRef } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import ConversionNumber from 'bloko/blocks/conversion';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    text: 'employer.publicationReadyDrafts.text',
    vacancy: 'employer.publicationReadyDrafts.vacancy',
    vacancies: 'employer.vacancyDraft.saveNotificaition.vacancies',
    waiting: 'employer.publicationReadyDrafts.waitingForPublication',
    titleOne: 'employer.publicationReadyDrafts.title.one',
    titleSome: 'employer.publicationReadyDrafts.title.some',
    titleMany: 'employer.publicationReadyDrafts.title.many',
};

const VacancyDraftPublicationReady: TranslatedComponent<ComplexNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const closeNotificationRef = useRef<VoidFunction>(null);
    const { params } = notification;
    const ready = parseInt(params.ready || '0', 10);

    if (ready === 0) {
        onClose();
        return null;
    }

    return (
        <SynchronizedNotification notification={notification} onClose={onClose} closeFuncRef={closeNotificationRef}>
            {ready !== 1 && (
                <>
                    <NotificationHeading>
                        <ConversionNumber
                            value={ready}
                            one={trls[TrlKeys.titleOne]}
                            some={trls[TrlKeys.titleSome]}
                            many={trls[TrlKeys.titleMany]}
                            hasValue
                        />
                    </NotificationHeading>
                    {trls[TrlKeys.text]}
                </>
            )}
            {NON_BREAKING_SPACE}
            <SPALink
                onClick={() => closeNotificationRef.current?.()}
                to={ready === 1 ? `/employer/vacancy/create?draftId=${params.draft_id}` : '/employer/vacancies'}
            >
                {ready === 1 ? trls[TrlKeys.vacancy] : trls[TrlKeys.vacancies]}
            </SPALink>
            {ready === 1 && `${NON_BREAKING_SPACE}${trls[TrlKeys.waiting]}`}
        </SynchronizedNotification>
    );
};

export default translation(VacancyDraftPublicationReady);
