import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { useSelector } from 'src/hooks/useSelector';
import { EmployerBillingInfo } from 'src/models/employerBillingInfo';
import fetcher from 'src/utils/fetcher';

const BILLING_INFO_API_PATH = '/shards/employer/billing_info';

declare global {
    interface FetcherGetApi {
        [BILLING_INFO_API_PATH]: {
            queryParams: void;
            response: EmployerBillingInfo;
        };
    }
}

const billingInfoAction = makeSetStoreField('employerBillingInfo');

const useEmployerBillingInfo = (): {
    billingInfo: EmployerBillingInfo;
    fetchEmployerBillingInfo: (abortController?: AbortController) => Promise<void>;
} => {
    const billingInfo = useSelector(({ employerBillingInfo }) => employerBillingInfo);
    const dispatch = useDispatch();

    const fetchData = useCallback(
        async (abortController?: AbortController) => {
            try {
                const data = await fetcher.get(BILLING_INFO_API_PATH, { signal: abortController?.signal });
                dispatch(billingInfoAction(data));
            } catch (e) {
                console.error(e);
            }
        },
        [dispatch]
    );

    return {
        billingInfo,
        fetchEmployerBillingInfo: fetchData,
    };
};

export default useEmployerBillingInfo;
