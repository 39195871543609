import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CriteriaKey } from 'src/types/search/common/criteria';

export const SEARCH_QUERY_KEYS = [
    CriteriaKey.Logic,
    CriteriaKey.Pos,
    CriteriaKey.ExpPeriod,
    CriteriaKey.ExpCompanySize,
] as const;

export type SearchQueryKeys = (typeof SEARCH_QUERY_KEYS)[number];

export enum LogicOption {
    Normal = 'normal',
    Any = 'any',
    Phrase = 'phrase',
    Except = 'except',
}

export enum PosOption {
    FullText = 'full_text',
    Position = 'position',
    Education = 'education',
    Keywords = 'keywords',
    Workplaces = 'workplaces',
    PreviousWorkplace = 'previous_workplace',
    WorkplaceOrganization = 'workplace_organization',
    WorkplacePosition = 'workplace_position',
    WorkplaceDescription = 'workplace_description',
}

export enum ExpPeriodOption {
    AllTime = 'all_time',
    LastYear = 'last_year',
    LastThreeYears = 'last_three_years',
    LastSixYears = 'last_six_years',
}

export enum ExpCompanySizeOption {
    Any = 'any',
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}

export interface SearchQueries {
    id: number;
    text: string[];
    [CriteriaKey.Logic]: LogicOption[];
    [CriteriaKey.Pos]: PosOption[];
    [CriteriaKey.ExpPeriod]: ExpPeriodOption[];
    [CriteriaKey.ExpCompanySize]: ExpCompanySizeOption[];
    [CriteriaKey.ExpIndustry]: string[];
}

interface SearchQueriesTrls {
    [CriteriaKey.Logic]: Record<LogicOption, string>;
    [CriteriaKey.Pos]: Record<PosOption, string>;
    [CriteriaKey.ExpPeriod]: Record<ExpPeriodOption, string>;
    [CriteriaKey.ExpCompanySize]: Record<ExpCompanySizeOption, string>;
    [CriteriaKey.ExpIndustry]: Record<string, string>;
}

export interface SearchQueriesWithTrl {
    default: SearchQueries;
    values: SearchQueries[];
    trls: SearchQueriesTrls;
}

export default autoGeneratedReducer<SearchQueriesWithTrl>(null);
