import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export const SET_DUPLICATED_VACANCY_IDS = 'UPDATE_DUPLICATED_VACANCY_IDS';

interface PayloadTypes {
    [SET_DUPLICATED_VACANCY_IDS]: number[];
}

export const initialState = [];

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const setDuplicatedVacancyIds = actionCreator(SET_DUPLICATED_VACANCY_IDS);

export default createReducer<number[], PayloadTypes>(initialState, {
    [SET_DUPLICATED_VACANCY_IDS]: (state, action) => {
        return [...action.payload];
    },
});
