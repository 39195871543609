import { useCallback, useEffect, useRef, useState } from 'react';
import parse from 'date-fns/parse';

import Analytics from '@hh.ru/analytics-js';
import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Card,
    DateTimeInput,
    Modal,
    NavigationBar,
    Text,
    Title,
    useBreakpoint,
    VSpacing,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import { dispatchNotLuxDataUpdate } from 'Modules/notLuxData';
import translation from 'src/components/translation';
import { CurrentInterview, TopicNegotiationInterviewInfo } from 'src/models/candidatesList';
import fetcher from 'src/utils/fetcher';

import DateInput from 'src/components/InterviewReminder/DateInput';

const REMINDER_URL = '/shards/employer/resume/interview';

declare global {
    interface FetcherDeleteApi {
        [REMINDER_URL]: {
            queryParams: { negotiationInterviewId: number };
            response: void;
        };
    }

    interface FetcherPostApi {
        [REMINDER_URL]: {
            queryParams: void;
            body: {
                scheduledTime: string;
                topicId: string;
            };
            response: CurrentInterview;
        };
    }

    interface FetcherPutApi {
        [REMINDER_URL]: {
            queryParams: void;
            body: {
                scheduledTime: string;
                negotiationInterviewId: number;
            };
            response: CurrentInterview;
        };
    }
}

interface RemindModalProps {
    remindInfo: TopicNegotiationInterviewInfo;
    visible: boolean;
    topicId: string;
    onClose: () => void;
    onChange: (interview: CurrentInterview) => void;
    onDelete: () => void;
    fromNotification?: boolean;
}

const TrlKeys = {
    title: 'resume.interview.schedule.title',
    description: 'resume.interview.schedule.description',
    alreadyNotified: 'resume.interview.schedule.alreadyNotified',
    time: 'resume.interview.schedule.time.title.alt',
    save: 'resume.interview.schedule.save.alt',
    saveChanges: 'resume.interview.schedule.saveChanges',
    cancel: 'Cancel',
    delete: 'Delete',
    error: 'resume.interview.schedule.error.time.alt',
};

const RemindModal: TranslatedComponent<RemindModalProps> = ({
    trls,
    visible,
    topicId,
    remindInfo: { currentInterview, allowedSchedulingParams },
    fromNotification,
    onClose,
    onChange,
    onDelete,
}) => {
    const [timeError, setTimeError] = useState(false);
    const currentDate = new Date().toISOString();
    const [selectedDate, setSelectedDate] = useState(
        currentInterview?.scheduledTime?.split('T')[0] || currentDate.split('T')[0]
    );
    const [isDatepickerVisible, setIsDatepickerVisible] = useState(false);
    const [time, setTime] = useState(
        formatDate(currentInterview?.scheduledTime || allowedSchedulingParams.nearestAllowedScheduleTime, 'HH:mm')
    );
    const analyticsSentRef = useRef(false);
    const elementRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useBreakpoint();

    const setReminder = () => {
        const parsedTime = parse(time, 'HH:mm', new Date());
        if (Number.isNaN(parsedTime.getTime())) {
            setTimeError(true);
            return;
        }
        const scheduledTime = new Date(`${selectedDate}T${time}`).toISOString();
        const request = currentInterview
            ? fetcher.put(REMINDER_URL, {
                  negotiationInterviewId: currentInterview.negotiationInterviewId,
                  scheduledTime,
              })
            : fetcher.post(REMINDER_URL, {
                  topicId,
                  scheduledTime,
              });

        request.then(
            (response) => {
                if (fromNotification) {
                    Analytics.sendHHEventButtonClick('schedule_interview_submit', { from: 'notification' });
                } else {
                    onChange(response.data);
                }
                setTimeError(false);
                onClose();
            },
            () => {
                setTimeError(true);
            }
        );
    };

    const deleteReminder = () => {
        void fetcher
            .delete(REMINDER_URL, {
                params: { negotiationInterviewId: currentInterview?.negotiationInterviewId || 0 },
            })
            .then(() => {
                if (!fromNotification) {
                    onDelete();
                }
                setTimeError(false);
                onClose();
            }, console.error);
    };

    useEffect(() => {
        if (!analyticsSentRef.current && elementRef.current !== null) {
            Analytics.sendHHEventElementShown(elementRef.current, {
                name: 'interview_shedule',
                labelType: 'create',
            });
            analyticsSentRef.current = true;
        }
    }, []);

    useEffect(() => {
        if (visible) {
            dispatchNotLuxDataUpdate({ enableShortCuts: false });
        } else {
            dispatchNotLuxDataUpdate({ enableShortCuts: true });
        }
    }, [visible]);

    const buttons = (
        <>
            <Button
                mode="tertiary"
                onClick={currentInterview ? deleteReminder : onClose}
                stretched={isMobile}
                data-qa={`interview-reminder-${currentInterview ? 'delete' : 'close'}`}
            >
                {trls[currentInterview ? TrlKeys.delete : TrlKeys.cancel]}
            </Button>
            <Button
                mode="primary"
                style="accent"
                onClick={setReminder}
                stretched={isMobile}
                data-qa="interview-reminder-submit"
            >
                {trls[currentInterview ? TrlKeys.saveChanges : TrlKeys.save]}
            </Button>
        </>
    );

    const onModalClose = useCallback(() => {
        setIsDatepickerVisible(() => false);
        onClose();
    }, [onClose]);

    const content = (
        <div ref={elementRef}>
            {currentInterview?.applicantWasNotified && (
                <>
                    <Card style="warning-secondary" borderRadius={12} padding={16} stretched>
                        {trls[TrlKeys.alreadyNotified]}
                    </Card>
                    <VSpacing default={16} />
                </>
            )}
            <div className="resume-interview-schedule__container">
                <div className="resume-interview-schedule__input">
                    <DateInput
                        isDatepickerVisible={isDatepickerVisible}
                        setIsDatepickerVisible={setIsDatepickerVisible}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        isMobile={isMobile}
                        timeError={timeError}
                    />
                </div>
                <div className={'resume-interview-schedule__input'}>
                    <DateTimeInput
                        timeMask
                        elevatePlaceholder
                        placeholder={trls[TrlKeys.time]}
                        size="large"
                        invalid={timeError}
                        onChange={(value) => {
                            setTime(value);
                        }}
                        value={time}
                        errorMessage={trls[TrlKeys.error]}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Modal
                actions={<Action mode="secondary" onClick={onModalClose} icon={CrossOutlinedSize24} />}
                onClose={onModalClose}
                visible={!isMobile && visible}
                title={
                    <Title Element="h2" size="large">
                        {trls[TrlKeys.title]}
                    </Title>
                }
                titleDescription={
                    <Text typography={'paragraph-1-regular'} style="secondary">
                        {trls[TrlKeys.description]}
                    </Text>
                }
                titleSize="medium"
                footer={<ActionBar primaryActions={buttons} />}
            >
                {content}
            </Modal>
            <BottomSheet
                visible={isMobile && visible}
                onClose={onModalClose}
                footer={<BottomSheetFooter>{buttons}</BottomSheetFooter>}
                header={
                    <NavigationBar
                        showDivider="always"
                        title={trls[TrlKeys.title]}
                        subtitle={trls[TrlKeys.description]}
                    />
                }
            >
                {content}
            </BottomSheet>
        </>
    );
};

export default translation(RemindModal);
