import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { VacancySearchItem } from 'src/types/search/vacancy/card';

import { Paging } from 'src/models/paging.types';

export interface FavoriteVacancies {
    vacancies?: VacancySearchItem[];
    paging?: Paging;
}

export default autoGeneratedReducer<FavoriteVacancies>({});
