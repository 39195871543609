import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { VacancyAdviceWithStatistics } from 'src/models/vacancy/vacancyAdvices.types';

import {
    createFunnelIdsSet,
    createFunnelsData,
    filterFunnel,
    getNewTotalVacancyCount,
    getSelectedVacancies,
} from 'src/models/employerVacancies/utils/managerVacancies';
import {
    Vacancy,
    Drafts,
    EmployerManager,
    ALL_VACANCIES_GROUP_ID,
    ManagerVacancies,
    VacanciesList,
    DEFAULT_GROUP_ID,
    VacanciesGroupItem,
} from 'src/models/employerVacancies/vacancy.types';

const FILTER_VACANCIES = 'FILTER_VACANCIES';
const CHANGE_LOADING_LIST_STATUS = 'CHANGE_LOADING_LIST_STATUS';
const SORT_VACANCIES = 'SORT_VACANCIES';
const ADD_VACANCIES = 'ADD_VACANCIES';
const ADD_VACANCIES_GROUPS = 'ADD_VACANCIES_GROUPS';
const ARCHIVE_MANAGER_VACANCY = 'ARCHIVE_MANAGER_VACANCY';
const ARCHIVE_MANAGER_VACANCIES_IN_GROUPS = 'ARCHIVE_MANAGER_VACANCIES_IN_GROUPS';
const REMOVE_MANAGER_VACANCIES = 'REMOVE_MANAGER_VACANCIES';
const MOVE_MANAGER_VACANCIES = 'MOVE_MANAGER_VACANCIES';
const UPGRADE_MANAGER_VACANCY = 'UPGRADE_MANAGER_VACANCY';
const UPDATE_MANAGER_VACANCIES = 'UPDATE_MANAGER_VACANCIES';
const SET_DRAFTS = 'SET_DRAFTS';
const DISABLE_AUTO_PUBLICATION = 'DISABLE_AUTO_PUBLICATION';
const CANCEL_SCHEDULED_PUBLICATION = 'CANCEL_SCHEDULED_PUBLICATION';
const REFRESH_MANAGER_VACANCY = 'REFRESH_MANAGER_VACANCY';
const UPDATE_HIRING_PLAN_DATA = 'UPDATE_HIRING_PLAN_DATA';
const CHANGE_MANAGER_VACANCY_VISIBILITY = 'CHANGE_MANAGER_VACANCY_VISIBILITY';
const UPDATE_MANAGER_VACANCY_NEGOTIATIONS_NOTPROCESSED_RESPONSES_COUNT =
    'UPDATE_MANAGER_VACANCY_NEGOTIATIONS_NOTPROCESSED_RESPONSES_COUNT';
const SET_VACANCY_ADVICE = 'SET_VACANCY_ADVICE';
const REMOVE_FUNNELS = 'REMOVE_FUNNELS';
const RENAME_FUNNEL = 'RENAME_FUNNEL';
const REMOVE_VACANCIES_FROM_FUNNELS = 'REMOVE_VACANCIES_FROM_FUNNELS';
const UPDATE_FUNNELS_AFTER_ADD_VACANCIES = 'UPDATE_FUNNELS_AFTER_ADD_VACANCIES';

const INITIAL_STATE: ManagerVacancies = {};

interface PayloadTypes {
    [REMOVE_MANAGER_VACANCIES]: {
        vacancyIds: number[] | number;
    };
    [REFRESH_MANAGER_VACANCY]: Vacancy;
    [ARCHIVE_MANAGER_VACANCY]: number[];
    [ARCHIVE_MANAGER_VACANCIES_IN_GROUPS]: number[];
    [MOVE_MANAGER_VACANCIES]: {
        checkedVacancies: number[];
        managerToTransfer: EmployerManager;
        filterEmployerManagerIdList: string[];
        groupId: string | undefined;
        groupManagersIds: Set<number>;
    };
    [UPGRADE_MANAGER_VACANCY]: { vacancy: Vacancy };
    [ADD_VACANCIES]: {
        vacancies: VacanciesList;
        groupId?: string;
        replaceExistedVacancies?: boolean;
    };
    [ADD_VACANCIES_GROUPS]: {
        vacanciesGroups: VacanciesGroupItem[];
    };
    [SET_DRAFTS]: {
        draftsData: {
            draftsTotalCount: number;
            drafts: Drafts;
        };
    };
    [SORT_VACANCIES]: {
        vacancies: VacanciesList;
    };
    [CHANGE_LOADING_LIST_STATUS]: {
        isFetching: boolean;
    };
    [FILTER_VACANCIES]: {
        vacancies: VacanciesList;
    };
    [UPDATE_MANAGER_VACANCIES]: { vacancies: Vacancy[] };
    [DISABLE_AUTO_PUBLICATION]: {
        draftId: number;
    };
    [CANCEL_SCHEDULED_PUBLICATION]: {
        draftId: number;
    };
    [UPDATE_HIRING_PLAN_DATA]: {
        vacancyId: number;
        hasHiringPlan: boolean;
    };
    [CHANGE_MANAGER_VACANCY_VISIBILITY]: {
        vacancyId: number;
        type: 'open' | 'closed';
    };
    [UPDATE_MANAGER_VACANCY_NEGOTIATIONS_NOTPROCESSED_RESPONSES_COUNT]: {
        vacancyId: number;
    };
    [SET_VACANCY_ADVICE]: {
        vacancyId: number;
        adviceWithStatistics: VacancyAdviceWithStatistics[];
    };
    [REMOVE_FUNNELS]: {
        funnelIds: number[];
        currentManagerId: string;
    };
    [RENAME_FUNNEL]: {
        funnelId: number;
        funnelName: string;
    };
    [REMOVE_VACANCIES_FROM_FUNNELS]: {
        vacancyIds: number[];
        currentManagerId: string;
    };
    [UPDATE_FUNNELS_AFTER_ADD_VACANCIES]: {
        funnelId: number;
        vacancies: Vacancy[];
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const moveManagerVacancies = actionCreator(MOVE_MANAGER_VACANCIES);
export const addVacancies = actionCreator(ADD_VACANCIES);

export const addVacanciesGroups = actionCreator(ADD_VACANCIES_GROUPS);
export const setDrafts = actionCreator(SET_DRAFTS);
export const sortVacancies = actionCreator(SORT_VACANCIES);
export const changeListLoadingStatus = actionCreator(CHANGE_LOADING_LIST_STATUS);
export const archiveManagerVacancies = actionCreator(ARCHIVE_MANAGER_VACANCY);
export const archiveManagerVacanciesInGroups = actionCreator(ARCHIVE_MANAGER_VACANCIES_IN_GROUPS);
export const removeManagerVacancies = actionCreator(REMOVE_MANAGER_VACANCIES);
export const upgradeManagerVacancy = actionCreator(UPGRADE_MANAGER_VACANCY);
export const updateManagerVacancies = actionCreator(UPDATE_MANAGER_VACANCIES);
export const filterVacancies = actionCreator(FILTER_VACANCIES);
export const removeAutoPublicationDraft = actionCreator(DISABLE_AUTO_PUBLICATION);
export const removeScheduledPublication = actionCreator(CANCEL_SCHEDULED_PUBLICATION);
export const refreshManagerVacancy = actionCreator(REFRESH_MANAGER_VACANCY);
export const updateHasHiringPlan = actionCreator(UPDATE_HIRING_PLAN_DATA);
export const changeManagerVacancyVisibility = actionCreator(CHANGE_MANAGER_VACANCY_VISIBILITY);
export const updateManagerVacancyNegotiationsNotProcessedResponsesCount = actionCreator(
    UPDATE_MANAGER_VACANCY_NEGOTIATIONS_NOTPROCESSED_RESPONSES_COUNT
);
export const setVacancyAdvice = actionCreator(SET_VACANCY_ADVICE);

export const removeFunnels = actionCreator(REMOVE_FUNNELS);
export const renameFunnel = actionCreator(RENAME_FUNNEL);
export const removeVacanciesFromFunnels = actionCreator(REMOVE_VACANCIES_FROM_FUNNELS);
export const updateFunnelsAfterAddVacancies = actionCreator(UPDATE_FUNNELS_AFTER_ADD_VACANCIES);

const filterVacancyIds = (values: Vacancy[], filterList: number[]) =>
    values.filter(({ vacancyId }) => {
        return !filterList.includes(vacancyId);
    });

const getTotalVacanciesCount = (
    oldShownVacanciesCount: number,
    newShownVacanciesCount: number,
    oldTotalVacanciesCount: number
): number => {
    const delta = oldShownVacanciesCount - newShownVacanciesCount;
    return Math.max(oldTotalVacanciesCount - delta, 0);
};

export default createReducer<ManagerVacancies, PayloadTypes>(INITIAL_STATE, {
    [REMOVE_MANAGER_VACANCIES]: (state, action) => {
        const { vacancyIds } = action.payload;

        if (!!state.drafts && state.draftsTotalCount !== undefined) {
            const idsContainer: number[] = [];
            const ids = idsContainer.concat(vacancyIds);
            const { drafts, draftsTotalCount } = state;
            const newManagerDrafts = drafts.filter(({ id }) => !ids.includes(id));
            const newTotalDraftsCount = getTotalVacanciesCount(
                drafts.length,
                newManagerDrafts.length,
                draftsTotalCount
            );

            return {
                ...state,
                drafts: newManagerDrafts,
                draftsTotalCount: newTotalDraftsCount,
            };
        }

        return { ...state };
    },
    [REFRESH_MANAGER_VACANCY]: (state, { payload: vacancy }) => {
        const { vacancyId } = vacancy;

        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            const newVacanciesList = vacanciesGroup.vacanciesList.map((managerVacancy) => {
                if (managerVacancy.vacancyId === vacancyId) {
                    return { ...managerVacancy, ...vacancy };
                }
                return managerVacancy;
            });

            return {
                ...vacanciesGroup,
                vacanciesList: newVacanciesList,
            };
        });

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [ARCHIVE_MANAGER_VACANCIES_IN_GROUPS]: (state, { payload: vacancies }) => {
        if (!state?.vacanciesGroups) {
            return state;
        }

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            const oldArchivedCount = vacanciesGroup.counters.archivedVacancyCount;
            let newArchivedCount = 0;
            const newVacanciesList = vacanciesGroup.vacanciesList.map((managerVacancy) => {
                if (vacancies.some((id) => managerVacancy.vacancyId === id)) {
                    newArchivedCount += 1;
                    return {
                        ...managerVacancy,
                        archived: true,
                        canBeArchived: false,
                        canEnableAutoProlongation: false,
                        autoProlongationCanBeCanceled: false,
                    };
                }
                return managerVacancy;
            });

            return {
                ...vacanciesGroup,
                counters: {
                    ...vacanciesGroup.counters,
                    archivedVacancyCount: oldArchivedCount + newArchivedCount,
                },
                vacanciesList: newVacanciesList,
            };
        });

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [ARCHIVE_MANAGER_VACANCY]: (state, action) => {
        const checkedVacancies = action.payload;

        if (!state?.vacanciesGroups) {
            return { ...state };
        }
        const oldTotalVacanciesCount = state.vacanciesGroups.activeVacanciesCount;
        let newShownVacanciesCount = 0;
        let oldShownVacanciesCount = 0;

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            const oldManagerGroupVacancies = vacanciesGroup.vacanciesList;
            const newVacanciesList = filterVacancyIds(oldManagerGroupVacancies, checkedVacancies);
            newShownVacanciesCount += newVacanciesList.length;
            oldShownVacanciesCount += oldManagerGroupVacancies.length;

            return {
                ...vacanciesGroup,
                vacanciesList: newVacanciesList,
            };
        });

        const activeVacanciesCount = getTotalVacanciesCount(
            oldShownVacanciesCount,
            newShownVacanciesCount,
            oldTotalVacanciesCount
        );

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                activeVacanciesCount,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [MOVE_MANAGER_VACANCIES]: (state, action) => {
        const { checkedVacancies, managerToTransfer, filterEmployerManagerIdList, groupId, groupManagersIds } =
            action.payload;

        const isManagerToTransferFiltered =
            filterEmployerManagerIdList.length > 0 &&
            !filterEmployerManagerIdList.includes(managerToTransfer.managerId);
        const isGroupContainsManager =
            groupId === ALL_VACANCIES_GROUP_ID || groupManagersIds.has(managerToTransfer.managerId);

        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        let oldShownVacanciesCount = 0;
        let newShownVacanciesCount = 0;

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            const newVacanciesList = vacanciesGroup.vacanciesList.reduce<Vacancy[]>((resultList, vacancy) => {
                const isCheckedVacancy = checkedVacancies.includes(vacancy.vacancyId);
                const isSameManager = vacancy.employerManager.managerId === managerToTransfer.managerId;
                if ((isCheckedVacancy && (isManagerToTransferFiltered || !isGroupContainsManager)) || isSameManager) {
                    return resultList;
                }

                const newVacancy = isCheckedVacancy ? { ...vacancy, employerManager: managerToTransfer } : vacancy;

                resultList.push(newVacancy);
                return resultList;
            }, []);

            newShownVacanciesCount += newVacanciesList.length;
            oldShownVacanciesCount += vacanciesGroup.vacanciesList.length;

            return {
                ...vacanciesGroup,
                vacanciesList: newVacanciesList,
            };
        });

        const activeVacanciesCount = getTotalVacanciesCount(
            oldShownVacanciesCount,
            newShownVacanciesCount,
            state.vacanciesGroups.activeVacanciesCount
        );

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                activeVacanciesCount,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [UPGRADE_MANAGER_VACANCY]: (state, { payload: { vacancy } }) => {
        const { vacancyId } = vacancy;

        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            const newVacanciesList = vacanciesGroup.vacanciesList.map((managerVacancy) => {
                if (managerVacancy.vacancyId === vacancyId) {
                    return { ...managerVacancy, ...vacancy, autoProlongationState: {} };
                }
                return managerVacancy;
            });

            return {
                ...vacanciesGroup,
                vacanciesList: newVacanciesList,
            };
        });

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [ADD_VACANCIES]: (
        state,
        { payload: { groupId = DEFAULT_GROUP_ID, vacancies, replaceExistedVacancies = false } }
    ) => {
        const isAnyVacancyHasBrandingTemplate = vacancies.isAnyVacancyHasBrandingTemplate;
        const isAuctionPresent = vacancies.isAuctionPresent;
        const isCallsPresent = vacancies.isCallsPresent;
        const list: Vacancy[] = vacancies.list || [];

        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            if (vacanciesGroup.groupId === groupId) {
                const newVacanciesList = replaceExistedVacancies ? list : vacanciesGroup.vacanciesList.concat(list);

                return {
                    ...vacanciesGroup,
                    vacanciesList: newVacanciesList,
                    activeVacanciesCount: vacancies.activeVacanciesCount,
                };
            }

            return vacanciesGroup;
        });

        return {
            ...state,
            isFetching: false,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                isAnyVacancyHasBrandingTemplate:
                    state.vacanciesGroups.isAnyVacancyHasBrandingTemplate || isAnyVacancyHasBrandingTemplate,
                isAuctionPresent: state.vacanciesGroups.isAuctionPresent || isAuctionPresent,
                isCallsPresent: state.vacanciesGroups.isCallsPresent || isCallsPresent,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [SET_DRAFTS]: (state, { payload: { draftsData } }) => {
        const { drafts, draftsTotalCount } = draftsData;

        return {
            ...state,
            isFetching: false,
            drafts,
            draftsTotalCount,
        };
    },
    // на экранах, где больше одной воронки не фильтруем и не сортируем.
    // поэтому тут сортируем только первую воронку в списке.
    [SORT_VACANCIES]: (state, { payload: { vacancies } }) => {
        const vacanciesGroups = state.vacanciesGroups!;
        const { list, ...other } = vacancies;

        return {
            ...state,
            isFetching: false,
            vacanciesGroups: {
                ...other,
                vacanciesGroupList: [{ ...vacanciesGroups?.vacanciesGroupList[0], vacanciesList: list || [] }],
            },
        };
    },
    [CHANGE_LOADING_LIST_STATUS]: (state, { payload: { isFetching } }) => ({
        ...state,
        isFetching,
    }),
    // на экранах, где больше одной воронки не фильтруем и не сортируем.
    // поэтому тут фильтруем только первую воронку в списке.
    [FILTER_VACANCIES]: (state, { payload: { vacancies } }) => {
        const vacanciesGroups = state.vacanciesGroups!;
        const { list, ...other } = vacancies;

        return {
            ...state,
            vacanciesGroups: {
                ...other,
                vacanciesGroupList: [{ ...vacanciesGroups?.vacanciesGroupList[0], vacanciesList: list || [] }],
            },
        };
    },
    [UPDATE_MANAGER_VACANCIES]: (state, { payload: { vacancies } }) => {
        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const vacanciesObj: Record<number, Vacancy> = {};
        vacancies.forEach((vacancy) => {
            vacanciesObj[vacancy.vacancyId] = vacancy;
        });

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            const newVacanciesList = vacanciesGroup.vacanciesList.map((managerVacancy) => {
                if (managerVacancy.vacancyId in vacanciesObj) {
                    return { ...managerVacancy, ...vacanciesObj[managerVacancy.vacancyId] };
                }
                return managerVacancy;
            });

            return {
                ...vacanciesGroup,
                vacanciesList: newVacanciesList,
            };
        });

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [DISABLE_AUTO_PUBLICATION]: (state, { payload: { draftId } }) => {
        const autoPublications = state.autoPublications;
        let newAutoPublications;

        if (autoPublications) {
            newAutoPublications = {
                ...autoPublications,
                billsByDrafts: { ...autoPublications.billsByDrafts, [draftId]: null },
                list: autoPublications.list.filter(({ id }) => id !== draftId),
            };
        }
        return {
            ...state,
            autoPublications: newAutoPublications,
        };
    },
    [CANCEL_SCHEDULED_PUBLICATION]: (state, { payload: { draftId } }) => {
        const scheduledPublications = state.scheduledPublications;
        let newScheduledPublications;

        if (scheduledPublications) {
            newScheduledPublications = {
                ...scheduledPublications,
                publicationTime: { ...scheduledPublications.publicationTime, [draftId]: null },
                list: scheduledPublications.list.filter(({ id }) => id !== draftId),
            };
        }

        return {
            ...state,
            scheduledPublications: newScheduledPublications,
        };
    },
    [UPDATE_HIRING_PLAN_DATA]: (state, { payload: { vacancyId, hasHiringPlan } }) => {
        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            const newVacanciesList = vacanciesGroup.vacanciesList.map((vacancy) => {
                if (vacancy.vacancyId === vacancyId) {
                    return {
                        ...vacancy,
                        hasHiringPlan,
                    };
                }
                return vacancy;
            });

            return {
                ...vacanciesGroup,
                vacanciesList: newVacanciesList,
            };
        });

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [CHANGE_MANAGER_VACANCY_VISIBILITY]: (state, { payload: { vacancyId, type } }) => {
        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            const newVacanciesList = vacanciesGroup.vacanciesList.map((vacancy) => {
                if (vacancy.vacancyId === vacancyId) {
                    return {
                        ...vacancy,
                        type,
                    };
                }
                return vacancy;
            });

            return {
                ...vacanciesGroup,
                vacanciesList: newVacanciesList,
            };
        });

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [UPDATE_MANAGER_VACANCY_NEGOTIATIONS_NOTPROCESSED_RESPONSES_COUNT]: (state, { payload: { vacancyId } }) => {
        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            const newVacanciesList = vacanciesGroup.vacanciesList.map((vacancy) => {
                if (vacancy.vacancyId === vacancyId) {
                    return {
                        ...vacancy,
                        negotiations: { ...vacancy.negotiations, notProcessedResponsesCount: 0 },
                    };
                }
                return vacancy;
            });

            return {
                ...vacanciesGroup,
                vacanciesList: newVacanciesList,
            };
        });

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [ADD_VACANCIES_GROUPS]: (state, { payload: { vacanciesGroups } }) => {
        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const newVacanciesList = state.vacanciesGroups.vacanciesGroupList.concat(vacanciesGroups);
        return {
            ...state,
            isFetching: false,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newVacanciesList,
            },
        };
    },
    [SET_VACANCY_ADVICE]: (state, { payload: { vacancyId, adviceWithStatistics } }) => {
        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const newVacanciesGroupList = state.vacanciesGroups.vacanciesGroupList.map((vacanciesGroup) => {
            const newVacanciesList = vacanciesGroup.vacanciesList.map((vacancy) => {
                if (vacancy.vacancyId === vacancyId) {
                    return {
                        ...vacancy,
                        vacancyAdvice: {
                            ...vacancy.vacancyAdvice,
                            adviceWithStatistics,
                        },
                    };
                }
                return vacancy;
            });

            return {
                ...vacanciesGroup,
                vacanciesList: newVacanciesList,
            };
        });

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newVacanciesGroupList,
            },
        };
    },
    [REMOVE_FUNNELS]: (state, { payload: { funnelIds, currentManagerId } }) => {
        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const funnels = state.vacanciesGroups.vacanciesGroupList;
        const funnelsCount = state.vacanciesGroups?.totalVacanciesGroupCount || 0;
        const resultFunnels = funnels
            .filter((funnel) => !funnelIds.includes(funnel.groupId))
            .map((funnel) =>
                funnel.default && String(funnel.ownerEmployerManagerId) === currentManagerId
                    ? { ...funnel, inProgress: true }
                    : funnel
            );

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: resultFunnels,
                totalVacanciesGroupCount: getTotalVacanciesCount(funnels.length, resultFunnels.length, funnelsCount),
            },
        };
    },
    [RENAME_FUNNEL]: (state, { payload: { funnelId, funnelName } }) => {
        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const funnels = state.vacanciesGroups.vacanciesGroupList;
        const resultFunnels = funnels.map((funnel) => {
            if (funnel.groupId === funnelId.toString()) {
                return { ...funnel, name: funnelName };
            }

            return funnel;
        });

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: resultFunnels,
            },
        };
    },
    [REMOVE_VACANCIES_FROM_FUNNELS]: (state, { payload: { vacancyIds, currentManagerId } }) => {
        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const selectedVacancies = getSelectedVacancies(state.vacanciesGroups.vacanciesGroupList, vacancyIds);
        const selectedFunnelIds = createFunnelIdsSet(selectedVacancies);

        const newGroupList = state.vacanciesGroups.vacanciesGroupList
            .map((group) => {
                if (group.default && String(group.ownerEmployerManagerId) === currentManagerId) {
                    return {
                        ...group,
                        inProgress: true,
                    };
                }

                if (!selectedFunnelIds.has(group.groupId)) {
                    return group;
                }

                const newVacanciesList = group.vacanciesList.filter(({ vacancyId }) => !vacancyIds.includes(vacancyId));

                return {
                    ...group,
                    vacanciesList: newVacanciesList,
                    counters: {
                        ...group.counters,
                        totalVacancyCount: getTotalVacanciesCount(
                            group.vacanciesList.length,
                            newVacanciesList.length,
                            group.counters.totalVacancyCount
                        ),
                    },
                    inProgress: true,
                };
            })
            .filter((funnel) => filterFunnel(funnel, selectedFunnelIds));

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newGroupList,
                totalVacanciesGroupCount: getTotalVacanciesCount(
                    state.vacanciesGroups.vacanciesGroupList.length,
                    newGroupList.length,
                    state.vacanciesGroups.totalVacanciesGroupCount || 0
                ),
            },
        };
    },
    [UPDATE_FUNNELS_AFTER_ADD_VACANCIES]: (state, { payload: { vacancies, funnelId } }) => {
        if (!state?.vacanciesGroups) {
            return { ...state };
        }

        const vacancyIds = vacancies.map(({ vacancyId }) => vacancyId);
        const selectedFunnelIds = createFunnelIdsSet(vacancies);
        const funnelsData = createFunnelsData(vacancies);

        const newGroupList = state.vacanciesGroups.vacanciesGroupList
            .map((funnel) => {
                if (funnel.groupId === funnelId.toString()) {
                    return { ...funnel, inProgress: true };
                }

                if (!selectedFunnelIds.has(funnel.groupId)) {
                    return funnel;
                }

                const newTotalVacancyCount = getNewTotalVacancyCount(funnel, funnelsData);

                const newVacanciesList = funnel.vacanciesList.filter(
                    ({ vacancyId }) => !vacancyIds.includes(vacancyId)
                );

                return {
                    ...funnel,
                    vacanciesList: newVacanciesList,
                    counters: {
                        ...funnel.counters,
                        totalVacancyCount: newTotalVacancyCount,
                    },
                    inProgress: true,
                };
            })
            .filter((funnel) => filterFunnel(funnel, selectedFunnelIds));

        return {
            ...state,
            vacanciesGroups: {
                ...state.vacanciesGroups,
                vacanciesGroupList: newGroupList,
                totalVacanciesGroupCount: getTotalVacanciesCount(
                    state.vacanciesGroups.vacanciesGroupList.length,
                    newGroupList.length,
                    state.vacanciesGroups.totalVacanciesGroupCount || 0
                ),
            },
        };
    },
});
