import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const CHANGE_VACANCY_CREATE_DESCRIPTION_AI_GENERATED = 'CHANGE_VACANCY_CREATE_DESCRIPTION_AI_GENERATED';
const INCREASE_VACANCY_CREATE_DESCRIPTION_AI_ATTEMPTS = 'INCREASE_VACANCY_CREATE_DESCRIPTION_AI_ATTEMPTS';
const TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_PROCESSING = 'TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_PROCESSING';
const TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_WARNING = 'TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_WARNING';
const TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_ALERT = 'TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_ALERT';
const TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_ERROR = 'TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_ERROR';

interface PayloadTypes {
    [CHANGE_VACANCY_CREATE_DESCRIPTION_AI_GENERATED]: string;
    [INCREASE_VACANCY_CREATE_DESCRIPTION_AI_ATTEMPTS]: void;
    [TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_PROCESSING]: boolean;
    [TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_WARNING]: boolean;
    [TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_ALERT]: boolean;
    [TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_ERROR]: boolean;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const changeVacancyCreateDescriptionAIGenerated = actionCreator(CHANGE_VACANCY_CREATE_DESCRIPTION_AI_GENERATED);
export const increaseVacancyCreateDescriptionAIAttempts = actionCreator(
    INCREASE_VACANCY_CREATE_DESCRIPTION_AI_ATTEMPTS
);
export const toggleVacancyCreateDescriptionAIProcessing = actionCreator(
    TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_PROCESSING
);
export const toggleVacancyCreateDescriptionAIWarning = actionCreator(TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_WARNING);
export const toggleVacancyCreateDescriptionAIAlert = actionCreator(TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_ALERT);
export const toggleVacancyCreateDescriptionAIError = actionCreator(TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_ERROR);

const initialState = {
    isEnabled: false,
    isProcessing: false,
    isError: false,
    generated: '',
    attempts: 0,
    showEditorWarning: false,
    isAlertEnabled: false,
};

interface State {
    isEnabled: boolean;
    isProcessing: boolean;
    isError: boolean;
    generated: string;
    attempts: number;
    showEditorWarning: boolean; // отвечает за показ уведомления
    isAlertEnabled: boolean; // показ алёрта о затирании текста описания
}

export default createReducer<State, PayloadTypes>(initialState, {
    [CHANGE_VACANCY_CREATE_DESCRIPTION_AI_GENERATED]: (state, action) => {
        return {
            ...state,
            generated: action.payload,
        };
    },
    [INCREASE_VACANCY_CREATE_DESCRIPTION_AI_ATTEMPTS]: (state) => {
        return {
            ...state,
            attempts: state.attempts + 1,
        };
    },
    [TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_PROCESSING]: (state, action) => {
        return {
            ...state,
            isProcessing: action.payload,
        };
    },
    [TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_WARNING]: (state, action) => {
        return {
            ...state,
            showEditorWarning: action.payload,
        };
    },
    [TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_ALERT]: (state, action) => {
        return {
            ...state,
            isAlertEnabled: action.payload,
        };
    },
    [TOGGLE_VACANCY_CREATE_DESCRIPTION_AI_ERROR]: (state, action) => {
        return {
            ...state,
            isError: action.payload,
        };
    },
});
