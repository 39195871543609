import { FC } from 'react';
import classnames from 'classnames';

import { IconColor } from '@hh.ru/magritte-ui';
import { ArrowUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';

import styles from './table-flexible.less';

export enum SortIcons {
    Up = 'Up',
    Down = 'Down',
}

interface TableFlexibleSortingProps {
    direction: SortIcons;
    color?: IconColor;
}

const TableFlexibleSorting: FC<TableFlexibleSortingProps> = ({ direction, color }) => {
    return (
        <span
            className={classnames(styles.tableFlexibleSortIcon, {
                [styles.tableFlexibleSortIconDown]: direction === SortIcons.Down,
            })}
        >
            <BlokoIconReplaceContainer>
                <ArrowUpOutlinedSize16 initialColor={color} />
            </BlokoIconReplaceContainer>
        </span>
    );
};

export default TableFlexibleSorting;
