import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface SubscriptionField {
    value: string;
    channel: 'email' | 'sms' | 'push';
    label: string;
    formLabel: string;
}

export interface FormValues {
    emailCheckboxes: string[];
    pushCheckboxes: string[];
    smsCheckboxes: string[];
}

export interface ApplicantSubscriptions {
    formItems: { [channel: string]: SubscriptionField[] };
    initialValues: FormValues;
    email: string | null;
    emailVerified: boolean | null;
}

export default autoGeneratedReducer<ApplicantSubscriptions>({
    formItems: {},
    initialValues: { emailCheckboxes: [], pushCheckboxes: [], smsCheckboxes: [] },
    email: null,
    emailVerified: null,
});
