import { EmployerSubscriptions } from 'src/models/employerSubscriptions';
import fetcher from 'src/utils/fetcher';

const EMPLOYER_SUBSCRIBE_URL = '/employer/settings/subscription';

interface Payload {
    subscriptions: EmployerSubscriptions['initialValues'];
    employerManagerId?: string;
}

declare global {
    interface FetcherPostApi {
        [EMPLOYER_SUBSCRIBE_URL]: {
            queryParams: { employerManagerId?: string };
            body: Payload['subscriptions'];
            response: void;
        };
    }
}

const saveEmployerSubscriptions = async ({ subscriptions, employerManagerId }: Payload) =>
    await fetcher.post(EMPLOYER_SUBSCRIBE_URL, subscriptions, {
        params: employerManagerId ? { employerManagerId } : {},
    });

export default saveEmployerSubscriptions;
