import { MouseEvent, useContext, useCallback, PropsWithChildren } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Loader } from '@hh.ru/magritte-ui';
import { TrashOutlinedSize24, IconDynamic } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SupernovaDropdownContext from 'src/components/SupernovaMainMenu/SupernovaDropdownContext';
import useDeleteUserNotificationById from 'src/components/SupernovaMainMenu/UserNotifications/useDeleteNotification';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

import NotificationLayout, {
    NotificationLayoutProps,
} from 'src/components/SupernovaMainMenu/UserNotifications/Notification/Layout';

export interface UserNotificationProps extends NotificationLayoutProps, ComplexUserNotification {
    onRemove?: () => void;
}

const sortFunc = (arr: string[]) => {
    const closeButtonIndexes = arr.reduce<number[]>(
        (result, item, index) => (item.startsWith('notificationClose') ? [...result, index] : result),
        [-1]
    );

    const result = [];
    for (let i = 1; i < closeButtonIndexes.length; i += 1) {
        result.push(arr[closeButtonIndexes[i]], ...arr.slice(closeButtonIndexes[i - 1] + 1, closeButtonIndexes[i]));
    }

    result.push(...arr.slice(closeButtonIndexes[closeButtonIndexes.length - 1] + 1));

    return result;
};

const TrlKeys = {
    remove: 'supernova.notifications.remove',
};

const Notification: TranslatedComponent<UserNotificationProps & PropsWithChildren> = ({
    children,
    dataQa,
    dataQaText,
    iconKind,
    onRemove,
    trls,
    ...notification
}) => {
    const [isProgressRemove, deleteNotification] = useDeleteUserNotificationById(notification.id);
    const { elements: dropdownContext, sort } = useContext(SupernovaDropdownContext);
    sort.current = sortFunc;
    const updateRef = useCallback(
        (element: HTMLElement | null) => (dropdownContext[`notificationClose${notification.id}`] = element),
        [dropdownContext, notification.id]
    );

    return (
        <NotificationLayout
            dataQa={dataQa}
            dataQaText={dataQaText}
            iconKind={iconKind}
            data-notification-id={notification.id}
            data-notification-template-key={notification.templateKey}
            removeComponent={
                <>
                    {' '}
                    {isProgressRemove ? (
                        <Loader size={24} initial="secondary" />
                    ) : (
                        <IconDynamic>
                            <button
                                aria-label={trls[TrlKeys.remove]}
                                title={trls[TrlKeys.remove]}
                                onClick={async (event: MouseEvent) => {
                                    event.stopPropagation();
                                    onRemove?.();

                                    if (await deleteNotification()) {
                                        const buttonName = notification.templateKey
                                            .split('.')
                                            .join('_')
                                            .concat('_delete');
                                        Analytics.sendHHEventButtonClick(buttonName);
                                    }
                                }}
                                ref={updateRef}
                            >
                                <TrashOutlinedSize24 initialColor="tertiary" highlightedColor="primary" />
                            </button>
                        </IconDynamic>
                    )}
                </>
            }
        >
            {children}
        </NotificationLayout>
    );
};

export default translation(Notification);
