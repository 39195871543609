export enum SearchType {
    Resume = 'resume',
    Vacancy = 'vacancy',
}

export enum SearchTypeGenitive {
    Resumes = 'resumes',
    Vacancies = 'vacancies',
}

export enum SearchPage {
    ApplicantIndex = 'applicantIndex',
    EmployerView = 'employerView',
    ResumeSearch = 'resumeSearch',
    VacancySearch = 'vacancySearch',
    VacancySearchMap = 'vacancySearchMap',
}

export interface DistrictItem {
    id: number;
    name: string;
}

export type DistrictsMap = Record<string, DistrictItem[]>;
