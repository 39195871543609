import { useState, useCallback, useMemo } from 'react';

import { Action, BottomSheet, NavigationBar, BottomSheetFooter, Button, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ConditionSelect from 'src/components/ResumeAdvancedSearch/SearchQueries/Magritte/ConditionSelect';
import MobilePosSelect from 'src/components/ResumeAdvancedSearch/SearchQueries/Magritte/MobilePosSelect';
import { getHasWorkplaceOptions } from 'src/components/ResumeAdvancedSearch/SearchQueries/posUtils';
import translation from 'src/components/translation';
import {
    SearchQueries,
    PosOption,
    ExpCompanySizeOption,
    ExpPeriodOption,
    LogicOption,
} from 'src/models/search/resume/queries';
import { CriteriaKey } from 'src/types/search/common/criteria';

const TrlKeys = {
    settingsTitle: 'search.resume.query.settings.title',
    settingsSave: 'search.resume.query.settings.button.save',
    settingsReset: 'search.resume.query.settings.button.reset',
};

const defaultSettings = {
    [CriteriaKey.ExpCompanySize]: [ExpCompanySizeOption.Any],
    [CriteriaKey.ExpPeriod]: [ExpPeriodOption.AllTime],
    [CriteriaKey.Logic]: [LogicOption.Normal],
    [CriteriaKey.Pos]: [PosOption.FullText],
    [CriteriaKey.ExpIndustry]: ['any'],
};

interface Props {
    isVisible: boolean;
    onClose: () => void;
    currentQuery: SearchQueries;
    setCurrentQuery: (value: SearchQueries) => void;
}

const QuerySettingsMobile: TranslatedComponent<Props> = ({
    trls,
    isVisible,
    onClose,
    currentQuery,
    setCurrentQuery,
}) => {
    const [selectKey, changeSelectKey] = useState(0);
    const hasWorkplaceOptions = useMemo(() => getHasWorkplaceOptions(currentQuery[CriteriaKey.Pos]), [currentQuery]);

    const isQueryChanged = useMemo(() => {
        return Object.keys(defaultSettings).some((key) => {
            const currentQueryItem = currentQuery[key];

            return (
                key in currentQuery && (currentQueryItem?.length > 1 || currentQueryItem[0] !== defaultSettings[key][0])
            );
        });
    }, [currentQuery]);

    const handleResetQuery = useCallback(() => {
        setCurrentQuery({
            ...currentQuery,
            ...defaultSettings,
        });
        changeSelectKey((val) => val + 1);
    }, [setCurrentQuery, currentQuery, changeSelectKey]);

    return (
        <BottomSheet
            height="full-screen"
            visible={isVisible}
            onClose={onClose}
            header={
                <NavigationBar
                    title={trls[TrlKeys.settingsTitle]}
                    right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                />
            }
            footer={
                <BottomSheetFooter>
                    <Button style="accent" mode="primary" onClick={onClose} stretched>
                        {trls[TrlKeys.settingsSave]}
                    </Button>
                    {isQueryChanged && (
                        <Button style="accent" mode="secondary" onClick={handleResetQuery} stretched>
                            {trls[TrlKeys.settingsReset]}
                        </Button>
                    )}
                </BottomSheetFooter>
            }
        >
            <ConditionSelect name={CriteriaKey.Logic} query={currentQuery} setQuery={setCurrentQuery} />
            <VSpacing default={28} />
            <MobilePosSelect selectKey={selectKey} currentQuery={currentQuery} setCurrentQuery={setCurrentQuery} />
            <ConditionSelect
                name={CriteriaKey.ExpPeriod}
                query={currentQuery}
                setQuery={setCurrentQuery}
                isHidden={!hasWorkplaceOptions}
            />
            {hasWorkplaceOptions && (
                <>
                    <VSpacing default={12} />
                    <ConditionSelect
                        name={CriteriaKey.ExpCompanySize}
                        query={currentQuery}
                        setQuery={setCurrentQuery}
                        isHidden={!hasWorkplaceOptions}
                    />
                </>
            )}
        </BottomSheet>
    );
};

export default translation(QuerySettingsMobile);
