import { AnyAction, Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Push } from '@hh.ru/redux-spa-middleware';
import ModalHelper from 'bloko/common/modalHelper';
import urlParser from 'bloko/common/urlParser';

import { Banner } from 'src/models/banners/banners';
import { ResumeCommentsState } from 'src/models/employer/resume/commentsByUser';
import { ResumeCommentState } from 'src/models/employer/resume/resumeComments';
import { MicroFrontends, updateMicroFrontends } from 'src/models/microFrontends';
import { ResumeId } from 'src/models/resume/resume.types';
import { VerifiedSkillsWithReportsFlag } from 'src/models/resume/resumeVerifiedSkills.types';
import { setSearchClustersOrder } from 'src/models/search/common/clustersOrder';
import { ResumeSearchResult } from 'src/models/search/resume/result';
import { VacancyToResumeSkillMatch } from 'src/models/vacancyToResumeSkillMatch';
import { SearchClusterMap, ClusterKey } from 'src/types/search/common/clusters';
import { CriteriaKey } from 'src/types/search/common/criteria';
import fetcher from 'src/utils/fetcher';

const resumeSearchResultAction = makeSetStoreField('resumeSearchResult');
const searchClustersAction = makeSetStoreField('searchClusters');
const searchSessionIdAction = makeSetStoreField('searchSessionId');
const bannersAction = makeSetStoreField('banners');
const searchCountsAction = makeSetStoreField('searchCounts');
const resumeCommentsAction = makeSetStoreField('resumeComments');
const commentsByUserIdAction = makeSetStoreField('commentsByUserId');
const resumeVerifiedSkillsAction = makeSetStoreField('resumeVerifiedSkills');
const vacancyToResumeSkillMatchAction = makeSetStoreField('vacancyToResumeSkillMatch');

interface SearchResumeResponse {
    searchClusters: SearchClusterMap;
    resumeSearchResult: ResumeSearchResult;
    banners: Record<string, Banner[]>;
    searchClustersOrder: Record<ClusterKey, string[]>;
    resumeComments: ResumeCommentState;
    commentsByUserId: ResumeCommentsState;
    microFrontends?: MicroFrontends;
    resumeVerifiedSkills: Record<ResumeId, VerifiedSkillsWithReportsFlag>;
    vacancyToResumeSkillMatch: VacancyToResumeSkillMatch;
}

const RESUME_SEARCH_URL = '/shards/resume/search';

declare global {
    interface FetcherGetApi {
        [RESUME_SEARCH_URL]: {
            response: SearchResumeResponse;
            queryParams: undefined;
        };
    }
}

interface Params {
    dispatch: Dispatch;
    query: string;
    abortSignal?: AbortSignal;
}

type FetchResume = () => Promise<{ totalResults: number; redirect?: string }>;

const getFetchResume =
    ({ dispatch, query, abortSignal }: Params, push: Push): FetchResume =>
    async () => {
        let response;
        try {
            response = await fetcher.get(`${RESUME_SEARCH_URL}?${query}` as typeof RESUME_SEARCH_URL, {
                signal: abortSignal,
                params: undefined,
            });
        } catch (error) {
            return Promise.reject(error as Error);
        }

        // try to stable resume search with default cluster position
        ModalHelper.disableScroll();

        // get search data
        const {
            searchClusters,
            resumeSearchResult,
            banners,
            searchClustersOrder,
            resumeComments,
            commentsByUserId,
            microFrontends,
            resumeVerifiedSkills,
            vacancyToResumeSkillMatch,
        } = response;

        // update url
        const { pathname } = location;
        const parsedUrl = urlParser(`${pathname}?${query}`);
        if (parsedUrl.params.searchSessionId) {
            delete parsedUrl.params.searchSessionId;
        }

        push(parsedUrl.href, undefined, { cancelFetchingData: true });

        // send search data
        const actions: AnyAction[] = [
            setSearchClustersOrder(searchClustersOrder),
            searchClustersAction(searchClusters),
            resumeSearchResultAction(resumeSearchResult),
            bannersAction(banners),
            resumeCommentsAction(resumeComments),
            commentsByUserIdAction(commentsByUserId),
            searchCountsAction({ isLoad: false, value: 0 }), // delete after use nova controls on resume search
            resumeVerifiedSkillsAction(resumeVerifiedSkills),
            vacancyToResumeSkillMatchAction(vacancyToResumeSkillMatch),
        ];
        if (microFrontends) {
            actions.push(updateMicroFrontends(microFrontends));
        }
        const searchSessionId = resumeSearchResult.criteria?.[CriteriaKey.SearchSessionId];
        if (searchSessionId) {
            actions.push(searchSessionIdAction(searchSessionId));
        }
        dispatch(actions);

        return { totalResults: resumeSearchResult.stats.totalUngroupedResults };
    };

export default getFetchResume;
