import { ComponentType, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import userStatusSelectWhereFoundScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_where_found_screen_shown';
import {
    Modal,
    Action,
    Button,
    BottomSheet,
    BottomSheetFooter,
    NavigationBar,
    useBreakpoint,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { LangTrls } from 'bloko/common/hooks/useTranslations';

import applicantJobSearchStatusSuccess from 'src/components/Notifications/ApplicantJobSearchStatusSuccess';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useTopLevelSite } from 'src/hooks/useSites';
import { hidePostChangeModal } from 'src/models/applicant/jobSearchStatus';
import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import { TopLevelSite } from 'src/models/topLevelSite';

import useFetchPossibleJobOffers from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/api/useFetchPossibleJobOffers';
import {
    useFoundOnHHStep,
    useReasonsStep,
    useOffersStep,
} from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/hooks';
import FoundOnHhStep from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/steps/FoundOnHHStep';
import OffersStep from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/steps/OffersStep';
import ReasonsStep from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/steps/ReasonsStep';
import { ModalStep, StepContent } from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/types';

const TrlKeys = {
    title: {
        [ModalStep.FoundOnHHStep]: {
            [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.postChangeModal.step.foundOnHH.has_job_offer.v2',
            others: 'jobSearchStatus.postChangeModal.step.foundOnHH.v2',
        },
        [ModalStep.ChooseReasonStep]: {
            [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.postChangeModal.step.ChooseOfferStep.has_job_offer.v2',
            others: 'jobSearchStatus.postChangeModal.step.ChooseOfferStep.others.v2',
        },
        [ModalStep.ChooseOfferStep]: {
            [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.postChangeModal.step.ChooseOfferStep.has_job_offer.v2',
            others: 'jobSearchStatus.postChangeModal.step.ChooseOfferStep.others.v2',
        },
    },
    description: {
        [ModalStep.ChooseReasonStep]: 'jobSearchStatus.postChangeModal.notOnHH.description',
    },
    buttons: {
        save: 'jobSearchStatus.postChangeModal.footer.save.v2',
        anotherCompany: 'jobSearchStatus.postChangeModal.footer.anotherCompany.v2',
    },
};

const getStatusKey = (userStatus: JobSearchStatus) => {
    return userStatus === JobSearchStatus.HasJobOffer ? JobSearchStatus.HasJobOffer : 'others';
};

const getModalTitle = (
    step: ModalStep,
    trls: LangTrls,
    topLevelDomain: string | null,
    userStatus: JobSearchStatus
): string => {
    const statusKey = getStatusKey(userStatus);

    if (step === ModalStep.FoundOnHHStep) {
        return trls[TrlKeys.title[ModalStep.FoundOnHHStep][statusKey]].replace(
            '{0}',
            topLevelDomain ?? TopLevelSite.RU
        );
    }

    return trls[TrlKeys.title[step][statusKey]];
};

const getModalDescription = (step: ModalStep, trls: LangTrls) => {
    if (step === ModalStep.ChooseReasonStep) {
        return trls[TrlKeys.description[step]];
    }

    return null;
};

const getModalButtons = (step: ModalStep, steps: StepContent, userStatus: JobSearchStatus) => {
    const statusKey = getStatusKey(userStatus);

    if (step === ModalStep.ChooseOfferStep) {
        return steps[step].buttons[statusKey];
    }

    return null;
};

const PostChangeModal: ComponentType = translation(({ trls }) => {
    const dispatch = useDispatch();

    const { addNotification } = useNotification();
    const { isMobile } = useBreakpoint();
    const { fetchOffers, cancelFetch } = useFetchPossibleJobOffers();

    const modalVisible = useSelector((state) => state.jobSearchStatusPostChangeModal?.visibleForApplicantResumes);
    const showStatusChangeNotification = useSelector(
        (state) => state.jobSearchStatusPostChangeModal?.showStatusChangeNotification
    );
    const topLevelSite = useTopLevelSite();
    const userStatuses = useSelector((state) => state.applicantUserStatuses);
    const userStatus = userStatuses?.jobSearchStatus?.name || JobSearchStatus.AcceptedJobOffer;

    const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.FoundOnHHStep);

    const hideModal = useCallback(() => {
        dispatch(hidePostChangeModal(true));

        if (showStatusChangeNotification) {
            addNotification(applicantJobSearchStatusSuccess);
        }
    }, [dispatch, showStatusChangeNotification, addNotification]);

    const handleClose = useCallback(() => {
        cancelFetch();
        hideModal();
    }, [hideModal, cancelFetch]);

    const updateModalStep = useCallback((step: ModalStep) => {
        setModalStep(step);
    }, []);

    const { foundOnHHValue, updateFoundOnHHValue } = useFoundOnHHStep({
        setModalStep: updateModalStep,
        closeModal: handleClose,
        fetchOffers,
    });

    const { reasonValue, updateReasonValue } = useReasonsStep({
        closeModal: handleClose,
    });

    const { selectedOffers, addOffer, finishChooseOffers, updateSelectedOffers } = useOffersStep({
        userStatus,
        closeModal: hideModal,
    });

    useEffect(() => {
        if (modalVisible) {
            updateModalStep(ModalStep.FoundOnHHStep);
            userStatusSelectWhereFoundScreenShown();
        }
    }, [modalVisible, updateModalStep]);

    useEffect(() => {
        if (!modalVisible) {
            updateFoundOnHHValue(null);
            updateReasonValue(null);
            updateSelectedOffers([]);
        }
    }, [modalVisible, updateFoundOnHHValue, updateReasonValue, updateSelectedOffers]);

    const STEP_CONTENT: StepContent = {
        [ModalStep.FoundOnHHStep]: {
            content: <FoundOnHhStep checkedValue={foundOnHHValue} setValue={updateFoundOnHHValue} />,
        },
        [ModalStep.ChooseReasonStep]: {
            content: <ReasonsStep checkedValue={reasonValue} setValue={updateReasonValue} />,
        },
        [ModalStep.ChooseOfferStep]: {
            content: <OffersStep userStatus={userStatus} addOffer={addOffer} selectedOffers={selectedOffers} />,
            buttons: {
                [JobSearchStatus.HasJobOffer]: (
                    <>
                        <Button
                            mode="secondary"
                            style="accent"
                            onClick={() => {
                                hideModal();
                            }}
                        >
                            {trls[TrlKeys.buttons.anotherCompany]}
                        </Button>
                        <Button
                            mode="primary"
                            style="accent"
                            disabled={selectedOffers.length === 0}
                            onClick={() => {
                                finishChooseOffers(selectedOffers);
                            }}
                        >
                            {trls[TrlKeys.buttons.save]}
                        </Button>
                    </>
                ),
                others: (
                    <Button
                        mode="secondary"
                        style="neutral"
                        onClick={() => {
                            hideModal();
                        }}
                    >
                        {trls[TrlKeys.buttons.anotherCompany]}
                    </Button>
                ),
            },
        },
    };

    const title = getModalTitle(modalStep, trls, topLevelSite, userStatus);
    const description = getModalDescription(modalStep, trls);
    const buttons = getModalButtons(modalStep, STEP_CONTENT, userStatus);

    const content = STEP_CONTENT[modalStep].content;

    return (
        <>
            <Modal
                visible={!isMobile && modalVisible}
                title={title}
                titleDescription={description}
                footer={!!buttons && <ActionBar primaryActions={buttons} />}
                titleDescriptionStyle="secondary"
                onClose={handleClose}
                actions={
                    <Action
                        data-qa={'post-change-modal-close'}
                        mode="secondary"
                        onClick={handleClose}
                        icon={CrossOutlinedSize24}
                    />
                }
            >
                {content}
            </Modal>
            <BottomSheet
                visible={isMobile && !!modalVisible}
                onClose={handleClose}
                header={
                    <NavigationBar
                        title={title}
                        subtitle={description}
                        right={
                            <Action
                                data-qa={'post-change-modal-close'}
                                icon={CrossOutlinedSize24}
                                onClick={handleClose}
                            />
                        }
                        size="large"
                    />
                }
                footer={buttons ? <BottomSheetFooter>{buttons}</BottomSheetFooter> : null}
            >
                {content}
            </BottomSheet>
        </>
    );
});

export default PostChangeModal;
