import {
    MutableRefObject,
    FC,
    PropsWithChildren,
    ReactNode,
    useEffect,
    useContext,
    useState,
    useRef,
    memo,
    StrictMode,
} from 'react';

import Loading, { LoadingScale } from 'bloko/blocks/loading';

import Debug from 'HHC/Debug';
import loadProxyService, { RemoteModuleOrNull } from 'Modules/ProxyExternalServiceLoader';
import { useSelector } from 'src/hooks/useSelector';
import { SerivceInfo, MicroFrontendServiceName } from 'src/models/microFrontends';

import { CodeInjectorContext } from 'src/components/CodeInjector';

interface ContainerForMicroFrontendProps {
    preloadContent?: ReactNode;
    place: string;
    isFullPage?: boolean;
    serviceInfo: MutableRefObject<SerivceInfo>;
    innerRef?: MutableRefObject<HTMLDivElement | null>;
}

const getClassName = (place: string, id?: string) => `HH-ContainerForMicroFrontend-${place}-${id ?? 'emptyId'}`;
const getHiddenContainerClassName = (place: string) => `.HH-ContainerForMicroFrontend-Hidden-${place}`;

let treeId = 0;

/**
 * Этот компонент должен рендерится только 1 раз, множественные ререндеры
 * будут приводить к удалению содержимого */
const ContainerForMicroFrontend: FC<PropsWithChildren<ContainerForMicroFrontendProps>> = ({
    preloadContent = <Loading scale={LoadingScale.Small} />,
    serviceInfo,
    place,
    isFullPage = false,
    innerRef,
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const preloadContentRef = useRef<HTMLDivElement | null>(null);
    const codeInjectorContext = useContext(CodeInjectorContext);
    const classNameForJS = getClassName(place, serviceInfo?.current?.id);
    let content = null;

    let innerHTML = {};

    if (process.env.SSR) {
        codeInjectorContext.placesNamesForMicroFrontends.push(place);
        const MicrofrontendDelimeter = place;
        content = <MicrofrontendDelimeter />;
    } else if (serviceInfo.current?.isSuccessSSR) {
        const container: HTMLDivElement | null =
            document.querySelector(`.${classNameForJS}`) ?? document.querySelector(getHiddenContainerClassName(place));

        innerHTML = {
            dangerouslySetInnerHTML: {
                /* Если пытаться переиспользовать отгидрированную верстку
                 * при новом ините, будут ошибки гидрации */
                __html: container?.dataset?.rendered === 'true' ? undefined : container?.innerHTML,
            },
        };
    }

    useEffect(() => {
        const container = containerRef.current;
        const abortController = new AbortController();
        let globalServiceName: MicroFrontendServiceName;
        let app: RemoteModuleOrNull = null;

        treeId += 1;
        const identifier = `${place}-${treeId}`;
        document.querySelector(getHiddenContainerClassName(place))?.remove();

        /* Если пытаться переиспользовать отгидрированную верстку
         * при новом ините, будут ошибки гидрации */
        if (!serviceInfo.current?.isSuccessSSR && container?.dataset?.rendered === 'true') {
            container.innerHTML = '';
        }

        void loadProxyService(place, serviceInfo.current, container, identifier, abortController.signal, isFullPage)
            .then((data) => {
                if (data && container) {
                    const [appModule, globalServiceNameValue] = data;
                    app = appModule;
                    globalServiceName = globalServiceNameValue;
                }

                if (preloadContentRef.current) {
                    preloadContentRef.current.style.display = 'none';
                }

                if (container) {
                    container.dataset.rendered = 'true';
                }
            })
            .catch((error: DOMException | Error) => {
                if (error.message !== 'Operation aborted loadProxyService') {
                    throw error;
                }
            });

        return () => {
            abortController.abort();
            if (!globalServiceName) {
                return;
            }
            /* Destroy надо делать в micro || macro таске, иначе получаем гонку между unmount
             * microfrontend и xhh https://github.com/facebook/react/issues/25675
             * */
            setTimeout(() => {
                const isSupportDestroy = window.globalServiceVars?.[globalServiceName]?.hasSupportToDestroyApp;
                if (!isSupportDestroy) {
                    Debug.log('error out', `Legacy service: ${globalServiceName}, not support to destroy`, { place });
                }

                if (container && isSupportDestroy) {
                    app?.({
                        unmountApp: true,
                        container,
                        identifier,
                        place,
                    });
                    app = null;
                }
            }, 0);
        };
    }, [place, isFullPage, serviceInfo]);

    if (!serviceInfo.current) {
        return null;
    }

    return (
        <>
            <div ref={preloadContentRef}>{!serviceInfo?.current?.isSuccessSSR && preloadContent}</div>
            <div
                ref={(element) => {
                    containerRef.current = element;
                    if (innerRef) {
                        innerRef.current = element;
                    }
                }}
                className={classNameForJS}
                {...innerHTML}
            >
                {content}
            </div>
        </>
    );
};

interface ContainerForMicroFrontendWrapper {
    preloadContent?: ReactNode;
    place: string;
    isFullPage?: boolean;
    innerRef?: MutableRefObject<HTMLDivElement | null>;
}

const ContainerForMicroFrontendWrapper: FC<PropsWithChildren<ContainerForMicroFrontendWrapper>> = ({
    place,
    preloadContent,
    innerRef,
}) => {
    const serviceInfo = useSelector(({ microFrontends }) => microFrontends[place]);
    const [keyComponent, setKeyComponent] = useState(place);
    const previousServiceInfoRef = useRef(serviceInfo);

    useEffect(() => {
        if (previousServiceInfoRef.current !== serviceInfo) {
            previousServiceInfoRef.current = serviceInfo;
            setKeyComponent(`${place}-${Date.now()}`);
        }
    }, [place, serviceInfo]);

    if (!serviceInfo) {
        return null;
    }

    /* Идея в уничтожении компонента если serviceInfo возвращает новую ссылку
     * это поможет корректно инициализировать приложение всегда на новой DOM ноде
     * */
    return (
        <StrictMode>
            <ContainerForMicroFrontend
                key={keyComponent}
                preloadContent={preloadContent}
                serviceInfo={previousServiceInfoRef}
                place={place}
                innerRef={innerRef}
            />
        </StrictMode>
    );
};

export default memo(ContainerForMicroFrontendWrapper, () => true);
