import { useRef, ReactElement, ComponentProps, useEffect, SyntheticEvent } from 'react';

import notVerirfiedStatusRemoveRestrictionsElementShown, {
    notVerirfiedStatusRemoveRestrictionsElementShownRaw,
    ViewLabel,
} from '@hh.ru/analytics-js-events/build/xhh/employer/verification/not_verirfied_status_remove_restrictions_element_shown';
import { Text, BottomSheet, VSpacing, Button, BottomSheetFooter, TooltipHover } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { TooltipType } from 'src/components/EmployerVerification/useEmployerVerificationState';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';

import styles from './styles.less';

interface EmployerVerificationIconProps {
    icon: ReactElement;
    tipContent: ReactElement | string;
    placement: ComponentProps<typeof TooltipHover>['placement'];
    actionButton?: ReactElement;
    tooltipActionProps?: { buttons?: (onClose: VoidFunction) => ReactElement; showClose: boolean };
    analyticsParams?: {
        vacancyId?: string;
        draftId?: string;
        viewLabel: ViewLabel;
        tooltipType: TooltipType;
    };
    shouldShowTooltip?: boolean;
}

const TrlKeys = {
    close: 'employer.notVerifiedBanner.close',
};

const EmployerVerificationIcon: TranslatedComponent<EmployerVerificationIconProps> = ({
    trls,
    icon,
    tipContent,
    placement,
    actionButton,
    tooltipActionProps,
    analyticsParams,
    shouldShowTooltip = true,
}) => {
    const [isVisible, showTooltip, hideTooltip] = useOnOffState(false);
    const activatorRef = useRef<HTMLSpanElement>(null);
    const notVerirfiedStatusTooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isVisible && notVerirfiedStatusTooltipRef.current && analyticsParams) {
            const { stopSpying } = notVerirfiedStatusRemoveRestrictionsElementShown(
                notVerirfiedStatusTooltipRef.current,
                analyticsParams
            );
            return () => stopSpying();
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    const handleClickIcon = (e: SyntheticEvent) => {
        e.stopPropagation();

        showTooltip();
    };

    return (
        <>
            <span className={styles.verificationEmployerIcon} ref={activatorRef} onClick={handleClickIcon}>
                {icon}
            </span>
            {shouldShowTooltip && (
                <div ref={notVerirfiedStatusTooltipRef}>
                    <BottomSheet
                        visible={isVisible}
                        footer={
                            <BottomSheetFooter>
                                <VSpacing default={12} />
                                {actionButton}
                                <Button mode="secondary" style="accent" onClick={hideTooltip}>
                                    {trls[TrlKeys.close]}
                                </Button>
                            </BottomSheetFooter>
                        }
                        onClose={hideTooltip}
                    >
                        <>
                            <VSpacing default={16} />
                            <Text typography="label-1-regular">{tipContent}</Text>
                        </>
                    </BottomSheet>
                    <TooltipHover
                        onClose={hideTooltip}
                        activatorRef={activatorRef}
                        placement={placement}
                        forcePlacement
                        onAppear={() => {
                            if (analyticsParams) {
                                notVerirfiedStatusRemoveRestrictionsElementShownRaw(analyticsParams);
                            }
                        }}
                        {...tooltipActionProps}
                    >
                        <Text>{tipContent}</Text>
                    </TooltipHover>
                </div>
            )}
        </>
    );
};
export default translation(EmployerVerificationIcon);
