import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import {
    MailTemplateTypeToExplicitlySaveByFrontType,
    SmsTemplateTypeToExplicitlySaveByFrontType,
    TemplateType,
} from 'src/models/employerMailTemplates';
import { StateId } from 'src/models/employerNegotiations/stateId.types';
import { Permission } from 'src/models/resume/resumeCommon.types';

import {
    ChangeTopicContentError,
    ChangeTopicFormErrorCode,
    isChangeTopicContentError,
} from 'src/models/employerNegotiationsChangeTopic/error.types';
import { NonappearanceReason } from 'src/models/employerNegotiationsChangeTopic/nonappearanceReason.types';

export interface Area {
    id: string;
    name: string;
}

export interface Resume {
    areaId: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    openedContacts: boolean;
    permission: Permission;
    resumeHash: string;
    resumeId: number;
    userId: string;
    vacancyId?: number;
    topicId?: number;
}

interface SimhashVacancy {
    id: string;
    name: string;
}

interface Address {
    id: number | null;
    city: string | null;
    street: string | null;
    building: string | null;
    displayName: string | null;
    rawAddress: string | null;
    mapData: Record<string, unknown> | null;
    metroStations: Record<string, unknown> | null;
}

export interface Vacancy {
    id: number;
    isSelected: boolean;
    name: string;
    area: {
        id: number;
        name: string;
    };
    address: Address;
    nameWithArea: string;
}

export enum AssessmentTool {
    HhOwnertool = 'HH_OWNERTOOL',
    Skillaz = 'SKILLAZ_VIDEO_INTERVIEW',
}

export interface Assessment {
    isSelected: boolean;
    code: AssessmentTool;
    count: number | null;
    countWithQuotas: number | null;
    quotasApplied: boolean | null;
}

interface State {
    isSelected: boolean;
    id: StateId;
    showNonappearanceReasons: boolean | null;
    hasAddress: boolean;
}

export interface RootStage {
    funnelStageId: number;
    state: StateId;
    name: string;
    selected: boolean;
}

export interface TemplateInfo {
    addressAllowed: boolean;
    addressId: number | null;
    employerManagerTemplateId: number | null;
    fromEmployerManagerTemplates: boolean;
    sendMessagePredefinedByUser: boolean;
    sendMessageRequired: boolean;
    sendMessageRequiredWarning: boolean;
    sendSmsPredefinedByUser: boolean;
    smsAllowed: boolean;
    smsEmployerManagerTemplateId: number | null;
    smsTemplate: string | null;
    usedSmsTemplateType: TemplateType | null;
    template: string | null;
    templateModified: boolean;
    usedTemplateType: TemplateType | null;
    mailTemplateTypeToExplicitlySaveByFront: MailTemplateTypeToExplicitlySaveByFrontType | null; // выпилить как удалим на бэке этот флаг
    smsTemplateTypeToExplicitlySaveByFront: SmsTemplateTypeToExplicitlySaveByFrontType | null; // выпилить как удалим на бэке этот флаг
    useAddressFromVacancy: boolean;
}

export interface FullStage extends RootStage {
    collectionId: string;
    parentStageId: number | null;
    quickTransferToStageEnabled: boolean;
    templateInfo: TemplateInfo;
}

export interface AvailableStagesForSelectedState extends FullStage {
    substates: FullStage[];
}

export interface Template {
    type: string | null;
    content?: string;
    isModified: boolean;
}

interface Error {
    code: string;
    trl: string;
}

export interface CustomError {
    code: ChangeTopicContentError;
    props?: Record<string, unknown>;
}

export const isCustomError = (error: Error | CustomError): error is CustomError => {
    return isChangeTopicContentError(error.code);
};

interface ChangeTopic {
    vacancies: {
        total: number;
        list: Vacancy[];
    };
    assessment: Assessment[];
    states: State[];
    allAvailableRootStages: RootStage[];
    availableStagesForSelectedState: AvailableStagesForSelectedState;
    templates: Template[];
    resumes: Resume[];
    topicsWontBeUpdated: number | null;
    maxDisplayedVacancies: number;
    nonappearanceReasons: NonappearanceReason[];
    errors: {
        list: Error[] | CustomError[];
    };
    smsModificationBlocked: boolean;
    canDiscardWithoutFeedback: boolean;
    funnel?: {
        currentUserHasWriteAccess: boolean;
        funnelId: number;
        ownerEmployerManagerId: number;
    };
    sendMessageAllowed: boolean;
    pfpFiltered?: boolean;
    useAnonymousTemplate: boolean;
    changeTopicTemplateParams: Record<string, string>;
}

export interface ChangeTopicFormError {
    code: ChangeTopicFormErrorCode;
    trl: string;
}

export interface EmployerNegotiationsChangeTopicState {
    employerId: number;
    addressesCount?: number;
    areas?: Area[];
    simhashVacancy?: SimhashVacancy;
    useSendSmsToInvite?: boolean;
    transliterationData?: {
        mapping: Record<string, string>;
    };
    discardedNotifier: number;
    changeTopic: ChangeTopic;
    error?: ChangeTopicFormError;
    loading?: boolean;
    simhash?: string;
}

const UPDATE_CHANGE_TOPIC = 'UPDATE_CHANGE_TOPIC';
const SET_LOADING_CHANGE_TOPIC = 'SET_LOADING_CHANGE_TOPIC';
const SET_ERROR_CHANGE_TOPIC = 'SET_ERROR_CHANGE_TOPIC';
const SET_SELECTED_SUBSTATE_CHANGE_TOPIC = 'SET_SELECTED_SUBSTATE_CHANGE_TOPI';

interface PayloadTypes {
    [UPDATE_CHANGE_TOPIC]: EmployerNegotiationsChangeTopicState;
    [SET_LOADING_CHANGE_TOPIC]: boolean;
    [SET_ERROR_CHANGE_TOPIC]: CustomError;
    [SET_SELECTED_SUBSTATE_CHANGE_TOPIC]: FullStage | undefined;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const updateChangeTopic = actionCreator(UPDATE_CHANGE_TOPIC);
export const setLoadingChangeTopic = actionCreator(SET_LOADING_CHANGE_TOPIC);
export const setErrorChangeTopic = actionCreator(SET_ERROR_CHANGE_TOPIC);
export const setSelectedSubstateChangeTopic = actionCreator(SET_SELECTED_SUBSTATE_CHANGE_TOPIC);

export default createReducer<EmployerNegotiationsChangeTopicState, PayloadTypes>(null, {
    [UPDATE_CHANGE_TOPIC]: (state, { payload }) => {
        const newChangeTopic = payload.changeTopic;
        const vacancies = state.changeTopic.vacancies;
        if (vacancies.list.length <= 1) {
            vacancies.list = newChangeTopic.vacancies.list;
        } else {
            const newSelectedVacancy = newChangeTopic.vacancies.list.find((v) => v.isSelected);
            vacancies.list.forEach((vacancy) => (vacancy.isSelected = vacancy.id === newSelectedVacancy?.id));
        }

        return {
            ...state,
            ...payload,
            changeTopic: {
                ...newChangeTopic,
                ...{
                    vacancies,
                },
            },
        };
    },
    [SET_LOADING_CHANGE_TOPIC]: (state, { payload }) => {
        return { ...state, loading: payload };
    },
    [SET_ERROR_CHANGE_TOPIC]: (state, { payload }) => {
        return {
            ...state,
            changeTopic: {
                ...state.changeTopic,
                errors: {
                    list: [payload],
                },
            },
        };
    },
    [SET_SELECTED_SUBSTATE_CHANGE_TOPIC]: (state, { payload }) => {
        if (!payload) {
            return state;
        }

        const { availableStagesForSelectedState } = state.changeTopic;
        const substates = availableStagesForSelectedState.substates;

        substates.forEach((substate) => {
            if (substate.funnelStageId === payload.funnelStageId) {
                substate.selected = payload.selected;
            } else {
                substate.selected = false;
            }
        });

        availableStagesForSelectedState.selected = !substates.find((substate) => substate.selected);

        const updatedAvailableStagesForSelectedState = { ...availableStagesForSelectedState, substates };

        return {
            ...state,
            changeTopic: {
                ...state.changeTopic,
                availableStagesForSelectedState: updatedAvailableStagesForSelectedState,
            },
        };
    },
});
