import { useEffect, useState } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ImportantNotifications from 'src/components/ImportantNotifications';
import NoIndex from 'src/components/NoIndex';
import translation from 'src/components/translation';

const TrlKeys = {
    content: 'unsupportedBrowser.text',
    link: 'unsupportedBrowser.linkText',
};

interface UserAgentDetails {
    isSupported: true;
}

const UnsupportedBrowser: TranslatedComponent = ({ trls }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (typeof window?.bloko?.getUserAgentDetails === 'function') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            const userAgentDetails = window.bloko.getUserAgentDetails(window.navigator.userAgent) as UserAgentDetails;

            if (!userAgentDetails?.isSupported) {
                setShow(true);
            }
        }
    }, []);

    if (!show) {
        return null;
    }

    return (
        <NoIndex>
            <ImportantNotifications>
                {[trls[TrlKeys.content]]}
                &nbsp;
                <BlokoLink to="/article/176" Element={SPALink} kind={LinkKind.Inversed}>
                    {trls[TrlKeys.link]}
                </BlokoLink>
            </ImportantNotifications>
        </NoIndex>
    );
};

export default translation(UnsupportedBrowser);
