import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatNewLine } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';

import styles from './requisites.less';

const TrlKeys = {
    requisites: 'index.footer.requisites',
    bankRequisites: 'index.footer.bankRequisites',
    requisitesAdditional: 'index.footer.requisites.additional',
    bankRequisitesAdditional: 'index.footer.bankRequisites.additional',
};

const FooterRequisites: TranslatedComponent = ({ trls }) => {
    const staticHost = useSelector(({ config }) => config.staticHost);
    const isRabotaBy = useIsRabotaBy();

    if (!isRabotaBy) {
        return null;
    }

    return (
        <>
            <p>{formatNewLine(trls[TrlKeys.requisites])}</p>
            <VSpacing base={2}></VSpacing>
            <p>{formatNewLine(trls[TrlKeys.bankRequisites])}</p>
            <VSpacing base={6}></VSpacing>
            <p>
                <img
                    loading="lazy"
                    className={`${styles.requisitesImg} ${styles.requisitesImgMastercard}`}
                    src={`${staticHost}/images/creditcards/grey-mastercard.svg?v=22042022`}
                    alt="mastercard"
                />
                <img
                    loading="lazy"
                    className={`${styles.requisitesImg} ${styles.requisitesImgVisa}`}
                    src={`${staticHost}/images/creditcards/grey-visa.svg?v=22042022`}
                    alt="visa"
                />
                <img
                    loading="lazy"
                    className={`${styles.requisitesImg} ${styles.requisitesImgBelcard}`}
                    src={`${staticHost}/images/creditcards/grey-belcart.svg?v=28122023`}
                    alt="belcart"
                />
                <img
                    loading="lazy"
                    className={`${styles.requisitesImg} ${styles.requisitesImgInternetparol}`}
                    src={`${staticHost}/images/creditcards/grey-belcart-internetparol.svg?v=28122023`}
                    alt="belcart-internetparol"
                />
            </p>
        </>
    );
};

export default translation(FooterRequisites);
