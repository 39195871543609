import { useMemo } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { SearchType, SearchPage } from 'src/types/search/common';

export const useSearchSettings = () => {
    const searchType = useSelector((state) => state.searchSettings.type);
    const searchPage = useSelector((state) => state.searchSettings.page);
    const searchActionPath = useSelector((state) => state.searchSettings.actionPath);
    return useMemo(
        () => ({
            searchType,
            searchPage,
            searchActionPath,
            // search types
            isResumeSearchType: searchType === SearchType.Resume,
            isVacancySearchType: searchType === SearchType.Vacancy,
            // search pages
            isApplicantIndexPage: searchPage === SearchPage.ApplicantIndex,
            isEmployerViewPage: searchPage === SearchPage.EmployerView,
            isResumeSearchPage: searchPage === SearchPage.ResumeSearch,
            isVacancySearchPage: searchPage === SearchPage.VacancySearch,
            isVacancySearchMapPage: searchPage === SearchPage.VacancySearchMap,
        }),
        [searchActionPath, searchPage, searchType]
    );
};
