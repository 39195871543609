export const REGULAR_SPACE = '\u0020';
export const NON_BREAKING_SPACE = '\u00a0';
export const NARROW_NON_BREAKING_SPACE = '\u202f';
export const THIN_SPACE = '\u2009';
export const ARROW_UP = '\u2191';
export const ARROW_DOWN = '\u2193';
export const ARROW_RIGHT = '\u2192';
export const COLON = '\u003a';
export const EN_DASH = '\u2013';
export const LEFT_DOUBLE_QUOTE = '\u00ab';
export const RIGHT_DOUBLE_QUOTE = '\u00bb';
export const EM_DASH = '\u2014';
export const ELLIPSIS = '\u2026';
export const DOT = '\u2022';
export const MIDDLE_DOT = '\u00B7';
export const COMMA = '\u002c';
export const MINUS = '\u2212';
export const PLUS = '\u002B';
export const NUMERO_SIGN = '\u2116';
