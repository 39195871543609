import { FC } from 'react';

import { HeartFilledSize24, HeartOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';

const FavVacancies: FC<NavItem> = ({ active, name, trackClicks, url, analytics }) => {
    return (
        <SupernovaNaviItem active={active}>
            <SupernovaActionLink
                active={active}
                activeIcon={<HeartFilledSize24 />}
                icon={<HeartOutlinedSize24 />}
                name={name}
                url={url}
                analytics={analytics}
                trackClicks={trackClicks}
                data-qa={`mainmenu_${name}`}
            />
        </SupernovaNaviItem>
    );
};

export default FavVacancies;
