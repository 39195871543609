import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { EmployerManager as VacancyEmployerManager } from 'src/models/employer/managers';

import { COMBINED_VACANCIES_FILTERED_GROUP_ID } from 'src/models/employerVacancies/vacancy.types';

export interface VacancyFilteredGroup {
    groupId: string;
    groupName: string;
    groupNameForSearch: string | null;
    vacanciesCount: number;
    plannedPublicationsCount: number;
    employerManagers: VacancyEmployerManager[];
    default: boolean;
}

export type EntityType = 'vacancy' | 'draft';

export const UPDATE_VACANCIES_FILTERED_GROUPS = 'vacanciesGroups/UPDATE_VACANCIES_FILTERED_GROUPS';

interface PayloadTypes {
    [UPDATE_VACANCIES_FILTERED_GROUPS]: {
        groups: VacancyFilteredGroup[];
        currentGroupId: string;
        entityType?: EntityType;
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const updateVacanciesFilteredGroups = actionCreator(UPDATE_VACANCIES_FILTERED_GROUPS);

const ALL_ENTITIES_COUNT = 1;

export const STATIC_GROUPS_COUNT = 2;
export const STATIC_GROUPS_COUNT_FOR_DRAFTS = STATIC_GROUPS_COUNT - ALL_ENTITIES_COUNT;

const entityTypeToStaticGroupsCount = {
    vacancy: STATIC_GROUPS_COUNT,
    draft: STATIC_GROUPS_COUNT_FOR_DRAFTS,
} as const;

export const hasCombinedVacanciesFilteredGroup = (filteredGroups: VacancyFilteredGroup[]): boolean => {
    return filteredGroups.some((group) => group.groupId === COMBINED_VACANCIES_FILTERED_GROUP_ID);
};

export const getStaticGroupsLength = (filteredGroups: VacancyFilteredGroup[]): number => {
    let staticGroupsCount = STATIC_GROUPS_COUNT;
    if (hasCombinedVacanciesFilteredGroup(filteredGroups)) {
        staticGroupsCount += 1;
    }
    return staticGroupsCount;
};

// если всего 3 группы, и среди них есть CombinedVacancies, должны показать группы не включая "Мои вакансии".
export const shouldHideCurrentManagerVacanciesGroup = (filteredGroups: VacancyFilteredGroup[]): boolean => {
    const staticGroupsCount = getStaticGroupsLength(filteredGroups);
    return filteredGroups.length === staticGroupsCount && hasCombinedVacanciesFilteredGroup(filteredGroups);
};

export const shouldShowVacanciesFilteredGroups = (
    managerVacanciesFilteredGroups: VacancyFilteredGroup[],
    entityType: EntityType = 'vacancy'
): boolean => {
    return managerVacanciesFilteredGroups.length > entityTypeToStaticGroupsCount[entityType];
};

export default createReducer<VacancyFilteredGroup[], PayloadTypes>([], {
    [UPDATE_VACANCIES_FILTERED_GROUPS]: (state, action) => {
        const entityType = action.payload.entityType || 'vacancy';
        let staticGroupsCount = entityTypeToStaticGroupsCount[entityType];
        if (hasCombinedVacanciesFilteredGroup(state)) {
            staticGroupsCount += 1;
        }

        const payloadGroups = new Map<string, VacancyFilteredGroup>();
        action.payload.groups.forEach((group) => {
            payloadGroups.set(group.groupId, group);
        });

        const currentGroupsUpdated = state.map((group) => {
            const payloadGroup = payloadGroups.get(group.groupId);
            if (payloadGroup) {
                payloadGroups.delete(payloadGroup.groupId);
                return payloadGroup;
            }
            return { ...group, vacanciesCount: 0 };
        });

        const newGroupsFromPayload = payloadGroups.values();
        const newGroups = [...currentGroupsUpdated, ...newGroupsFromPayload];

        const staticGroups = newGroups.slice(0, staticGroupsCount);
        const dynamicGroupsFiltered = newGroups
            .slice(staticGroupsCount)
            .filter(
                ({ groupId, vacanciesCount, plannedPublicationsCount }) =>
                    groupId === action.payload.currentGroupId || vacanciesCount > 0 || plannedPublicationsCount > 0
            );
        dynamicGroupsFiltered.sort((a, b) => a.groupName.localeCompare(b.groupName));

        return [...staticGroups, ...dynamicGroupsFiltered];
    },
});
