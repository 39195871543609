import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { SearchClusterMap } from 'src/types/search/common/clusters';

import { VacancySearchResult } from 'src/models/search/vacancy/result';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';

export interface Coordinates {
    lat: number;
    lng: number;
}

export interface BBox {
    topRight: Coordinates;
    bottomLeft: Coordinates;
}

export interface GeoCluster {
    bbox: BBox;
    centerPoint: Coordinates;
    count: number;
    geohash: string;
}

export interface Vacancy {
    address: Coordinates;
    company: {
        id: number;
        name: string;
        url: string;
    };
    compensation: VacancyCompensation;
    id: number;
    name: string;
    url: string;
    isSimilar?: boolean;
}

interface SearchMapResponse {
    vacanciesFromResponse: Vacancy[];
    geoClusters: GeoCluster[];
    mapHasGeoClusters: boolean;
    query: string;
    clusters?: SearchClusterMap;
    vacancySearchResult?: VacancySearchResult;
    sendResponse: boolean;
}

const DEFAULT_STATE: SearchMapResponse = {
    vacanciesFromResponse: [],
    geoClusters: [],
    mapHasGeoClusters: false,
    query: '',
    sendResponse: false,
};

export default autoGeneratedReducer<SearchMapResponse>(DEFAULT_STATE);
