import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { EmployerStateType } from 'src/models/negotiationTopic.types';

export type TargetTopicEmployerState =
    | EmployerStateType.PhoneInterview
    | EmployerStateType.Interview
    | EmployerStateType.Response
    | EmployerStateType.Consider
    | EmployerStateType.Offer
    | EmployerStateType.Hired;

export interface AIAssistantHrSettings {
    questions: string[];
    additionalContext: string;
    targetTopicEmployerState: TargetTopicEmployerState;
}

export interface AIAssistantHR {
    ruleId: number | null;
    enabled: boolean;
    configurableSettings: AIAssistantHrSettings;
}

const INITIAL_STATE: AIAssistantHR = {
    ruleId: null,
    enabled: false,
    configurableSettings: {
        questions: [],
        additionalContext: '',
        targetTopicEmployerState: EmployerStateType.PhoneInterview,
    },
};

export default autoGeneratedReducer<AIAssistantHR>(INITIAL_STATE);
