// tempexp_PORTFOLIO-38449_file
import { useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Button, VSpacing } from '@hh.ru/magritte-ui';
import { Text } from '@hh.ru/magritte-ui-typography';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import useBreakpoint from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { Breakpoint } from 'bloko/common/media';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './valentine-day.less';

const TrlKeys = {
    title: 'marketing.special.valentine.day.notification.title',
    description: 'marketing.special.valentine.day.notification.description',
    link: 'marketing.special.valentine.day.notification.link',
};

const analyticsName = 'valentine_day_notification';

const ValentineDay: TranslatedComponent<SimpleNotificationComponentProps> = ({ trls, ...notification }) => {
    const [visible, setVisible] = useState(true);
    const link = useSelector(({ valentineDayNotificationLink }) => valentineDayNotificationLink);
    const breakpoint = useBreakpoint(Breakpoint.XS);
    const isSmallScreen = [Breakpoint.XS, Breakpoint.S].includes(breakpoint);
    if (isSmallScreen || !visible) {
        return null;
    }
    return (
        <SynchronizedNotification
            analyticsElementName={analyticsName}
            notification={notification}
            closeEvent={`${analyticsName}_close`}
            isTempNotification={true}
        >
            <div className={styles.notificationImg} />
            <VSpacing default={12} />
            <div>
                <div className={styles.notificationHeader}>
                    <Text typography="subtitle-2-semibold" style="contrast">
                        {trls[TrlKeys.title]}
                    </Text>
                </div>
                <div className={styles.notificationText}>
                    <Text typography="label-3-regular" style="contrast">
                        {trls[TrlKeys.description]}
                    </Text>
                </div>
            </div>
            <VSpacing default={24} />
            <div className={styles.bannerButtonContainer}>
                <Button
                    Element={SPALink}
                    onClick={() => {
                        Analytics.sendHHEventButtonClick(`${analyticsName}_link`);
                        setVisible(false);
                    }}
                    target="_blank"
                    to={link}
                    mode="primary"
                    style="contrast"
                    stretched
                >
                    {trls[TrlKeys.link]}
                </Button>
            </div>
        </SynchronizedNotification>
    );
};

export default translation(ValentineDay);
