import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import { SalaryRange } from '@hh.ru/types-hh-microcore';

import { CurrencyType } from 'src/models/currencies.types';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';

export type MarketRange = {
    upper: number | null;
    bottom: number | null;
    currency: CurrencyType | null;
    maximal: number | null;
    median: number | null;
    minimal: number | null;
} | null;

export enum CompetitionLevel {
    High = 'HIGH',
    Medium = 'MEDIUM',
    Low = 'LOW',
    Undefined = 'UNDEFINED',
}

export enum CanvacBannerCase {
    Salary = 'salary',
    CompetitionLevel = 'competitionLevel',
}

export enum AnalyzeResultType {
    CalculatedFull = 'CALCULATED_FULL',
    NotEnoughData = 'NOT_ENOUGH_DATA',
    NonApplicableProfRoleOther = 'NON_APPLICABLE_PROF_ROLE_OTHER',
    NonApplicableProfCurrencyType = 'NON_APPLICABLE_CURRENCY_TYPE',
}

export interface CanvacVacancySalaryData {
    analyzeResultType: AnalyzeResultType;
    calculationTime: string;
    marketRange: MarketRange;
    vacancyCompensation: Pick<VacancyCompensation, 'from' | 'to' | 'gross'> & { currency: CurrencyType };
    competitionLevel: CompetitionLevel;
    salaryRange: SalaryRange;
    applicantsPerVacancy: number;
    bottomResponseCount: number;
    upperResponseCount: number;
    numberOfSimilarVacancies: number;
}

export default autoGeneratedReducer<CanvacVacancySalaryData>(null);
