import { getLeftToShow } from 'src/components/EmployerVacancies/utils';
import { VacanciesGroupItem, Vacancy } from 'src/models/employerVacancies/vacancy.types';

/**
 * Ключ - id воронки, значение - ее вакансии
 */
type FunnelData = Record<string, Vacancy[]>;

export const getSelectedVacancies = (vacanciesGroupList: VacanciesGroupItem[], vacancyIds: number[]) =>
    vacanciesGroupList
        .flatMap((group) => group.vacanciesList)
        .filter(({ vacancyId }) => vacancyIds.includes(vacancyId));

export const filterFunnel = (
    { groupId, vacanciesList, counters }: VacanciesGroupItem,
    selectedFunnelIds: Set<string>
): boolean => {
    return (
        !selectedFunnelIds.has(groupId) ||
        vacanciesList.length > 0 ||
        getLeftToShow(counters.totalVacancyCount, vacanciesList.length) > 0
    );
};

export const createFunnelsData = (vacancies: Vacancy[]): FunnelData => {
    return vacancies.reduce<FunnelData>((acc, vacancy) => {
        if (!vacancy.funnel) {
            return acc;
        }

        const funnelId = vacancy.funnel.funnelId.toString();

        if (!acc[funnelId]) {
            acc[funnelId] = [];
        }

        acc[funnelId].push(vacancy);

        return acc;
    }, {});
};

export const createFunnelIdsSet = (vacancies: Vacancy[]) =>
    new Set(vacancies.map(({ funnel }) => funnel?.funnelId.toString() || ''));

export const getNewTotalVacancyCount = (funnel: VacanciesGroupItem, funnelsData: FunnelData) => {
    const vacanciesCount = funnelsData[funnel.groupId].length;
    return Math.max(0, funnel.counters.totalVacancyCount - vacanciesCount);
};
