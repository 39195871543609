import { useMemo, useCallback, useContext, forwardRef, ForwardRefRenderFunction } from 'react';

import { Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import SupernovaDropdownContext from 'src/components/SupernovaMainMenu/SupernovaDropdownContext';
import { NotificationLinkProps } from 'src/components/UserNotifications/common/notificationComponentProps';

let linkId = 0;

const NotificationLink: ForwardRefRenderFunction<HTMLAnchorElement, NotificationLinkProps> = (
    { children, to, onClick, target, ...props },
    ref
) => {
    const url = urlParser(to);
    url.params = { ...url.params, from: 'user_notification', hhtmFromLabel: 'user_notification' };

    const id = useMemo(() => (linkId += 1), []);
    const { elements: dropdownContext } = useContext(SupernovaDropdownContext);
    const updateRef = useCallback(
        (element: HTMLAnchorElement) => {
            dropdownContext[`notificationLink${id}`] = element;
            if (ref) {
                if (typeof ref === 'function') {
                    ref(element);
                } else {
                    ref.current = element;
                }
            }
        },
        [dropdownContext, id, ref]
    );

    return (
        <Link
            data-qa={props['data-qa'] || 'notification-link'}
            Element={SPALink}
            to={url.href}
            onClick={onClick}
            ref={updateRef}
            target={target}
            inline
        >
            {children}
        </Link>
    );
};

export default forwardRef(NotificationLink);
