import { FC } from 'react';

import { Text, GridColumn, GridRow, GridLayout, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru, useTopLevelSite } from 'src/hooks/useSites';
import useUserTypeForMenu from 'src/hooks/useUserTypeForMenu';
import { NavItems } from 'src/models/supernovaNaviMenu';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { TopLevelSite } from 'src/models/topLevelSite';

import FooterCopyright from 'src/components/Footer/Copyright';
import MobileAppButtons from 'src/components/Footer/MobileAppButtons';
import FooterNavItems from 'src/components/Footer/NavItems';
import NavItemsDocs from 'src/components/Footer/NavItems/Docs';
import FooterNavSeparator from 'src/components/Footer/NavSeparator';
import FooterRecommendTechInfo from 'src/components/Footer/RecommendTechInfo';
import FooterRequisites from 'src/components/Footer/Requisites';
import FooterSocialNetworks from 'src/components/Footer/SocialNetworks';

import styles from './nav.less';

interface FooterNavProps {
    items?: NavItems;
}

const FooterNav: FC<FooterNavProps> = ({ items }) => {
    const topLevelSite = useTopLevelSite();
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);
    const isApplicant = useUserTypeForMenu(SupernovaUserType.Applicant);
    const isZarplata = useIsZarplataPlatform();
    const isHhru = useIsHhru();
    const { isXS } = useBreakpoint();

    if (!items) {
        return null;
    }

    const additional = items.find(({ name }) => name === 'additional');
    const site = items.find(({ name }) => name === 'site');
    const articles = items.find(({ name }) => name === 'articles');
    const forTeens = items.find(({ name }) => name === 'forTeens');
    const about = items.find(({ name }) => name === 'about');
    const misc = items.find(({ name }) => name === 'misc');
    const applicantServices = items.find(({ name }) => name === 'applicantServices');
    const employerServices = items.find(({ name }) => name === 'employerServices');
    const bottom = items.find(({ name }) => name === 'bottom');
    const docs = items.find(({ name }) => name === 'docs');

    const additionalMenu = additional?.subItems?.map((subItem, index) => (
        <FooterNavItems isMobileFriendly={isXS} key={`${subItem.name}.${index}`} nav={subItem} />
    ));

    const needCustomTemplate =
        topLevelSite === TopLevelSite.KZ ||
        topLevelSite === TopLevelSite.AZ ||
        topLevelSite === TopLevelSite.UZ ||
        topLevelSite === TopLevelSite.KG ||
        topLevelSite === TopLevelSite.GE;

    /**
     * Показываем кнопки сторов вместо QR-кода на соискательской zp,
     * так как у zp нет подходящей разводящей для моб. приложения
     */
    const isApplicantZarplata = isZarplata && isApplicant;

    return (
        <div className={styles.footerNav}>
            <GridLayout>
                <GridRow>
                    <GridColumn xs={4} s={8} m={6} l={4}>
                        <FooterNavItems
                            isMobileFriendly={isXS}
                            nav={site}
                            showHeader={isApplicant}
                            spacing={<VSpacing default={40} gteL={0} />}
                        />
                        <FooterNavItems
                            isMobileFriendly={isXS}
                            nav={about}
                            spacing={<VSpacing default={40} gteL={0} />}
                        />
                    </GridColumn>

                    {!needCustomTemplate && (
                        <GridColumn xs={4} s={8} m={6} l={4}>
                            <FooterNavItems
                                isMobileFriendly={isXS}
                                nav={applicantServices}
                                showHeader={isApplicant}
                                spacing={<VSpacing default={40} />}
                            />
                            <FooterNavItems
                                isMobileFriendly={isXS}
                                nav={employerServices}
                                showHeader={isApplicant}
                                spacing={<VSpacing default={40} />}
                            />
                            <FooterNavItems
                                isMobileFriendly={isXS}
                                nav={articles}
                                showHeader={isApplicant}
                                spacing={<VSpacing default={40} gteM={0} />}
                            />
                            <FooterNavItems
                                isMobileFriendly={isXS}
                                nav={misc}
                                spacing={<VSpacing default={40} gteM={0} />}
                            />
                        </GridColumn>
                    )}

                    <GridColumn xs={4} s={8} m={6} l={4}>
                        <FooterNavItems
                            isMobileFriendly={isXS}
                            nav={forTeens}
                            showHeader={isApplicant}
                            spacing={<VSpacing default={40} />}
                        />
                        {additionalMenu}
                        <VSpacing default={additional?.subItems?.length ? 40 : 0} />
                        {!isApplicantZarplata && <MobileAppButtons isApplicant={isApplicant} />}
                    </GridColumn>
                </GridRow>

                {isApplicantZarplata && (
                    <MobileAppButtons isApplicant={isApplicant} showHeader={false} desktopView="buttons" />
                )}

                {docs?.subItems?.length ? (
                    <>
                        <VSpacing default={64} xs={40} />

                        <GridRow>
                            <Text typography="label-3-regular" style="secondary">
                                <NavItemsDocs nav={docs} isMobileFriendly={isXS} />
                            </Text>
                        </GridRow>

                        <VSpacing default={24} />
                    </>
                ) : null}

                <GridRow>
                    <GridColumn xs={4} s={8} m={12}>
                        <Text typography="label-3-regular" style="secondary">
                            {(isHhru || (isZarplata && supernovaUserType !== SupernovaUserType.Employer)) && (
                                <FooterRecommendTechInfo />
                            )}

                            <FooterRequisites />
                        </Text>
                    </GridColumn>
                </GridRow>

                <GridRow>
                    <GridColumn xs={4} s={8} m={12} l={12}>
                        <FooterNavSeparator />

                        <div className={styles.bottomSectionWrapper}>
                            <FooterSocialNetworks />

                            <Text typography="label-3-regular">
                                <div className={styles.bottomSection}>
                                    <FooterNavItems isMobileFriendly={isXS} nav={bottom} direction="horizontal" />
                                    <FooterCopyright />
                                </div>
                            </Text>
                        </div>
                    </GridColumn>
                </GridRow>
            </GridLayout>
        </div>
    );
};

export default FooterNav;
