import { TopLevelSite } from 'src/models/topLevelSite';

import { useSelector } from 'src/hooks/useSelector';

export const useTopLevelSite = (): TopLevelSite => {
    const topLevelSite = useSelector(({ topLevelSite }) => topLevelSite);
    return topLevelSite === TopLevelSite.JTB ? TopLevelSite.BY : topLevelSite;
};

export const useIsHhru = (): boolean => {
    const topLevelSite = useTopLevelSite();
    return topLevelSite === TopLevelSite.RU;
};

export const useIsRabotaBy = (): boolean => {
    const topLevelSite = useTopLevelSite();
    return topLevelSite === TopLevelSite.BY || topLevelSite === TopLevelSite.JTB;
};

export const useIsHhKz = (): boolean => {
    const topLevelSite = useTopLevelSite();
    return topLevelSite === TopLevelSite.KZ;
};

export const useIsHeadhunterKg = (): boolean => {
    const topLevelSite = useTopLevelSite();
    return topLevelSite === TopLevelSite.KG;
};

export const useIsHhUz = (): boolean => {
    const topLevelSite = useTopLevelSite();
    return topLevelSite === TopLevelSite.UZ;
};

export const useIsHhAz = (): boolean => {
    const topLevelSite = useTopLevelSite();
    return topLevelSite === TopLevelSite.AZ;
};

export const useIsMiddleAsia = (): boolean => {
    const isHhKz = useIsHhKz();
    const isHhUz = useIsHhUz();
    const isHeadhunterKg = useIsHeadhunterKg();
    return isHhKz || isHhUz || isHeadhunterKg;
};
