import { FC } from 'react';

import headerGeoDefinitionSuggestButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_suggest_button_click';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text from 'bloko/blocks/text';

import { TopLevelDomain } from 'src/components/AreaSwitcher/areaSwitcherTypes';

interface AreaSwitcherMobileListProps {
    topLevelDomains?: TopLevelDomain[];
    isLoading: boolean;
}

const AreaSwitcherMobileList: FC<AreaSwitcherMobileListProps> = ({ topLevelDomains, isLoading }) => {
    if (isLoading) {
        return <Loading scale={LoadingScale.Medium} />;
    }

    return (
        <div data-qa="area-switcher-welcome">
            <ul className="area-switcher-mobile-cities">
                {topLevelDomains?.[0].subdomains.map(
                    (domain) =>
                        domain.href && (
                            <li key={domain.href}>
                                <BlokoLink
                                    to={domain.href}
                                    Element={SPALink}
                                    kind={LinkKind.Tertiary}
                                    data-qa="area-switcher-title"
                                    onClick={() => {
                                        if (typeof domain.areaId === 'number') {
                                            headerGeoDefinitionSuggestButtonClick({
                                                selectedArea: domain.areaId.toString(),
                                            });
                                        }
                                    }}
                                >
                                    <span className="easy-tap-link">
                                        <Text>{domain.name}</Text>
                                    </span>
                                </BlokoLink>
                            </li>
                        )
                )}
            </ul>
        </div>
    );
};

export default AreaSwitcherMobileList;
