import { RefObject, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import headerGeoDefinitionButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_button_click';
import headerGeoDefinitionElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_element_shown';
import { Button, Text, Tooltip, useBreakpoint } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import { useNotification } from 'src/components/Notifications/Provider';
import { OverlayTypes, OverlayTypeSetterFunc } from 'src/components/SupernovaOverlayMenu/Overlay';
import regionClarificationComponent from 'src/components/SupernovaOverlayMenu/RegionClarification';
import translation from 'src/components/translation';
import useIsRedesignGeoExpActive from 'src/hooks/useIsRedesignGeoExpActive';
import { useSelector } from 'src/hooks/useSelector';
import useSendCheckGeoSwitcherRedesignExp from 'src/hooks/useSendCheckGeoSwitcherRedesignExp';
import { setIsGeoSwitcherActive } from 'src/models/isGeoSwitcherActive';

import styles from './styles.less';

const TrlKeys = {
    text: 'regionClarification.tooltip.text',
    submit: 'regionClarification.tooltip.actions.submit',
    change: 'regionClarification.tooltip.actions.cancel',
};

interface RegionClarificationTooltipProps {
    activatorRef: RefObject<HTMLElement>;
    showOverlay: OverlayTypeSetterFunc;
}

const setRegionClarification = makeSetStoreField('regionClarification');

const RegionClarificationTooltip: TranslatedComponent<RegionClarificationTooltipProps> = ({
    activatorRef,
    trls,
    showOverlay,
}) => {
    const { isMobile } = useBreakpoint();
    const isRedesignGeoExp = useIsRedesignGeoExpActive();
    const sendExpCheck = useSendCheckGeoSwitcherRedesignExp();
    const { addNotification } = useNotification();
    const dispatch = useDispatch();

    const regionClarification = useSelector(({ regionClarification }) => regionClarification);

    useEffect(() => {
        if (regionClarification) {
            sendExpCheck();
        }
    }, [regionClarification, sendExpCheck]);

    const clearRegionClarification = useCallback(() => {
        dispatch(setRegionClarification(null));
    }, [dispatch]);

    const handleChangeClick = useCallback(() => {
        headerGeoDefinitionButtonClick({ buttonName: 'header_geo_definition_reject' });

        if (isRedesignGeoExp) {
            dispatch(setIsGeoSwitcherActive(true));
        } else {
            showOverlay(OverlayTypes.AreaSwitcherOverlay);
        }

        clearRegionClarification();
    }, [clearRegionClarification, dispatch, isRedesignGeoExp, showOverlay]);

    const handleCloseClick = useCallback(() => {
        headerGeoDefinitionButtonClick({ buttonName: 'header_geo_definition_confirm' });

        if (regionClarification?.host) {
            void axios.post('/shards/region_clarified', null, {
                params: { host: regionClarification.host },
            });
        }

        clearRegionClarification();
    }, [clearRegionClarification, regionClarification?.host]);

    const shouldShowNewTooltip = useMemo(() => !isMobile && isRedesignGeoExp, [isMobile, isRedesignGeoExp]);

    useEffect(() => {
        if (shouldShowNewTooltip) {
            return;
        }

        if (!regionClarification) {
            return;
        }

        addNotification(regionClarificationComponent, {
            uniqueType: true,
            props: {
                ...regionClarification,
                onChangeClick: handleChangeClick,
                onConfirmClick: handleCloseClick,
            },
        });

        if (!isRedesignGeoExp) {
            // Сбрасываем поле, чтобы больше не показалась нотификация
            clearRegionClarification();
        }
    }, [
        addNotification,
        clearRegionClarification,
        dispatch,
        shouldShowNewTooltip,
        regionClarification,
        showOverlay,
        isRedesignGeoExp,
        handleChangeClick,
        handleCloseClick,
    ]);

    const visible = useMemo(
        () => !!(shouldShowNewTooltip && regionClarification),
        [shouldShowNewTooltip, regionClarification]
    );

    const areaQuestionContent = (
        <div className={styles.textContainer}>
            <Text typography="title-5-semibold">
                {format(trls[TrlKeys.text], {
                    '{0}': regionClarification?.translations.area || '',
                })}{' '}
            </Text>
        </div>
    );

    return (
        <Tooltip
            buttons={(onClose) => (
                <>
                    <Button
                        data-qa="region-clarification-submit-button"
                        onClick={onClose}
                        style="contrast"
                        size="small"
                        stretched
                        mode="primary"
                    >
                        {trls[TrlKeys.submit]}
                    </Button>
                    <Button
                        data-qa="region-clarification-change-button"
                        onClick={handleChangeClick}
                        style="contrast"
                        size="small"
                        stretched
                        mode="secondary"
                    >
                        {trls[TrlKeys.change]}
                    </Button>
                </>
            )}
            visible={visible}
            activatorRef={activatorRef}
            placement="bottom-center"
            onClose={handleCloseClick}
            closeByClickOutside={false}
        >
            <ElementShownAnchor fn={headerGeoDefinitionElementShown}>{areaQuestionContent}</ElementShownAnchor>
        </Tooltip>
    );
};

export default translation(RegionClarificationTooltip);
