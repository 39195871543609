import appInstallBannerButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/app_install_banner_button_click';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { H1 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './footer-mobile-banner.less';

const TrlKeys = {
    header: 'supernova.footer.mobile.banner.header',
    description: 'supernova.footer.mobile.banner.description.qr',
    additional: 'supernova.footer.mobile.banner.description.additional',
    appButton: 'supernova.footer.mobile.banner.appButton',
};

interface MobileBannerContentProps {
    href: string;
    hasAppLink: boolean;
}

const MobileBannerContent: TranslatedComponent<MobileBannerContentProps> = ({ trls, href, hasAppLink }) => {
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const url = urlParser(href);
    url.params = { ...url.params, [`footer_banner`]: [hhtmSource] };

    return (
        <>
            <div className={styles.footerMobileBannerHeader}>
                <H1 Element="div" lite>
                    {trls[TrlKeys.header]}
                </H1>
                <VSpacing base={4} />
            </div>
            <div className={styles.footerMobileBannerDescription}>
                {trls[TrlKeys.description]}{' '}
                {!hasAppLink && (
                    <div className={styles.footerMobileBannerDescriptionAdditional}>{trls[TrlKeys.additional]}</div>
                )}
            </div>

            {hasAppLink && (
                <div className={styles.footerMobileBannerAppButton}>
                    <Button
                        Element={SPALink}
                        kind={ButtonKind.Primary}
                        stretched
                        to={url.href}
                        onClick={() => {
                            appInstallBannerButtonClick({ hhtmSourceLabel: 'bottom' });
                        }}
                    >
                        {trls[TrlKeys.appButton]}
                    </Button>
                </div>
            )}
        </>
    );
};

export default translation(MobileBannerContent);
