import { MetroType, Range } from 'src/types/search/common/clusters';
import { CriteriaKey } from 'src/types/search/common/criteria';

export enum ClustersSetting {
    ShowClusters = 'show_clusters',
    ForceFiltersSaving = 'forceFiltersSaving',
    FromSearchLine = 'fromSearchLine',
    EnableSnippets = 'enable_snippets',
}

const PARAMS_BLACKLIST = [
    CriteriaKey.EmployerManagerId,
    CriteriaKey.ControlFlag,
    CriteriaKey.SearchDebug,
    ClustersSetting.ShowClusters,
    'searchSessionId',
];

export const rangeKeys = [Range.From, Range.To];

export const CRITERIA_ETC_KEYS = [CriteriaKey.ExpCompanySize] as const;
export const CLUSTERS_EXCLUDE_HIDDEN_INPUT = [
    CriteriaKey.AcceptTemporary,
    CriteriaKey.AgeFrom,
    CriteriaKey.AgeTo,
    CriteriaKey.Citizenship,
    CriteriaKey.DriverLicenseTypes,
    CriteriaKey.IsAutosearch,
    CriteriaKey.Language,
    CriteriaKey.Page,
    CriteriaKey.Salary,
    CriteriaKey.SalaryFrequency,
    CriteriaKey.SalaryFrom,
    CriteriaKey.SalaryTo,
    CriteriaKey.ProfessionalRole,
    CriteriaKey.WorkTicket,
    ...PARAMS_BLACKLIST,
    ...CRITERIA_ETC_KEYS,
    CriteriaKey.OnlyWithSalary,
    CriteriaKey.EmployerId,
    CriteriaKey.CurrencyCode,
    CriteriaKey.Experience,
    CriteriaKey.Label,
    CriteriaKey.ExcludedText,
    ClustersSetting.FromSearchLine,
    CriteriaKey.Text,
    ClustersSetting.ForceFiltersSaving,
];

export const SORTS_WITH_DEFAULT_VALUE = {
    [CriteriaKey.SearchPeriod]: null,
    [CriteriaKey.OrderBy]: 'relevance',
};

export const isMetroCheckedOrIndeterminate = (
    id: string,
    selectedMetro: string[],
    _color: string,
    type: MetroType
): { checked: boolean; indeterminate: boolean } => {
    const checked = selectedMetro.includes(id);
    let indeterminate = false;
    if (!checked && type === MetroType.Line) {
        indeterminate = selectedMetro.some((item) => item.split('.')[0] === id);
    }
    return {
        checked,
        indeterminate,
    };
};
