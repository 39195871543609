import CustomSelect, {
    CustomSelectLayer,
    CustomSelectOption,
    CustomSelectOptionSecondaryText,
} from 'bloko/blocks/customSelect';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { SearchQueryKeys, ExpCompanySizeOption, SearchQueries } from 'src/models/search/resume/queries';
import { CriteriaKey } from 'src/types/search/common/criteria';

const TrlKeys = {
    [ExpCompanySizeOption.Small]: 'resumeSearch.company_size.count.small',
    [ExpCompanySizeOption.Medium]: 'resumeSearch.company_size.count.medium',
    [ExpCompanySizeOption.Large]: 'resumeSearch.company_size.count.large',
};

interface ConditionSelectProps {
    name: SearchQueryKeys;
    query: SearchQueries;
    setQuery: (value: SearchQueries) => void;
    isHidden?: boolean;
}

const ConditionSelect: TranslatedComponent<ConditionSelectProps> = ({ trls, name, query, setQuery, isHidden }) => {
    const preparedTrls = useSelector((state) => state.searchQueries?.trls);
    const options = useSelector((state) => state.resumeSearchDictionaries?.[name]);

    if (isHidden) {
        return <input type="hidden" name={name} value={query[name].join(',')} />;
    }

    if (!preparedTrls) {
        return null;
    }

    return (
        <CustomSelect
            name={name}
            value={query[name][0]}
            onChange={(newValue) => setQuery({ ...query, [name]: [newValue] })}
            wrapperProps={{ 'data-qa': `resumes-search-wizard-${name}-switcher` }}
            layer={CustomSelectLayer.AboveOverlayContent}
            light
        >
            {options.map((option) => {
                const trlsList = preparedTrls[name];
                const optionText = trlsList[option as keyof typeof trlsList];
                return (
                    <CustomSelectOption value={option} key={option} data-qa={`resumes-search-wizard-${name}-${option}`}>
                        {optionText}
                        {name === CriteriaKey.ExpCompanySize && (
                            <CustomSelectOptionSecondaryText>
                                {option !== ExpCompanySizeOption.Any
                                    ? trls[TrlKeys[option as keyof typeof TrlKeys]]
                                    : ''}
                            </CustomSelectOptionSecondaryText>
                        )}
                    </CustomSelectOption>
                );
            })}
        </CustomSelect>
    );
};

export default translation(ConditionSelect);
