import { ReactNode, FC } from 'react';

import Strings from 'Utils/Strings';
import Currency from 'src/components/Currency';
import { CurrencyType } from 'src/models/currencies.types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

export type FormatMoneyRenderFunction = (amount: string, currencyNode: ReactNode) => JSX.Element;
interface FormatMoneyProps {
    children: number | string;
    currency: CurrencyType;
    inCents?: boolean;
    forceShowCents?: boolean;
    render?: FormatMoneyRenderFunction;
    groupSeparator?: string;
}

const isNumber = (children: ReactNode): children is number => {
    return typeof children === 'number';
};

const isString = (children: ReactNode): children is string => {
    return typeof children === 'string';
};

const DefaultFormatMoney: FormatMoneyRenderFunction = (amount, currencyNode) => (
    <>
        {amount}
        {NON_BREAKING_SPACE}
        {currencyNode}
    </>
);

const FormatMoney: FC<FormatMoneyProps> = ({
    children,
    currency,
    inCents = false,
    forceShowCents,
    render = DefaultFormatMoney,
    groupSeparator,
}) => {
    let value = children;
    if (isString(value)) {
        value = parseFloat(value);
    }
    if (!isNumber(value)) {
        return null;
    }
    const amount = inCents
        ? Strings.formatCostInCents(value, groupSeparator, forceShowCents)
        : Strings.formatCost(value, groupSeparator);
    const currencyNode = <Currency value={currency} />;

    return render(amount, currencyNode);
};

export default FormatMoney;
