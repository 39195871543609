import { Text, HSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { ArrowRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import styles from './styles.less';

const TrlKeys = {
    employerId: 'supernova.modal.help.employerId',
};

const SupportEmployerId: TranslatedComponent = ({ trls }) => {
    const userType = useSelector(({ userType }) => userType);
    const employerId = useSelector(({ employerId }) => employerId);
    const isEmployer = UserType.Employer === userType;
    const { isMobile } = useBreakpoint();

    if (!isEmployer || !employerId) {
        return null;
    }

    return (
        <div className={styles.employerIdContainer}>
            <Text typography={isMobile ? 'label-3-regular' : 'label-2-regular'} style="secondary">
                {trls[TrlKeys.employerId]}
            </Text>
            <HSpacing default={12} />
            <Text typography={isMobile ? 'label-3-regular' : 'label-2-regular'}>
                <div className={styles.employerNumberContainer}>
                    <ArrowRightOutlinedSize16 initialColor="secondary" />
                    <HSpacing default={12} />
                    {employerId}
                </div>
            </Text>
        </div>
    );
};

export default translation(SupportEmployerId);
