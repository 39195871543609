import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Gender } from 'src/models/resume/resumeCommon.types';
import { SearchClusterMap, ClusterKey, Range, SelectedValuesTypeByClusterKey } from 'src/types/search/common/clusters';
import { FilterExpPeriodOption } from 'src/types/search/common/criteria';
import { RelocationOption } from 'src/types/search/resume/criteria';

export const EMPTY_CLUSTER = { groups: {}, selectedValues: [] };
export const EMPTY_RANGE_CLUSTER = {
    groups: {},
    selectedValues: {
        [Range.From]: null,
        [Range.To]: null,
        onlyWith: false,
    },
};
export const EMPTY_COMPENSATION_CLUSTER = {
    groups: {},
    selectedValues: {
        salary: null,
        onlyWithSalary: false,
    },
};
export const EMPTY_GENDER_CLUSTER = {
    groups: {},
    selectedValues: {
        value: Gender.Unknown,
        onlyWith: false,
    },
};

export const SEARCH_CLUSTERS_INITIAL_STATE = {
    [ClusterKey.Age]: EMPTY_RANGE_CLUSTER,
    [ClusterKey.Citizenship]: EMPTY_CLUSTER,
    [ClusterKey.CompensationFrequency]: EMPTY_CLUSTER,
    [ClusterKey.DriverLicenseTypes]: EMPTY_CLUSTER,
    [ClusterKey.Education]: EMPTY_CLUSTER,
    [ClusterKey.EducationLevel]: EMPTY_CLUSTER,
    [ClusterKey.Employment]: EMPTY_CLUSTER,
    [ClusterKey.EmploymentForm]: EMPTY_CLUSTER,
    [ClusterKey.ExcludedText]: EMPTY_CLUSTER,
    [ClusterKey.Experience]: EMPTY_CLUSTER,
    [ClusterKey.FilterExpIndustry]: EMPTY_CLUSTER,
    [ClusterKey.FilterExpPeriodForIndustry]: { groups: {}, selectedValues: FilterExpPeriodOption.AllTime },
    [ClusterKey.Gender]: { groups: {}, selectedValues: EMPTY_GENDER_CLUSTER },
    [ClusterKey.Industry]: EMPTY_CLUSTER,
    [ClusterKey.JobSearchStatus]: EMPTY_CLUSTER,
    [ClusterKey.Label]: EMPTY_CLUSTER,
    [ClusterKey.Language]: EMPTY_CLUSTER,
    [ClusterKey.Metro]: EMPTY_CLUSTER,
    [ClusterKey.PartTime]: EMPTY_CLUSTER,
    [ClusterKey.ProfessionalRole]: EMPTY_CLUSTER,
    [ClusterKey.Relocation]: { groups: {}, selectedValues: RelocationOption.Living },
    [ClusterKey.Salary]: EMPTY_RANGE_CLUSTER,
    [ClusterKey.Schedule]: EMPTY_CLUSTER,
    [ClusterKey.SearchField]: EMPTY_CLUSTER,
    [ClusterKey.Skill]: EMPTY_CLUSTER,
    [ClusterKey.University]: EMPTY_CLUSTER,
    [ClusterKey.VerifiedLanguages]: EMPTY_CLUSTER,
    [ClusterKey.WorkFormat]: EMPTY_CLUSTER,
    [ClusterKey.WorkTicket]: EMPTY_CLUSTER,
} as unknown as SearchClusterMap;

const UPDATE_CLUSTER_SELECTED_VALUES = 'UPDATE_CLUSTER_SELECTED_VALUES';

interface UpdateClusterSelectedValuesProps<T extends ClusterKey> {
    data: SelectedValuesTypeByClusterKey<T>;
    filter: T;
}

interface PayloadTypes {
    [UPDATE_CLUSTER_SELECTED_VALUES]: UpdateClusterSelectedValuesProps<ClusterKey>;
}

const actionHelper = ActionCreatorHelper<PayloadTypes>();

export const updateSearchCluster = actionHelper(UPDATE_CLUSTER_SELECTED_VALUES);

export default createReducer<SearchClusterMap, PayloadTypes>(SEARCH_CLUSTERS_INITIAL_STATE, {
    [UPDATE_CLUSTER_SELECTED_VALUES]: (state, { payload: { data, filter } }) => ({
        ...state,
        [filter]: {
            ...(state[filter] ? state[filter] : { groups: {} }),
            selectedValues: data,
        },
    }),
});
