import { FC, PropsWithChildren } from 'react';

import styles from './floating-button.less';

interface FloatingButtonProps {
    onClick: () => void;
}

const FloatingButton: FC<FloatingButtonProps & PropsWithChildren> = ({ onClick, children }) => {
    return (
        <button type="button" className={styles.floatingButton} onClick={onClick}>
            {children}
        </button>
    );
};

export default FloatingButton;
