import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { VacancyAdvicesState, VacancyCreateAdvice, Salary } from 'src/models/vacancyCreate/vacancyCreateAdvices.types';

export const UPDATE_VACANCY_CREATE_ADVICES = 'UPDATE_VACANCY_CREATE_ADVICES';
export const CLEAN_VACANCY_CREATE_ADVICES = 'CLEAN_VACANCY_CREATE_ADVICES';
export const MAKE_VACANCY_CREATE_ADVICE_CLOSED = 'MAKE_VACANCY_CREATE_ADVICE_CLOSED';

interface PayloadTypes {
    [UPDATE_VACANCY_CREATE_ADVICES]: {
        visible: VacancyCreateAdvice[];
        salary: Salary | null;
    };
    [CLEAN_VACANCY_CREATE_ADVICES]: void;
    [MAKE_VACANCY_CREATE_ADVICE_CLOSED]: VacancyCreateAdvice[];
}

export const initialState = {
    visible: [],
    closed: [],
    salary: null,
};

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateVacancyCreateAdvices = actionCreator(UPDATE_VACANCY_CREATE_ADVICES);
export const cleanVacancyCreateAdvices = actionCreator(CLEAN_VACANCY_CREATE_ADVICES);
export const makeVacancyCreateAdviceClosed = actionCreator(MAKE_VACANCY_CREATE_ADVICE_CLOSED);

export default createReducer<VacancyAdvicesState, PayloadTypes>(initialState, {
    [UPDATE_VACANCY_CREATE_ADVICES]: (state, action) => {
        return {
            ...state,
            ...action.payload,
        };
    },
    [MAKE_VACANCY_CREATE_ADVICE_CLOSED]: (state, action) => {
        return {
            ...state,
            closed: [...state.closed, ...action.payload],
        };
    },
    [CLEAN_VACANCY_CREATE_ADVICES]: () => initialState,
});
