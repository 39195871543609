import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Phone, Recommendations } from 'src/models/resume/resumeCommon.types';

export interface StatisticsCount {
    count: number;
    countNew: number;
}

export interface Statistics {
    periodDays: number;
    views: StatisticsCount;
    searchShows: StatisticsCount;
    invitations: StatisticsCount;
}

export interface RecommendationDetails {
    surveyProfession: string;
    translations: {
        resumeStartSurveyProfession: string;
        resumeRecommendationTextProfession: string;
    };
}

export interface ResumeStatistics {
    statistics?: Statistics;
    recommendation?: Recommendations;
    recommendationDetails?: RecommendationDetails;
}

interface ApplicantResumesStatistics {
    recommendationsForAllResumes?: {
        phone: Phone;
        needResponseStreak: boolean;
        responsesCount: number;
        responsesRequired: number;
        needVerifiedPhone?: boolean;
    };
    resumes?: Record<string, ResumeStatistics>;
}

export default autoGeneratedReducer<ApplicantResumesStatistics>({});
