import { useRef } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';
import { DisableReason } from 'src/models/autoInvite';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    header: 'employer.candidates.autoinvite.notification.header',
    vacancy: 'employer.candidates.autoinvite.notification.vacancy',
    disableReason: {
        noServices: 'employer.candidates.autoinvite.notification.disableReason.noServices',
        noQuotas: 'employer.candidates.autoinvite.notification.disableReason.noQuotas',
        finished: 'employer.candidates.autoinvite.notification.disableReason.finished',
    },
};

const AutoInviteDisabled: TranslatedComponent<ComplexNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const closeNotificationRef = useRef<VoidFunction>(null);

    const getDisableReasonTrl = (disableReason?: DisableReason) => {
        if (disableReason === DisableReason.NoAvailableService) {
            return trls[TrlKeys.disableReason.noServices];
        } else if (disableReason === DisableReason.QuotaExceeded) {
            return trls[TrlKeys.disableReason.noQuotas];
        } else if (disableReason === DisableReason.Finished) {
            return trls[TrlKeys.disableReason.finished];
        }
        return null;
    };

    return (
        <SynchronizedNotification
            analyticsElementName="auto-invite-disabled"
            kind={
                notification.params.disableReason === DisableReason.Finished
                    ? NotificationKind.Ok
                    : NotificationKind.Error
            }
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={onClose}
        >
            <NotificationHeading>{trls[TrlKeys.header]}</NotificationHeading>
            <p>{getDisableReasonTrl(notification.params.disableReason as DisableReason)}</p>
            <VSpacing base={1} />
            <p>
                {trls[TrlKeys.vacancy]}
                {NON_BREAKING_SPACE}
                <BlokoLink
                    Element={SPALink}
                    to={`/employer/vacancyresponses/autoinvite?vacancyId=${notification.params.vacancyId}`}
                >
                    {notification.params.vacancyTitle}
                </BlokoLink>
            </p>
        </SynchronizedNotification>
    );
};

export default translation(AutoInviteDisabled);
