import { useState, useRef } from 'react';

import applicantActivityButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/main/applicant_activity_button_click';
import applicantActivityElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/main/applicant_activity_element_shown';
import applicantActivityScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/main/applicant_activity_screen_shown';
import {
    Banner,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Card,
    Modal,
    Text,
    Tooltip,
    useBreakpoint,
    VSpacing,
    VSpacingContainer,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24, InfoCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import ApplicantActivity from 'src/components/Applicant/ActivityCard/Activity';
import ActivityEventScoreList from 'src/components/Applicant/ActivityCard/EventScoreList';

type ApplicantActivityCardVariant = 'full' | 'compact' | 'response';

const TrlKeys = {
    content: 'applicant.gamification.activity.hint',
    close: 'close',
    tooltipText: {
        p1: 'applicantActivityMeter.description.part1',
        p2: 'applicantActivityMeter.description.part2',
    },
};

interface ApplicantActivityCardProps {
    variant?: ApplicantActivityCardVariant;
    width?: number;
    showScoreChange?: boolean;
    showBorder?: boolean;
    iconType?: 'chevron' | 'info';
}

const ApplicantActivityCard: TranslatedComponent<ApplicantActivityCardProps> = ({
    trls,
    width,
    variant = 'full',
    showScoreChange = false,
    showBorder = false,
    iconType,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isMobile } = useBreakpoint();
    const activity = useSelector((state) => state.applicantActivity);
    const activityEvents = useSelector((state) => state.applicantActivityEvents);

    const activatorRef = useRef<HTMLDivElement>(null);
    const [isTooltipVisible, setTooltipVisibility] = useState(false);

    if (!activity) {
        return null;
    }

    const score = activity.userActivityScore ?? 0;
    const scoreChange = activity.userActivityScoreChange ?? 0;
    const eventScores = activityEvents?.eventScores ?? [];

    const PaddingMap: Record<ApplicantActivityCardVariant, 24 | 16 | 0> = {
        full: isMobile ? 16 : 24,
        compact: 0,
        response: 24,
    };

    const BorderRadiusMap: Record<ApplicantActivityCardVariant, 24 | 16 | 12 | 0> = {
        full: isMobile ? 16 : 24,
        compact: 0,
        response: 12,
    };

    const handleClick = (): void => {
        applicantActivityButtonClick({ index: score, type: variant === 'response' ? 'response' : 'regular' });
        if (iconType === 'chevron' || isMobile) {
            applicantActivityScreenShown({ index: score });
            setIsModalOpen(true);
        }
    };

    const handleClose = (): void => {
        setIsModalOpen(false);
    };

    const closeButton = (
        <Button mode="secondary" onClick={handleClose}>
            {trls[TrlKeys.close]}
        </Button>
    );

    const modalContent = (
        <VSpacingContainer default={16}>
            <ApplicantActivity score={score} size="large" counterPosition="right" />
            <Banner content={<Text Element="p">{trls[TrlKeys.content]}</Text>} style="secondary" showClose={false} />
            {!!eventScores.length && <ActivityEventScoreList eventScores={eventScores} />}
        </VSpacingContainer>
    );

    const renderInfoWithTooltip = () => (
        <>
            <span ref={activatorRef}>
                <InfoCircleOutlinedSize24 initialColor="secondary" onClick={() => setTooltipVisibility(true)} />
            </span>
            <Tooltip
                aria-label-close="close"
                placement="bottom-right"
                activatorRef={activatorRef}
                visible={isTooltipVisible}
                showClose
                onClose={() => setTooltipVisibility(false)}
            >
                <Text style="primary" typography="paragraph-2-regular">
                    {trls[TrlKeys.tooltipText.p1]}
                </Text>
                <VSpacing default={24} />
                <Text style="primary" typography="paragraph-2-regular">
                    {trls[TrlKeys.tooltipText.p2]}
                </Text>
            </Tooltip>
        </>
    );
    const renderChevronIcon = () => <ChevronRightOutlinedSize24 initialColor="secondary" />;

    const renderIconContent = () => {
        if (!iconType) {
            return null;
        }
        if (iconType === 'chevron') {
            return renderChevronIcon();
        }
        if (iconType === 'info') {
            return renderInfoWithTooltip();
        }
        return null;
    };

    return (
        <ElementShownAnchor
            fn={applicantActivityElementShown}
            index={score}
            type={variant === 'response' ? 'response' : 'regular'}
        >
            <div style={{ width: width ? `${width}px` : '100%' }}>
                <Card
                    stretched
                    borderWidth={showBorder ? 'default' : 'none'}
                    padding={PaddingMap[variant]}
                    borderRadius={BorderRadiusMap[variant]}
                    onClick={handleClick}
                    style={variant === 'response' ? 'special-secondary' : undefined}
                    data-qa={`activity-${variant === 'response' ? 'response' : 'card'}`}
                >
                    <ApplicantActivity
                        score={score}
                        scoreChange={showScoreChange ? scoreChange : undefined}
                        size="small"
                        right={renderIconContent()}
                        counterPosition="left"
                    />
                </Card>
                {isMobile ? (
                    <BottomSheet
                        visible={isModalOpen}
                        footer={<BottomSheetFooter>{closeButton}</BottomSheetFooter>}
                        onClose={handleClose}
                    >
                        <VSpacing default={12} />
                        {modalContent}
                    </BottomSheet>
                ) : (
                    <Modal visible={isModalOpen} footer={<ActionBar primaryActions={closeButton} />}>
                        {modalContent}
                    </Modal>
                )}
            </div>
        </ElementShownAnchor>
    );
};

export default translation(ApplicantActivityCard);
