import type { StatusEmployer } from '@hh.ru/analytics-js-events/build/xhh/employer/verification/not_verified_status_banner_element_shown';

import { EmployerVerificationState } from 'src/components/EmployerVerification/useEmployerVerificationState';

export const getEmployerStatusForAnalytics = (
    verificationState?: EmployerVerificationState
): StatusEmployer | undefined => {
    const convertMap: Partial<Record<EmployerVerificationState, StatusEmployer>> = {
        [EmployerVerificationState.NewWithDocs]: 'employer_new_with_doc',
        [EmployerVerificationState.NewWithoutDocs]: 'employer_new_without_doc',
        [EmployerVerificationState.NotVerifiedWithoutDocs]: 'employer_not_verified_without_doc',
    };

    if (verificationState && verificationState in convertMap) {
        return convertMap[verificationState];
    }
    return undefined;
};
