import AccountAddEmail from 'src/pages/AccountAddEmail';
import AccountCaptcha from 'src/pages/AccountCaptcha';
import AccountConnect from 'src/pages/AccountConnect';
import AccountDelete from 'src/pages/AccountDelete';
import AccountDeleteAffirmation from 'src/pages/AccountDeleteAffirmation';
import AccountDeleteConfirmation from 'src/pages/AccountDeleteConfirmation';
import AccountDeleteGratitude from 'src/pages/AccountDeleteGratitude';
import AccountHistory from 'src/pages/AccountHistory';
import AccountLogin from 'src/pages/AccountLogin';
import AccountPhoneVerification from 'src/pages/AccountPhoneVerification';
import AdvancedResumeSearch from 'src/pages/AdvancedResumeSearch';
import AdvancedVacancySearch from 'src/pages/AdvancedVacancySearch';
import Agencies from 'src/pages/Agencies';
import AgenciesList from 'src/pages/AgenciesList';
import AnonymousEmployer from 'src/pages/AnonymousEmployer';
import AppPromo from 'src/pages/AppPromo';
import ApplicantAddAuthPhone from 'src/pages/ApplicantAddAuthPhone';
import ApplicantApplications from 'src/pages/ApplicantApplications';
import ApplicantAssessment from 'src/pages/ApplicantAssessment';
import ApplicantBlacklistEmployers from 'src/pages/ApplicantBlacklistEmployers';
import ApplicantBlacklistVacancy from 'src/pages/ApplicantBlacklistVacancy';
import ApplicantCountriesVisibility from 'src/pages/ApplicantCountriesVisibility';
import ApplicantGallery from 'src/pages/ApplicantGallery';
import ApplicantNegotiations from 'src/pages/ApplicantNegotiations';
import ApplicantPhoneVerification from 'src/pages/ApplicantPhoneVerification';
import ApplicantPortrait from 'src/pages/ApplicantPortrait';
import ApplicantResumes from 'src/pages/ApplicantResumes';
import ApplicantRsvVerifications from 'src/pages/ApplicantRsvVerifications';
import ApplicantServicesPayment from 'src/pages/ApplicantServicesPayment';
import ApplicantSignup from 'src/pages/ApplicantSignup';
import ApplicantSkillVerificationsMethodsCatalog from 'src/pages/ApplicantSkillVerificationsMethods';
import ApplicantSuitableVacancies from 'src/pages/ApplicantSuitableVacancies';
import ApplicantVacancyResponsesSummaryPayment from 'src/pages/ApplicantVacancyResponsesSummaryPayment';
import ApplicantVacancyResponsesSummarySuccess from 'src/pages/ApplicantVacancyResponsesSummarySuccess';
import ApplyAgreement from 'src/pages/ApplyAgreement';
import Article from 'src/pages/Article';
import AuthEmployer from 'src/pages/AuthEmployer';
import AuthEmployerAlready from 'src/pages/AuthEmployerAlready';
import AuthEmployerDuplicated from 'src/pages/AuthEmployerDuplicated';
import Autologin from 'src/pages/Autologin';
import Away from 'src/pages/Away';
import Blog from 'src/pages/Blog';
import BrandSnippetEditor from 'src/pages/BrandSnippetEditor';
import CareerMarathonPayment from 'src/pages/CareerMarathonPayment';
import CareerMarathonSuccess from 'src/pages/CareerMarathonSuccess';
import Chat from 'src/pages/Chat';
import EmployerAddresses from 'src/pages/EmployerAddresses';
import EmployerAdvices from 'src/pages/EmployerAdvices';
import EmployerAgreements from 'src/pages/EmployerAgreements';
import EmployerApplications from 'src/pages/EmployerApplications';
import EmployerAutomationInfo from 'src/pages/EmployerAutomationInfo';
import EmployerConstructor from 'src/pages/EmployerConstructor';
import EmployerConstructorWebview from 'src/pages/EmployerConstructorWebView';
import EmployerContactsVisibility from 'src/pages/EmployerContactsVisibility';
import EmployerDeeds from 'src/pages/EmployerDeeds';
import EmployerDeedsOrder from 'src/pages/EmployerDeedsOrder';
import EmployerDocuments from 'src/pages/EmployerDocuments';
import EmployerDocumentsManagement from 'src/pages/EmployerDocumentsManagement';
import EmployerFinancialDocuments from 'src/pages/EmployerFinancialDocuments';
import EmployerHiringManagerVacancies from 'src/pages/EmployerHiringManagerVacancies';
import EmployerInvoiceFinish from 'src/pages/EmployerInvoiceFinish';
import EmployerInvoicePaylink from 'src/pages/EmployerInvoicePaylink';
import EmployerInvoicePayment from 'src/pages/EmployerInvoicePayment';
import EmployerInvoicePurchase from 'src/pages/EmployerInvoicePurchase';
import EmployerMailTemplates from 'src/pages/EmployerMailTemplates';
import EmployerMakeupWebView from 'src/pages/EmployerMakeupWebView';
import EmployerManagers from 'src/pages/EmployerManagers';
import EmployerManagersCreate from 'src/pages/EmployerManagersCreate';
import EmployerNegotiationsChangeTopic from 'src/pages/EmployerNegotiationsChangeTopic';
import EmployerQuota from 'src/pages/EmployerQuota';
import EmployerQuotaHistory from 'src/pages/EmployerQuotaHistory';
import EmployerQuotas from 'src/pages/EmployerQuotas';
import EmployerReviewsSettings from 'src/pages/EmployerReviewsSettings';
import EmployerSearchPublication from 'src/pages/EmployerSearchPublication';
import EmployerSettings from 'src/pages/EmployerSettings';
import EmployerSidebarEdit from 'src/pages/EmployerSidebarEdit';
import EmployerSimpleEdit from 'src/pages/EmployerSimpleEdit';
import EmployerStructure from 'src/pages/EmployerStructure';
import EmployerStructureChangelog from 'src/pages/EmployerStructureChangelog';
import EmployerSubscription from 'src/pages/EmployerSubscription';
import EmployerSuitablesUnsubscribe from 'src/pages/EmployerSuitablesUnsubscribe';
import EmployerTests from 'src/pages/EmployerTests';
import EmployerTestsConstructor from 'src/pages/EmployerTestsConstructor';
import EmployerTestsLibrary from 'src/pages/EmployerTestsLibrary';
import EmployerTestsView from 'src/pages/EmployerTestsView';
import EmployerUnpaidCarts from 'src/pages/EmployerUnpaidCarts';
import EmployerUnpremoderatedVacancyList from 'src/pages/EmployerUnpremoderatedVacancyList';
import EmployerVacancies from 'src/pages/EmployerVacancies';
import EmployerVacanciesArchived from 'src/pages/EmployerVacanciesArchived';
import EmployerVacanciesAuctionWebview from 'src/pages/EmployerVacanciesAuctionWebview';
import EmployerVacanciesDrafts from 'src/pages/EmployerVacanciesDrafts';
import EmployerVacanciesFunnelGroups from 'src/pages/EmployerVacanciesFunnelGroups';
import EmployerVacanciesPromotion from 'src/pages/EmployerVacanciesPromotion';
import EmployerVacanciesRise from 'src/pages/EmployerVacanciesRise';
import EmployerVacanciesTemplates from 'src/pages/EmployerVacanciesTemplates';
import EmployerVacanciesVr from 'src/pages/EmployerVacanciesVr';
import EmployerVacancyResponseTest from 'src/pages/EmployerVacancyResponseTest';
import EmployerVerificationActs from 'src/pages/EmployerVerificationActs';
import EmployerView from 'src/pages/EmployerView';
import EmployersCompany from 'src/pages/EmployersCompany';
import EmployersList from 'src/pages/EmployersList';
import ErrorPage from 'src/pages/ErrorPage';
import FastVacanciesSubscription from 'src/pages/FastVacanciesSubscription';
import Feedback from 'src/pages/Feedback';
import ForbiddenPage from 'src/pages/ForbiddenPage';
import IndexPage from 'src/pages/IndexPage';
import InvalidatePassword from 'src/pages/InvalidatePassword';
import JobStatistics from 'src/pages/JobStatistics';
import LegacyPage from 'src/pages/LegacyPage';
import LockHistory from 'src/pages/LockHistory';
import Mailto from 'src/pages/Mailto';
import MessengerAuthCodeForward from 'src/pages/MessengerAuthCodeForward';
import OauthAuthorize from 'src/pages/OauthAuthorize';
import OauthAuthorizeEmployer from 'src/pages/OauthAuthorizeEmployer';
import OauthCallback from 'src/pages/OauthCallback';
import OauthMergeByCode from 'src/pages/OauthMergeByCode';
import PartTimeVacancies from 'src/pages/PartTimeVacancies';
import PersonalManager from 'src/pages/PersonalManager';
import PriceAddContacts from 'src/pages/PriceAddContacts';
import PriceBranding from 'src/pages/PriceBranding';
import PriceClickmeProducts from 'src/pages/PriceClickmeProducts';
import PriceCrmSystem from 'src/pages/PriceCrmSystem';
import PriceDBAccess from 'src/pages/PriceDBAccess';
import PriceEmployerBrand from 'src/pages/PriceEmployerBrand';
import PricePayForPerformance from 'src/pages/PricePayForPerformance';
import PriceProfRolesPublications from 'src/pages/PriceProfRolesPublications';
import PriceRecommended from 'src/pages/PriceRecommended';
import PriceRegionalPublications from 'src/pages/PriceRegionalPublications';
import PriceZp from 'src/pages/PriceZp';
import PromotionPayForContact from 'src/pages/PromotionPayForContact';
import PromotionUseSearchTop from 'src/pages/PromotionUseSearchTop';
import ProxyExternalService from 'src/pages/ProxyExternalService';
import Push2FACallback from 'src/pages/Push2FACallback';
import RememberPassword from 'src/pages/RememberPassword';
import RestorePassword from 'src/pages/RestorePassword';
import ResumeBuilderAdditional from 'src/pages/ResumeBuilderAdditional';
import ResumeBuilderContacts from 'src/pages/ResumeBuilderContacts';
import ResumeBuilderCreds from 'src/pages/ResumeBuilderCreds';
import ResumeBuilderEducation from 'src/pages/ResumeBuilderEducation';
import ResumeBuilderExperience from 'src/pages/ResumeBuilderExperience';
import ResumeBuilderPersonal from 'src/pages/ResumeBuilderPersonal';
import ResumeBuilderPhoto from 'src/pages/ResumeBuilderPhoto';
import ResumeBuilderPosition from 'src/pages/ResumeBuilderPosition';
import ResumeCompletion from 'src/pages/ResumeCompletion';
import ResumeFolder from 'src/pages/ResumeFolder';
import ResumeFolders from 'src/pages/ResumeFolders';
import ResumeFoldersSearch from 'src/pages/ResumeFoldersSearch';
import ResumeSavedSearch from 'src/pages/ResumeSavedSearch';
import ResumeSearch from 'src/pages/ResumeSearch';
import ResumeSearchCatalog from 'src/pages/ResumeSearchCatalog';
import ResumeView from 'src/pages/ResumeView';
import ResumeViewHistory from 'src/pages/ResumeViewHistory';
import ResumeVisibility from 'src/pages/ResumeVisibility';
import SalaryStat from 'src/pages/SalaryStat';
import SearchCatalogList from 'src/pages/SearchCatalogList';
import Services from 'src/pages/Services';
import ShortResume from 'src/pages/ShortResume';
import SkillsLevelsEditor from 'src/pages/SkillsLevelsEditor';
import Subscribe from 'src/pages/Subscribe';
import TargetAgenciesPayment from 'src/pages/TargetAgenciesPayment';
import TargetEmployerResumes from 'src/pages/TargetEmployerResumes';
import TrustSource from 'src/pages/TrustSource';
import TwoFactorArticle from 'src/pages/TwoFactorArticle';
import Unsubscribe from 'src/pages/Unsubscribe';
import VacanciesProbationCatalog from 'src/pages/VacanciesProbationCatalog';
import VacancyConstructor from 'src/pages/VacancyConstructor';
import VacancyConstructorMobilePreview from 'src/pages/VacancyConstructorMobilePreview';
import VacancyConstructorTemplatePreview from 'src/pages/VacancyConstructorTemplatePreview';
import VacancyCreate from 'src/pages/VacancyCreate';
import VacancyDuplicate from 'src/pages/VacancyDuplicate';
import VacancyEdit from 'src/pages/VacancyEdit';
import VacancyEditPending from 'src/pages/VacancyEditPending';
import VacancyFavorite from 'src/pages/VacancyFavorite';
import VacancyMetroCatalog from 'src/pages/VacancyMetroCatalog';
import VacancyMetroMain from 'src/pages/VacancyMetroMain';
import VacancyPreview from 'src/pages/VacancyPreview';
import VacancyPrint from 'src/pages/VacancyPrint';
import VacancyResponse from 'src/pages/VacancyResponse';
import VacancyResponses from 'src/pages/VacancyResponses';
import VacancyResponsesAutoAction from 'src/pages/VacancyResponsesAutoAction';
import VacancyResponsesAutoInvite from 'src/pages/VacancyResponsesAutoInvite';
import VacancyResponsesCalls from 'src/pages/VacancyResponsesCalls';
import VacancyResponsesOutcomingCalls from 'src/pages/VacancyResponsesCalls/VacancyResponsesOutcomingCalls';
import VacancyResponsesFunnel from 'src/pages/VacancyResponsesFunnel';
import VacancyRestore from 'src/pages/VacancyRestore';
import VacancySavedSearch from 'src/pages/VacancySavedSearch';
import VacancySavedSearchConfirm from 'src/pages/VacancySavedSearchConfirm';
import VacancySavedSearchUnsubscribe from 'src/pages/VacancySavedSearchUnsubscribe';
import VacancySearch from 'src/pages/VacancySearch';
import VacancySearchCatalog from 'src/pages/VacancySearchCatalog';
import VacancySearchMap from 'src/pages/VacancySearchMap';
import VacancyTemplate from 'src/pages/VacancyTemplate';
import VacancyView from 'src/pages/VacancyView';
import VideoVacancy from 'src/pages/VideoVacancy';
import VrSignupLogin from 'src/pages/VrSignupLogin';
import WaitForActivationCarts from 'src/pages/WaitForActivationCarts';
import WantWorkPayment from 'src/pages/WantWorkPayment';

import paths from 'src/app/routePaths';

const routes = [
    {
        path: [
            paths.mentors,
            paths.support,
            paths.resumeProfile,
            paths.employerReviews,
            paths.applicantServices,
            paths.vrsurvey,
            paths.brandingProxyService,
            paths.resumeVerificationReport,
            paths.applicantKeyskillsVerificationMethods,
            paths.applicantSkillVerificationReport,
        ],
        component: ProxyExternalService,
        strict: false,
        exact: false,
        forceReload: true,
        isProxyService: true,
    },
    {
        path: paths.accountAddEmail,
        component: AccountAddEmail,
    },
    {
        path: paths.accountCaptcha,
        component: AccountCaptcha,
        forceReload: true,
    },
    {
        path: paths.agencies,
        component: Agencies,
    },
    {
        path: paths.applicantBlacklistVacancy,
        component: ApplicantBlacklistVacancy,
    },
    {
        path: paths.applicantBlacklistEmployers,
        component: ApplicantBlacklistEmployers,
    },
    {
        path: paths.applicantApplications,
        component: ApplicantApplications,
    },
    {
        path: paths.applicantGallery,
        component: ApplicantGallery,
    },
    {
        path: paths.applicantNegotiations,
        component: ApplicantNegotiations,
    },
    {
        path: paths.applicantPortrait,
        component: ApplicantPortrait,
    },
    {
        path: paths.applyAgreement,
        component: ApplyAgreement,
    },
    {
        path: paths.appPromo,
        component: AppPromo,
    },
    {
        path: paths.resumeVisibility,
        component: ResumeVisibility,
    },
    {
        path: paths.applicantServicesPayment,
        component: ApplicantServicesPayment,
    },
    {
        path: paths.fastVacanciesSubscription,
        component: FastVacanciesSubscription,
    },
    {
        path: paths.partTimeVacancies,
        component: PartTimeVacancies,
    },
    {
        path: paths.wantWorkPayment,
        component: WantWorkPayment,
    },
    {
        path: paths.vacancyPreview,
        component: VacancyPreview,
    },
    {
        path: paths.vacancyPreviewDraft,
        component: VacancyPreview,
    },
    {
        path: paths.salaryStat,
        component: SalaryStat,
    },
    {
        path: [
            paths.resumeSearchCatalogListMain,
            paths.resumeSearchCatalogListByLetter,
            paths.vacancySearchCatalogListMain,
            paths.vacancySearchCatalogListByLetter,
        ],
        component: SearchCatalogList,
    },
    {
        path: [paths.resumeSearchCatalog, paths.resumeSearchCatalogRegion],
        component: ResumeSearchCatalog,
    },
    {
        path: [paths.vacancyView, paths.vacancyViewAliases, paths.vacancyViewZarplata, paths.employerVacancyView],
        component: VacancyView,
        forceReload: true,
    },
    {
        path: paths.VacancySavedSearch,
        component: VacancySavedSearch,
        forceReload: true,
    },
    {
        path: paths.VacancySavedSearchConfirm,
        component: VacancySavedSearchConfirm,
        forceReload: true,
    },
    {
        path: paths.VacancySavedSearchUnsubscribe,
        component: VacancySavedSearchUnsubscribe,
        forceReload: true,
    },
    {
        path: paths.resumeSavedSearch,
        component: ResumeSavedSearch,
        forceReload: true,
    },
    {
        path: paths.targetAgenciesPayment,
        component: TargetAgenciesPayment,
    },
    {
        path: paths.careerMarathonPayment,
        component: CareerMarathonPayment,
    },
    {
        path: paths.careerMarathonSuccess,
        component: CareerMarathonSuccess,
    },
    {
        path: paths.applicantVacancyResponsesSummaryPayment,
        component: ApplicantVacancyResponsesSummaryPayment,
    },
    {
        path: paths.applicantVacancyResponsesSummarySuccess,
        component: ApplicantVacancyResponsesSummarySuccess,
    },
    {
        path: paths.vacancyResponse,
        component: VacancyResponse,
    },
    {
        path: paths.vacancyResponseTest,
        component: VacancyResponse,
    },
    {
        path: paths.anonymousEmployer,
        component: AnonymousEmployer,
        forceReload: true,
    },
    {
        path: paths.accountHistory,
        component: AccountHistory,
    },
    {
        path: paths.accountHistoryMoney,
        component: AccountHistory,
    },
    {
        path: paths.accountHistoryExpenditures,
        component: AccountHistory,
    },
    {
        path: paths.accountHistoryReplenishments,
        component: AccountHistory,
    },
    {
        path: paths.accountHistoryServices,
        component: AccountHistory,
    },
    {
        path: paths.employerUnpremoderatedVacancyList,
        component: EmployerUnpremoderatedVacancyList,
    },
    {
        path: paths.employerApplications,
        component: EmployerApplications,
        forceReload: true,
    },
    {
        path: paths.employerVacancies,
        component: EmployerVacancies,
    },
    {
        path: paths.employerVacanciesDrafts,
        component: EmployerVacanciesDrafts,
    },
    {
        path: paths.EmployerVacanciesAuctionWebview,
        component: EmployerVacanciesAuctionWebview,
    },
    {
        path: paths.employerVacanciesPromotion,
        component: EmployerVacanciesPromotion,
    },
    {
        path: paths.promotionUseSearchTop,
        component: PromotionUseSearchTop,
    },
    {
        path: paths.promotionPayForContact,
        component: PromotionPayForContact,
    },
    {
        path: paths.employerVacanciesRise,
        component: EmployerVacanciesRise,
    },
    {
        path: paths.employerVacanciesArchived,
        component: EmployerVacanciesArchived,
    },
    {
        path: paths.employerVacanciesTemplates,
        component: EmployerVacanciesTemplates,
    },
    {
        path: paths.employerVacanciesVr,
        component: EmployerVacanciesVr,
    },
    {
        path: paths.employerVacanciesFunnelGroups,
        component: EmployerVacanciesFunnelGroups,
    },
    {
        path: paths.employerAdvices,
        component: EmployerAdvices,
    },
    {
        path: paths.employerQuotas,
        component: EmployerQuotas,
    },
    {
        path: paths.vacancyConstructorTemplatePreview,
        component: VacancyConstructorTemplatePreview,
        forceReload: true,
    },
    {
        path: paths.vacancyConstructorMobilePreview,
        component: VacancyConstructorMobilePreview,
    },
    {
        path: paths.vacancyConstructor,
        component: VacancyConstructor,
    },
    {
        path: paths.vacancyCreate,
        component: VacancyCreate,
    },
    {
        path: paths.vacancyEdit,
        component: VacancyEdit,
    },
    {
        path: paths.vacancyEditPending,
        component: VacancyEditPending,
    },
    {
        path: paths.vacancyRestore,
        component: VacancyRestore,
    },
    {
        path: paths.vacancyDuplicate,
        component: VacancyDuplicate,
    },
    {
        path: paths.videoVacancy,
        component: VideoVacancy,
        forceReload: true,
    },
    {
        path: paths.vacancyTemplate,
        component: VacancyTemplate,
    },
    {
        path: paths.targetEmployerResumes,
        component: TargetEmployerResumes,
    },
    {
        path: paths.employerConstructorWebview,
        component: EmployerConstructorWebview,
    },
    {
        path: paths.employerContactsVisibility,
        component: EmployerContactsVisibility,
    },
    {
        path: paths.employersCompany,
        component: EmployersCompany,
    },
    {
        path: [paths.employersList, paths.employersListZarplata],
        component: EmployersList,
        forceReload: true,
    },
    {
        path: paths.employerMakeupWebView,
        component: EmployerMakeupWebView,
    },
    {
        path: paths.vacancyPrint,
        component: VacancyPrint,
    },
    {
        path: paths.applicantResumes,
        component: ApplicantResumes,
    },
    {
        path: paths.applicantSkillVerificationsMethods,
        component: ApplicantSkillVerificationsMethodsCatalog,
    },
    {
        path: paths.applicantRsvVerifications,
        component: ApplicantRsvVerifications,
    },
    {
        path: paths.applicantResumesList,
        component: ApplicantResumes,
    },
    {
        path: paths.applicantPhoneVerification,
        component: ApplicantPhoneVerification,
    },
    {
        path: paths.shortResume,
        component: ShortResume,
    },
    {
        path: paths.resumeCompletion,
        component: ResumeCompletion,
    },
    {
        path: paths.applicantSuitableVacancies,
        component: ApplicantSuitableVacancies,
    },
    {
        path: paths.resumeViewByID,
        component: ResumeView,
    },
    {
        path: paths.resumeView,
        component: ResumeView,
    },
    {
        path: paths.employerDocuments,
        component: EmployerDocuments,
    },
    {
        path: paths.employerQuota,
        component: EmployerQuota,
    },
    {
        path: paths.employerQuotaHistory,
        component: EmployerQuotaHistory,
    },
    {
        path: paths.employerReviewsSettings,
        component: EmployerReviewsSettings,
    },
    {
        path: paths.employerStructure,
        component: EmployerStructure,
    },
    {
        path: paths.employerStructureChangelog,
        component: EmployerStructureChangelog,
    },
    {
        path: paths.employerDocumentsManagement,
        component: EmployerDocumentsManagement,
    },
    {
        path: paths.employerDeeds,
        component: EmployerDeeds,
    },
    {
        path: paths.employerMailTemplates,
        component: EmployerMailTemplates,
    },
    {
        path: paths.employerCarts,
        component: WaitForActivationCarts,
    },
    {
        path: paths.employerUnpaidCarts,
        component: EmployerUnpaidCarts,
    },
    {
        path: paths.employerNegotiationsChangeTopic,
        component: EmployerNegotiationsChangeTopic,
        forceReload: true,
    },
    {
        path: paths.employerConstructor,
        component: EmployerConstructor,
    },
    {
        path: [paths.employerView, paths.employerViewZarplata],
        component: EmployerView,
        forceReload: true,
    },
    {
        path: paths.employerSimpleEdit,
        component: EmployerSimpleEdit,
    },
    {
        path: paths.employerSidebarEdit,
        component: EmployerSidebarEdit,
    },
    {
        path: paths.invalidatePassword,
        component: InvalidatePassword,
    },
    {
        path: paths.brandSnippetEditor,
        component: BrandSnippetEditor,
    },
    {
        path: paths.trustSource,
        component: TrustSource,
    },
    {
        path: paths.oauthAuthorize,
        component: OauthAuthorize,
    },
    {
        path: paths.oauthAuthorizeEmployer,
        component: OauthAuthorizeEmployer,
    },
    {
        path: paths.oauthCallback,
        component: OauthCallback,
    },
    {
        path: paths.oauthMergeByCode,
        component: OauthMergeByCode,
    },
    {
        path: paths.accountPhoneVerification,
        component: AccountPhoneVerification,
    },
    {
        path: paths.personalManager,
        component: PersonalManager,
    },
    {
        path: paths.priceDBAccess,
        component: PriceDBAccess,
    },
    {
        path: paths.priceEmployerBrand,
        component: PriceEmployerBrand,
    },
    {
        path: paths.pricePayForPerformance,
        component: PricePayForPerformance,
    },
    {
        path: paths.priceRegionalPublications,
        component: PriceRegionalPublications,
    },
    {
        path: paths.priceProfRolesPublications,
        component: PriceProfRolesPublications,
    },
    // tempexp_33624_start
    {
        path: paths.priceCrmSystem,
        component: PriceCrmSystem,
    },
    // tempexp_33624_end
    {
        path: paths.priceRecommended,
        component: PriceRecommended,
    },
    {
        path: paths.priceClickmeProducts,
        component: PriceClickmeProducts,
    },
    {
        path: paths.priceBranding,
        component: PriceBranding,
    },
    {
        path: paths.priceAddContacts,
        component: PriceAddContacts,
    },
    {
        path: paths.priceZpResumeAccess,
        component: PriceZp,
    },
    {
        path: paths.priceZpPublications,
        component: PriceZp,
    },
    {
        path: paths.priceZpBundles,
        component: PriceZp,
    },
    {
        path: paths.priceZpPromotion,
        component: PriceZp,
    },
    {
        path: paths.priceZpBackoffice,
        component: PriceZp,
    },
    {
        path: [paths.applicantSignup, paths.authRegistrationZarplata, paths.registrationZarplata],
        component: ApplicantSignup,
        forceReload: true,
    },
    {
        path: paths.applicantSignupVerify,
        component: ApplicantSignup,
        forceReload: true,
    },
    {
        path: paths.rememberPassword,
        component: RememberPassword,
        forceReload: true,
    },
    {
        path: paths.restorePassword,
        component: RestorePassword,
        forceReload: true,
    },
    {
        path: paths.resumeSearch,
        component: ResumeSearch,
    },
    {
        path: paths.resumeSearchAliases,
        component: ResumeSearch,
        forceReload: true,
    },
    {
        path: paths.advancedResumeSearchVariants,
        component: AdvancedResumeSearch,
        forceReload: true,
    },
    {
        path: paths.advancedVacancySearch,
        component: AdvancedVacancySearch,
        forceReload: true,
    },
    {
        path: paths.resumeFolder,
        component: ResumeFolder,
        forceReload: true,
    },
    {
        path: paths.resumeFolders,
        component: ResumeFolders,
        forceReload: true,
    },
    {
        path: paths.resumeFoldersSearch,
        component: ResumeFoldersSearch,
        forceReload: true,
    },
    {
        path: paths.resumeBuilderPersonal,
        component: ResumeBuilderPersonal,
    },
    {
        path: paths.resumeBuilderExperience,
        component: ResumeBuilderExperience,
    },
    {
        path: paths.resumeBuilderPhoto,
        component: ResumeBuilderPhoto,
    },
    {
        path: paths.resumeBuilderContacts,
        component: ResumeBuilderContacts,
    },
    {
        path: paths.resumeBuilderCreds,
        component: ResumeBuilderCreds,
    },
    {
        path: paths.skillsLevelsEditor,
        component: SkillsLevelsEditor,
    },
    {
        path: paths.resumeBuilderAdditional,
        component: ResumeBuilderAdditional,
    },
    {
        path: paths.unsubscribe,
        component: Unsubscribe,
    },
    {
        path: paths.resumeBuilderPosition,
        component: ResumeBuilderPosition,
    },
    {
        path: paths.resumeBuilderEducation,
        component: ResumeBuilderEducation,
    },
    {
        path: paths.authEmployer,
        component: AuthEmployer,
        forceReload: true,
    },
    {
        path: paths.authEmployerAlready,
        component: AuthEmployerAlready,
        forceReload: true,
    },
    {
        path: paths.authEmployerDuplicated,
        component: AuthEmployerDuplicated,
    },
    {
        path: paths.accountConnect,
        component: AccountConnect,
        forceReload: true,
    },
    {
        path: [paths.accountLogin, paths.authLoginZarplata],
        component: AccountLogin,
        forceReload: true,
    },
    {
        path: paths.vacancyResponses,
        component: VacancyResponses,
    },
    {
        path: paths.vacancyResponsesAutoAction,
        component: VacancyResponsesAutoAction,
    },
    {
        path: paths.VacancyResponsesAutoInvite,
        component: VacancyResponsesAutoInvite,
    },
    {
        path: paths.VacancyResponsesFunnel,
        component: VacancyResponsesFunnel,
    },
    {
        path: paths.vacancyResponsesCalls,
        component: VacancyResponsesCalls,
    },
    {
        path: paths.vacancyResponsesOutcomingCalls,
        component: VacancyResponsesOutcomingCalls,
    },
    {
        path: [paths.vacancySearch, paths.vacancySearchZarplata],
        component: VacancySearch,
    },
    {
        path: paths.vacancySearchMap,
        component: VacancySearchMap,
        forceReload: true,
    },
    {
        path: paths.specializedPartTimeVacancies,
        component: PartTimeVacancies,
        exact: true,
    },
    {
        path: paths.vacancyMetroMain,
        component: VacancyMetroMain,
    },
    {
        path: paths.vacancySearchMetroCatalog,
        component: VacancyMetroCatalog,
    },
    {
        path: paths.vacanciesProbationCatalog,
        component: VacanciesProbationCatalog,
        exact: true,
    },
    {
        path: paths.vacanciesProbationCatalogProfarea,
        component: VacanciesProbationCatalog,
    },
    {
        path: paths.vacancyFavorite,
        component: VacancyFavorite,
    },
    {
        path: paths.employerFinancialDocuments,
        component: EmployerFinancialDocuments,
    },
    {
        path: paths.employerVerificationActs,
        component: EmployerVerificationActs,
        forceReload: true,
    },
    {
        path: paths.accountDelete,
        component: AccountDelete,
    },
    {
        path: paths.accountDeleteConfirmation,
        component: AccountDeleteConfirmation,
    },
    {
        path: paths.accountDeleteAffirmation,
        component: AccountDeleteAffirmation,
    },
    {
        path: paths.accountDeleteGratitude,
        component: AccountDeleteGratitude,
    },
    {
        path: paths.indexPage,
        component: IndexPage,
        forceReload: true,
    },
    {
        path: paths.employerAddresses,
        component: EmployerAddresses,
    },
    {
        path: paths.employerAgreements,
        component: EmployerAgreements,
    },
    {
        path: paths.employerAutomationInfo,
        component: EmployerAutomationInfo,
    },
    {
        path: paths.vrSignupLogin,
        component: VrSignupLogin,
    },
    {
        path: [paths.subscribe, paths.subscribeZarplata],
        component: Subscribe,
    },
    {
        path: paths.mailto,
        component: Mailto,
    },
    {
        path: paths.messengerAuthCodeForward,
        component: MessengerAuthCodeForward,
    },
    {
        path: paths.applicantCountriesVisibility,
        component: ApplicantCountriesVisibility,
    },
    {
        path: paths.applicantAddAuthPhone,
        component: ApplicantAddAuthPhone,
    },
    {
        path: paths.employerManagers,
        component: EmployerManagers,
    },
    {
        path: paths.employerSubscription,
        component: EmployerSubscription,
    },
    {
        path: paths.employerTests,
        component: EmployerTests,
    },
    {
        path: paths.employerTestsView,
        component: EmployerTestsView,
    },
    {
        path: paths.employerTestsConstructor,
        component: EmployerTestsConstructor,
    },
    {
        path: paths.employerTestsLibrary,
        component: EmployerTestsLibrary,
        forceReload: true,
    },
    {
        path: paths.employerTestsLibraryCategory,
        component: EmployerTestsLibrary,
        forceReload: true,
    },
    {
        path: paths.employerInvoicePurchase,
        component: EmployerInvoicePurchase,
        forceReload: true,
    },
    {
        path: paths.employerInvoicePaylink,
        component: EmployerInvoicePaylink,
    },
    {
        path: paths.employerInvoicePayment,
        component: EmployerInvoicePayment,
        forceReload: true,
    },
    {
        path: paths.employerInvoiceFinish,
        component: EmployerInvoiceFinish,
        forceReload: true,
    },
    {
        path: paths.employerSettings,
        component: EmployerSettings,
    },
    {
        path: paths.employerSearchPublication,
        component: EmployerSearchPublication,
    },
    {
        path: paths.employerManagersCreate,
        component: EmployerManagersCreate,
        forceReload: true,
    },
    {
        path: [paths.eventsForStudents, paths.events],
        component: Blog,
        forceReload: true,
    },
    {
        path: paths.blog,
        component: Blog,
        exact: false,
        forceReload: true,
    },
    {
        path: paths.article,
        component: Article,
        forceReload: true,
    },
    {
        path: paths.twoFactorArticle,
        component: TwoFactorArticle,
    },
    {
        path: [paths.vacancySearchCatalog, paths.vacancySearchCatalogRegion, paths.vacancySearchCatalogZarplata],
        component: VacancySearchCatalog,
    },
    {
        path: paths.feedback,
        component: Feedback,
        forceReload: true,
    },
    {
        path: paths.away,
        component: Away,
    },
    {
        path: paths.services,
        component: Services,
    },
    {
        path: [paths.jobStatistics, paths.jobStatisticsAdmin],
        component: JobStatistics,
    },
    {
        path: paths.lockHistory,
        component: LockHistory,
    },
    {
        path: paths.autologin,
        component: Autologin,
    },
    {
        path: paths.resumeViewHistory,
        component: ResumeViewHistory,
    },
    {
        path: paths.employerDeedsOrder,
        component: EmployerDeedsOrder,
    },
    {
        path: paths.agenciesList,
        component: AgenciesList,
    },
    {
        path: paths.applicantAssessment,
        component: ApplicantAssessment,
    },
    {
        path: paths.employerHiringManagerVacancies,
        component: EmployerHiringManagerVacancies,
    },
    {
        path: paths.chat,
        component: Chat,
    },
    {
        path: paths.employerSuitablesUnsubscribe,
        component: EmployerSuitablesUnsubscribe,
    },
    {
        path: paths.employerVacancyResponseTest,
        component: EmployerVacancyResponseTest,
    },
    {
        path: paths.push2FACallback,
        component: Push2FACallback,
    },
];

routes.push({
    component: LegacyPage,
});

export const forbiddenPageRoute = {
    path: '*',
    component: ForbiddenPage,
    forceReload: true,
};
export const errorPageRoute = {
    path: '*',
    component: ErrorPage,
    forceReload: true,
};

export default routes;
