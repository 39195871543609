import { FC } from 'react';

import { Card } from '@hh.ru/magritte-ui';
import { WhatsappBrandSize24, TelegramBrandSize24, ViberBrandSize24 } from '@hh.ru/magritte-ui/service';

import { MessengerType } from 'src/models/messengers';

import styles from './styles.less';

const MESSENGERS_ICONS = {
    [MessengerType.Telegram]: <TelegramBrandSize24 />,
    [MessengerType.Whatsapp]: <WhatsappBrandSize24 />,
    [MessengerType.Viber]: <ViberBrandSize24 />,
};

const MESSENGERS_CARD_STYLES = {
    [MessengerType.Telegram]: 'accent-secondary',
    [MessengerType.Whatsapp]: 'positive-secondary',
    [MessengerType.Viber]: 'special-secondary',
} as const;

interface SupportMessengerProps {
    messenger: MessengerType;
    href: string;
    onClick?: () => void;
}

const SupportMessenger: FC<SupportMessengerProps> = ({ messenger, href, onClick }) => {
    return (
        <Card
            Element="a"
            href={href}
            target="_blank"
            rel="noreferrer"
            onClick={onClick}
            style={MESSENGERS_CARD_STYLES[messenger]}
            borderRadius={12}
            padding={16}
            stretched
            aria-label={messenger}
        >
            <div className={styles.supportMessenger}>{MESSENGERS_ICONS[messenger]}</div>
        </Card>
    );
};

export default SupportMessenger;
