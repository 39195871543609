import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { ModelDataMetro } from 'src/components/Search/Common/Metro/types';

export enum CurrentGeoFilter {
    AREA = 'AREA',
    DISTRICT = 'DISTRICT',
    METRO = 'METRO',
}

interface SearchGeoDrawer {
    isVisible: boolean;
    currentGeoFilter: CurrentGeoFilter;
    metroCache: Record<string, ModelDataMetro[]>;
}

const SET_GEO_DRAWER_CLOSED = 'SET_GEO_DRAWER_CLOSED';
const SET_GEO_DRAWER_OPENED = 'SET_GEO_DRAWER_OPENED';
const SET_GEO_DRAWER_CURRENT_FILTER = 'SET_GEO_DRAWER_CURRENT_FILTER';
const APPEND_METRO_CACHE_ITEM = 'APPEND_METRO_CACHE_ITEM';

interface PayloadTypes {
    [SET_GEO_DRAWER_CLOSED]: void;
    [SET_GEO_DRAWER_OPENED]: void;
    [SET_GEO_DRAWER_CURRENT_FILTER]: CurrentGeoFilter;
    [APPEND_METRO_CACHE_ITEM]: Record<string, ModelDataMetro[]>;
}

const actionHelper = ActionCreatorHelper<PayloadTypes>();

export const setSearchGeoDrawerClosed = actionHelper(SET_GEO_DRAWER_CLOSED);
export const setSearchGeoDrawerOpened = actionHelper(SET_GEO_DRAWER_OPENED);
export const setSearchGeoDrawerCurrentFilter = actionHelper(SET_GEO_DRAWER_CURRENT_FILTER);
export const appendMetroCacheItem = actionHelper(APPEND_METRO_CACHE_ITEM);

const INITIAL_STATE: SearchGeoDrawer = {
    isVisible: false,
    currentGeoFilter: CurrentGeoFilter.AREA,
    metroCache: {},
};

export default createReducer<SearchGeoDrawer, PayloadTypes>(INITIAL_STATE, {
    [SET_GEO_DRAWER_CLOSED]: (state) => ({ ...state, isVisible: false }),
    [SET_GEO_DRAWER_OPENED]: (state) => ({ ...state, isVisible: true }),
    [SET_GEO_DRAWER_CURRENT_FILTER]: (state, { payload }) => ({ ...state, currentGeoFilter: payload }),
    [APPEND_METRO_CACHE_ITEM]: (state, { payload }) => ({ ...state, metroCache: { ...state.metroCache, ...payload } }),
});
