import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { PfpInfo } from 'src/models/payForPerformance/pfpInfo';
import { PfpBalance, PfpBalanceByAgreement } from 'src/models/price/pricePayForPerformance';

const ACCOUNT_HISTORY_MONEY = 'ACCOUNT_HISTORY_MONEY';
const UPDATE_PFP_BALANCE = 'UPDATE_PFP_BALANCE';

interface AccountHistoryMoneyState {
    hasPersonalManager: boolean;
    state: string;
    email: string;
    balances: {
        accountBalance?: number;
        activationReserved?: number;
        hrsReserved?: number;
        otherReserved?: number;
        totalReserved?: number;
    };
    employerCountry: string;
    // string потому что в xhh/pages/employer/account/__init__.py преобразуется сразу в перевод руб и так далее
    currency: string;
    hrs: {
        orders: string[];
    };
    availableWithoutBlockedMoney: number;
    blockedMoney: number;
    pfp?: PfpInfo;
    hhEmployees: {
        paymentLink?: string;
        serviceLink?: string;
    };
    hasPaymentFeature: boolean;
    clickmeAmounts: {
        minAmount: number;
        recommendedAmount: number;
        suggestedAmounts: number[];
    };
}

interface PayloadTypes {
    [ACCOUNT_HISTORY_MONEY]: {
        email?: string;
        state: StateStatus;
    };
    [UPDATE_PFP_BALANCE]: PfpBalance;
}

export enum StateStatus {
    Fetching = 'fetching',
    Fail = 'fail',
    Clear = 'clear',
    Edit = 'edit',
}

// TODO добавить описание исходник смотреть тут xhh/pages/employer/account/__init__.py
const initialState: AccountHistoryMoneyState = {
    hasPersonalManager: false,
    state: '',
    email: '',
    currency: '',
    hrs: {
        orders: [],
    },
    balances: {},
    employerCountry: '',
    availableWithoutBlockedMoney: 0.0,
    blockedMoney: 0.0,
    hhEmployees: {},
    hasPaymentFeature: true,
    clickmeAmounts: {
        minAmount: 0,
        recommendedAmount: 0,
        suggestedAmounts: [],
    },
};

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const accountHistoryMoneyAction = ActionCreator(ACCOUNT_HISTORY_MONEY);
export const updatePfpBalance = ActionCreator(UPDATE_PFP_BALANCE);

export default createReducer<AccountHistoryMoneyState, PayloadTypes>(initialState, {
    [ACCOUNT_HISTORY_MONEY]: (state, action) => {
        return { ...state, ...action.payload };
    },
    [UPDATE_PFP_BALANCE]: (state, { payload: balance }) => {
        const getAgreementNumber = (balanceByAgreement: PfpBalanceByAgreement) =>
            state.pfp?.pfpDetails?.balance?.balanceByAgreements?.find(
                ({ agreementId }) => balanceByAgreement.agreementId === agreementId
            )?.agreementNumber;

        return {
            ...state,
            pfp: {
                ...state.pfp!,
                pfpDetails: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    ...state.pfp?.pfpDetails!,
                    balance: {
                        ...balance,
                        balanceByAgreements: balance.balanceByAgreements?.map((balanceByAgreement) => {
                            return {
                                ...balanceByAgreement,
                                agreementNumber: getAgreementNumber(balanceByAgreement),
                            };
                        }),
                    },
                },
            },
        };
    },
});
