import { PropsWithChildren } from 'react';

import { Tag, HSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SupernovaLink, { SupernovaLinkKind } from 'src/components/SupernovaMainMenu/Link';
import translation from 'src/components/translation';
import { NavItem } from 'src/models/supernovaNaviMenu';

interface NavItemLinkProps extends NavItem {
    dataQa: string;
    kind: SupernovaLinkKind;
    onClick?: () => void;
}

const TrlKeys = {
    tagNew: 'navi.menuitem.tag.new',
};

const NavItemLink: TranslatedComponent<NavItemLinkProps & PropsWithChildren> = ({
    trls,
    name,
    url,
    translations,
    target,
    dataQa,
    kind,
    onClick,
}) => {
    const targetProps = target ? { target } : {};
    const tag = name === 'hhPro' ? <Tag style="positive">{trls[TrlKeys.tagNew]}</Tag> : null;

    if (!url) {
        return null;
    }

    return (
        <SupernovaLink to={url} kind={kind} data-qa={dataQa} onClick={onClick} {...targetProps}>
            <HSpacingContainer default={8}>
                {translations.name}
                {tag}
            </HSpacingContainer>
        </SupernovaLink>
    );
};

export default translation(NavItemLink);
