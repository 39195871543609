import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import { SalaryRange } from '@hh.ru/types-hh-microcore';

import { MarketRange } from 'src/models/canvacVacancySalaryData';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';
import { VacancyAdviceWithStatistics } from 'src/models/vacancy/vacancyAdvices.types';

export interface VacancyAdviceModalData {
    vacancy: {
        vacancyId: number;
        vacancyName: string;
        vacancyAreaName: string;
    };
    adviceWithStatistics: VacancyAdviceWithStatistics[];
    compensation?: VacancyCompensation;
    marketRange?: MarketRange;
    salaryRange?: SalaryRange;
    numberOfSimilarVacancies: number;
}

const SET_DATA_VACANCY_ADVICE_MODAL = 'SET_DATA_VACANCY_ADVICE_MODAL';
const SET_ADVICE_VACANCY_ADVICE_MODAL = 'SET_ADVICE_VACANCY_ADVICE_MODAL';

interface PayloadTypes {
    [SET_DATA_VACANCY_ADVICE_MODAL]: VacancyAdviceModalData;
    [SET_ADVICE_VACANCY_ADVICE_MODAL]: VacancyAdviceWithStatistics[];
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const setDataVacancyAdviceModal = actionCreator(SET_DATA_VACANCY_ADVICE_MODAL);
export const setAdviceVacancyAdviceModal = actionCreator(SET_ADVICE_VACANCY_ADVICE_MODAL);

const INITIAL_STATE = {
    vacancy: {
        vacancyId: 0,
        vacancyName: '',
        vacancyAreaName: '',
    },
    adviceWithStatistics: [],
    numberOfSimilarVacancies: 0,
} as VacancyAdviceModalData;

export default createReducer<VacancyAdviceModalData, PayloadTypes>(INITIAL_STATE, {
    [SET_DATA_VACANCY_ADVICE_MODAL]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
    SET_ADVICE_VACANCY_ADVICE_MODAL: (state, { payload }) => ({
        ...state,
        adviceWithStatistics: payload,
    }),
});
