import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface VacancyAnalyticsConversion {
    startDate: string;
    endDate: string;
    vacancyId: number;
    conversion?: number;
    viewCount?: number;
    responseCount?: number;
    searchCount?: number;
}

export default autoGeneratedReducer<VacancyAnalyticsConversion | null>(null);
