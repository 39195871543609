import { useEffect, useRef } from 'react';
import classNames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import footerAnalytics from 'src/components/Footer/analytics';
import MobileAppButton from 'src/components/MobileAppButton';
import translation from 'src/components/translation';
import { useAppLinkHref } from 'src/hooks/useAppLinkHref';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

import styles from './mobile-app-buttons.less';

const TrlKeys = {
    title: 'supernova.navi.footer.mobileApps',
};

const getAppLinkHref = (appHref: string, mobilePage: string | null) => {
    if (mobilePage) {
        return `${mobilePage}?from=footer&hhtmFromLabel=footer`;
    }

    return appHref;
};

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

interface MobileAppLinksProps {
    isApplicant: boolean;
    showHeader?: boolean;
    desktopView?: 'qr' | 'buttons';
}

const getQrCodeDataQa = (isEmployer: boolean, isRabotaBy: boolean): string => {
    let dataQa = 'footer-mobile-app-qr-code';

    dataQa += isEmployer ? '_empl' : '_appl';

    if (isRabotaBy) {
        dataQa += '_rabotaby';
    }

    return dataQa;
};

const MobileAppLinks: TranslatedComponent<MobileAppLinksProps> = ({
    trls,
    isApplicant,
    showHeader = true,
    desktopView = 'qr',
}) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const applicationItemsRef = useRef(null);
    const mobilePage = useSelector((state) => state.stayInTouch.mobilePage);
    const apps = useSelector((state) => state.stayInTouch.apps);
    const userType = useSelector(({ userType }) => userType);
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);
    const device = useSelector(({ device }) => device);
    const isRabotaBy = useIsRabotaBy();
    const appLinkHref = useAppLinkHref({ from: 'footer' });

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && apps?.length && applicationItemsRef.current) {
            footerAnalytics.applicationItemsShown(
                applicationItemsRef.current,
                apps.map((item) => item.name)
            );
        }
    }, [apps, employerMainPageAnalyticsEnabled]);

    const isEmployer = !isApplicant;

    if (!apps.length) {
        return null;
    }

    return (
        <div ref={applicationItemsRef}>
            {showHeader && (
                <>
                    <Text typography="label-2-regular">{trls[TrlKeys.title]}</Text>

                    <VSpacing default={12} />
                </>
            )}

            <div
                className={classNames(styles.mobileAppButtons, {
                    [styles.onlyButtons]: desktopView === 'buttons',
                })}
            >
                {apps.map(({ name }) => (
                    <MobileAppButton
                        key={name}
                        name={name}
                        appLink={getAppLinkHref(appLinkHref, device?.type ? null : mobilePage)}
                        onClickLink={() => {
                            if (userType === UserType.Employer || supernovaUserType === SupernovaUserType.Employer) {
                                Analytics.sendEvent(userType, 'click_hrmobile', 'footer');
                            }
                            if (employerMainPageAnalyticsEnabled) {
                                footerAnalytics.applicationItemClick(name);
                            }
                        }}
                    />
                ))}
            </div>

            {desktopView === 'qr' && (
                <div className={styles.qrCode}>
                    <div
                        className={classNames(styles.qr, {
                            [styles.qrApplicant]: isApplicant,
                            [styles.qrEmployer]: isEmployer,
                            [styles.qrApplicantRabotaby]: isRabotaBy && isApplicant,
                            [styles.qrEmployerRabotaby]: isRabotaBy && isEmployer,
                        })}
                        data-qa={getQrCodeDataQa(isEmployer, isRabotaBy)}
                    />
                </div>
            )}
        </div>
    );
};

export default translation(MobileAppLinks);
