import { FC, useEffect, useRef } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';

import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';

import { MenuItemRendererProps } from 'src/components/SupernovaOverlayMenu/MenuItemRendererProps';
import overlayMenuAnalytics from 'src/components/SupernovaOverlayMenu/analytics';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const ServicesMenuItem: FC<MenuItemRendererProps> = ({ menuItem }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const menuItemRef = useRef(null);
    const handleClick = useTrackClicks(menuItem.name, menuItem.trackClicks, menuItem.analytics);

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && menuItemRef.current) {
            overlayMenuAnalytics.overlayMenuItemShown(menuItemRef.current, menuItem.name);
        }
    }, [employerMainPageAnalyticsEnabled, menuItem.name]);

    return (
        <div
            ref={menuItemRef}
            className="supernova-navi-item supernova-navi-item_lvl-1 supernova-navi-item_overlay-toggle"
        >
            <span className="supernova-icon-services-dynamic">
                <SPALink
                    className="supernova-icon-link-switch HH-Supernova-Overlay-Toggle"
                    onClick={handleClick}
                    data-qa={`mainmenu_${menuItem.name}`}
                    to="/services"
                >
                    <span className="supernova-link-switch supernova-link-switch_dimmed">
                        {menuItem.translations.name}
                    </span>
                    <span>&#160;</span>
                    <span className="supernova-icon-services" />
                </SPALink>
            </span>
        </div>
    );
};

export default ServicesMenuItem;
