/**
 * Коды service
 * https://wiki.hh.ru/pages/viewpage.action?pageId=334867428
 */
export enum ServiceCode {
    /** Докупка */
    Add = 'ADD',
    /** Bundle. Поконтактный доступ и публикации стандарт. Всегда содержит CIV и VP */
    ContactsViewWithStandart = 'CIV+VPPL',
    /** Разовая услуга */
    Container = 'CONTAINER',
    /** Просто заглушка (технический код), может содержать под собой практически любой service_item */
    Disposable = 'DI',
    /** Безлимитный доступ к базе резюме */
    FullAccess = 'FA',
    /** Bundle. Безлимитный доступ к базе резюме с публикациями стандарт */
    FullAccessWithStandart = 'FA+VPPL',
    /** Публикации с ограничением по регионам и проф. областям */
    LimitedVacancy = 'VPPL',
    /** Резервация */
    Reservation = 'RESERVATION',
    /** Пакеты услуг */
    Bundle = 'BUNDLE',
    /** Конструктор страницы вакансий */
    BrandingBuilderVacancy = 'VBC',
    /** Конструктор страницы вакансии */
    BrandingBuilderPage = 'Z1',
    /** Брендированная страница вакансии */
    BrandingVacancy = 'Z5',
    /** Брендированная страница работодателя */
    BrandingPageCompany = 'Z6',
    /** Брендированный шаблон письма */
    BrandingMailTemplate = 'Z2B',
    /** Билеты на HR-мероприятия */
    ZpEvent = 'ZP_EVENT',
}

/**
 * Коды service_item
 * https://wiki.hh.ru/pages/viewpage.action?pageId=334867428
 */
export enum ServiceItemCode {
    /** Рекламные публикации */
    Advertizing = 'ADN',
    /** Анонимные публикации */
    Anonymous = 'AP',
    /** Доступ к API */
    ApiLimited = 'API_LIMITED',
    /** Просмотр контактов резюме */
    ContactsView = 'CIV',
    /** Динамический тест числовых способностей */
    DynamicTestOfNumericalAbilities = 'TEST_SHL_MATH',
    /** Динамический тест вербальных способностей */
    DynamicTestOfVerbalAbilities = 'TEST_SHL_VERB',
    Clickme = 'CLKM',
    /** Бесплатные публикации в регионах */
    FreeRegionalVacancy = 'RFPN',
    /** Бесплатные публикации */
    FreeVacancy = 'FPN',
    /** Публикации премиум */
    Premium = 'VPREM',
    /** Выборка */
    ResumeSelection = 'VSIM',
    /** Публикации стандарт */
    Standart = 'VP',
    /** Публикации стандарт+ */
    StandartPlus = 'RENEWAL_VP',
    /** Опросник выявления универсальных компетенций */
    UniversalCompetencyQuestionnaire = 'TEST_SHL_OPQ',
    /** Поднятие вакансии */
    VacancySearchBoost = 'VSB',
    /** Видеоинтервью */
    VideoInterview = 'VIDEO_INTVW',
    /** Публикация на «Зарплата.ру» */
    ZpCrosspost = 'ZP_CROSSPOST',
    /** Публикация «Промо» на «Зарплата.ру» */
    ZpPromo = 'ZP_PROMO',
    /** Публикация «Бизнес» на «Зарплата.ру» */
    ZpBusiness = 'ZP_BUSINESS',
    /** Анонимная публикация на «Зарплата.ру» */
    ZpAnonymous = 'ZP_ANONYMOUS',
    /** Публикация «Демо» на «Зарплата.ру» */
    ZpDemo = 'ZP_DEMO',
    /** Опции */
    OptionPremium = 'OPTION_PREMIUM',
    /** Конструктор вакансий базовый */
    VacancyBuilderBasic = 'VBC_BASIC',
    /** Баланс PFP */
    PfpBalance = 'PFP_BALANCE',
    /** Публикация «Спринт» на «Зарплата.ру» */
    ZpSprint = 'ZP_SPRINT',
    // tempexp_37869_next_line
    RiseExtra = 'RISE_EXTRA',
}

export const additionalPurchaseCodes = [ServiceCode.Add, ServiceCode.Reservation] as const;
export const publicationsCodes = [
    ServiceItemCode.Standart,
    ServiceItemCode.StandartPlus,
    ServiceItemCode.Premium,
    ServiceItemCode.Anonymous,
    ServiceItemCode.Advertizing,
    ServiceItemCode.FreeRegionalVacancy,
    ServiceItemCode.ZpPromo,
    ServiceItemCode.ZpBusiness,
    ServiceItemCode.ZpAnonymous,
    ServiceItemCode.ZpDemo,
    ServiceItemCode.ZpSprint,
];

export type PublicationCodeAvailableForPurchase =
    | ServiceItemCode.Standart
    | ServiceItemCode.StandartPlus
    | ServiceItemCode.Premium
    | ServiceItemCode.Anonymous
    | ServiceItemCode.ZpPromo
    | ServiceItemCode.ZpBusiness
    | ServiceItemCode.ZpAnonymous
    | ServiceItemCode.ZpSprint;
