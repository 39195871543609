import { useMemo } from 'react';

import { ChatikProviderProps } from '@hh.ru/chatik-integration';

import chatikErrorNotificationMagritte from 'src/components/Notifications/ChatikErrorNotificationMagritte';
import { useNotification } from 'src/components/Notifications/Provider';
import { WEBCALL_CUSTOM_EVENTS } from 'src/components/Webcall/const';
import { WebCallData } from 'src/components/Webcall/types';
import { useSelector } from 'src/hooks/useSelector';
import { UXFeedback } from 'src/utils/uxfeedback';

type InitParams = ChatikProviderProps['initParams'];

interface ShowNotificationParams {
    content: string;
    error?: boolean;
}

const useInitParams = (origin: string): InitParams => {
    const webviewAppType = useSelector((state) => state.webviewAppType);
    const displayType = useSelector((state) => state.displayType);

    const { addNotification } = useNotification();

    const initParams = useMemo(
        () =>
            ({
                origin,
                webviewAppType,
                displayType,
                incomingMessageHandlers: {
                    showNotification: (notificationParams: ShowNotificationParams) => {
                        addNotification(chatikErrorNotificationMagritte, {
                            props: notificationParams,
                        });
                    },
                    uxfbEvent: (data: { name: string }) => {
                        UXFeedback.sendEvent(data.name);
                    },
                    redirectTo: (data: { path: string }) => {
                        window.location.assign(data.path);
                    },
                    startCall: (data: WebCallData) => {
                        const detail = {
                            ...data,
                            widgetId: 'webcall-front-dynamic',
                        };
                        window.dispatchEvent(new CustomEvent(WEBCALL_CUSTOM_EVENTS.INIT_CALL, { detail }));
                    },
                },
            }) as InitParams,
        [origin, webviewAppType, displayType, addNotification]
    );

    return initParams;
};
export default useInitParams;
