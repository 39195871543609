import { useMemo } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { SearchQueries } from 'src/models/search/resume/queries';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';

const BASE_PATHS_MAP = {
    [SupernovaSearchName.Resumes]: '/search/resume/advanced',
    [SupernovaSearchName.Vacancies]: '/search/vacancy/advanced',
};

const isSomeResumeSearchQueryEmpty = (
    searchName: SupernovaSearchName,
    resumeSearchQueries: SearchQueries[]
): boolean => {
    // проверяем, что переключились с поиска резюме на поиск вакансий и при этом какой-то из полей поиска пустой
    // для того, чтобы не добавлять для этого кейста параметр text=, так как там будет брошеная запятая
    return (
        searchName !== SupernovaSearchName.Resumes &&
        resumeSearchQueries.length > 1 &&
        resumeSearchQueries.some((query) => query.text[0] === '')
    );
};

const getAdvancedSearchHref = (
    searchName: SupernovaSearchName,
    criteriaTextUpdated: string | string[],
    params: string,
    resumeSearchQueries?: SearchQueries[]
): string => {
    let href = '';

    if (searchName !== SupernovaSearchName.Employers) {
        // array only with employers search
        const text = criteriaTextUpdated as string;
        const searchParams = params ? `no_default_area&${params}` : '';
        const textParam =
            params.includes('text=') ||
            isSomeResumeSearchQueryEmpty(searchName, resumeSearchQueries || []) ||
            !criteriaTextUpdated
                ? ''
                : `text=${text}`;
        const mainDelimiter = searchParams || textParam ? '?' : '';
        const extraDelimiter = searchParams && textParam ? '&' : '';
        href = `${BASE_PATHS_MAP[searchName]}${mainDelimiter}${searchParams}${extraDelimiter}${textParam}`;
    }

    return href;
};

export default (searchName: SupernovaSearchName): string => {
    const criteriaTextUpdated = useSelector(({ criteriaTextUpdated }) => criteriaTextUpdated);
    const resumeSearchQueries = useSelector((state) => state.searchQueries?.values);

    const vacancySearchParams = useSelector(({ vacancySearchResult }) => vacancySearchResult.queries?.currentParams);
    const resumeSearchParams = useSelector(({ resumeSearchResult }) => resumeSearchResult.queries?.currentParams);
    const params = useMemo(() => {
        const searchParams = {
            [SupernovaSearchName.Resumes]: resumeSearchParams || '',
            [SupernovaSearchName.Vacancies]: vacancySearchParams || '',
        };
        return searchName !== SupernovaSearchName.Employers ? searchParams[searchName] : '';
    }, [resumeSearchParams, searchName, vacancySearchParams]);

    return getAdvancedSearchHref(searchName, criteriaTextUpdated, params, resumeSearchQueries);
};
