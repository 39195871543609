import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';

import { useTopLevelSite } from 'src/hooks/useSites';
import { setSmartScriptAvailability } from 'src/models/smartScript';
import { TopLevelSite } from 'src/models/topLevelSite';

const MOBILE_APP_LANDING_PATH = '/mobile';

const SmartScript: FC = () => {
    const dispatch = useDispatch();
    const topLevelSite = useTopLevelSite();
    const smartScriptHref = useSelector(({ smartScript }) => smartScript.href);
    const location = useSelector((state) => state.router.location);
    const isMobileAppLanding = location.pathname === MOBILE_APP_LANDING_PATH;

    useEffect(() => {
        if (![TopLevelSite.BY].includes(topLevelSite) && !!smartScriptHref && !isMobileAppLanding) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            import('smart-script.v1').then(() => dispatch(setSmartScriptAvailability(true))).catch(console.error);
        }
    }, [dispatch, topLevelSite, smartScriptHref, isMobileAppLanding]);

    return null;
};

export default SmartScript;
