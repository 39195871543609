import { RefObject, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import headerGeoDefinitionSuggestElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_suggest_element_shown';
import headerGeoDefinitionSuggestScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/header/geo_definition/header_geo_definition_suggest_screen_shown';
import { Action, BottomSheet, Drop, NavigationBar, Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { setIsGeoSwitcherActive } from 'src/models/isGeoSwitcherActive';

import GeoSwitcherContent from 'src/components/SupernovaMainMenu/GeoSwitcherPopup/GeoSwitcherPopupContent';

import styles from './styles.less';

interface GeoSwitcherPopupProps {
    activatorRef: RefObject<HTMLDivElement>;
    placement?: 'bottom-right' | 'bottom-left';
}

const TrlKeys = {
    title: 'geoSwitcher.popup.title',
};

const setRegionClarification = makeSetStoreField('regionClarification');

const GeoSwitcherPopup: TranslatedComponent<GeoSwitcherPopupProps> = ({
    activatorRef,
    placement = 'bottom-right',
    trls,
}) => {
    const visible = useSelector(({ isGeoSwitcherActive }) => isGeoSwitcherActive);
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();

    const handleClose = () => {
        dispatch(setIsGeoSwitcherActive(false));
    };

    const clearRegionClarification = useCallback(() => {
        dispatch(setRegionClarification(null));
    }, [dispatch]);

    useEffect(() => {
        if (visible) {
            clearRegionClarification();
        }
    }, [clearRegionClarification, visible]);

    useEffect(() => {
        if (visible) {
            if (isMobile) {
                headerGeoDefinitionSuggestScreenShown({ hhtmSource: 'header_geo_definition_suggest' });
            }
        }
    }, [isMobile, visible]);

    const closeAction = <Action icon={CrossOutlinedSize24} mode="secondary" style="neutral" onClick={handleClose} />;

    return (
        <>
            <Drop
                width={480}
                space={600}
                onClose={handleClose}
                visible={visible}
                autoFocusWhenOpened={false}
                placement={placement}
                activatorRef={activatorRef}
                style={{ marginTop: '4px', marginLeft: '-12px' }}
                forcePlacement
                ignoreIntersections
            >
                <ElementShownAnchor fn={headerGeoDefinitionSuggestElementShown}>
                    <div className={styles.header}>
                        <Text typography={'title-4-semibold'} data-qa="geo-switcher-header">
                            {trls[TrlKeys.title]}
                        </Text>
                        {closeAction}
                    </div>
                    <VSpacing default={28} />
                    <GeoSwitcherContent />
                </ElementShownAnchor>
            </Drop>
            <BottomSheet
                height="full-screen"
                header={<NavigationBar title={trls[TrlKeys.title]} right={[closeAction]} />}
                onClose={handleClose}
                withContentPaddings
                visible={visible}
            >
                <GeoSwitcherContent />
            </BottomSheet>
        </>
    );
};

export default translation(GeoSwitcherPopup);
