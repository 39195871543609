import { CriteriaKey } from 'src/types/search/common/criteria';

import { GetSearchParamsArgs } from 'src/components/Search/Common/Filters/utils/getSearchParams/types';
import getVacancySearchParams, {
    VacancySearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancy';

export type VacancyMapSearchParams = VacancySearchParams & {
    [CriteriaKey.BottomLeftLat]: string;
    [CriteriaKey.BottomLeftLng]: string;
    [CriteriaKey.GeocodeType]: string;
    [CriteriaKey.Height]: string;
    [CriteriaKey.ItemsOnPage]: string;
    [CriteriaKey.Precision]: string;
    [CriteriaKey.TopRightLat]: string;
    [CriteriaKey.TopRightLng]: string;
    [CriteriaKey.Width]: string;
};

const getVacancyMapSearchParams = (args: GetSearchParamsArgs): VacancyMapSearchParams => {
    const searchParams = getVacancySearchParams(args) as VacancyMapSearchParams;
    return searchParams;
};

export default getVacancyMapSearchParams;
