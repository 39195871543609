import { FC, useEffect, useCallback } from 'react';
import add from 'date-fns/add';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import parseISO from 'date-fns/parseISO';

import Modal from 'bloko/blocks/modal';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import { useAppLinkHref } from 'src/hooks/useAppLinkHref';
import useOnOffState from 'src/hooks/useOnOffState';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import ZPView from 'src/components/AppPromoBanner/ZPView';

const APP_PROMO_BANNER_HIDDEN_UNTIL = 'appPromoBannerHiddenUntil';

const DAYS_AFTER_INSTALL = 14;
const DAYS_AFTER_CLOSE = 1;

const AppPromoBanner: FC = () => {
    const [isShowModal, openModal, closeModal] = useOnOffState(false);
    const isZP = useIsZarplataPlatform();
    const isMobile = useBreakpoint(Breakpoint.L) === Breakpoint.XS;
    const appLink = useSelector((state) => state.appLink);
    const isEmployer = useSelector(({ userType }) => userType) === UserType.Employer;
    const appLinkHref = useAppLinkHref();

    const handleInstallClick = () => {
        closeModal();
        LocalStorageWrapper.setItem(
            APP_PROMO_BANNER_HIDDEN_UNTIL,
            add(new Date(), { days: DAYS_AFTER_INSTALL }).toISOString()
        );
    };

    const showModal = useCallback(() => {
        openModal();
        LocalStorageWrapper.setItem(
            APP_PROMO_BANNER_HIDDEN_UNTIL,
            add(new Date(), { days: DAYS_AFTER_CLOSE }).toISOString()
        );
    }, [openModal]);

    useEffect(() => {
        const lastShownDate = LocalStorageWrapper.getItem(APP_PROMO_BANNER_HIDDEN_UNTIL);

        if (!lastShownDate || (lastShownDate && differenceInMilliseconds(parseISO(lastShownDate), new Date()) < 0)) {
            showModal();
        }
    }, [showModal]);

    if (!isMobile || !isZP || isEmployer || !appLink.detected || !isShowModal) {
        return null;
    }

    return (
        <Modal visible={isShowModal} onClose={closeModal} closeButtonShow={false}>
            <ZPView appHref={appLinkHref} onInstall={handleInstallClick} onClose={closeModal} />
        </Modal>
    );
};

export default AppPromoBanner;
