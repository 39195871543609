import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { LatestActivity } from 'src/types/search/vacancy/card';

import { CountryCode } from 'src/models/countryId';
import { VacancyProperties } from 'src/models/vacancyProperties';

interface VacancyEmployerManager {
    hhid: number;
    managerId: number;
    userId: number;
    latestActivity: LatestActivity;
    firstName?: string;
    lastName?: string;
    middleName?: string;
}

export interface VacancyFull {
    address?: {
        disabled: boolean;
        id: number;
        building: string;
        city: string;
        displayName: string;
    };
    vacancyId: number;
    publicationTimeIso: string;
    area: {
        id: number;
        regionId: number;
        countryCodeIso: CountryCode;
        name: string;
        path: string;
    };
    name: string;
    company: {
        id: number;
    };
    vacancyProperties: VacancyProperties;
    keySkill: string[];
    employerManager: VacancyEmployerManager;
}

export default autoGeneratedReducer<VacancyFull>(null);
