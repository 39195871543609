import { FC } from 'react';
import classnames from 'classnames';

import { GridLayout, GridColumn } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import routePaths from 'src/app/routePaths';
import StaticImg from 'src/components/StaticImg';
import { useAppLinkHref } from 'src/hooks/useAppLinkHref';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import { UserType } from 'src/models/userType';

import AppBannerQR from 'src/components/Footer/MobileBanner/AppBannerQRRedesign';
import AppBannerStore from 'src/components/Footer/MobileBanner/AppBannerStoreRedesign';
import MobileBannerContent from 'src/components/Footer/MobileBanner/Content';
import MobileBannerImage from 'src/components/Footer/MobileBanner/Image';

import stylesRedesign from './footer-mobile-banner-redesign.less';
import styles from './footer-mobile-banner.less';

const MobileBanner: FC = () => {
    const appLink = useSelector(({ appLink }) => appLink);
    const { apps } = useSelector((state) => state.stayInTouch);
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const isIndexPage = useSelector(({ router }) => router.location.pathname === routePaths.indexPage);
    const userType = useSelector(({ userType }) => userType);
    const isApplicant = UserType.Applicant === userType;
    const appLinkHref = useAppLinkHref();
    const isRabotaBy = useIsRabotaBy();

    const isMobileDevice = appLink.detected;

    const isMagritte = (isApplicant && isIndexPage) || isAnonymousMagritteExp;

    if (isMagritte) {
        return (
            <div
                className={classnames(stylesRedesign.footerAppBannerRedesign, {
                    [stylesRedesign.footerAppBannerRedesignAnon]: isAnonymousMagritteExp,
                })}
            >
                <GridLayout contentDataQA="footer-app-banner">
                    <div className={stylesRedesign.footerAppBannerRedesignContainer}>
                        <GridColumn l={10} m={12} s={8} xs={4} flexibleContent>
                            <div className={stylesRedesign.footerAppBannerRedesignContent}>
                                <GridColumn l={6} m={7} s={8} xs={4} flexibleContent>
                                    {isMobileDevice ? <AppBannerStore app={apps[0]} /> : <AppBannerQR />}
                                </GridColumn>
                                <GridColumn m={4} l={3} s={8} xs={4} flexibleContent>
                                    <div className={stylesRedesign.footerAppBannerRedesignPhoneImageContainer}>
                                        <StaticImg
                                            className={stylesRedesign.footerAppBannerRedesignPhoneImage}
                                            path={
                                                isAnonymousMagritteExp
                                                    ? '/images/hh/appbanner/phone_negotiations.png'
                                                    : '/images/hh/appbanner/phone.png'
                                            }
                                        />
                                    </div>
                                </GridColumn>
                            </div>
                        </GridColumn>
                    </div>
                </GridLayout>
            </div>
        );
    }

    const content = appLink.detected ? (
        <>
            <Column xs="4" s="4" m="5" l="5">
                <MobileBannerContent href={appLinkHref} hasAppLink />
            </Column>
            <Column xs="0" s="0" m="2" l="2" />
            <Column xs="4" s="4" m="5" l="5">
                <MobileBannerImage hasAppLink />
            </Column>
        </>
    ) : (
        <>
            <Column xs="0" s="3" m="5" l="5">
                <MobileBannerContent href={appLinkHref} hasAppLink={false} />
            </Column>
            <Column xs="0" s="2" m="2" l="2">
                <div
                    className={classnames(styles.footerMobileBannerQrCode, {
                        [styles.footerMobileBannerQrCodeJtb]: isRabotaBy,
                    })}
                />
            </Column>
            <Column xs="0" s="0" m="1" l="1" />
            <Column xs="0" s="3" m="4" l="5">
                <MobileBannerImage hasAppLink={false} />
            </Column>
        </>
    );

    return (
        <div
            className={classnames(styles.footerMobileBanner, {
                [styles.footerMobileBannerApp]: appLink.detected,
            })}
        >
            <ColumnsWrapper>
                <Column xs="0" s="0" m="0" l="2"></Column>
                {content}
                <Column xs="0" s="0" m="0" l="1"></Column>
            </ColumnsWrapper>
        </div>
    );
};

export default MobileBanner;
