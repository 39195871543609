import { ResumeEmployersListType } from 'src/models/applicant/resume/api';
import { ResumeVisibilityEmployerListDTO, ResumeVisibilitySearchListDTO } from 'src/models/applicant/resume/api/dto';
import { createSlice } from 'src/models/applicant/resume/lib/slice';
import { ReducerAction } from 'src/models/applicant/resume/lib/slice/types';

export type BatchEmployersUpdateAction = 'add' | 'delete';
export type BatchEmployersUpdateMap = Record<number, BatchEmployersUpdateAction>;

type ResumeEmployersSteps = 'accessType' | ResumeEmployersListType;
type ResumeEmployersSubSteps = 'list' | 'search' | null;

interface State {
    whitelist?: ResumeVisibilityEmployerListDTO;
    blacklist?: ResumeVisibilityEmployerListDTO;
    searchList?: ResumeVisibilitySearchListDTO;
    fetching: boolean;
    saving: boolean;
    searching: boolean;
    step: ResumeEmployersSteps;
    subStep: ResumeEmployersSubSteps;
    editState: BatchEmployersUpdateMap;
}

const initialState: State = {
    whitelist: undefined,
    blacklist: undefined,
    searchList: undefined,
    fetching: false,
    saving: false,
    searching: false,
    step: 'accessType',
    subStep: null,
    editState: {},
};

const employersListSlice = createSlice({
    initialState,
    reducers: {
        resetResumeEmployersStepper: () => initialState,
        changeResumeEmployerListFetching: (state, { payload }: ReducerAction<boolean>) => ({
            ...state,
            fetching: payload,
        }),
        changeResumeEmployerListSaving: (state, { payload }: ReducerAction<boolean>) => ({
            ...state,
            saving: payload,
        }),
        changeResumeEmployerListSearching: (state, { payload }: ReducerAction<boolean>) => ({
            ...state,
            searching: payload,
        }),
        loadResumeEmployersList: (
            state,
            { payload }: ReducerAction<{ type: ResumeEmployersListType; response: ResumeVisibilityEmployerListDTO }>
        ) => {
            return { ...state, [payload.type]: payload.response, fetching: false };
        },
        loadResumeEmployersSearchList: (
            state,
            { payload }: ReducerAction<{ response: ResumeVisibilitySearchListDTO }>
        ) => {
            return { ...state, searchList: payload.response, fetching: false };
        },
        changeResumeEmployersListStep: (
            state,
            { payload }: ReducerAction<{ step: ResumeEmployersSteps; subStep: ResumeEmployersSubSteps }>
        ) => {
            return { ...state, step: payload.step, subStep: payload.subStep, editState: {} };
        },
        addResumeEmployersEditStateAction: (
            state,
            { payload }: ReducerAction<{ action: BatchEmployersUpdateAction; employerId: number }>
        ) => {
            return { ...state, editState: { ...state.editState, [payload.employerId]: payload.action } };
        },
    },
});

export const {
    loadResumeEmployersList,
    changeResumeEmployerListFetching,
    changeResumeEmployerListSaving,
    changeResumeEmployersListStep,
    addResumeEmployersEditStateAction,
    loadResumeEmployersSearchList,
    changeResumeEmployerListSearching,
    resetResumeEmployersStepper,
} = employersListSlice.actions;

export const employersListReducer = employersListSlice.reducer;
