import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { OrderByOption } from 'src/types/search/common/criteria';
import { SearchPeriodOption } from 'src/types/search/vacancy/criteria';

export interface NovaSort {
    orderBy: OrderByOption;
    searchPeriod: SearchPeriodOption;
}

export default autoGeneratedReducer<NovaSort>({
    orderBy: OrderByOption.Relevance,
    searchPeriod: SearchPeriodOption.AllTime,
});
