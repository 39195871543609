import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { AppStore } from 'src/app/store';
import { ResumeCardData } from 'src/models/resumeCard';
import { ResumeSavedSearchItem } from 'src/models/resumeSavedSearch';
import fetcher from 'src/utils/fetcher';

import { ResumeSearchesWidget, ResumeSearchesWidgetTabCounters } from 'src/models/employer/resumeSearchesWidget/types';

const INITIAL_STATE = {
    tabs: [],
    savedSearches: {
        items: [],
    },
    targetResumes: {
        items: [],
    },
    favoriteResumes: {
        items: [],
    },
};

const UPDATE_SAVED_SEARCHES = 'UPDATE_SAVED_SEARCHES';
const UPDATE_TARGET_RESUMES = 'UPDATE_TARGET_RESUMES';
const UPDATE_FAVORITE_RESUMES = 'UPDATE_FAVORITE_RESUMES';

const actionCreator = ActionCreatorHelper<PayloadTypes>();

const updateSavedSearches = actionCreator(UPDATE_SAVED_SEARCHES);
const updateTargetResumes = actionCreator(UPDATE_TARGET_RESUMES);
const updateFavoriteResumes = actionCreator(UPDATE_FAVORITE_RESUMES);

interface PayloadTypes {
    [UPDATE_SAVED_SEARCHES]: { items: ResumeSavedSearchItem[]; counters: ResumeSearchesWidgetTabCounters };
    [UPDATE_TARGET_RESUMES]: { items: ResumeCardData[]; counters: ResumeSearchesWidgetTabCounters };
    [UPDATE_FAVORITE_RESUMES]: { items: ResumeCardData[] };
}

declare global {
    interface FetcherGetApi {
        '/employer/saved_search/resume': {
            response: {
                items: ResumeSavedSearchItem[];
            };
            queryParams: void;
        };
    }
}

declare global {
    interface FetcherGetApi {
        '/employer/target_resumes': {
            response: {
                items: ResumeCardData[];
            };
            queryParams: void;
        };
    }
}

declare global {
    interface FetcherGetApi {
        '/employer/favorite_resumes': {
            response: {
                items: ResumeCardData[];
            };
            queryParams: void;
        };
    }
}

export const refetchSavedSearches = (
    abortController: AbortController
): ThunkAction<Promise<unknown>, AppStore, unknown, Action> => {
    return async (dispatch, getState) => {
        try {
            const { items } = await fetcher.get('/employer/saved_search/resume', { signal: abortController.signal });
            const userStats = getState().userStats;
            dispatch(updateSavedSearches({ items, counters: { new: userStats?.['new-resumes-saved-search'] } }));
        } catch (error) {
            if (!fetcher.isCancel(error)) {
                console.error(error);
            }
        }
    };
};

export const refetchTargetResumes = (
    abortController: AbortController
): ThunkAction<Promise<unknown>, AppStore, unknown, Action> => {
    return async (dispatch, getState) => {
        try {
            const { items } = await fetcher.get('/employer/target_resumes', { signal: abortController.signal });
            const userStats = getState().userStats;
            dispatch(
                updateTargetResumes({
                    items,
                    counters: { all: userStats?.['wanna-work'], new: userStats?.['new-wanna-work'] },
                })
            );
        } catch (error) {
            if (!fetcher.isCancel(error)) {
                console.error(error);
            }
        }
    };
};

export const refetchFavoriteResumes = (
    abortController: AbortController
): ThunkAction<Promise<unknown>, AppStore, unknown, Action> => {
    return async (dispatch) => {
        try {
            const { items } = await fetcher.get('/employer/favorite_resumes', { signal: abortController.signal });
            dispatch(updateFavoriteResumes({ items }));
        } catch (error) {
            if (!fetcher.isCancel(error)) {
                console.error(error);
            }
        }
    };
};

export default createReducer<ResumeSearchesWidget, PayloadTypes>(INITIAL_STATE, {
    [UPDATE_SAVED_SEARCHES]: (state, { payload: { items, counters } }) => {
        const hasTab = state.tabs.find((tab) => tab.name === 'savedSearches');
        const tabs = [...state.tabs];

        if (!hasTab && items.length) {
            tabs.push({
                name: 'savedSearches',
                counters,
            });
        }

        return { ...state, savedSearches: { items }, tabs: hasTab ? state.tabs : tabs };
    },
    [UPDATE_TARGET_RESUMES]: (state, { payload: { items, counters } }) => {
        const hasTab = state.tabs.find((tab) => tab.name === 'targetResumes');
        const tabs = [...state.tabs];

        if (!hasTab && items.length) {
            tabs.push({
                name: 'targetResumes',
                counters,
            });
        }

        return { ...state, targetResumes: { items }, tabs: hasTab ? state.tabs : tabs };
    },
    [UPDATE_FAVORITE_RESUMES]: (state, { payload: { items } }) => {
        const hasTab = state.tabs.find((tab) => tab.name === 'favoriteResumes');
        const tabs = [...state.tabs];

        if (!hasTab && items.length) {
            tabs.push({
                name: 'favoriteResumes',
            });
        }

        return { ...state, favoriteResumes: { items }, tabs: hasTab ? state.tabs : tabs };
    },
});
