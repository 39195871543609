import { urlParser } from '@hh.ru/browser-api-utils';

import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import { useSmartScript, ONLINK_FROM_PARAM_NAME } from 'src/hooks/useSmartScript';

interface AppLinkProps {
    from?: string;
}

export const useAppLinkHref = ({ from }: AppLinkProps = {}) => {
    const isRabotaBy = useIsRabotaBy();
    const userType = useSelector((state) => state.userType);
    const appLink = useSelector(({ appLink }) => appLink);
    const mobilePage = useSelector((state) => state.stayInTouch.mobilePage);
    const supernovaUserType = useSelector((state) => state.supernovaUserType);
    const smartScriptHref = useSmartScript({ from });
    const isEmployer = userType === UserType.Employer || supernovaUserType === SupernovaUserType.Employer;
    const isZp = useIsZarplataPlatform();

    if (!isZp && (!appLink.detected || !appLink.href)) {
        return mobilePage || '/mobile';
    }

    const parsedAppLinkHref = urlParser(appLink.href);

    if (isRabotaBy || isEmployer) {
        from && (parsedAppLinkHref.params[from] = '');

        return parsedAppLinkHref.href;
    }

    parsedAppLinkHref.params[ONLINK_FROM_PARAM_NAME] = from;

    return smartScriptHref || parsedAppLinkHref.href;
};
