import { useMemo } from 'react';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useEmployerBillingInfo from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';
import translation from 'src/components/translation';
import { ServiceEmployerDescription } from 'src/models/oldEmployer/employerBillingSummary';
import { ServiceItemCode } from 'src/models/price/productCodes';
import formatNumber from 'src/utils/formatNumber';

import Informer from 'src/components/SupernovaMainMenu/EmployerProfile/Informers/Informer';

const TrlKeys = {
    contacts: 'employer.employerProfile.popup.informers.contacts',
    contactQuotas: 'employer.employerProfile.popup.informers.contactQuotas',
    of: 'employer.employerProfile.popup.informers.of',
};

export const isContactsViewService = (service: ServiceEmployerDescription): boolean =>
    service.code === ServiceItemCode.ContactsView;

const ContactsInformer: TranslatedComponent = ({ trls }) => {
    const { billingInfo } = useEmployerBillingInfo();

    const contactsTotal = useMemo(() => {
        return (
            billingInfo?.employer?.serviceDescription
                ?.filter(isContactsViewService)
                .reduce((total, service) => total + Number(service.count), 0) || 0
        );
    }, [billingInfo]);

    if (billingInfo?.quotas?.contactsQuotas) {
        return (
            <Informer data-qa="contacts-quotas" title={trls[TrlKeys.contactQuotas]}>
                {formatNumber(billingInfo.quotas.contactsQuotas)}{' '}
                <Text Element="span" style="secondary" typography="label-2-regular">
                    {trls[TrlKeys.of]} {formatNumber(contactsTotal || 0)}
                </Text>
            </Informer>
        );
    }

    if (!!contactsTotal || contactsTotal === 0) {
        return (
            <Informer data-qa="opening-contacts-total" title={trls[TrlKeys.contacts]}>
                {formatNumber(contactsTotal)}
            </Informer>
        );
    }

    return null;
};

export default translation(ContactsInformer);
