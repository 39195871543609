import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import { CountrySelection, DataQaKey, DATA_QA_ATTR_PREFIX } from 'src/components/ResumesCountriesVisibility/types';

const TrlKeys = {
    common: 'settings.countries_visible.common_countries_description.applicant',
    uzbekistan: 'settings.countries_visible.uzbekistan_description.applicant',
    descriptionEnd: 'settings.countries_visible.description_end.applicant',
    conditions: 'settings.countries_visible.condition.applicant',
    rules: 'settings.countries_visible.rules.applicant',
};

const ARTICLES = {
    uzbekistan: {
        conditions: 'https://rabota.by/article/31423',
        rules: 'https://rabota.by/article/31423?hash=#clause1',
    },
    common: {
        conditions: 'https://rabota.by/article/31422',
        rules: 'https://rabota.by/article/31422?hash=#clause1',
    },
};

interface ResumesCountriesVisibilityFormDescriptionProps {
    dataQaKey: DataQaKey;
    country: CountrySelection;
    conditionsClickHandler?: (country: CountrySelection) => void;
    rulesClickHandler?: (country: CountrySelection) => void;
}

const ResumesCountriesVisibilityFormDescription: TranslatedComponent<
    ResumesCountriesVisibilityFormDescriptionProps
> = ({ dataQaKey, country, conditionsClickHandler, rulesClickHandler, trls }) => {
    const conditionsOnClick = () => conditionsClickHandler?.(country);
    const rulesOnClick = () => rulesClickHandler?.(country);

    return (
        <Text
            size={TextSize.Small}
            importance={TextImportance.Secondary}
            data-qa={`${DATA_QA_ATTR_PREFIX}-description-${country}-${dataQaKey}`}
        >
            {trls[TrlKeys[country]]}
            {NON_BREAKING_SPACE}
            <BlokoLink
                Element={SPALink}
                onClick={conditionsOnClick}
                data-qa={`${DATA_QA_ATTR_PREFIX}-conditions-${country}-${dataQaKey}`}
                disableVisited
                to={ARTICLES[country].conditions}
                target="_blank"
            >
                {trls[TrlKeys.conditions]}
            </BlokoLink>
            . {trls[TrlKeys.descriptionEnd]}
            {NON_BREAKING_SPACE}
            <BlokoLink
                Element={SPALink}
                onClick={rulesOnClick}
                data-qa={`${DATA_QA_ATTR_PREFIX}-rules-${country}-${dataQaKey}`}
                disableVisited
                to={ARTICLES[country].rules}
                target="_blank"
            >
                {trls[TrlKeys.rules]}
            </BlokoLink>
            .
        </Text>
    );
};
export default translation(ResumesCountriesVisibilityFormDescription);
