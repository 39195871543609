import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum JobSearchStatus {
    Unknown = 'unknown',
    ActiveSearch = 'active_search',
    LookingForOffers = 'looking_for_offers',
    NotLookingForJob = 'not_looking_for_job',
    HasJobOffer = 'has_job_offer',
    AcceptedJobOffer = 'accepted_job_offer',
}

export enum JobSearchStatusForm {
    VacancyResponseNotification = 'job-search-status-form-vacancy-response-notification',
    RenewNotification = 'job-search-status-form-notification',
    MyResumesXsModal = 'job-search-status-form-modal',
    MyResumesDesktop = 'job-search-status-form-block',
    ResponsesPageBanner = 'responses-page-banner',
}

export enum JobSearchStatusTrigger {
    NegotiationsScreen = 'negotiations_screen',
    AfterVacancyResponse = 'after_vacancy_response',
}

export type JobSearchStatusSetted = Exclude<JobSearchStatus, JobSearchStatus.Unknown>;
type JobSearchStatusFull = { name: JobSearchStatusSetted } | null;

export type ApplicantUserStatuses = Record<
    string,
    { jobSearchStatus: { name: JobSearchStatus; lastChangeTime: string } | null }
>;

export default autoGeneratedReducer<{
    jobSearchStatus: JobSearchStatusFull;
}>(null);
