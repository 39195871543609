import { useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonScale } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';

const AUTO_CLOSE_DELAY_MS = 10000;

const TrlKeys = {
    title: 'youth.special.project.notification.title',
    description: 'youth.special.project.notification.description',
    link: 'youth.special.project.notification.link',
};

const YouthSpecailProject: TranslatedComponent<ComplexNotificationComponentProps> = ({
    trls,
    onClose,
    ...notification
}) => {
    const analyticsName = `youth_special_project_notification`;
    const closeNotificationRef = useRef<VoidFunction>(null);
    const breakpoint = useBreakpoint(Breakpoint.XS);
    const coversSearch = breakpoint === Breakpoint.XS || breakpoint === Breakpoint.S;

    if (coversSearch) {
        return null;
    }

    return (
        <SynchronizedNotification
            autoClose
            autoCloseDelay={AUTO_CLOSE_DELAY_MS}
            analyticsElementName={analyticsName}
            closeEvent={`${analyticsName}_close`}
            markAsViewedEvent={MarkAsViewedEvent.OnShow}
            notification={notification}
            closeFuncRef={closeNotificationRef}
            onClose={onClose}
            kind={NotificationKind.Ok}
        >
            <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
            <p>{trls[TrlKeys.description]}</p>
            <NotificationFooter>
                <Button
                    Element={SPALink}
                    scale={ButtonScale.Small}
                    onClick={() => {
                        Analytics.sendHHEventButtonClick(analyticsName);
                        closeNotificationRef.current?.();
                    }}
                    target="_blank"
                    to="/shards/notifications/youth_special_project_visit"
                    additionalQueryParams={{ hhtmFromLabel: 'user_notification' }}
                >
                    {trls[TrlKeys.link]}
                </Button>
            </NotificationFooter>
        </SynchronizedNotification>
    );
};

export default translation(YouthSpecailProject);
