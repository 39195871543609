import {
    PhoneWaveOutlinedSize24,
    QuestionCircleOutlinedSize24,
    BubbleRoundDoubleOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import { MailSolidSize24 } from '@hh.ru/magritte-ui/service';

export const SUPPORT_ACTIONS_ICONS = {
    employerHelp: <QuestionCircleOutlinedSize24 />,
    employerAnonHelp: <QuestionCircleOutlinedSize24 />,
    writeToUs: <MailSolidSize24 />,
    supportChat: <BubbleRoundDoubleOutlinedSize24 />,
    orderCall: <PhoneWaveOutlinedSize24 />,

    applicantHelp: <QuestionCircleOutlinedSize24 />,
    applicantHelpOther: <QuestionCircleOutlinedSize24 />,
    feedback: <MailSolidSize24 />,
    anonHelp: <QuestionCircleOutlinedSize24 />,
    copiny: <QuestionCircleOutlinedSize24 />,
};
