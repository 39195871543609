import { LoadedFile } from '@hh.ru/magritte-ui-upload';

import { ArtifactDTO } from 'src/models/applicant/resume/api/artifacts/dto';
import { createSlice } from 'src/models/applicant/resume/lib/slice';
import { ReducerAction } from 'src/models/applicant/resume/lib/slice/types';

export interface ArtifactRemoveDate {
    loading: boolean;
}
export interface ArtifactUploadDate {
    files: LoadedFile[];
    loading: boolean;
}

export interface ApplicantResumeArtifacts {
    remove: ArtifactRemoveDate;
    upload: ArtifactUploadDate;
    artifacts: ArtifactDTO[];
}

const initialState: ApplicantResumeArtifacts = {
    artifacts: [],
    remove: {
        loading: false,
    },
    upload: {
        files: [],
        loading: false,
    },
};

const applicantResumeArtifactsSlice = createSlice({
    initialState,
    reducers: {
        initApplicantResumeArtifacts: (_, { payload }: ReducerAction<ApplicantResumeArtifacts>) => ({ ...payload }),
        setApplicantResumeArtifacts: (state, { payload }: ReducerAction<ArtifactDTO[]>) => ({
            ...state,
            artifacts: payload,
        }),
        setApplicantResumeArtifactsRemoveData: (state, { payload }: ReducerAction<ArtifactRemoveDate>) => ({
            ...state,
            remove: payload,
        }),
        setApplicantResumeArtifactsRemoving: (state, { payload }: ReducerAction<boolean>) => ({
            ...state,
            remove: {
                ...state.remove,
                loading: payload,
            },
        }),
        setApplicantResumeArtifactsUploadData: (state, { payload }: ReducerAction<ArtifactUploadDate>) => ({
            ...state,
            upload: payload,
        }),
    },
});

export const {
    setApplicantResumeArtifacts,
    initApplicantResumeArtifacts,
    setApplicantResumeArtifactsRemoving,
    setApplicantResumeArtifactsUploadData,
    setApplicantResumeArtifactsRemoveData,
} = applicantResumeArtifactsSlice.actions;

export const applicantResumeArtifacts = applicantResumeArtifactsSlice.reducer;
