import { autoGeneratedReducer, makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';

export interface CanvacSimilarVacancy {
    id: number;
    name: string;
    compensation: {
        from: number;
        to: number;
        currencyCode: CurrencyType;
        isGross: boolean;
    };
    company: string;
    area: string;
}

export const setCanvacSimilarVacancies = makeSetStoreField('canvacSimilarVacancies');

export default autoGeneratedReducer<CanvacSimilarVacancy[]>([]);
