import type { ReactElement } from 'react';
import classnames from 'classnames';

import { ProgressBar, Text, VSpacing } from '@hh.ru/magritte-ui';
import { BoltOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import type { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import type { ApplicantActivityContentSize } from 'src/components/Applicant/ActivityCard/Activity/types';
import {
    IconSizeMap,
    MAX_ACTIVITY_SCORE,
    PercentTypographyMap,
    TitleTypographyMap,
} from 'src/components/Applicant/ActivityCard/Activity/utils';

import styles from './styles.less';

const TrlKeys = {
    title: 'applicantActivityMeter.title',
};

interface ApplicantActivityProps {
    score?: number;
    scoreChange?: number;
    size?: ApplicantActivityContentSize;
    right?: ReactElement | null;
    counterPosition?: 'left' | 'right';
}

const ApplicantActivity: TranslatedComponent<ApplicantActivityProps> = ({
    trls,
    score = 0,
    scoreChange = 0,
    size = 'small',
    counterPosition = 'left',
    right,
}) => {
    const progress = Math.min(score, MAX_ACTIVITY_SCORE);
    const percent = `${Math.round((progress / MAX_ACTIVITY_SCORE) * 100)}%`;
    const progressChange = Math.min(scoreChange, MAX_ACTIVITY_SCORE);
    const percentChange = `(${progressChange >= 0 ? '+' : '-'}${Math.round((progressChange / MAX_ACTIVITY_SCORE) * 100)}%)`;

    const content = (
        <section className={classnames(styles.content, { [styles.contentCompact]: counterPosition === 'left' })}>
            <Text Element="span" typography={TitleTypographyMap[size]}>
                {trls[TrlKeys.title]}
            </Text>
            <div
                className={classnames(styles.extra, {
                    [styles.extraSmall]: size === 'small',
                    [styles.extraLarge]: size === 'large',
                })}
            >
                <BoltOutlinedSize24 width={IconSizeMap[size]} height={IconSizeMap[size]} initialColor="special" />
                <Text Element="span" typography={PercentTypographyMap[size]} style="primary" data-qa="activity-score">
                    {percent}
                </Text>
                {!!scoreChange && (
                    <Text
                        Element="span"
                        typography={PercentTypographyMap[size]}
                        style="positive"
                        data-qa="activity-score-change"
                    >
                        {percentChange}
                    </Text>
                )}
            </div>
        </section>
    );

    return (
        <div>
            {right ? (
                <div className={styles.group}>
                    {content}
                    {right}
                </div>
            ) : (
                content
            )}
            <VSpacing default={16} />
            <ProgressBar progress={progress} max={MAX_ACTIVITY_SCORE} style="special" />
        </div>
    );
};

export default translation(ApplicantActivity);
