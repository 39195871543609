import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Merge } from 'type-fest';

import 'Modules/AxiosSettings';
import prepareFormData, { PostFormData } from 'Modules/prepareFormData';
import notLuxDataInterceptor from 'src/utils/notLuxDataInterceptor';

import type { FetcherError } from 'src/utils/fetcher/fetcherError';

const axiosDefaultsCommonHeader = axios.defaults.headers.common as unknown as Record<string, string>;
axiosDefaultsCommonHeader.Accept = 'application/json';
axiosDefaultsCommonHeader['Content-Type'] = 'application/json';

axios.interceptors.response.use(notLuxDataInterceptor);

let controller: AbortController;

type DataRequest<D = Record<string, unknown>> = Merge<AxiosRequestConfig, { params?: D; isGetPage?: boolean }>;

export const isServerError = <E>(e: unknown): e is AxiosError<E> => axios.isAxiosError(e);

type ServerErrorWithKey = AxiosError<{ error: { key: string }[] }>;
export const isServerErrorWithKey = (error: unknown): error is ServerErrorWithKey =>
    axios.isAxiosError(error) && !!(error as ServerErrorWithKey).response?.data?.error?.[0]?.key;

declare global {
    /**
     * Интерфейсы описания запросов/ответов для fetcher
     * Можно дополнять с помощью declaration merging по месту использования
     *
     * declare global {
     *      interface FetcherPostApi {
     *          'some/url': {
     *              queryParams: {};
     *              body: {};
     *              response: {};
     *          }
     *      }
     * }
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    interface FetcherGetApi {}
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    interface FetcherPostApi {}
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    interface FetcherDeleteApi {}
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    interface FetcherPutApi {}
}

const performRequest = async <T>(fn: () => Promise<T>): Promise<T> => {
    try {
        return await fn();
    } catch (error) {
        if (typeof error === 'object' && error !== null) {
            (error as FetcherError).isFetcherError = true;
        }
        throw error;
    }
};

export default {
    ...axios,
    /**
     * PUT запрос
     *
     * @method
     * @name put
     * @param {string} url адрес, на который будет совершен запрос
     * @param {Object} [data] данные, которые будут переданы в body запроса
     * @param {Object} [config] axios конфиг, подробнее здесь https://github.com/axios/axios#request-config
     * @returns {Promise} промис зарезолвится c данными, если ответ 200 <= answer < 300 и зареджектится в других случаях
     */
    put: <U extends keyof FetcherPutApi>(
        url: U,
        data?: FetcherPutApi[U]['body'],
        config?: DataRequest<FetcherPutApi[U]['queryParams']>
    ): Promise<AxiosResponse<FetcherPutApi[U]['response']>> => {
        return performRequest(() => axios.put(url, data, config));
    },
    /**
     * POST запрос
     *
     * @method
     * @name post
     * @param {string} url адрес, на который будет совершен запрос
     * @param {Object} [data] данные, которые будут переданы в body запроса
     * @param {Object} [config] axios конфиг, подробнее здесь https://github.com/axios/axios#request-config
     * @returns {Promise} промис зарезолвится c данными, если ответ 200 <= answer < 300 и зареджектится в других случаях
     */
    post: <U extends keyof FetcherPostApi>(
        url: U,
        data?: FetcherPostApi[U]['body'],
        config?: DataRequest<FetcherPostApi[U]['queryParams']>
    ): Promise<AxiosResponse<FetcherPostApi[U]['response']>> => {
        return performRequest(() => axios.post(url, data, config));
    },
    /**
     * DELETE запрос
     *
     * @method
     * @name delete
     * @param {string} url адрес, на который будет совершен запрос
     * @param {Object} [data] данные и конфиг. Поля конфига описаны здесь: https://github.com/axios/axios#request-config
     * @param {Object} [data.params] данные, которые будут добавлены в query string запроса
     * @returns {Promise} промис зарезолвится c данными, если ответ 200 <= answer < 300 и зареджектится в других случаях
     */
    delete: <U extends keyof FetcherDeleteApi>(
        url: U,
        config?: DataRequest<FetcherDeleteApi[U]['queryParams']>
    ): Promise<AxiosResponse<FetcherDeleteApi[U]['response']>> => {
        return performRequest(() => axios.delete(url, config));
    },
    /**
     * POST запрос, который процессит все JSON данные в виде FormData.
     * В этом случае в body будет сериализован не в JSON,
     * а в формат `multipart/form-data`
     *
     * @method
     * @name postFormData
     * @param {string} url адрес, на который будет совершен запрос
     * @param {Object} [data] данные, которые будут переданы в body запроса
     * @param {Object} [config] axios конфиг, подробнее здесь https://github.com/axios/axios#request-config
     * @returns {Promise} промис зарезолвится c данными, если ответ 200 <= answer < 300 и зареджектится в других случаях
     */
    postFormData: <U extends keyof FetcherPostApi>(
        url: U,
        data?: FetcherPostApi[U]['body'] | FormData,
        config?: DataRequest<FetcherPostApi[U]['queryParams']>
    ): Promise<AxiosResponse<FetcherPostApi[U]['response']>> => {
        return performRequest(() => {
            const formData = prepareFormData((data || {}) as PostFormData);
            return axios.post(url, formData, config);
        });
    },

    /**
     * GET запрос, который превращает все JSON данные в QueryParams.
     * В этом случае у запроса не может быть body:
     * A payload within a GET request message has no defined semantics;
     * sending a payload body on a GET request might cause some existing
     * implementations to reject the request. https://tools.ietf.org/html/rfc7231#section-4.3.1
     *
     * @method
     * @name get
     * @param {string} url адрес, на который будет совершен запрос
     * @param {Object} [data] данные и конфиг. Поля конфига описаны здесь: https://github.com/axios/axios#request-config
     * @param {Object} [data.params] данные, которые будут добавлены в query string запроса
     * @returns {Promise} промис зарезолвится c данными, если ответ 200 <= answer < 300 и зареджектится в других случаях
     */
    get: async <U extends keyof FetcherGetApi>(
        url: U | string,
        data?: DataRequest<FetcherGetApi[U]['queryParams']>
    ): Promise<FetcherGetApi[U]['response']> => {
        return performRequest(async () => {
            // intentionally returns only data field from response,
            // but this should be reconsidered and made consistent with other methods
            const result = await axios.get<FetcherGetApi[U]['response']>(url, data);
            return result.data;
        });
    },
    /**
     * Создает уникальный запрос. Используется для GET запросов при переходе между страницами в SPA приложении.
     * Если через этот метод будет создан второй запрос, то предыдущий отменяется.
     * Это гарантирует, что только последний пользовательский запрос будет исполнен.
     * @method
     * @name getPage
     * @param {string} url адрес, на который будет совершен запрос
     * @param {Object} [config] данные и конфиг. Поля конфига описаны здесь: https://github.com/axios/axios#request-config
     * @param {Object} [config.params] данные, которые будут добавлены в query string запроса
     * @returns {Promise} промис зарезолвится c данными, если ответ 200 <= answer < 300 и зареджектится в других случаях
     */
    getPage(url: string, config: DataRequest = { params: {} }): Promise<unknown> {
        controller?.abort?.();
        controller = new AbortController();

        config.signal = controller.signal;
        config.headers = config.headers || {};
        config.headers['X-Static-Version'] = window.globalVars.build;
        config.isGetPage = true;

        return axios.get<unknown>(url, config).then((response) => {
            // intentionally returns only data field from response,
            // but this should be reconsidered and made consistent with other methods
            return response.data;
        });
    },
};
