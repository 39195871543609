import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { ClusterKey } from 'src/types/search/common/clusters';

const SET_SEARCH_CLUSTER_TIP_SHOW = 'SET_SEARCH_CLUSTER_TIP_SHOW';
const SET_SEARCH_CLUSTER_TIP_DATA = 'SET_SEARCH_CLUSTER_TIP_DATA';

interface PayloadTypes {
    [SET_SEARCH_CLUSTER_TIP_SHOW]: boolean;
    [SET_SEARCH_CLUSTER_TIP_DATA]: { show: boolean; totalResults: number; filter?: ClusterKey | null };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const setSearchClusterTipShow = actionCreator(SET_SEARCH_CLUSTER_TIP_SHOW);
export const setSearchClusterTipData = actionCreator(SET_SEARCH_CLUSTER_TIP_DATA);

interface SearchClustersTipState {
    show: boolean;
    totalResults: number;
    filter?: ClusterKey | null;
}

const INITIAL_STATE = {
    show: false,
    totalResults: 0,
    filter: null,
};

export default createReducer<SearchClustersTipState, PayloadTypes>(INITIAL_STATE, {
    [SET_SEARCH_CLUSTER_TIP_SHOW]: (state, action) => ({ ...state, show: action.payload }),
    [SET_SEARCH_CLUSTER_TIP_DATA]: (state, action) => ({
        ...state,
        ...action.payload,
    }),
});
