import { Dispatch, SetStateAction, useMemo } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { findStaticDataFetcherItemByValue, useStaticDataProvider } from '@hh.ru/magritte-ui';
import { Select } from '@hh.ru/magritte-ui-select';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';

const TrlKeys = {
    [SupernovaSearchName.Resumes]: 'navi.search.tab.resumeSearch',
    [SupernovaSearchName.Vacancies]: 'navi.search.tab.searchVacancy',
    [SupernovaSearchName.Employers]: 'navi.search.tab.employersList',
    search: 'supernovaSearch.action.search',
};

const sendAnalytics = () => Analytics.sendHHEventButtonClick('employer_search_select_button');

const SearchNameSelect: TranslatedComponent<{
    searchName: SupernovaSearchName;
    setSearchName: Dispatch<SetStateAction<SupernovaSearchName>>;
}> = ({ searchName, setSearchName, trls }) => {
    const items = useMemo(
        () =>
            [SupernovaSearchName.Resumes, SupernovaSearchName.Vacancies, SupernovaSearchName.Employers].map((name) => {
                return {
                    value: name,
                    text: trls[TrlKeys[name]],
                };
            }),
        [trls]
    );

    const provider = useStaticDataProvider(items);

    return (
        <Select
            type="radio"
            multiple={false}
            bottomSheetHeight="content"
            name="search-name"
            triggerProps={{
                size: 'medium',
                label: trls[TrlKeys.search],
                stretched: true,
            }}
            widthEqualToActivator={false}
            dataProvider={provider}
            value={findStaticDataFetcherItemByValue(searchName, items)}
            onChange={(value) => {
                sendAnalytics();
                setSearchName(value.value);
            }}
            data-qa="search-select"
        />
    );
};

export default translation(SearchNameSelect);
