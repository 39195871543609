import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface Subscriptions {
    emailCheckboxes: string[];
    pushCheckboxes: string[];
    smsCheckboxes: string[];
}

interface SubscriptionInformer {
    isShow: boolean;
    applicantSubscriptions: Subscriptions;
    employerSubscriptions: Subscriptions;
}

const subscriptions = { emailCheckboxes: [], pushCheckboxes: [], smsCheckboxes: [] };

export default autoGeneratedReducer<SubscriptionInformer>({
    isShow: false,
    applicantSubscriptions: subscriptions,
    employerSubscriptions: subscriptions,
});
