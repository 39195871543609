// tempexp_38140_file
import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import type { EmployerPfpLiquidity } from 'src/models/employerPfpLiquidity';
import type { LowActivityAdviceVacancy } from 'src/models/lowActivityVacancies';
import { PfpLiquidityVacanciesPageBannerData } from 'src/models/pfpLiquidityWarning';

interface Banner {
    priority: number;
}

export interface VerificationBanner extends Banner {
    type: 'VERIFICATION';
    details: {
        hasDocuments: boolean;
    };
}

export interface UnreadResponsesBanner extends Banner {
    type: 'MCP_MANAGERS_UNREAD_RESPONSES' | 'MANAGER_UNREAD_RESPONSES';
    details: {
        unreadResponsesCount: number;
    };
}

export interface CloseToMarketBanner extends Banner {
    type: 'MAKE_SALARY_CLOSER_TO_MARKET';
    details: {
        vacancyCount: number;
        vacancyIds: number[];
    };
}
export interface LowActivityBanner extends Banner {
    type: 'LOW_ACTIVITY';
    details: {
        vacancies: LowActivityAdviceVacancy[];
    };
}

export interface ActiveVacanciesDefaultBanner extends Banner {
    type: 'ACTIVE_VACANCIES_DEFAULT';
}

export interface PfpLiquidityBanner extends Banner {
    type: 'PFP_LIQUIDITY';
    details: EmployerPfpLiquidity | PfpLiquidityVacanciesPageBannerData;
}

export interface RiseBanner extends Banner {
    type: 'RAISE_VACANCY';
    details: {
        vacancyCount: number;
        vacancyIds: number[];
    };
}

export type EmployerBanner =
    | VerificationBanner
    | UnreadResponsesBanner
    | CloseToMarketBanner
    | ActiveVacanciesDefaultBanner
    | PfpLiquidityBanner
    | LowActivityBanner
    | RiseBanner;

export type BannerType = EmployerBanner['type'];

export default autoGeneratedReducer<EmployerBanner[]>([]);
