import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface AdfoxVacancyParams {
    puid23: string;
    puid24: string;
    puid25: string;
    puid26: string;
    puid27: string;
    puid28: string;
    puid30: string;
    puid31: string;
    puid32: string;
}

export default autoGeneratedReducer<Partial<AdfoxVacancyParams>>({});
