import { FC, useRef } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';

import LinkSecondaryContrast from 'src/components/MagritteRedesignComponents/LinkSecondaryContrast';
import SupernovaBadge from 'src/components/SupernovaMainMenu/SupernovaBadge';
import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import useTrackElementShown from 'src/components/SupernovaMainMenu/useTrackElementShown';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

import styles from './styles.less';

interface MobileMagritteLinkProps extends NavItem {
    onClick?: () => void;
}

const MobileMagritteLink: FC<MobileMagritteLinkProps> = ({
    onClick,
    translations,
    trackClicks,
    name,
    url,
    trackElementShown,
    analytics,
    dropdown,
    counter,
}) => {
    const elementRef = useRef(null);

    const handleTrackClick = useTrackClicks(name, trackClicks, analytics);
    const userStat = useSelector(({ userStats }) => counter && userStats?.[counter]);

    const handleClick = () => {
        onClick && onClick();
        handleTrackClick();
    };

    useTrackElementShown(elementRef, name, trackElementShown);

    const badge = userStat ? (
        <SupernovaBadge onLightBg={false} data-qa={`${name}-badge`}>
            {userStat}
        </SupernovaBadge>
    ) : null;

    const dataQa = `mobile-link-${name}`;

    let link = (
        <LinkSecondaryContrast
            data-qa={dataQa}
            style="contrast"
            onClick={handleClick}
            typography="label-2-regular"
            Element="button"
        >
            {translations.name}
        </LinkSecondaryContrast>
    );

    if (url && !dropdown) {
        link = (
            <LinkSecondaryContrast
                data-qa={dataQa}
                style="contrast"
                onClick={handleClick}
                typography="label-2-regular"
                to={url}
                isSeoLink
                Element={SPALink}
            >
                {translations.name}
            </LinkSecondaryContrast>
        );
    }
    return (
        <span ref={elementRef}>
            {badge ? (
                <div className={styles.badgeContainer}>
                    {link}
                    {badge}
                </div>
            ) : (
                link
            )}
        </span>
    );
};

export default MobileMagritteLink;
