import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import { H2Promo } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './index.less';

const TrlKeys = {
    title: 'app_promo_banner.title',
    install: 'app_promo_banner.install',
    close: 'app_promo_banner.close',
};

interface Props {
    appHref: string;
    onInstall: () => void;
    onClose: () => void;
}

const ZPView: TranslatedComponent<Props> = ({ trls, appHref, onInstall, onClose }) => {
    return (
        <div className={styles.banner}>
            <div className={styles.content}>
                <div className={styles.title}>
                    <H2Promo Element="span">{trls[TrlKeys.title]}</H2Promo>
                </div>
            </div>
            <div className={styles.buttons}>
                <Button stretched Element={SPALink} to={appHref} kind={ButtonKind.Primary} onClick={onInstall}>
                    {trls[TrlKeys.install]}
                </Button>
                <VSpacing base={4} />
                <Button stretched kind={ButtonKind.Primary} appearance={ButtonAppearance.Flat} onClick={onClose}>
                    {trls[TrlKeys.close]}
                </Button>
            </div>
        </div>
    );
};

export default translation(ZPView);
