import {
    CompensationFrequency,
    EmploymentForm,
    WorkFormat,
    WorkScheduleByDays,
    WorkingHours,
} from '@hh.ru/types-hh-microcore';

import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import DriverLicenseOption from 'src/models/driverLicense.types';
import { EducationLevel, EmploymentType, Gender } from 'src/models/resume/resumeCommon.types';
import { ExpCompanySizeOption } from 'src/models/search/resume/queries';
import { LabelOption as SearchResumeLabelOption, RelocationOption } from 'src/types/search/resume/criteria';
import { LabelOption as SearchVacancyLabelOption, EducationOption } from 'src/types/search/vacancy/criteria';

import {
    CriteriaKey,
    ExperienceOption,
    PartTimeOption,
    FilterExpPeriodOption,
    SearchFieldOption,
    ScheduleOption,
} from 'src/types/search/common/criteria';

export const ClusterKey = {
    AcceptTemporary: 'accept_temporary',
    Age: 'age',
    Area: 'area',
    Citizenship: 'citizenship',
    Company: 'company',
    Compensation: 'compensation',
    CompensationFrequency: 'compensation_frequency',
    District: 'district',
    DriverLicenseTypes: 'driver_license_types',
    Education: 'education',
    EducationLevel: 'education_level',
    Employment: 'employment',
    EmploymentForm: 'employment_form',
    Etc: 'etc',
    ExcludedText: 'excluded_text',
    Experience: 'experience',
    FilterExpIndustry: 'filter_exp_industry',
    FilterExpPeriodForIndustry: 'filter_exp_period',
    Gender: 'gender',
    Industry: 'industry',
    JobSearchStatus: 'job_search_status',
    Label: 'label',
    Language: 'language',
    Metro: 'metro',
    Neighbours: 'neighbours',
    PartTime: 'part_time',
    ProfessionalRole: 'professional_role',
    Relocation: 'relocation',
    Resume: 'resume',
    Salary: 'salary',
    Schedule: 'schedule',
    SearchField: 'search_field',
    Skill: 'skill',
    SubIndustry: 'subIndustry',
    University: 'university',
    VacancyId: 'vacancy_id',
    VerifiedLanguages: 'verified_languages',
    VerifiedSkills: 'verified_skills',
    WorkFormat: 'work_format',
    WorkingHours: 'working_hours',
    WorkScheduleByDays: 'work_schedule_by_days',
    WorkTicket: 'work_ticket',
} as const;

export type ClusterKey = (typeof ClusterKey)[keyof typeof ClusterKey];

interface ClusterGroupBase<Value extends string = string> {
    id: Value;
    title: string;
    order?: number;
    disabled?: boolean;
    query?: string;
}

export type ClusterGroup<Value extends string = string> = ClusterGroupBase<Value> & {
    count: number;
};

export type ClusterGroupMap<
    Value extends string = string,
    Group extends ClusterGroupBase<Value> = ClusterGroup<Value>,
> = Record<Value, Group>;

interface Cluster<
    SelectedValues,
    Value extends string = string,
    Group extends ClusterGroupBase<Value> = ClusterGroup<Value>,
> {
    groups: ClusterGroupMap<Value, Group>;
    selectedValues: SelectedValues;
}

type StringCluster<
    Value extends string = string,
    Group extends ClusterGroupBase<Value> = ClusterGroup<Value>,
> = Cluster<string, Value, Group>;

type MultiplyNumberCluster<Group extends ClusterGroupBase = ClusterGroup> = Cluster<Array<number>, string, Group>;

type MultiplyStringCluster<
    Value extends string = string,
    Group extends ClusterGroupBase<Value> = ClusterGroup<Value>,
> = Cluster<Array<Value>, Value, Group>;

export interface CompensationClusterSelectedValues {
    salary: number | null;
    onlyWithSalary: boolean;
}

type EtcCluster = Cluster<{ [CriteriaKey.ExpCompanySize]?: Array<ExpCompanySizeOption> }>;

export interface GenderClusterSelectedValues {
    value: Gender;
    onlyWith: boolean;
}

type SearchResumeLabelCluster = MultiplyStringCluster<SearchResumeLabelOption>;
export type SearchVacancyLabelCluster = MultiplyStringCluster<SearchVacancyLabelOption>;

export enum MetroType {
    Station = 'station',
    Line = 'line',
}

export type SearchVacancyMetroClusterGroup = ClusterGroup & {
    color: string;
    type: MetroType;
    cityId?: string;
    lat?: string;
    lineId?: string;
    lng?: string;
    stationId?: string;
    stationOrder?: string;
    translit?: string;
};

export type SearchVacancyMetroClusterGroupMap = ClusterGroupMap<string, SearchVacancyMetroClusterGroup>;

export type SearchVacancyMetroCluster = MultiplyStringCluster<string, SearchVacancyMetroClusterGroup>;

type SearchResumeMetroCluster = MultiplyStringCluster<string, ClusterGroupBase>;

export enum Range {
    From = 'from',
    To = 'to',
}

export interface RangeClusterSelectedValues {
    [Range.From]: number | null;
    [Range.To]: number | null;
    onlyWith: boolean;
}

export interface RangeClusterGroup extends ClusterGroup {
    [Range.From]?: number;
    [Range.To]?: number;
}

export type RangeClusterGroupMap = Record<string, RangeClusterGroup>;

interface RangeCluster {
    selectedValues: RangeClusterSelectedValues;
    groups: RangeClusterGroupMap;
}

export interface SubIndustryClusterGroup extends ClusterGroup {
    industryId: string;
    subIndustryId: string;
}

export type SubIndustryClusterGroupMap = Record<string, SubIndustryClusterGroup>;

interface SubIndustryCluster {
    selectedValues: string[];
    groups: SubIndustryClusterGroupMap;
}

export interface SearchTypicalClusterMap {
    [ClusterKey.CompensationFrequency]: MultiplyStringCluster<CompensationFrequency>;
    [ClusterKey.DriverLicenseTypes]: MultiplyStringCluster<DriverLicenseOption>;
    [ClusterKey.Education]: MultiplyStringCluster<EducationOption>;
    [ClusterKey.EducationLevel]: MultiplyStringCluster<EducationLevel>;
    [ClusterKey.Employment]: MultiplyStringCluster<EmploymentType>;
    [ClusterKey.EmploymentForm]: MultiplyStringCluster<EmploymentForm>;
    [ClusterKey.Experience]: MultiplyStringCluster<ExperienceOption>;
    [ClusterKey.JobSearchStatus]: MultiplyStringCluster<JobSearchStatus>;
    [ClusterKey.Label]: SearchResumeLabelCluster | SearchVacancyLabelCluster;
    [ClusterKey.Metro]: SearchResumeMetroCluster;
    [ClusterKey.PartTime]: MultiplyStringCluster<PartTimeOption>;
    [ClusterKey.Schedule]: MultiplyStringCluster<ScheduleOption>;
    [ClusterKey.SearchField]: MultiplyStringCluster<SearchFieldOption>;
    [ClusterKey.Skill]: MultiplyNumberCluster;
    [ClusterKey.WorkFormat]: MultiplyStringCluster<WorkFormat>;
    [ClusterKey.WorkingHours]: MultiplyStringCluster<WorkingHours>;
    [ClusterKey.WorkScheduleByDays]: MultiplyStringCluster<WorkScheduleByDays>;

    // optional
    [ClusterKey.Area]?: MultiplyNumberCluster;
    [ClusterKey.Company]?: MultiplyNumberCluster;
    [ClusterKey.District]?: MultiplyNumberCluster;
    [ClusterKey.Neighbours]?: MultiplyNumberCluster;
    [ClusterKey.Resume]?: MultiplyStringCluster;
    [ClusterKey.VacancyId]?: MultiplyNumberCluster;
}

export type SearchClusterMap = SearchTypicalClusterMap & {
    [ClusterKey.Age]: RangeCluster;
    [ClusterKey.Citizenship]: MultiplyNumberCluster<ClusterGroupBase>;
    [ClusterKey.ExcludedText]: MultiplyStringCluster;
    [ClusterKey.FilterExpIndustry]: MultiplyStringCluster<string, ClusterGroupBase>;
    [ClusterKey.FilterExpPeriodForIndustry]: StringCluster<
        FilterExpPeriodOption,
        ClusterGroupBase<FilterExpPeriodOption>
    >;
    [ClusterKey.Gender]: Cluster<GenderClusterSelectedValues, Gender>;
    [ClusterKey.Industry]: MultiplyStringCluster;
    [ClusterKey.Language]: MultiplyStringCluster;
    [ClusterKey.ProfessionalRole]: MultiplyNumberCluster;
    [ClusterKey.Relocation]: StringCluster<RelocationOption, ClusterGroupBase<RelocationOption>>;
    [ClusterKey.Salary]: RangeCluster;
    [ClusterKey.University]: MultiplyNumberCluster<ClusterGroupBase>;
    [ClusterKey.VerifiedLanguages]: MultiplyStringCluster;
    [ClusterKey.VerifiedSkills]: MultiplyNumberCluster;
    [ClusterKey.WorkTicket]: MultiplyNumberCluster<ClusterGroupBase>;

    // optional
    [ClusterKey.AcceptTemporary]?: Cluster<boolean | null>;
    [ClusterKey.Compensation]?: Cluster<CompensationClusterSelectedValues>;
    [ClusterKey.Etc]?: EtcCluster;
    [ClusterKey.Metro]?: SearchVacancyMetroCluster;
    [ClusterKey.SubIndustry]?: SubIndustryCluster;
};

export type TypeByClusterKey<K extends ClusterKey> = Required<SearchClusterMap>[K];
export type SelectedValuesTypeByClusterKey<K extends ClusterKey> = TypeByClusterKey<K>['selectedValues'];
