import { Entries, Primitive } from 'type-fest';

import urlParser from 'bloko/common/urlParser';

import { CLUSTERS_EXCLUDE_HIDDEN_INPUT, SORTS_WITH_DEFAULT_VALUE } from 'src/components/Search/Common/Filters/utils';
import { CurrencyType } from 'src/models/currencies.types';
import { WITH_TOP_FILTER_CATALOG } from 'src/models/topFilterCatalog';
import { SearchPage, SearchType } from 'src/types/search/common';
import { SearchClusterMap, ClusterKey } from 'src/types/search/common/clusters';
import { CriteriaKey, OrderByOption } from 'src/types/search/common/criteria';
import {
    Criteria as ResumeSearchCriteria,
    LabelOption as SearchResumeLabelOption,
    SearchPeriodOption,
} from 'src/types/search/resume/criteria';
import {
    LabelOption as SearchVacancyLabelOption,
    Criteria as VacancySearchCriteria,
} from 'src/types/search/vacancy/criteria';

import {
    GetSearchParamsArgs,
    GetSearchParamsFromFilterKeysType,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/types';

const typicalFilters = [
    CriteriaKey.Area,
    CriteriaKey.Citizenship,
    CriteriaKey.District,
    CriteriaKey.DriverLicenseTypes,
    CriteriaKey.Employment,
    CriteriaKey.EmploymentForm,
    CriteriaKey.ExcludedText,
    CriteriaKey.Experience,
    CriteriaKey.Education,
    CriteriaKey.EducationLevel,
    CriteriaKey.FilterExpIndustry,
    CriteriaKey.FilterExpPeriodForIndustry,
    CriteriaKey.University,
    // industry and subIndustry
    CriteriaKey.Industry,
    CriteriaKey.JobSearchStatus,
    CriteriaKey.Label,
    CriteriaKey.Language,
    CriteriaKey.VerifiedLanguages,
    CriteriaKey.Metro,
    CriteriaKey.PartTime,
    CriteriaKey.ProfessionalRole,
    CriteriaKey.Relocation,
    CriteriaKey.Resume,
    CriteriaKey.Schedule,
    CriteriaKey.SearchField,
    CriteriaKey.Skill,
    CriteriaKey.VerifiedSkills,
    CriteriaKey.VacancyId,
    CriteriaKey.WorkingHours,
    CriteriaKey.WorkFormat,
    CriteriaKey.WorkScheduleByDays,
    CriteriaKey.WorkTicket,
    CriteriaKey.AcceptTemporary,
] as const;

export type SharedSearchParams = GetSearchParamsFromFilterKeysType<(typeof typicalFilters)[number]> & {
    [CriteriaKey.CurrencyCode]: CurrencyType;
    [CriteriaKey.Text]: string | string[];
    [CriteriaKey.SearchPeriod]?: null | SearchPeriodOption;
    [CriteriaKey.OrderBy]?: OrderByOption;
    [CriteriaKey.Label]?: SearchResumeLabelOption[] | SearchVacancyLabelOption[];
    ['L_save_area']?: boolean;
    searchSessionId?: string;
};

type UntypedSearchParams = Record<string, Primitive | Primitive[]>;

const controlledLabels = [
    SearchResumeLabelOption.OnlyWithSalary,
    SearchResumeLabelOption.OnlyWithAge,
    SearchResumeLabelOption.OnlyWithGender,
];

const getSharedSearchParams = ({
    criteriaCurrencyCode,
    criteriaTextUpdated,
    currencies,
    router,
    resumeSearchResult,
    searchCatalogRedirect,
    searchClusters,
    searchType,
    searchPage,
    vacancySearchResult,
    employerVacancySearch,
    searchSessionId,
    isSearchSessionIdSaved,
}: GetSearchParamsArgs): SharedSearchParams => {
    const searchParams = {} as SharedSearchParams;
    const untypedSearchParams = {} as UntypedSearchParams;

    typicalFilters.forEach(<Key extends keyof SearchClusterMap & keyof SharedSearchParams>(filter: Key) => {
        if (searchClusters[filter]) {
            const filterFromClusters = searchClusters[filter];
            searchParams[filter] = filterFromClusters.selectedValues as SharedSearchParams[Key];
        }
    });

    if (searchClusters[ClusterKey.Label]) {
        const labels = searchClusters[ClusterKey.Label].selectedValues as string[];
        searchParams.label = labels.filter((item) => !controlledLabels.includes(item)) as SearchResumeLabelOption[];
    }

    const { default: currencyCodeDefault } = currencies;
    if (currencyCodeDefault !== criteriaCurrencyCode) {
        searchParams[CriteriaKey.CurrencyCode] = criteriaCurrencyCode;
    }

    const { pathname, search } = router.location;
    const urlParams = urlParser(pathname + search).params;

    if (searchCatalogRedirect || urlParams[CriteriaKey.Text]) {
        searchParams.text = criteriaTextUpdated;
    }

    if (isSearchSessionIdSaved && searchSessionId) {
        searchParams.searchSessionId = searchSessionId;
    }

    // proxy other get params (like "hhtmFrom")
    Object.entries(urlParams).forEach(([key, values]) => {
        if (
            key !== WITH_TOP_FILTER_CATALOG &&
            !CLUSTERS_EXCLUDE_HIDDEN_INPUT.includes(key) &&
            // Временный костыль, значения нельзя доставать из urlParams, это противоречит SSOT
            // TODO: реализовать полноценную фильтрацию или прокидывать только нужные параметры HH-250177
            !Object.values(ClusterKey).includes(key)
        ) {
            untypedSearchParams[key] = values;
        }
    });

    let criteria: Partial<VacancySearchCriteria> | ResumeSearchCriteria | undefined;
    if (searchPage === SearchPage.EmployerView) {
        criteria = employerVacancySearch.criteria;
    } else if (searchType === SearchType.Resume) {
        criteria = resumeSearchResult.criteria;
    } else {
        criteria = vacancySearchResult.criteria;
    }

    // proxy sorts without default values
    const entries = Object.entries(SORTS_WITH_DEFAULT_VALUE) as Entries<typeof SORTS_WITH_DEFAULT_VALUE>;
    entries.forEach(([key]) => {
        if (!urlParams[key] && criteria?.[key]) {
            untypedSearchParams[key] = criteria[key];
        }
    });

    return {
        ...untypedSearchParams,
        ...searchParams,
    };
};

export default getSharedSearchParams;
