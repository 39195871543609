import { useState, FC, useContext, useEffect } from 'react';
import classnames from 'classnames';

import AutoInviteContext from 'src/components/AutoInviteModal/common/AutoInviteContext';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { SearchQueries } from 'src/models/search/resume/queries';
import { CriteriaKey } from 'src/types/search/common/criteria';

import ConditionSelect from 'src/components/ResumeAdvancedSearch/SearchQueries/ConditionSelect';
import IndustrySelect from 'src/components/ResumeAdvancedSearch/SearchQueries/IndustrySelect';
import PosSelectorDrop from 'src/components/ResumeAdvancedSearch/SearchQueries/PosSelectorDrop';
import { getHasWorkplaceOptions } from 'src/components/ResumeAdvancedSearch/SearchQueries/posUtils';

interface QueryConditionsProps {
    query: SearchQueries;
    setQuery: (value: SearchQueries) => void;
    isNaviSearch?: boolean;
    autoInvite?: boolean;
}

const QueryConditions: FC<QueryConditionsProps> = ({ query, setQuery, isNaviSearch, autoInvite }) => {
    const [hasWorkplaceOptions, setHasWorkplaceOptions] = useState(getHasWorkplaceOptions(query[CriteriaKey.Pos]));
    const isHeadHunterPlatform = useIsHeadHunterPlatform();
    const autoInviteHandlerFormChange = useContext(AutoInviteContext);

    useEffect(() => {
        autoInviteHandlerFormChange?.();
    }, [autoInviteHandlerFormChange, query]);

    return (
        <div
            className={classnames('condition-group', {
                'condition-group_inline': isNaviSearch,
            })}
        >
            <div className="condition-row">
                <ConditionSelect name={CriteriaKey.Logic} query={query} setQuery={setQuery} />
                <PosSelectorDrop query={query} setQuery={setQuery} setHasWorkplaceOptions={setHasWorkplaceOptions} />
                <ConditionSelect
                    name={CriteriaKey.ExpPeriod}
                    query={query}
                    setQuery={setQuery}
                    isHidden={!hasWorkplaceOptions}
                />
            </div>
            {isHeadHunterPlatform && (
                <div className="condition-row">
                    <ConditionSelect
                        name={CriteriaKey.ExpCompanySize}
                        query={query}
                        setQuery={setQuery}
                        isHidden={!hasWorkplaceOptions}
                    />
                    {autoInvite && (
                        <IndustrySelect
                            value={query[CriteriaKey.ExpIndustry]}
                            setValue={(values: string[]) => setQuery({ ...query, [CriteriaKey.ExpIndustry]: values })}
                            isNaviSearch={isNaviSearch}
                            isHidden={!hasWorkplaceOptions}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default QueryConditions;
