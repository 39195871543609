import { FC, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';

import { Card, Cell, ScrollableItem, Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';

import styles from 'src/components/SupernovaMainMenu/EmployerProfile/styles.less';

interface InformerProps {
    children: ReactNode;
    title: ReactNode;
    'data-qa'?: string;
    wide?: boolean;
    action?: ReactElement;
}

const Informer: FC<InformerProps> = ({ children, title, wide, action, ...props }) => {
    const { isMobile } = useBreakpoint();

    const content = (
        <>
            <Text typography="label-3-regular" style="secondary">
                {title}
            </Text>
            <VSpacing default={6} />
            <Text typography="subtitle-1-semibold" style="primary">
                {children}
            </Text>
        </>
    );

    const informer = (
        <div className={classnames(styles.informersItem, { [styles.informersItemWide]: !!wide })} {...props}>
            <Card style="secondary" stretched borderRadius={16} padding={16}>
                {action ? <Cell right={action}>{content}</Cell> : content}
            </Card>
        </div>
    );

    if (isMobile) {
        return <ScrollableItem className={styles.informersItemScrollable}>{informer}</ScrollableItem>;
    }
    return informer;
};

export default Informer;
