import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { MessageItem } from 'src/models/employer/resume/pinnedChatMessages';
import {
    ActionType,
    Collection,
    CollectionName,
    CollectionNameType,
    EnteringCollection,
    FilterType,
    ResumeSettingType,
    SuitableWorkflowCollection,
    UnsuitableWorkflowCollection,
    VisitorCollection,
} from 'src/models/employer/vacancyresponses/collections.types';
import NegotiationTopic from 'src/models/negotiationTopic.types';
import { Paging } from 'src/models/paging.types';
import { ResumeCardData } from 'src/models/resumeCard';
import { SortingFilter } from 'src/models/sortingFilters';
import { TopicCall } from 'src/models/topicsCallHistory';
import { VacancyFunnel, VacancyFunnelStage } from 'src/models/vacancyFunnel';

const MARK_TOPICS_AS_VIEWED = 'MARK_TOPICS_AS_VIEWED';
const CHANGE_TOPIC_STATE = 'CHANGE_TOPIC_STATE';
const DISCARD_CANDIDATES = 'DISCARD_CANDIDATES';
const INVITE_TO_VIDEOCALL = 'INVITE_TO_VIDEOCALL';
const SET_CANDIDATES_LIST = 'SET_CANDIDATES_LIST';
const REMOVE_INTERVIEW_REMIND = 'REMOVE_INTERVIEW_REMIND';
const SET_INTERVIEW_REMIND = 'SET_INTERVIEW_REMIND';
const SET_LOADING_ACTION = 'SET_LOADING_ACTION';
const SET_NOT_APPEARANCE_DATE = 'SET_NOT_APPEARANCE_DATE';
const UPDATE_RESUME = 'UPDATE_RESUME';
const UPDATE_SUBSTATES = 'UPDATE_SUBSTATES';
const UPDATE_SUBSTATE_ORDER = 'UPDATE_SUBSTATE_ORDER';
const UPDATE_SUBSTATES_BY_FUNNEL = 'UPDATE_SUBSTATES_BY_FUNNEL';
const SET_COLLECTION_VISIBILITY = 'SET_COLLECTION_VISIBILITY';

type TopicList = Record<string, NegotiationTopic>;

const SUBSTATE_ENDWITH_REGEXP = /_\d+$/;

export interface CurrentInterview {
    applicantWasNotified: boolean;
    negotiationInterviewId: number;
    scheduledTime: string;
    topicId: number;
}

export interface TopicNegotiationInterviewInfo {
    allowedSchedulingParams: {
        hoursUntilClosestScheduleTimeKnownToUser: number;
        nearestAllowedScheduleTime: string;
    };
    currentInterview: CurrentInterview | null;
}

export enum Advice {
    NeedHowSaveHiddenResumes = 'needHowSaveHiddenResumes',
}

export enum ConnectedServiceTypes {
    Clickme = 'CLICKME',
    VacancyOfTheDay = 'VACANCY_OF_THE_DAY',
    VacancyOfTheDayTurbo = 'VACANCY_OF_THE_DAY_TURBO',
    ClickmeSearchAuction = 'CLICKME_SEARCH_AUCTION',
}

export type ConnectedServiceList = Array<{ name: ConnectedServiceTypes; trl: string }>;

export interface ConnectedService {
    connectedServiceList: ConnectedServiceList;
    topicId: number;
}

export enum TagName {
    IncomingCall = 'INCOMING_CALL',
    MissedCall = 'MISSED_CALL',
    ResponseFromChat = 'RESPONSE_FROM_CHAT',
    AutoSurveyInProgress = 'AUTO_SURVEY_IN_PROGRESS',
    ResponseFromVr = 'RESPONSE_FROM_VR',
    AutoInvite = 'AUTO_INVITE',
    AwaitingReply = 'AWAITING_REPLY',
    ApplicantQuestion = 'APPLICANT_QUESTION',
    AutoResponse = 'AUTO_RESPONSE',
    MoreResponses = 'MORE_THEN_ONE_TOPIC_WITH_THIS_RESUME',
    ResponseFromClickmeCpa = 'RESPONSE_FROM_CLICKME_CPA',
}

export interface TagCallData {
    creationTime: string;
    minutes: number;
    callId: string;
}

export interface TagAwaitingReplyData {
    durationMs: number;
}

export type Tag =
    | {
          data: TagCallData;
          type: TagName.IncomingCall | TagName.MissedCall;
      }
    | {
          data: TagAwaitingReplyData;
          type: TagName.AwaitingReply;
      }
    | {
          data: undefined;
          type:
              | TagName.AutoInvite
              | TagName.AutoSurveyInProgress
              | TagName.ResponseFromChat
              | TagName.ApplicantQuestion
              | TagName.AutoResponse
              | TagName.MoreResponses
              | TagName.ResponseFromVr
              | TagName.ResponseFromClickmeCpa;
      };

export type FilterDistance = 500 | 1000 | 3000 | 5000 | 10000 | 15000;

export interface VacanciesInfo {
    name: string;
    pfp: boolean;
    vacancyId: number;
    area: {
        id: number;
        name: string;
    };
    employerManager: {
        managerId: number;
    };
}

export interface CandidatesList {
    candidateTags: {
        tagsByTopicIds: Record<string, { tags: Tag[] }>;
    };
    missedCalls: number;
    filterDistance?: FilterDistance;
    collection: Collection;
    collections: Collection[];
    enteringCollections: EnteringCollection[];
    suitableWorkflowCollections: SuitableWorkflowCollection[];
    unsuitableWorkflowCollections: UnsuitableWorkflowCollection[];
    visitorCollection: VisitorCollection;
    chatsByIds: {
        chats: Record<string, { pinnedMessagesByChatId: MessageItem[]; lastMessage: MessageItem }>;
    };
    connectedServicesList?: Record<string, ConnectedService>;
    prompts?: {
        prompts: Array<{
            additionalInfo: {
                conversionPercent: number;
                conversionPercentThreshold: number;
                totalResponsesMinThreshold: number;
                totalTopics: number;
                type: string;
                unprocessResponsesThreshold: number;
                unprocessTopics: number;
                viewCount: number;
                marketFrom: number;
                marketTo: number;
                compensationFrom: number;
                compensationTo: number;
            };
            advice: Advice[];
        }>;
    };
    resumes: {
        elementsJson: ResumeCardData[];
        notFound: number;
        total: number;
    };
    videos?: { [userId: string]: string };
    shortTopicList?: {
        autoActionReadyToFailCount: number;
        autoActionReadyToPassCount: number;
        untrustedEmployerRestrictionsApplied?: boolean;
        topicList: TopicList;
    };
    topicsNegotiationInterviewInfo: {
        topics: Record<string, TopicNegotiationInterviewInfo> | null;
    };
    paging: [number, null | Paging];
    topicsCallHistory: Record<number, TopicCall>;
    tests?: {
        testSolutions: { uidPk: number; score: number }[];
    };
    totalCollectionItemCount: number;
    sortingFilters: SortingFilter[];
    funnel: {
        funnelId: number;
        currentUserHasWriteAccess: boolean;
        name: string;
        ownerEmployerManagerId: number;
    };
    vacanciesInfo?: Record<string, VacanciesInfo>;
    loadingChangeTopicState?: { isLoading: boolean; funnelStageId?: number };
}

interface PayloadTypes {
    [REMOVE_INTERVIEW_REMIND]: { id: string };
    [SET_INTERVIEW_REMIND]: { currentInterview: CurrentInterview };
    [MARK_TOPICS_AS_VIEWED]: { topicIds: string[]; isSubstatesExp?: boolean };
    [DISCARD_CANDIDATES]: { topicIds: string[]; isSubstatesExp?: boolean };
    [CHANGE_TOPIC_STATE]: { topicId: string; collectionToId?: string; isSubstatesExp?: boolean };
    [SET_LOADING_ACTION]: { isLoading: boolean; funnelStageId?: number };
    [SET_CANDIDATES_LIST]: CandidatesList;
    [INVITE_TO_VIDEOCALL]: { topicId: string; isSubstatesExp?: boolean };
    [SET_NOT_APPEARANCE_DATE]: { resumeHash: string };
    [UPDATE_RESUME]: ResumeCardData;
    [UPDATE_SUBSTATES]: { name: string; eventType: 'add' | 'delete' | 'edit'; id: string; parentId: string };
    [UPDATE_SUBSTATE_ORDER]: { rootStageId: string; substatesIds: number[] };
    [UPDATE_SUBSTATES_BY_FUNNEL]: VacancyFunnel;
    [SET_COLLECTION_VISIBILITY]: { collectionName: CollectionNameType; hidden: boolean; isSubstatesExp?: boolean };
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const setCandidatesList = ActionCreator(SET_CANDIDATES_LIST);
export const markTopicsAsViewed = ActionCreator(MARK_TOPICS_AS_VIEWED);
export const discardCandidates = ActionCreator(DISCARD_CANDIDATES);
export const changeTopicState = ActionCreator(CHANGE_TOPIC_STATE);
export const setLoadingAction = ActionCreator(SET_LOADING_ACTION);
export const removeInterviewRemind = ActionCreator(REMOVE_INTERVIEW_REMIND);
export const setInterviewRemind = ActionCreator(SET_INTERVIEW_REMIND);
export const inviteToVideocall = ActionCreator(INVITE_TO_VIDEOCALL);
export const setNotAppearanceDate = ActionCreator(SET_NOT_APPEARANCE_DATE);
export const candidatesListUpdateResume = ActionCreator(UPDATE_RESUME);
export const updateSubstates = ActionCreator(UPDATE_SUBSTATES);
export const updateSubstatesOrder = ActionCreator(UPDATE_SUBSTATE_ORDER);
export const updateSubstatesByFunnel = ActionCreator(UPDATE_SUBSTATES_BY_FUNNEL);
export const setCollectionVisibility = ActionCreator(SET_COLLECTION_VISIBILITY);

const getNewCollectionObject = <T extends CollectionNameType | string>({
    collection,
    newCount = 0,
    moveCount = 0,
    hidden,
}: {
    collection: Collection<T>;
    newCount?: number;
    moveCount?: number;
    hidden?: boolean;
}): Collection<T> => {
    const { newOrUpdated, total, applicantQuestionsCount } = collection.collectionItemCount;

    return {
        ...collection,
        collectionItemCount: {
            newOrUpdated: newOrUpdated > 0 ? newOrUpdated - newCount : newOrUpdated,
            total: total - moveCount,
            applicantQuestionsCount,
        },
        ...(hidden !== undefined && { hidden }),
    };
};

const getNewCollection = <T extends CollectionNameType | string>({
    collections,
    collectionNameFrom,
    collectionNameTo = CollectionName.DiscardByEmployer,
    newCount = 0,
    moveCount = 0,
    hidden,
}: {
    collections: Collection<T>[];
    collectionNameFrom: CollectionNameType;
    collectionNameTo?: CollectionNameType;
    newCount?: number;
    moveCount?: number;
    hidden?: boolean;
}): Collection<T>[] => {
    const collectionFromIndex = collections.findIndex(({ name }) => name === collectionNameFrom);
    const collectionToIndex = moveCount > 0 ? collections.findIndex(({ name }) => name === collectionNameTo) : -1;
    const responsesIndex =
        collectionNameFrom === 'relevant_responses' ? collections.findIndex(({ name }) => name === 'response') : -1;

    return Object.assign([], collections, {
        ...(collectionFromIndex !== -1 && {
            [collectionFromIndex]: getNewCollectionObject({
                collection: collections[collectionFromIndex],
                newCount,
                moveCount,
                hidden,
            }),
        }),
        ...(responsesIndex !== -1 && {
            [responsesIndex]: getNewCollectionObject({
                collection: collections[responsesIndex],
                newCount,
                moveCount,
                hidden,
            }),
        }),
        ...(collectionToIndex !== -1 && {
            [collectionToIndex]: getNewCollectionObject({
                collection: collections[collectionToIndex],
                newCount: 0,
                moveCount: -moveCount,
                hidden,
            }),
        }),
    });
};

const getSubCollectionIndexById = <T extends CollectionNameType | string>(
    subCollections: Collection<T>[],
    id: string
): number => subCollections.findIndex((subCollection) => subCollection.id === id);

const findParentCollectionIndexById = <T extends CollectionNameType | string>(
    collections: Collection<T>[],
    isState: boolean,
    id: string
): number => {
    return isState
        ? collections.findIndex(({ id: parentId }) => parentId === id)
        : collections.findIndex(
              ({ candidatesSubCollections }) => getSubCollectionIndexById(candidatesSubCollections, id) !== -1
          );
};

const getNewCollectionWithSubstates = <T extends CollectionNameType | string>({
    collections,
    collectionFromId,
    collectionToId = CollectionName.DiscardByEmployer,
    newCount,
    moveCount = 0,
    hidden,
}: {
    collections: Collection<T>[];
    collectionFromId: string;
    collectionToId?: string;
    newCount: number;
    moveCount?: number;
    hidden?: boolean;
}): Collection<T>[] => {
    const isCollectionFromState = !SUBSTATE_ENDWITH_REGEXP.exec(collectionFromId);
    const isCollectionToState = !SUBSTATE_ENDWITH_REGEXP.exec(collectionToId);
    if (isCollectionFromState && isCollectionToState) {
        return getNewCollection<T>({
            collections,
            collectionNameFrom: collectionFromId as CollectionNameType,
            collectionNameTo: collectionToId as CollectionNameType,
            newCount,
            moveCount,
            hidden,
        });
    }
    const fromStateIndex = findParentCollectionIndexById(collections, isCollectionFromState, collectionFromId);
    const toStateIndex = findParentCollectionIndexById(collections, isCollectionToState, collectionToId);
    const isSameState = fromStateIndex !== -1 && fromStateIndex === toStateIndex;

    const fromSubcollectionIndex =
        fromStateIndex !== -1 &&
        getSubCollectionIndexById(collections[fromStateIndex].candidatesSubCollections, collectionFromId);
    const toSubcollectionIndex =
        toStateIndex !== -1 &&
        getSubCollectionIndexById(collections[toStateIndex].candidatesSubCollections, collectionToId);
    const responsesIndex =
        collectionFromId === 'relevant_responses' ? collections.findIndex(({ id }) => id === 'response') : -1;

    const newFromCollection =
        fromStateIndex !== -1
            ? getNewCollectionObject({
                  collection: collections[fromStateIndex],
                  newCount,
                  moveCount: isSameState ? 0 : moveCount,
                  hidden,
              })
            : null;
    const newToCollection =
        toStateIndex !== -1
            ? getNewCollectionObject({
                  collection: collections[toStateIndex],
                  newCount: 0,
                  moveCount: isSameState ? 0 : -moveCount,
                  hidden,
              })
            : null;

    const newFromSubcollection =
        newFromCollection && fromSubcollectionIndex && fromSubcollectionIndex !== -1
            ? getNewCollectionObject({
                  collection: newFromCollection.candidatesSubCollections[fromSubcollectionIndex],
                  newCount,
                  moveCount,
                  hidden,
              })
            : null;
    const newToSubcollection =
        newToCollection && toSubcollectionIndex && toSubcollectionIndex !== -1
            ? getNewCollectionObject({
                  collection: newToCollection.candidatesSubCollections[toSubcollectionIndex],
                  newCount: 0,
                  moveCount: -moveCount,
                  hidden,
              })
            : null;

    const newFromCollectionWithNewSubcollection =
        !isSameState && newFromCollection && newFromSubcollection
            ? {
                  ...newFromCollection,
                  candidatesSubCollections: Object.assign([], newFromCollection.candidatesSubCollections, {
                      [fromSubcollectionIndex as number]: newFromSubcollection,
                  }),
              }
            : null;
    const newToCollectionWithNewSubcollection =
        !isSameState && newToCollection && newToSubcollection
            ? {
                  ...newToCollection,
                  candidatesSubCollections: Object.assign([], newToCollection.candidatesSubCollections, {
                      [toSubcollectionIndex as number]: newToSubcollection,
                  }),
              }
            : null;
    const newCollectionWithNewSubcollections =
        isSameState && newFromCollection
            ? {
                  ...newFromCollection,
                  candidatesSubCollections: Object.assign([], newFromCollection.candidatesSubCollections, {
                      [fromSubcollectionIndex as number]: newFromSubcollection,
                      [toSubcollectionIndex as number]: newToSubcollection,
                  }),
              }
            : null;

    return Object.assign([], collections, {
        ...(isSameState
            ? {
                  [fromStateIndex]: newCollectionWithNewSubcollections,
              }
            : {
                  [fromStateIndex]: newFromCollectionWithNewSubcollection || newFromCollection,
                  [toStateIndex]: newToCollectionWithNewSubcollection || newToCollection,
              }),
        ...(responsesIndex !== -1 && {
            [responsesIndex]: getNewCollectionObject({
                collection: collections[responsesIndex],
                newCount,
                moveCount,
                hidden,
            }),
        }),
    });
};

// Т.к. мы можем выбирать топики из подстатусов на корневом статусе, то нам нужно
// понимать реальный id подстатуса/статуса, в котором сейчас топик.
const getCollectionIdByTopics = (state: CandidatesList, topicIds: string[]) => {
    // На всех статусах/подстатусах, кроме response, у нас можно массово только изменять статус.
    // Если это когда-то поменяется, то эта логика будет невалидна, т.к. в
    // корневом статусе можно будет выбрать отклики из нескольких подстатусов.
    if (topicIds.length !== 1) {
        return state.collection.id;
    }
    const { lastFunnelStage } = state.shortTopicList?.topicList[topicIds[0]] ?? {};
    return lastFunnelStage?.rootStage === false
        ? `${lastFunnelStage.employerState.toLowerCase()}_${lastFunnelStage.funnelStageId}`
        : state.collection.id;
};

const getUpdatedTopicInterviewInfo = (state: CandidatesList, id: string | number, value: null | CurrentInterview) => {
    if (!state.topicsNegotiationInterviewInfo.topics) {
        return state;
    }

    return {
        ...state,
        topicsNegotiationInterviewInfo: {
            ...state.topicsNegotiationInterviewInfo,
            topics: {
                ...state.topicsNegotiationInterviewInfo.topics,
                [id]: {
                    ...state.topicsNegotiationInterviewInfo.topics?.[id],
                    currentInterview: value,
                },
            },
        },
    };
};

const makeSubCollection = (id: string, name: string): Collection<string> => ({
    name,
    id,
    candidatesSubCollections: [],
    collectionItemCount: { newOrUpdated: 0, total: 0, applicantQuestionsCount: 0 },
    extraFields: {},
    resumeSettings: [{ type: ResumeSettingType.Folders }],
    actions: [{ type: ActionType.ChangeTopic }],
    filters: [{ type: FilterType.OnlyNew }],
    hidden: false,
});

const updateSubCollectionsByFunnelStage = (funnelStage: VacancyFunnelStage, collection: Collection): Collection => {
    const substates = funnelStage.substates || [];
    const subCollections = collection.candidatesSubCollections;
    const defaultSubCollection = subCollections.find(
        (subCollection) => subCollection.extraFields.EMPLOYER_STATE_WITHOUT_SUBSTATE
    );
    const newSubCollections: Collection<string>[] = defaultSubCollection ? [defaultSubCollection] : [];

    if (substates.length === 0) {
        return { ...collection, candidatesSubCollections: newSubCollections };
    }

    const subCollectionsMap = new Map<string, Collection<string>>(
        subCollections.map((subCollection) => [subCollection.id, subCollection])
    );

    substates.forEach(({ funnelStageId, state, substateName }) => {
        const id = `${state.toLowerCase()}_${funnelStageId}`;
        const subCollection = subCollectionsMap.get(id);
        newSubCollections.push(subCollection || makeSubCollection(id, substateName));
    });

    return { ...collection, candidatesSubCollections: newSubCollections };
};

export default createReducer<CandidatesList | null, PayloadTypes>(null, {
    [MARK_TOPICS_AS_VIEWED]: (state, action) => {
        if (!state) {
            return state;
        }

        const { topicIds, isSubstatesExp = false } = action.payload;
        let readCount = 0;
        const readTopics = topicIds.reduce((acc: TopicList, topicId) => {
            const currentTopic = state.shortTopicList?.topicList[topicId];
            if (currentTopic?.hasNewMessages) {
                readCount += 1;
                acc[topicId] = { ...currentTopic, hasNewMessages: false };
            }
            return acc;
        }, {});

        if (isSubstatesExp) {
            const commonProps = {
                collectionFromId: getCollectionIdByTopics(state, topicIds),
                newCount: readCount,
            };
            return {
                ...state,
                collections: getNewCollectionWithSubstates({
                    collections: state.collections,
                    ...commonProps,
                }),
                enteringCollections: getNewCollectionWithSubstates({
                    collections: state.enteringCollections,
                    ...commonProps,
                }),
                suitableWorkflowCollections: getNewCollectionWithSubstates({
                    collections: state.suitableWorkflowCollections,
                    ...commonProps,
                }),
                unsuitableWorkflowCollections: getNewCollectionWithSubstates({
                    collections: state.unsuitableWorkflowCollections,
                    ...commonProps,
                }),
                ...(state.shortTopicList && {
                    shortTopicList: {
                        ...state.shortTopicList,
                        topicList: {
                            ...state.shortTopicList.topicList,
                            ...readTopics,
                        },
                    },
                }),
            };
        }

        return {
            ...state,
            collections: getNewCollection({
                collections: state.collections,
                collectionNameFrom: state.collection.name,
                newCount: readCount,
            }),
            enteringCollections: getNewCollection({
                collections: state.enteringCollections,
                collectionNameFrom: state.collection.name,
                newCount: readCount,
            }),
            suitableWorkflowCollections: getNewCollection({
                collections: state.suitableWorkflowCollections,
                collectionNameFrom: state.collection.name,
                newCount: readCount,
            }),
            unsuitableWorkflowCollections: getNewCollection({
                collections: state.unsuitableWorkflowCollections,
                collectionNameFrom: state.collection.name,
                newCount: readCount,
            }),
            ...(state.shortTopicList && {
                shortTopicList: {
                    ...state.shortTopicList,
                    topicList: {
                        ...state.shortTopicList.topicList,
                        ...readTopics,
                    },
                },
            }),
        };
    },
    [DISCARD_CANDIDATES]: (state, action) => {
        if (!state) {
            return state;
        }

        const { topicIds, isSubstatesExp = false } = action.payload;
        const newCount = topicIds.reduce((sum, topicId) => {
            const currentTopic = state.shortTopicList?.topicList[topicId];
            if (currentTopic?.hasNewMessages && currentTopic.lastState === 'RESPONSE') {
                return sum + 1;
            }
            return sum;
        }, 0);
        const newList = state.resumes.elementsJson.filter((resume) => !topicIds.includes(resume.topicId));
        if (isSubstatesExp) {
            const commonProps = {
                collectionFromId: getCollectionIdByTopics(state, topicIds),
                newCount,
                moveCount: topicIds.length,
            };
            return {
                ...state,
                collections: getNewCollectionWithSubstates({
                    collections: state.collections,
                    ...commonProps,
                }),
                enteringCollections: getNewCollectionWithSubstates({
                    collections: state.enteringCollections,
                    ...commonProps,
                }),
                suitableWorkflowCollections: getNewCollectionWithSubstates({
                    collections: state.suitableWorkflowCollections,
                    ...commonProps,
                }),
                unsuitableWorkflowCollections: getNewCollectionWithSubstates({
                    collections: state.unsuitableWorkflowCollections,
                    ...commonProps,
                }),
                resumes: {
                    ...state.resumes,
                    elementsJson: newList,
                },
            };
        }
        return {
            ...state,
            collections: getNewCollection({
                collections: state.collections,
                collectionNameFrom: state.collection.name,
                newCount,
                moveCount: topicIds.length,
            }),
            enteringCollections: getNewCollection({
                collections: state.enteringCollections,
                collectionNameFrom: state.collection.name,
                newCount,
                moveCount: topicIds.length,
            }),
            suitableWorkflowCollections: getNewCollection({
                collections: state.suitableWorkflowCollections,
                collectionNameFrom: state.collection.name,
                newCount,
                moveCount: topicIds.length,
            }),
            unsuitableWorkflowCollections: getNewCollection({
                collections: state.unsuitableWorkflowCollections,
                collectionNameFrom: state.collection.name,
                newCount,
                moveCount: topicIds.length,
            }),
            resumes: {
                ...state.resumes,
                elementsJson: newList,
            },
        };
    },
    [CHANGE_TOPIC_STATE]: (state, action) => {
        if (!state) {
            return state;
        }

        const { topicId, collectionToId, isSubstatesExp = false } = action.payload;
        let newCount = 0;
        const currentTopic = state.shortTopicList?.topicList[topicId];
        if (currentTopic?.hasNewMessages) {
            newCount += 1;
        }

        const newList = state.resumes.elementsJson.filter((resume) => resume.topicId !== topicId);
        if (isSubstatesExp) {
            const commonProps = {
                collectionFromId: getCollectionIdByTopics(state, [topicId]),
                collectionToId,
                newCount,
                moveCount: 1,
            };
            return {
                ...state,
                collections: getNewCollectionWithSubstates({
                    collections: state.collections,
                    ...commonProps,
                }),
                enteringCollections: getNewCollectionWithSubstates({
                    collections: state.enteringCollections,
                    ...commonProps,
                }),
                suitableWorkflowCollections: getNewCollectionWithSubstates({
                    collections: state.suitableWorkflowCollections,
                    ...commonProps,
                }),
                unsuitableWorkflowCollections: getNewCollectionWithSubstates({
                    collections: state.unsuitableWorkflowCollections,
                    ...commonProps,
                }),
                resumes: {
                    ...state.resumes,
                    elementsJson: newList,
                },
            };
        }
        return {
            ...state,
            collections: getNewCollection({
                collections: state.collections,
                collectionNameFrom: state.collection.name,
                newCount,
                moveCount: 1,
            }),
            enteringCollections: getNewCollection({
                collections: state.enteringCollections,
                collectionNameFrom: state.collection.name,
                newCount,
                moveCount: 1,
            }),
            suitableWorkflowCollections: getNewCollection({
                collections: state.suitableWorkflowCollections,
                collectionNameFrom: state.collection.name,
                newCount,
                moveCount: 1,
            }),
            unsuitableWorkflowCollections: getNewCollection({
                collections: state.unsuitableWorkflowCollections,
                collectionNameFrom: state.collection.name,
                newCount,
                moveCount: 1,
            }),
            resumes: {
                ...state.resumes,
                elementsJson: newList,
            },
        };
    },
    [SET_LOADING_ACTION]: (state, { payload }) => {
        if (!state) {
            return state;
        }

        return {
            ...state,
            loadingChangeTopicState: { isLoading: payload.isLoading, funnelStageId: payload.funnelStageId },
        };
    },
    [SET_CANDIDATES_LIST]: (_, action) => action.payload,
    [REMOVE_INTERVIEW_REMIND]: (state, action) => {
        if (!state) {
            return state;
        }

        return getUpdatedTopicInterviewInfo(state, action.payload.id, null);
    },
    [SET_INTERVIEW_REMIND]: (state, action) => {
        if (!state) {
            return state;
        }

        return getUpdatedTopicInterviewInfo(
            state,
            action.payload.currentInterview.topicId,
            action.payload.currentInterview
        );
    },
    [INVITE_TO_VIDEOCALL]: (state, action) => {
        if (!state) {
            return state;
        }

        const { topicId, isSubstatesExp = false } = action.payload;
        if (state.collection.name === CollectionName.Interview) {
            return state;
        }
        const newList = state.resumes.elementsJson.filter((resume) => topicId !== resume.topicId);
        const currentTopic = state.shortTopicList?.topicList[topicId];

        if (isSubstatesExp) {
            const commonProps = {
                collectionFromId: getCollectionIdByTopics(state, [topicId]),
                collectionToId: CollectionName.Interview,
                newCount: currentTopic?.hasNewMessages && currentTopic.lastState === 'RESPONSE' ? 1 : 0,
                moveCount: 1,
            };
            return {
                ...state,
                collections: getNewCollectionWithSubstates({
                    collections: state.collections,
                    ...commonProps,
                }),
                enteringCollections: getNewCollectionWithSubstates({
                    collections: state.enteringCollections,
                    ...commonProps,
                }),
                suitableWorkflowCollections: getNewCollectionWithSubstates({
                    collections: state.suitableWorkflowCollections,
                    ...commonProps,
                }),
                unsuitableWorkflowCollections: getNewCollectionWithSubstates({
                    collections: state.unsuitableWorkflowCollections,
                    ...commonProps,
                }),
                resumes: {
                    ...state.resumes,
                    elementsJson: newList,
                },
            };
        }
        return {
            ...state,
            collections: getNewCollection({
                collections: state.collections,
                collectionNameFrom: state.collection.name,
                collectionNameTo: CollectionName.Interview,
                newCount: currentTopic?.hasNewMessages && currentTopic.lastState === 'RESPONSE' ? 1 : 0,
                moveCount: 1,
            }),
            enteringCollections: getNewCollection({
                collections: state.enteringCollections,
                collectionNameFrom: state.collection.name,
                collectionNameTo: CollectionName.Interview,
                newCount: currentTopic?.hasNewMessages && currentTopic.lastState === 'RESPONSE' ? 1 : 0,
                moveCount: 1,
            }),
            suitableWorkflowCollections: getNewCollection({
                collections: state.suitableWorkflowCollections,
                collectionNameFrom: state.collection.name,
                collectionNameTo: CollectionName.Interview,
                newCount: currentTopic?.hasNewMessages && currentTopic.lastState === 'RESPONSE' ? 1 : 0,
                moveCount: 1,
            }),
            unsuitableWorkflowCollections: getNewCollection({
                collections: state.unsuitableWorkflowCollections,
                collectionNameFrom: state.collection.name,
                collectionNameTo: CollectionName.Interview,
                newCount: currentTopic?.hasNewMessages && currentTopic.lastState === 'RESPONSE' ? 1 : 0,
                moveCount: 1,
            }),
            resumes: {
                ...state.resumes,
                elementsJson: newList,
            },
        };
    },
    [SET_NOT_APPEARANCE_DATE]: (state, action) => {
        if (!state) {
            return state;
        }

        const { resumeHash } = action.payload;

        const newList = state.resumes.elementsJson.map((resume) =>
            resumeHash === resume._attributes.hash
                ? { ...resume, lastNonappearanceComplaintDate: new Date().toString() }
                : resume
        );
        return {
            ...state,
            resumes: {
                ...state.resumes,
                elementsJson: newList,
            },
        };
    },
    [UPDATE_RESUME]: (state, action) => {
        if (!state) {
            return state;
        }

        const idx = state.resumes.elementsJson.findIndex(
            ({ _attributes }) => _attributes.id === action.payload._attributes.id
        );
        if (idx < 0) {
            return state;
        }
        const elementsJson = [...state.resumes.elementsJson];
        elementsJson.splice(idx, 1, action.payload);

        return {
            ...state,
            resumes: {
                ...state.resumes,
                elementsJson,
            },
        };
    },
    [UPDATE_SUBSTATES]: (state, action) => {
        if (!state) {
            return state;
        }

        const { eventType, id, name, parentId } = action.payload;
        const getUpdatedCollection = <T extends Collection>(collections: T[]) => {
            const parentIndex = collections.findIndex(({ id }) => id === parentId);
            if (parentIndex === -1) {
                return collections;
            }

            if (eventType === 'add') {
                return Object.assign([], collections, {
                    [parentIndex]: {
                        ...collections[parentIndex],
                        candidatesSubCollections: [
                            ...collections[parentIndex].candidatesSubCollections,
                            makeSubCollection(id, name),
                        ],
                    },
                });
            }

            if (eventType === 'delete') {
                return Object.assign([], collections, {
                    [parentIndex]: {
                        ...collections[parentIndex],
                        candidatesSubCollections: collections[parentIndex].candidatesSubCollections.filter(
                            (subcollection) => subcollection.id !== id
                        ),
                    },
                });
            }

            if (eventType === 'edit') {
                const subcollectionIndex = collections[parentIndex].candidatesSubCollections.findIndex(
                    (subcollection) => subcollection.id === id
                );
                if (subcollectionIndex === -1) {
                    return collections;
                }
                return Object.assign([], collections, {
                    [parentIndex]: {
                        ...collections[parentIndex],
                        candidatesSubCollections: Object.assign([], collections[parentIndex].candidatesSubCollections, {
                            [subcollectionIndex]: {
                                ...collections[parentIndex].candidatesSubCollections[subcollectionIndex],
                                name,
                            },
                        }),
                    },
                });
            }

            return collections;
        };

        return {
            ...state,
            collections: getUpdatedCollection(state.collections),
            suitableWorkflowCollections: getUpdatedCollection(state.suitableWorkflowCollections),
            unsuitableWorkflowCollections: getUpdatedCollection(state.unsuitableWorkflowCollections),
        };
    },
    [UPDATE_SUBSTATE_ORDER]: (state, action) => {
        if (!state) {
            return state;
        }

        const { rootStageId, substatesIds } = action.payload;
        const getUpdatedCollection = <T extends Collection>(collections: T[]) => {
            const collectionIndex = collections.findIndex(({ id }) => id === rootStageId);
            if (collectionIndex === -1) {
                return collections;
            }
            const currentCollection = collections[collectionIndex];
            return Object.assign([], collections, {
                [collectionIndex]: {
                    ...currentCollection,
                    candidatesSubCollections: [
                        currentCollection.candidatesSubCollections.find(
                            (subcollection) => subcollection.extraFields.EMPLOYER_STATE_WITHOUT_SUBSTATE
                        ),
                        ...substatesIds.map((substateId) =>
                            currentCollection.candidatesSubCollections.find(
                                ({ id }) => id === `${rootStageId}_${substateId}`
                            )
                        ),
                    ].filter(Boolean),
                },
            });
        };
        return {
            ...state,
            collections: getUpdatedCollection(state.collections),
            suitableWorkflowCollections: getUpdatedCollection(state.suitableWorkflowCollections),
            unsuitableWorkflowCollections: getUpdatedCollection(state.unsuitableWorkflowCollections),
        };
    },
    [UPDATE_SUBSTATES_BY_FUNNEL]: (state, action) => {
        if (!state) {
            return state;
        }

        const funnelStages = action.payload.stages;

        if (!funnelStages || funnelStages.length === 0) {
            return state;
        }

        let newCurrentCollection: Collection = state.collection;

        const funnelStagesMap = new Map<CollectionNameType, VacancyFunnelStage>(
            funnelStages.map((stage) => [stage.state.toLowerCase() as CollectionNameType, stage])
        );
        const newCollectionsMap = new Map<CollectionNameType, Collection>(
            state.collections.map((collection) => {
                const funnelStage = funnelStagesMap.get(collection.name);

                if (!funnelStage) {
                    return [collection.name, collection];
                }

                const newCollection = updateSubCollectionsByFunnelStage(funnelStage, collection);

                if (newCollection.name === state.collection.name) {
                    newCurrentCollection = newCollection;
                }

                return [collection.name, newCollection];
            })
        );

        const getNewCollection = <T extends Collection>(collection: T): T => {
            const newCollection = newCollectionsMap.get(collection.name);
            return (newCollection as T) || collection;
        };

        return {
            ...state,
            collection: newCurrentCollection,
            collections: [...newCollectionsMap.values()],
            enteringCollections: state.enteringCollections.map(getNewCollection),
            suitableWorkflowCollections: state.suitableWorkflowCollections.map(getNewCollection),
            unsuitableWorkflowCollections: state.unsuitableWorkflowCollections.map(getNewCollection),
        };
    },
    [SET_COLLECTION_VISIBILITY]: (state, action) => {
        if (!state) {
            return state;
        }

        const { collectionName: collectionNameFrom, hidden } = action.payload;
        const commonParams = { collectionNameFrom, hidden };

        return {
            ...state,
            collection:
                state.collection.name === collectionNameFrom
                    ? getNewCollectionObject({ collection: state.collection, hidden })
                    : state.collection,
            collections: getNewCollection({
                collections: state.collections,
                ...commonParams,
            }),
            enteringCollections: getNewCollection({
                collections: state.enteringCollections,
                ...commonParams,
            }),
            suitableWorkflowCollections: getNewCollection({
                collections: state.suitableWorkflowCollections,
                ...commonParams,
            }),
            unsuitableWorkflowCollections: getNewCollection({
                collections: state.unsuitableWorkflowCollections,
                ...commonParams,
            }),
        };
    },
});
