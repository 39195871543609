import { useEffect, useState } from 'react';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import startOfHour from 'date-fns/startOfHour';
import startOfToday from 'date-fns/startOfToday';
import startOfYesterday from 'date-fns/startOfYesterday';
import sub from 'date-fns/sub';

import format from 'bloko/common/format';

import formatDate from 'Modules/formatDate';

import Strings from 'Utils/Strings';

const ONLINE_PERIOD_IN_MINUTES = 15;

export enum OnlineStatus {
    Online = 'ONLINE',
    Offline = 'OFFLINE',
    Unknown = 'UNKNOWN',
}

interface DatePeriodTrls {
    online?: string;
    withinMinutes?: string[];
    today: string;
    yesterday: string;
    withinWeek: string[];
    weekAgo: string;
    withinTwoWeeks: string;
    moreThanTwoWeeks: string;
}

export const formatStringDate = (isoDateString: string, trls: DatePeriodTrls, withTime = true): string => {
    const date = parseISO(isoDateString);
    if (!isValid(date)) {
        return trls.moreThanTwoWeeks;
    }

    const now = new Date();

    if (withTime) {
        const minutesSinceActivity = differenceInMinutes(now, date);
        if (trls.online && minutesSinceActivity < ONLINE_PERIOD_IN_MINUTES) {
            return trls.online;
        }

        if (trls.withinMinutes && isAfter(date, startOfHour(now))) {
            const trl = Strings.pluralize(minutesSinceActivity, trls.withinMinutes);
            return format(trl, { '{0}': minutesSinceActivity.toString() });
        }
    }

    if (isAfter(date, startOfToday())) {
        return withTime ? format(trls.today, { '{0}': formatDate(date, 'HH:mm') }) : trls.today;
    }

    if (isAfter(date, startOfYesterday())) {
        return withTime ? format(trls.yesterday, { '{0}': formatDate(date, 'HH:mm') }) : trls.yesterday;
    }

    if (isAfter(date, startOfDay(sub(now, { days: 6 })))) {
        return trls.withinWeek[date.getDay()];
    }

    if (isAfter(date, startOfDay(sub(now, { weeks: 1 })))) {
        return trls.weekAgo;
    }

    if (isAfter(date, startOfDay(sub(now, { weeks: 2 })))) {
        return trls.withinTwoWeeks;
    }

    return trls.moreThanTwoWeeks;
};

/**
 * При использовании в рендер-функции может вызвать ошибку гидрации.
 * В реакт-компонентах лучше использовать хук useOnlineStatus
 */
export const getOnlineStatus = (isoDateString: string): OnlineStatus => {
    const date = parseISO(isoDateString);
    if (!isValid(date)) {
        return OnlineStatus.Unknown;
    }

    const minutesSinceActivity = differenceInMinutes(new Date(), date);
    if (minutesSinceActivity < ONLINE_PERIOD_IN_MINUTES) {
        return OnlineStatus.Online;
    }

    return OnlineStatus.Offline;
};

export const useOnlineStatus = (isoDateString: string): OnlineStatus => {
    const [status, setStatus] = useState<OnlineStatus>(OnlineStatus.Unknown);

    useEffect(() => {
        setStatus(getOnlineStatus(isoDateString));
    }, [isoDateString]);

    return status;
};

export enum PastPeriod {
    FewDays = 'FEW_DAYS',
    Week = 'WEEK',
    Later = 'LATER',
}

const FEW_DAYS_IN_MSECONDS = 2 * 24 * 60 * 60 * 1000;
const WEEK_IN_MSECONDS = 7 * 24 * 60 * 60 * 1000;

export const getPastPeriod = (date: number | string): PastPeriod => {
    const currentDate = new Date();
    const lastChangeDate = new Date(date);

    const pastPeriod = currentDate.valueOf() - lastChangeDate.valueOf();

    if (pastPeriod < FEW_DAYS_IN_MSECONDS) {
        return PastPeriod.FewDays;
    }

    if (pastPeriod < WEEK_IN_MSECONDS) {
        return PastPeriod.Week;
    }

    return PastPeriod.Later;
};
