import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { ClusterKey } from 'src/types/search/common/clusters';

export type OrderedClusterMap = Record<ClusterKey, Array<string>>;

export const SEARCH_CLUSTERS_ORDER_INITIAL_STATE = Object.values(ClusterKey).reduce((result, item) => {
    result[item] = [];
    return result;
}, {} as OrderedClusterMap);

const SET_SEARCH_CLUSTERS_ORDER = 'SET_SEARCH_CLUSTERS_ORDER';

interface PayloadTypes {
    [SET_SEARCH_CLUSTERS_ORDER]: Partial<OrderedClusterMap>;
}

const actionHelper = ActionCreatorHelper<PayloadTypes>();

export const setSearchClustersOrder = actionHelper(SET_SEARCH_CLUSTERS_ORDER);

export default createReducer<OrderedClusterMap, PayloadTypes>(SEARCH_CLUSTERS_ORDER_INITIAL_STATE, {
    [SET_SEARCH_CLUSTERS_ORDER]: (state, { payload }) => ({ ...state, ...payload }),
});
